import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import classes from './index.module.scss';
import TimeField from './TimeField';
import { Day, Time, WorkingHours } from './types';

interface Props {
	day: Day;
	start?: Time;
	end?: Time;
	disabled?: boolean;
	prefix?: boolean;
	onChange?: ({ start, end }: WorkingHours) => any;
}

const WorkingHourInputField = ({
	day,
	start = { min: '00', hr: '09', pm: false },
	end = { min: '00', hr: '09', pm: true },
	disabled,
	prefix = false,
	onChange,
}: Props) => {
	const [startTime, setStartTime] = useState<Time>(start);
	const [endTime, setEndTime] = useState<Time>(end);

	useEffect(() => {
		// console.log(startTime);
		onChange && onChange({ start: startTime, end: endTime });
	}, [startTime, endTime]);

	return (
		<div className={classes['workingHourField']}>
			<Typography className={classes['workingHourField-day']}>{day}</Typography>
			<div className={classes['workingHourField-fields']}>
				<TimeField prefix={prefix} {...start} onChange={(time) => setStartTime(time)} disabled={disabled} />
				<TimeField
					prefix={prefix}
					{...end}
					onChange={(time) => {
						console.log(time);
						setEndTime(time);
					}}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default WorkingHourInputField;
