import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	AccruedInvoiceInput,
	ApiResponse,
	AuthData,
	AvatarResponse,
	BuddyPunchEmployee,
	Conversation,
	CurrentUserUpdateInput,
	CustomCost,
	CustomCostInput,
	Employee,
	EmployeeCreateInput,
	EmployeeUpdateInput,
	Hospital,
	HospitalInput,
	HospitalService,
	HospitalServiceCreateInput,
	HospitalServiceUpdateInput,
	Inventory,
	InventoryInput,
	Invoice,
	InvoicePDF,
	Job,
	JobFile,
	JobInput,
	Message,
	Nurse,
	NurseCreateInput,
	NurseUpdateInput,
	OK,
	Service,
	ServiceInput,
	Setting,
	SettingInput,
	UnvoidItemsInput,
	User,
	UserCreateInput,
	UserUpdateInput,
	Vendor,
	VendorInput,
	VoidItemsInput,
} from 'types';
import routes from './routes';
import { BuddyPunchLocation } from '../../types/entity';

type Path = 'admin' | 'user';

class Api {
	private readonly BASE_URL: string = process.env.REACT_APP_API_URL!;
	private readonly pub: AxiosInstance;
	private readonly _auth: AxiosInstance;

	constructor() {
		const config: AxiosRequestConfig = { baseURL: `${this.BASE_URL}/api/v1` };

		const interceptorResponseFn = (response: AxiosResponse<any>): AxiosResponse<any> => response.data;

		const interceptorErrorFn = (error: any) => {
			const status = error.response.status;
			const message = status === 401 ? 'Unauthorized' : error.response.data.errors[0].msg;

			return Promise.reject({ status, message });
		};

		this.pub = axios.create(config);
		this.pub.interceptors.response.use(interceptorResponseFn, interceptorErrorFn);

		this._auth = axios.create(config);
		this._auth.interceptors.request.use(
			(config) => {
				const auth = window.localStorage.getItem('auth');

				if (auth) {
					const { accessToken } = JSON.parse(auth) as AuthData;
					config.headers.Authorization = `Bearer ${accessToken}`;
				}
				return config;
			},
			(err) => Promise.reject(err)
		);
		this._auth.interceptors.response.use(interceptorResponseFn, interceptorErrorFn);
	}

	//* Public getters for accessing Api methods in a form of a collection
	public get auth() {
		return {
			signin: this.getAccessToken,
			signout: this.signout,
		};
	}

	public get upload() {
		return {
			avatar: this.uploadAvatar,
			document: this.uploadDocument,
		};
	}

	public get user() {
		return {
			profile: this.getCurrentUser,
			update: this.updateCurrentUser,
		};
	}

	public get users() {
		return {
			getOne: this.getUserById,
			getAll: this.getAllUsers,
			create: this.addUser,
			update: this.updateUser,
			delete: this.deleteAdmin,
			toggleSuspend: this.toggleUserSuspend,
		};
	}

	public get nurses() {
		return {
			getBuddyPunchEmployee: this.getBuddyPunchEmployee,
			getOne: this.getNurse,
			getAll: this.getAllNurses,
			create: this.addNurse,
			update: this.updateNurse,
			delete: this.deleteNurse,
		};
	}

	public get vendors() {
		return {
			getOne: this.getVendor,
			getAll: this.getAllVendors,
			create: this.addVendor,
			update: this.updateVendor,
			delete: this.deleteVendor,
		};
	}

	public get inventory() {
		return {
			getOne: this.getItem,
			getAll: this.getAllItems,
			create: this.addItem,
			update: this.updateItem,
			delete: this.deleteItem,
		};
	}

	public get hospitals() {
		return {
			getBuddyPunchLocation: this.getBuddyPunchLocation,
			getOne: this.getHospital,
			getAll: this.getAllHospitals,
			create: this.addHospital,
			update: this.updateHospital,
			delete: this.deleteHospital,
			vendors: {
				restrict: this.restrictVendors,
				unrestrict: this.unrestrictVendors,
				listRestricted: this.listRestrictedVendors,
			},
			inventory: {
				restrict: this.restrictInventory,
				unrestrict: this.unrestrictInventory,
				listRestricted: this.listRestrictedInventory,
			},
			services: {
				getAll: this.listAllHospitalServices,
				store: this.storeHospitalService,
				update: this.updateHospitalService,
				delete: this.deleteHospitalService,
			},
		};
	}

	public get employees() {
		return {
			getOne: this.getEmployeeById,
			getAll: this.getAllEmployees,
			create: this.addEmployee,
			update: this.updateEmployee,
			delete: this.deleteEmployee,
		};
	}

	public get jobs() {
		return {
			getOne: this.getJob,
			getAll: this.getAllJobs,
			getForHospital: this.getAllJobsByHospital,
			create: this.addJob,
			update: this.updateJob,
			updateStatus: this.updateJobStatus,
			delete: this.deleteJob,
			deleteDocument: this.removeDocument,
			notes: {
				create: this.addNote,
				delete: this.deleteNote,
			},
		};
	}

	public get invoices() {
		return {
			getOne: this.getInvoice,
			getAll: this.getAllInvoices,
			getForHospital: this.getInvoicesForHospital,
			generate: this.generatePDF,
			generateAccrued: this.generateAccruedPDFs,
			send: this.sendPDF,
			void: this.voidInvoice,
			voidItems: this.voidInvoiceItems,
			unvoid: this.unvoidInvoice,
			unvoidItems: this.unvoidInvoiceItems,
		};
	}

	public get conversations() {
		return {
			getOne: this.getConversationById,
			getAll: this.getAllConversations,
			getMessages: this.getConversationMessages,
		};
	}

	public get services() {
		return {
			getOne: this.getService,
			getAll: this.getAllServices,
			create: this.addService,
			update: this.updateService,
			delete: this.deleteService,
		};
	}

	public get customCosts() {
		return {
			getOne: this.getCustomCost,
			getAll: this.getAllCustomCosts,
			create: this.addCustomCost,
			update: this.updateCustomCost,
			delete: this.deleteCustomCost,
		};
	}

	public get settings() {
		return {
			getAll: this.getAllSettings,
			update: this.updateSettings,
		};
	}

	// Auth methods
	private getAccessToken = async (uniqueId: string): Promise<ApiResponse<AuthData>> => {
		try {
			const data = await this.pub.post<{ uniqueId: string }, AuthData>(routes.AUTH_CALLBACK, { uniqueId });
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private signout = async (): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.get<any, OK>(routes.SIGN_OUT);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// User methods
	private getCurrentUser = async (): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.get<any, User>(routes.USER);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateCurrentUser = async (input: CurrentUserUpdateInput): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.put<CurrentUserUpdateInput, User>(routes.USER, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private uploadAvatar = async (id: number, file: File): Promise<ApiResponse<AvatarResponse>> => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'avatar');

			const data = await this._auth.post<FormData, AvatarResponse>(routes.UPLOAD_ID(id), formData);

			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getAllUsers = async (path: Path = 'user'): Promise<ApiResponse<User[]>> => {
		try {
			const data = await this._auth.get<any, User[]>(path === 'user' ? routes.USER_LIST : routes.ADMIN);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getUserById = async (id: number, path: Path = 'user'): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.get<any, User>(path === 'user' ? routes.USER_ID(id) : routes.ADMIN_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addUser = async (input: UserCreateInput, path: Path = 'user'): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<UserCreateInput, OK>(
				path === 'user' ? routes.USER : routes.ADMIN,
				input
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateUser = async (
		id: number,
		input: UserUpdateInput,
		path: Path = 'user'
	): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.put<UserUpdateInput, User>(
				path === 'user' ? routes.USER_ID(id) : routes.ADMIN_ID(id),
				input
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private toggleUserSuspend = async (id: number, path: Path = 'user'): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<any, OK>(
				path === 'user' ? routes.USER_SUSPEND(id) : routes.ADMIN_SUSPEND(id)
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteAdmin = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.ADMIN_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Nurse methods
	private addNurse = async (input: NurseCreateInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<NurseCreateInput, OK>(routes.NURSE, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateNurse = async (id: number, input: NurseUpdateInput): Promise<ApiResponse<Nurse>> => {
		try {
			const data = await this._auth.put<NurseUpdateInput, Nurse>(routes.NURSE_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteNurse = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.NURSE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getBuddyPunchEmployee = async (): Promise<ApiResponse<BuddyPunchEmployee[]>> => {
		try {
			const data = await this._auth.get<any, BuddyPunchEmployee[]>(routes.NURSE_BUDDY_PUNCH);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getNurse = async (id: number): Promise<ApiResponse<Nurse>> => {
		try {
			const data = await this._auth.get<any, Nurse>(routes.NURSE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getAllNurses = async (): Promise<ApiResponse<Nurse[]>> => {
		try {
			const data = await this._auth.get<any, Nurse[]>(routes.NURSE);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Vendor methods
	private addVendor = async (input: VendorInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<VendorInput, OK>(routes.VENDOR, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateVendor = async (id: number, input: VendorInput): Promise<ApiResponse<Vendor>> => {
		try {
			const data = await this._auth.put<VendorInput, Vendor>(routes.VENDOR_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteVendor = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.VENDOR_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getVendor = async (id: number): Promise<ApiResponse<Vendor>> => {
		try {
			const data = await this._auth.get<any, Vendor>(routes.VENDOR_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getAllVendors = async (): Promise<ApiResponse<Vendor[]>> => {
		try {
			const data = await this._auth.get<any, Vendor[]>(routes.VENDOR);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Inventory methods
	private addItem = async (input: InventoryInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<InventoryInput, OK>(routes.INVENTORY, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateItem = async (id: number, input: InventoryInput): Promise<ApiResponse<Inventory>> => {
		try {
			const data = await this._auth.put<InventoryInput, Inventory>(routes.INVENTORY_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteItem = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.INVENTORY_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getItem = async (id: number): Promise<ApiResponse<Inventory>> => {
		try {
			const data = await this._auth.get<any, Inventory>(routes.INVENTORY_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getAllItems = async (): Promise<ApiResponse<Inventory[]>> => {
		try {
			const data = await this._auth.get<any, Inventory[]>(routes.INVENTORY);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Hospital Methods
	private getAllHospitals = async (): Promise<ApiResponse<Hospital[]>> => {
		try {
			const data = await this._auth.get<any, Hospital[]>(routes.HOSPITAL);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getBuddyPunchLocation = async (): Promise<ApiResponse<BuddyPunchLocation[]>> => {
		try {
			const data = await this._auth.get<any, BuddyPunchLocation[]>(routes.HOSPITAL_BUDDY_PUNCH);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getHospital = async (id: number): Promise<ApiResponse<Hospital>> => {
		try {
			const data = await this._auth.get<any, Hospital>(routes.HOSPITAL_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addHospital = async (input: HospitalInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<HospitalInput, OK>(routes.HOSPITAL, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateHospital = async (id: number, input: HospitalInput): Promise<ApiResponse<Hospital>> => {
		try {
			const data = await this._auth.put<HospitalInput, Hospital>(routes.HOSPITAL_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteHospital = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.HOSPITAL_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private restrictVendors = async (hospitalId: number, vendors: number[]): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ vendors: number[] }, OK>(routes.HOSPITAL_VENDOR_RESTRICT(hospitalId), {
				vendors,
			});
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private unrestrictVendors = async (hospitalId: number, vendors: number[]): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ vendors: number[] }, OK>(
				routes.HOSPITAL_VENDOR_UNRESTRICT(hospitalId),
				{
					vendors,
				}
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private listRestrictedVendors = async (hospitalId: number): Promise<ApiResponse<number[]>> => {
		try {
			const data = await this._auth.get<any, number[]>(routes.HOSPITAL_VENDOR_LIST_RESTRICTED(hospitalId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private restrictInventory = async (hospitalId: number, inventory: number[]): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ vendors: number[] }, OK>(
				routes.HOSPITAL_INVENTORY_RESTRICT(hospitalId),
				{
					inventory,
				}
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private unrestrictInventory = async (hospitalId: number, inventory: number[]): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ inventory: number[] }, OK>(
				routes.HOSPITAL_INVENTORY_UNRESTRICT(hospitalId),
				{
					inventory,
				}
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private listRestrictedInventory = async (hospitalId: number): Promise<ApiResponse<number[]>> => {
		try {
			const data = await this._auth.get<any, number[]>(routes.HOSPITAL_INVENTORY_LIST_RESTRICTED(hospitalId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private listAllHospitalServices = async (hospitalId: number): Promise<ApiResponse<Array<HospitalService>>> => {
		try {
			const data = await this._auth.get<any, Array<HospitalService>>(routes.HOSPITAL_SERVICES(hospitalId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private storeHospitalService = async (
		hospitalId: number,
		input: HospitalServiceCreateInput
	): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<HospitalServiceCreateInput, OK>(
				routes.HOSPITAL_SERVICE_STORE(hospitalId),
				input
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateHospitalService = async (
		hospitalId: number,
		input: HospitalServiceUpdateInput
	): Promise<ApiResponse<HospitalService>> => {
		try {
			const data = await this._auth.put<HospitalServiceUpdateInput, HospitalService>(
				routes.HOSPITAL_SERVICE_UPDATE(hospitalId),
				input
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteHospitalService = async (hospitalId: number, ids: Array<number>): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<any, OK>(routes.HOSPITAL_SERVICE_DELETE(hospitalId), {
				ids,
			});
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Hospital Employee Methods
	private getAllEmployees = async (hospitalId: number): Promise<ApiResponse<Employee[]>> => {
		try {
			const data = await this._auth.get<any, Employee[]>(routes.HOSPITAL_EMPLOYEE(hospitalId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getEmployeeById = async (hospitalId: number, employeeId: number): Promise<ApiResponse<Employee>> => {
		try {
			const data = await this._auth.get<any, Employee>(routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addEmployee = async (hospitalId: number, input: EmployeeCreateInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<EmployeeCreateInput, OK>(routes.HOSPITAL_EMPLOYEE(hospitalId), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateEmployee = async (
		hospitalId: number,
		employeeId: number,
		input: EmployeeUpdateInput
	): Promise<ApiResponse<Employee>> => {
		try {
			const data = await this._auth.put<EmployeeUpdateInput, Employee>(
				routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId),
				input
			);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteEmployee = async (hospitalId: number, employeeId: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Job Methods
	private getAllJobs = async (): Promise<ApiResponse<Job[]>> => {
		try {
			const data = await this._auth.get<any, Job[]>(routes.JOB);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getAllJobsByHospital = async (hospitalId: number): Promise<ApiResponse<Job[]>> => {
		try {
			const data = await this._auth.get<any, Job[]>(routes.HOSPITAL_JOBS(hospitalId));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getJob = async (id: number): Promise<ApiResponse<Job>> => {
		try {
			const data = await this._auth.get<any, Job>(routes.JOB_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addJob = async (input: JobInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<JobInput, OK>(routes.JOB, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateJob = async (id: number, input: JobInput): Promise<ApiResponse<Job>> => {
		try {
			const data = await this._auth.put<JobInput, Job>(routes.JOB_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private uploadDocument = async (jobId: number, file: File): Promise<ApiResponse<JobFile>> => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'document');

			const data = await this._auth.post<FormData, JobFile>(routes.DOCUMENT_ID(jobId), formData);

			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private removeDocument = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.DOCUMENT_DELETE_ID(id));

			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateJobStatus = async (id: number, status: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ status: number }, OK>(routes.JOB_STATUS(id), { status });
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteJob = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.JOB_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addNote = async (id: number, message: string): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<{ message: string }, OK>(routes.JOB_NOTE_ID(id), { message });
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteNote = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.JOB_NOTE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Invoice methods
	private getAllInvoices = async (): Promise<ApiResponse<Invoice[]>> => {
		try {
			const data = await this._auth.get<any, Invoice[]>(routes.INVOICE);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getInvoicesForHospital = async (id: number): Promise<ApiResponse<Invoice[]>> => {
		try {
			const data = await this._auth.get<any, Invoice[]>(routes.HOSPITAL_INVOICES(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getInvoice = async (id: number): Promise<ApiResponse<Invoice>> => {
		try {
			const data = await this._auth.get<any, Invoice>(routes.INVOICE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private voidInvoice = async (id: number, reason: string): Promise<ApiResponse<Invoice>> => {
		try {
			const data = await this._auth.post<{ reason: string }, Invoice>(routes.INVOICE_VOID(id), { reason });
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private voidInvoiceItems = async (id: number, input: VoidItemsInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<VoidItemsInput, OK>(routes.INVOICE_VOID_ITEMS(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private unvoidInvoice = async (id: number): Promise<ApiResponse<Invoice>> => {
		try {
			const data = await this._auth.post<any, Invoice>(routes.INVOICE_UNVOID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private unvoidInvoiceItems = async (id: number, input: UnvoidItemsInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<UnvoidItemsInput, OK>(routes.INVOICE_UNVOID_ITEMS(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private generatePDF = async (id: number): Promise<ApiResponse<InvoicePDF>> => {
		try {
			const data = await this._auth.get<any, InvoicePDF>(routes.INVOICE_GENERATE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private sendPDF = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.get<any, OK>(routes.INVOICE_SEND_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private generateAccruedPDFs = async (input: AccruedInvoiceInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<AccruedInvoiceInput, OK>(routes.INVOICE_GENERATE_ACCRUE, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Conversation methods
	private getAllConversations = async (): Promise<ApiResponse<Conversation[]>> => {
		try {
			const data = await this._auth.get<any, Conversation[]>(routes.CONVERSATION);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getConversationById = async (id: number): Promise<ApiResponse<Conversation>> => {
		try {
			const data = await this._auth.get<any, Conversation>(routes.CONVERSATION_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getConversationMessages = async (id: number): Promise<ApiResponse<Message[]>> => {
		try {
			const data = await this._auth.get<any, Message[]>(routes.MESSAGE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Service methods
	private getAllServices = async (): Promise<ApiResponse<Service[]>> => {
		try {
			const data = await this._auth.get<any, Service[]>(routes.SERVICE);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getService = async (id: number): Promise<ApiResponse<Service>> => {
		try {
			const data = await this._auth.get<any, Service>(routes.SERVICE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addService = async (input: ServiceInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<ServiceInput, OK>(routes.SERVICE, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateService = async (id: number, input: ServiceInput): Promise<ApiResponse<Service>> => {
		try {
			const data = await this._auth.put<ServiceInput, Service>(routes.SERVICE_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteService = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.SERVICE_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Custom Cost methods
	private getAllCustomCosts = async (): Promise<ApiResponse<CustomCost[]>> => {
		try {
			const data = await this._auth.get<any, CustomCost[]>(routes.CUSTOM_COST);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private getCustomCost = async (id: number): Promise<ApiResponse<CustomCost>> => {
		try {
			const data = await this._auth.get<any, CustomCost>(routes.CUSTOM_COST_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private addCustomCost = async (input: CustomCostInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<CustomCostInput, OK>(routes.CUSTOM_COST, input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateCustomCost = async (id: number, input: CustomCostInput): Promise<ApiResponse<CustomCost>> => {
		try {
			const data = await this._auth.put<CustomCostInput, CustomCost>(routes.CUSTOM_COST_ID(id), input);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private deleteCustomCost = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.CUSTOM_COST_ID(id));
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	// Settings methods
	private getAllSettings = async (): Promise<ApiResponse<Setting[]>> => {
		try {
			const data = await this._auth.get<any, Setting[]>(routes.SETTINGS);
			return { data };
		} catch (error: any) {
			return { error };
		}
	};

	private updateSettings = async (input: Array<SettingInput>): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.put<Array<SettingInput>, OK>(routes.SETTINGS, { settings: input });
			return { data };
		} catch (error: any) {
			return { error };
		}
	};
}

export { routes };
export default new Api();
