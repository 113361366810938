import React, { useEffect, useRef } from 'react';
import { Typography } from 'lib/components';
import classes from './index.module.scss';
import Image from 'lib/components/Image';
import { Scrollbars } from 'react-custom-scrollbars';
import { useWindowResize } from 'hooks/useWindowResize';

const details = [
	{ id: '0', label: 'MOBILE NUMBER', value: '+1 69 87 45 218' },
	{ id: '1', label: 'EMAIL', value: 'reginasmuth@gmail.com' },
	{ id: '2', label: 'JOINED', value: '27.04.2019' },
	{ id: '3', label: 'POSITION', value: 'Nurse' },
	{ id: '4', label: 'DATE OF BIRTH', value: '01.02.80' },
];
const userId = '90';

const messages = [
	{
		id: '0',
		senderId: '41',
		type: 'TEXT',
		message: 'Hello, I can"t find the file of the patient, could you send it through chat?',
		timestamp: 'friday, 11:45am',
	},
	{
		id: '1',
		senderId: userId,
		type: 'TEXT',
		message: 'Yes of course. Let me just upload the pdf file.',
		timestamp: 'Friday, 11:58am',
	},
	{
		id: '1',
		senderId: userId,
		type: 'PDF',
		filename: 'patient_03421_file.pdf',
		timestamp: 'Friday, 11:58am',
	},
	{ id: '3', senderId: '41', type: 'TEXT', message: 'Downloaded, thank you!', timestamp: 'Friday, 11:59am' },
	{ id: '4', senderId: userId, type: 'TEXT', message: 'No problem', timestamp: 'Friday, 12:01pm' },
	{
		id: '0',
		senderId: '41',
		type: 'TEXT',
		message: 'Hello, I can"t find the file of the patient, could you send it through chat?',
		timestamp: 'friday, 11:45am',
	},
	{
		id: '1',
		senderId: userId,
		type: 'TEXT',
		message: 'Yes of course. Let me just upload the pdf file.',
		timestamp: 'Friday, 11:58am',
	},
	{
		id: '1',
		senderId: userId,
		type: 'PDF',
		filename: 'patient_03421_file.pdf',
		timestamp: 'Friday, 11:58am',
	},
	{ id: '3', senderId: '41', type: 'TEXT', message: 'Downloaded, thank you!', timestamp: 'Friday, 11:59am' },
	{ id: '4', senderId: userId, type: 'TEXT', message: 'No problem', timestamp: 'Friday, 12:01pm' },
];

const ChatScreen = () => {
	const scrollRef = useRef<Scrollbars>(null);
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	useEffect(() => {
		scrollRef.current?.scrollToBottom();
	}, []);

	return (
		<div className={classes['chat-screen-container']}>
			<div className={classes['chat-view']}>
				<div
					className={classes['details']}
					style={{
						paddingLeft: isSmallDevice ? '1rem' : undefined,
					}}
				>
					<Image
						src="plNurse4"
						style={{
							width: '3rem',
							height: '3rem',
						}}
					/>
					<div
						style={{
							paddingLeft: '0.5rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="medium">
							Regina Smith
						</Typography>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>On route to Dispatch #000154</Typography>
					</div>
				</div>
				<div
					className={classes['messages']}
					style={{
						padding: isSmallDevice ? '0 1rem' : undefined,
					}}
				>
					<Typography className={classes['active-time']} variant="h6" weight="light">
						Friday, 11:45am
					</Typography>
					<Scrollbars ref={scrollRef}>
						<div className={classes['inner-container']}>
							{messages.map(({ message, senderId, timestamp, type, filename }, index) => {
								if (type === 'TEXT') {
									return (
										<>
											<p
												className={classes['message']}
												style={{
													alignSelf: senderId === userId ? 'flex-end' : 'flex-start',
													borderBottomRightRadius: senderId === userId ? 0 : undefined,
													borderBottomLeftRadius: senderId === userId ? undefined : 0,
													color: senderId === userId ? undefined : '#000',
													backgroundColor: senderId === userId ? undefined : '#F5F5F5',
													marginTop:
														messages[+index - 1]?.senderId === senderId ? '0' : undefined,
												}}
											>
												{message}
											</p>
											{messages[+index + 1]?.senderId !== senderId && (
												<Typography
													className={classes['timestamp']}
													weight="light"
													style={{
														alignSelf: senderId === userId ? 'flex-end' : 'flex-start',
													}}
												>
													{timestamp}
												</Typography>
											)}
										</>
									);
								} else {
									return (
										<>
											<p
												className={classes['message']}
												style={{
													alignSelf: senderId === userId ? 'flex-end' : 'flex-start',
													borderBottomRightRadius: senderId === userId ? 0 : undefined,
													borderBottomLeftRadius: senderId === userId ? undefined : 0,
													color: senderId === userId ? undefined : '#000',
													backgroundColor: senderId === userId ? undefined : '#F5F5F5',
													marginTop:
														messages[+index - 1]?.senderId === senderId ? '0' : undefined,
												}}
											>
												<Image
													src={
														senderId === userId ? 'icAttachmentWhite' : 'icAttachmentBlack'
													}
													style={{
														width: '1rem',
														height: '1rem',
														marginRight: '0.5rem',
													}}
												/>
												{filename}
											</p>
											{messages[+index + 1]?.senderId !== senderId && (
												<Typography
													className={classes['timestamp']}
													variant="h6"
													weight="light"
													style={{
														alignSelf: senderId === userId ? 'flex-end' : 'flex-start',
													}}
												>
													{timestamp}
												</Typography>
											)}
										</>
									);
								}
							})}
						</div>
					</Scrollbars>
				</div>
				<div className={classes['chat-toolbar']}>
					<button>
						<Image
							src="icAttachmentBlack"
							style={{
								width: '1.4rem',
								height: '1.4rem',
							}}
						/>
					</button>
					<input placeholder="Type a message..." />
					<button>
						<Image
							src="icSmileyFace"
							style={{
								width: '1.4rem',
								height: '1.4rem',
							}}
						/>
					</button>
					<button className={classes['icon-button']}>
						<Image
							src="icSendPrimary"
							style={{
								width: '1.4rem',
								height: '1.4rem',
							}}
						/>
					</button>
				</div>
			</div>
			<div className={classes['details-view']}>
				<div className={classes['icons']}>
					<button className={classes['button']}>
						<Image
							src="icCall"
							style={{
								width: '1.3rem',
								height: '1.3rem',
								marginBottom: '0.25rem',
							}}
						/>
						<Typography variant="h6" weight="bold">
							Call
						</Typography>
					</button>
					<button className={classes['button']}>
						<Image
							src="icVideoPrimary"
							style={{
								width: '1.3rem',
								height: '1.3rem',
								marginBottom: '0.25rem',
							}}
						/>
						<Typography variant="h6" weight="bold">
							Video
						</Typography>
					</button>
					<button className={classes['button']}>
						<Image
							src="icEmail"
							style={{
								width: '1.2rem',
								height: '1.2rem',
								marginBottom: '0.25rem',
							}}
						/>
						<Typography variant="h6" weight="bold">
							Email
						</Typography>
					</button>
				</div>

				<div
					className={classes['user-profile']}
					style={{
						paddingLeft: isSmallDevice ? 0 : undefined,
					}}
				>
					<div className={classes['user-image']}>
						<Image
							src="plNurse4"
							style={{
								width: '3.6rem',
								height: '3.6rem',
							}}
						/>
					</div>
					<div
						style={{
							paddingLeft: '0.5rem',
						}}
					>
						<Typography variant="h6" weight="medium">
							Regina Smith
						</Typography>
						<Typography variant="h6">On route to Dispatch #000154</Typography>
					</div>
				</div>

				{details.map(({ label, id, value }) => (
					<div key={id} className={classes['detail']}>
						<Typography variant="h6" weight="light">
							{label}
						</Typography>
						<Typography
							variant="h6"
							weight="medium"
							style={{
								marginTop: '0.5rem',
							}}
						>
							{value}
						</Typography>
					</div>
				))}

				<button
					className={classes['row']}
					style={{
						backgroundColor: '#E7F7F9',
						border: '1px solid #0CB9C3',
						padding: '0.75rem 1rem',
						borderRadius: '0.5rem',
						marginTop: 'auto',
						alignSelf: 'center',
					}}
				>
					<Typography
						variant="h6"
						style={{
							marginRight: '0.5rem',
						}}
					>
						Close Nurse Information
					</Typography>
					<Image src="icTriArrowBlack" />
				</button>
			</div>
		</div>
	);
};

export default ChatScreen;
