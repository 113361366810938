import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Typography, Switch, SearchBar, Image } from 'lib/components';
import { useAppDispatch, actions, useSelectTopNavState, useSelectCurrentUser } from 'store';
import { useWindowResize } from 'hooks/useWindowResize';
import classes from './index.module.scss';

const TopNav = () => {
	const { push } = useHistory();
	const { contentHeader, mapToggleVisible, searchBarVisible, notificationVisible } = useSelectTopNavState();
	const me = useSelectCurrentUser();
	const dispatch = useAppDispatch();
	const { isSmallDevice } = useWindowResize();

	const [query, setQuery] = useState('');

	const {
		goBack,
		location: { pathname },
	} = useHistory();

	const openMenu = () => {
		dispatch(actions.sidenavToggled(true));
	};

	const hideBackIcon =
		pathname === '/dashboard' ||
		pathname === '/nurses' ||
		pathname === '/jobs' ||
		pathname === '/invoices' ||
		pathname === '/inventories' ||
		pathname === '/inventory/home' ||
		pathname === '/inventory/vendors' ||
		pathname === '/admins' ||
		pathname === '/hospitals';

	return (
		<div className={classes['topNav']}>
			<div className={classes['topNav-container']}>
				<div className={classes['topNav-logo']}>
					<Link to={'/'}>LOGO</Link>
				</div>
				<div className={classes['topNav-content']}>
					{contentHeader && (
						<div className={classes['topNav-content-header']}>
							{!hideBackIcon && (
								<a onClick={goBack} className={classes['topNav-content-header-back-btn']}>
									<Image src={'icLeftCaret'} />
								</a>
							)}
							<Typography variant="h3">{contentHeader}</Typography>
						</div>
					)}
					{pathname === '/dashboard' && (
						<div className={classes['topNav-content-mapToggle']}>
							<Typography className={classes['topNav-content-mapToggle-title']}>
								Toggle Map View:
							</Typography>
							<div>
								<Switch
									id={`topNav-content-mapToggle-switch`}
									checked={mapToggleVisible}
									handleChecked={(value) => dispatch(actions.mapDisplayToggled(value))}
								/>
							</div>
						</div>
					)}
					{searchBarVisible && (
						<div className={classes['topNav-content-search']}>
							<SearchBar query={query} setQuery={setQuery} placeholder="Search..." />
						</div>
					)}
				</div>
				<div className={classes['topNav-right']}>
					<div className={classes['topNav-userInfo']} onClick={() => push('/profile')}>
						<Image
							className={classes['topNav-userInfo-avatar']}
							alt="avatar"
							src={me.avatar ?? 'plAvatar'}
						/>
						<div className={classes['topNav-userInfo-detail']}>
							<Typography className={classes['topNav-userInfo-name']} variant="h5">
								{me.name}
							</Typography>
							<Typography className={classes['topNav-userInfo-role']} variant="h6">
								{me.roleId === 1 ? 'Super Administrator' : 'Administrator'}
							</Typography>
						</div>
					</div>
					{notificationVisible && (
						<a className={classes['topNav-notification']}>
							<Image src={'icBellPrimary'} />
						</a>
					)}
					{isSmallDevice && (
						<a className={classes['topNav-menuButton']} onClick={openMenu}>
							<Image src={'icMenu'} />
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default TopNav;
