import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOption } from 'lib/components/types';
import classes from './index.module.scss';
import { Vendor } from 'types';

interface Props {
	vendors: Vendor[];
	value?: number;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const VendorSelect = ({ value, setValue, vendors }: Props) => {
	const options: SelectOption<number>[] = vendors.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const [option, setOption] = useState<SelectOption<number> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
		setValue(option?.value);
	};

	return (
		<Select
			placeholder="Assign a Vendor"
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			options={options}
			isClearable={true}
			isSearchable={true}
			value={option}
			onChange={handleChange}
		/>
	);
};

export default VendorSelect;
