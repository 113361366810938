import { Employee } from 'types';
import { Sort } from './types';

export const employeeNameCompareFn = (a: Employee, b: Employee, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const employeeEmailCompareFn = (a: Employee, b: Employee, sort: Sort) => {
	if (sort === 'asc') {
		return a.email.localeCompare(b.email) === -1 ? 1 : -1;
	} else {
		return a.email.localeCompare(b.email) === -1 ? -1 : 1;
	}
	// return 0;
};

export const employeePhoneCompareFn = (a: Employee, b: Employee, sort: Sort) => {
	if (sort === 'asc') {
		return a.phoneNumber.localeCompare(b.phoneNumber) === -1 ? 1 : -1;
	} else {
		return a.phoneNumber.localeCompare(b.phoneNumber) === -1 ? -1 : 1;
	}
	// return 0;
};

export const employeeCreatedCompareFn = (a: Employee, b: Employee, sort: Sort) => {
	const valueA = new Date(a.createdAt).getTime();
	const valueB = new Date(b.createdAt).getTime();
	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
