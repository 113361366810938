import { Color } from 'lib/components/types';
import { InvoicePaymentStatusType } from '.';
import { Address } from 'types';

export const removeAuth = () => window.localStorage.removeItem('auth');

export const getPaymentStatusColor = (status: InvoicePaymentStatusType): Color => {
	if (status === InvoicePaymentStatusType.UNPAID) {
		return 'danger';
	}

	if (status === InvoicePaymentStatusType.REFUNDED) {
		return 'darkgrey';
	}

	return 'success';
};

export const getLocation = (address?: Address) => {
	if (!address) return '-';

	let result = address.addressLine1;
	if (address.addressLine2 && address.addressLine2 !== '') {
		result += `, ${address.addressLine2}`;
	}

	return `${result}, ${address.postalCode} ${address.city}, ${address.state}`;
};

export const toHHMMSS = (value: number) => {
	const hours = Math.floor(value / 3600);
	const minutes = Math.floor(value / 60) % 60;
	const seconds = value % 60;

	return [hours, minutes, seconds]
		.map((v) => (v < 10 ? '0' + v : v))
		.filter((v, i) => v !== '00' || i > 0)
		.join(':');
};
