import { useAddIdPrefix, useComprehensiveTopNav, useCustomToast, useSocket, useWindowResize } from 'hooks';
import routes from 'lib/api/routes';
import { Image, LoadingIndicator, Typography } from 'lib/components';
import Tag from 'lib/components/Tag';
import { getLocation, JobStatus, JobStatusType, MessageStatusType, Timing, toHHMMSS } from 'lib/util';
import { nanoid as ID } from 'nanoid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectConversations,
	useSelectCurrentUser,
	useSelectJob,
	useSelectRequest,
	useSelectSettings,
} from 'store';
import { Conversation, Job } from 'types';
import { uuid } from 'uuidv4';
import adjustTime from '../../lib/adjustTime';
import MapView from '../../lib/components/MapView';
import formatJobAttributeValue from '../../lib/util/formatJobAttributeValue';
import getJobAttributeIgnores from '../../lib/util/getJobAttributeIgnores';
import getJobAttributeName from '../../lib/util/getJobAttributeName';
import ChatPopup from './ChatPopup';
import FileList from './FileList';
import classes from './index.module.scss';
import JobCustomCostList from './JobCustomCostList';
import JobStatusSelect from './JobStatusSelect';
import Notes from './Notes';
import Timeline from './Timeline';

interface Props {
	job: Job;
	conversation: Conversation;
}

const JobScreen = () => {
	const { id } = useParams<{ id: string }>();
	const jobId = parseInt(id);

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const getJobById = useSelectJob();
	const conversations = useSelectConversations();

	const [job, setJob] = useState<Job>();
	const [conversation, setConversation] = useState<Conversation>();
	const [fetchedCount, setFetchedCount] = useState(0);

	useEffect(() => {
		dispatch(actions.fetchJob(parseInt(id)));
		dispatch(actions.fetchConversations());
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.CONVERSATION, 'GET')) {
			if (RM.succeeded()) {
				const findConversation = conversations.list.find((conversation) => conversation.jobId === jobId);

				if (findConversation) {
					setConversation(findConversation);
				}
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.JOB_ID(jobId), 'GET')) {
			if (RM.succeeded()) {
				const job = getJobById(jobId);

				if (job) {
					setJob(job);
				}
				setFetchedCount((prev) => prev + 1);
			}
		}
	}, [active]);

	useEffect(() => {
		const findConversation = conversations.list.find((conversation) => conversation.jobId === jobId);

		if (findConversation) {
			setConversation(findConversation);
		}
	}, [conversations.updatedAt]);

	if (fetchedCount < 2) return <LoadingIndicator />;

	if (!job || !conversation) return <LoadingIndicator />;

	return <JobScreenView job={job} conversation={conversation} />;
};

const JobScreenView = ({ job, conversation }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const settingsState = useSelectSettings();

	const toast = useCustomToast();
	const addPrefix = useAddIdPrefix();
	const { push } = useHistory();
	const { search } = useLocation();
	const chat = new URLSearchParams(search).get('chat');
	const { socket } = useSocket();

	const dispatch = useAppDispatch();
	const me = useSelectCurrentUser();
	const { canUpdateJob } = useSelectAuthorization();
	const { active } = useSelectRequest();

	const [chatPopupOpen, setChatPopupOpen] = useState(chat === 'open' ? true : false);
	const [message, setMessage] = useState('');
	const [status, setStatus] = useState<number | undefined>(job.status);
	const { isSmallDevice, width, isSmallerDevice } = useWindowResize();

	const canProceed = useMemo<boolean>(() => {
		return message !== '';
	}, [message]);

	// const handleCallButtonClick = useCallback(() => {
	// 	const link = document.createElement('a');
	// 	link.href = `tel:${job.nurse!.user.phoneNumber}`;
	// 	link.click();
	// }, [job.id]);

	// const handleEmailButtonClick = useCallback(() => {
	// 	const link = document.createElement('a');
	// 	link.href = `mailto:${job.nurse!.user.email}`;
	// 	link.click();
	// }, [job.id]);

	const handleSend = useCallback(() => {
		if (!canProceed || !socket) {
			return;
		}

		const temporaryId = uuid();
		socket.emit('conversation:message:create', conversation.id, temporaryId, message);
		dispatch(
			actions.messageCreated({
				conversationId: conversation.id,
				temporaryId,
				userId: me.id,
				message,
			})
		);

		setMessage('');
	}, [dispatch, canProceed, socket, message]);

	const handleRead = useCallback(() => {
		if (!socket) {
			return;
		}

		socket.emit('conversation:messages:read', conversation.id);
		dispatch(
			actions.messagesRead({
				conversationId: conversation.id,
				userId: me.id,
			})
		);

		setMessage('');
	}, [dispatch, socket]);

	useEffect(() => {
		if (!socket) return;

		socket.on(
			'nurse:location:updates',
			(nurseId: number, jobId: number, jobStatus: JobStatusType, longitude: number, latitude: number) => {
				console.log({ nurseId, jobId, jobStatus, longitude, latitude });
			}
		);
	}, [socket]);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.JOB_STATUS(job.id), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		if (!chatPopupOpen) {
			return;
		}

		handleRead();
	}, [chatPopupOpen, handleRead]);

	const handleStatusChange = (status: number) => {
		dispatch(actions.updateJobStatus(job.id, status));
	};

	const attributesDetails = React.useMemo(() => {
		let list = [];

		for (const waitEntry of job.waits) {
			list.push({
				title: `Wait Time: Actual (${waitEntry.actualTime}m) Rounded (${waitEntry.roundedTime}m)`,
				element: (
					<>
						{waitEntry.attributes
							.filter((element) => getJobAttributeIgnores().includes(element.name) === false)
							.map((element) => (
								<Typography
									key={`${waitEntry.id}-${element.id}`}
									variant={isSmallerDevice ? 'h5' : 'h6'}
								>
									{getJobAttributeName(element.name)}: <b>{formatJobAttributeValue(element.value)}</b>
								</Typography>
							))}
					</>
				),
			});
		}

		for (const jobStatus of Object.values(JobStatusType) as JobStatusType[]) {
			const found = job.attributes.filter((element) => element.status === jobStatus);
			if (found.length === 0) {
				continue;
			}

			list.push({
				title: `Job Status: ${JobStatus.text(jobStatus)}`,
				element: (
					<>
						{found
							.filter((element) => getJobAttributeIgnores().includes(element.name) === false)
							.map((element) => (
								<Typography key={`job-attribute-${element.id}`} variant={isSmallerDevice ? 'h5' : 'h6'}>
									{getJobAttributeName(element.name)}: <b>{formatJobAttributeValue(element.value)}</b>
								</Typography>
							))}
					</>
				),
			});
		}

		return list;
	}, [job]);

	useComprehensiveTopNav(`Job - ${addPrefix(job.id)}`);

	return (
		<>
			<ChatPopup
				isOpen={chatPopupOpen}
				message={message}
				setMessage={setMessage}
				conversation={conversation}
				onMessageSend={handleSend}
				onMessagesRead={handleRead}
				onClose={() => setChatPopupOpen(false)}
			/>
			<div className={classes['dispatch-view-container']} ref={containerRef}>
				<div
					style={{
						padding: isSmallDevice ? '1rem' : '1.5rem 3rem 0.75rem 3rem',
						borderBottom: '1px solid #E5E5E5',
					}}
				>
					<div
						className={classes['row']}
						style={{
							justifyContent: 'space-between',
						}}
					>
						<div className={classes['row']}>
							<div
								className={classes['badge']}
								style={{
									marginTop: '0.5rem',
									marginRight: '1rem',
								}}
							>
								<Typography>
									Order Number:{' '}
									<span
										style={{
											fontWeight: 600,
										}}
									>
										{addPrefix(job.id)}
									</span>
								</Typography>
							</div>
							<div
								className={classes['row']}
								style={{
									marginTop: '0.5rem',
								}}
							>
								<Image src="icClockPrimary" />
								<Typography
									style={{
										marginLeft: '0.5rem',
									}}
								>
									Job submitted at :{' '}
									{adjustTime(settingsState, job.createdAt).format('MM/DD/YYYY hh:mm A')}
								</Typography>
							</div>
						</div>
						<div className={classes['row']}>
							{JobStatus.isChatOpen(job.status) && (
								<button
									className={classes['row']}
									style={{
										marginTop: '0.5rem',
									}}
									onClick={() => setChatPopupOpen(true)}
								>
									<Image
										src="icChat"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Chat</Typography>
									{conversation.messages.filter(
										(message) =>
											message.status !== MessageStatusType.READ && message.userId !== me.id
									).length > 0 && (
										<div className={classes['chat-notifications-number']}>
											<Typography variant={'h6'}>
												{
													conversation.messages.filter(
														(message) =>
															message.status !== MessageStatusType.READ &&
															message.userId !== me.id
													).length
												}
											</Typography>
										</div>
									)}
								</button>
							)}
							{/* <button
								className={classes['row']}
								style={{
									marginLeft: '1.5rem',
									marginTop: '0.5rem',
								}}
							>
								<Image src="icExportBlack" />
								<Typography
									variant={isSmallerDevice ? 'h5' : 'h6'}
									style={{
										marginLeft: '0.5rem',
									}}
								>
									Export
								</Typography>
							</button> */}
							{canUpdateJob && (
								<button
									className={classes['row']}
									style={{
										marginLeft: '1.5rem',
										marginTop: '0.5rem',
									}}
									onClick={() => push(`/jobs/edit/${job.id}`)}
								>
									<Image src="icEdit" />
									<Typography
										variant={isSmallerDevice ? 'h5' : 'h6'}
										style={{
											marginLeft: '0.5rem',
										}}
									>
										Edit
									</Typography>
								</button>
							)}
						</div>
					</div>
					<div className={classes['row']} style={{ justifyContent: 'flex-end' }}>
						<div>
							<JobStatusSelect
								value={status}
								setValue={setStatus}
								onChangeAction={handleStatusChange}
								isDisabled={!canUpdateJob}
							/>
						</div>
					</div>
					<div className={classes['row']}>
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icThickPlusPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Hospital Name</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.hospital.name}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icClockPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Date/Time</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.startingAt}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />

						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icEmail"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Contact Information</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.contact?.phoneNumber}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '1rem',
											height: '1rem',
										}}
										src="icLocation"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Job Location</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{getLocation(job.hospital.address)}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icThickPlusPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Patient ID</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.patientId ?? '-'}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icThickPlusPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Patient Name</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.patientName ?? '-'}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icThickPlusPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Ordering Physician</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.orderingPhysician ?? '-'}
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icThickPlusPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Room Number</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.roomNumber ?? '-'}
							</Typography>
						</div>
						{job.waitingTime > 0 && (
							<>
								<div className={classes['vertical-separator']} />
								<div
									style={{
										marginTop: isSmallerDevice ? '2rem' : '1rem',
									}}
								>
									<div className={classes['row']}>
										<div className={classes['icon-button']}>
											<Image
												style={{
													width: '0.75rem',
													height: '0.75rem',
												}}
												src="icClockPrimary"
											/>
										</div>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
											Total Waiting Time
										</Typography>
									</div>
									<Typography
										variant={isSmallerDevice ? 'h5' : 'h6'}
										weight="bold"
										style={{
											marginTop: '0.5rem',
										}}
									>
										{toHHMMSS(job.waitingTime)}
									</Typography>
								</div>
							</>
						)}
					</div>
					<div className={classes['row']}>
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icClockPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
									Total Treatment Time (Rounded)
								</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.totalTreatmentTime}m ({job.totalTreatmentTimeRounded}m)
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icClockPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
									Total Waiting Time (Rounded)
								</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.totalWaitingTime}m ({job.totalWaitingTimeRounded}m)
							</Typography>
						</div>
						<div className={classes['vertical-separator']} />
						<div
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							<div className={classes['row']}>
								<div className={classes['icon-button']}>
									<Image
										style={{
											width: '0.75rem',
											height: '0.75rem',
										}}
										src="icClockPrimary"
									/>
								</div>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Total Time (Rounded)</Typography>
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="bold"
								style={{
									marginTop: '0.5rem',
								}}
							>
								{job.totalTreatmentTime + job.totalWaitingTime}m (
								{job.totalTreatmentTimeRounded + job.totalWaitingTimeRounded}m)
							</Typography>
						</div>
					</div>
				</div>
				<div
					style={{
						padding: isSmallDevice ? '0 1rem 1rem 1rem' : '0rem 3rem 1rem 3rem',
					}}
				>
					<div
						className={classes['row']}
						style={{
							alignItems: 'flex-start',
						}}
					>
						<div
							className={classes['wrapper']}
							style={{
								width: width <= 931 ? '100%' : '50%',
								minWidth: 300,
								margin: width <= 931 ? '1rem 0 0 0' : '1rem 1rem 0 0',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Map: </Typography>
							<MapView
								longitude={job.hospital.address.location[0]}
								latitude={job.hospital.address.location[1]}
								jobId={job.id}
								className={classes['wrapper--map']}
							/>
						</div>
						<div
							className={classes['wrapper']}
							style={{
								flex: 1,
								height: '17.5rem',
								marginTop: isSmallerDevice ? '2rem' : '1rem',
								minWidth: 270,
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Status: </Typography>
							<div className={classes['starts-in-container']}>
								<Typography
									variant={isSmallerDevice ? 'h5' : 'h6'}
									style={{
										alignSelf: 'center',
										textAlign: 'center',
									}}
									color="primary"
								>
									{Timing.to(job.startingAt).includes('ago') ? 'Started ' : 'Starts '}
									<span
										style={{
											fontWeight: 500,
										}}
									>
										{Timing.to(job.startingAt)}
									</span>
								</Typography>
								<div
									className={classes['row']}
									style={{ justifyContent: 'space-between', margin: '0.25rem 0' }}
								>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
										{JobStatus.progressText(job.status)}
									</Typography>
									{JobStatus.showProgressFractional(job.status) && (
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
											{JobStatus.progressFractional(job.status)}
										</Typography>
									)}
								</div>
								<div className={classes['progress-container']}>
									<div
										className={classes['progress']}
										style={{
											width: `${JobStatus.progressPercentage(job.status)}%`,
										}}
									/>
								</div>
							</div>
							{job.logs.length > 0 && (
								<>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Timeline: </Typography>
									<Timeline logs={job.logs} />
								</>
							)}
						</div>
					</div>
				</div>
				<div
					style={{
						padding: isSmallDevice ? '1rem' : '1rem 3rem',
					}}
				>
					<div className={classes['row']} style={{ marginTop: '0.5rem' }}>
						<div
							style={{
								width: width <= 623 ? '100%' : '33.33%',
								height: '100%',
								minWidth: 300,
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
									Inventory Used/Assigned:{' '}
								</Typography>
								<div
									className={classes['wrapper']}
									style={{
										width: '100%',
										height: '100%',
										marginTop: '0.5rem',
									}}
								>
									<Scrollbars
										renderThumbVertical={(props) => (
											<div {...props} className={classes['vertical-scroll-thumb']} />
										)}
									>
										<div
											className={classes['row']}
											style={{
												width: '100%',
												padding: '0rem 1rem 1rem 1rem',
												justifyContent: 'space-between',
											}}
										>
											<div className={classes['row']}>
												<Typography
													variant={isSmallerDevice ? 'h5' : 'h6'}
													style={{
														marginRight: '0.5rem',
													}}
												>
													Name
												</Typography>
												<Image src="icDownCaret" />
											</div>
											<div className={classes['row']}>
												<Typography
													variant={isSmallerDevice ? 'h5' : 'h6'}
													style={{
														marginRight: '0.5rem',
													}}
												>
													Available
												</Typography>
												<Image src="icDownCaret" />
											</div>
										</div>
										{job.inventory.map(({ amount, name }) => (
											<div className={classes['inventory-item']} key={ID()}>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{name}</Typography>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
													{amount}
												</Typography>
											</div>
										))}
									</Scrollbars>
								</div>
							</div>
						</div>
						<div
							style={{
								width: width <= 623 ? '100%' : '33.33%',
								marginTop: width <= 623 ? 32 : 0,
								height: '100%',
								minWidth: 300,
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Description: </Typography>
								<div
									className={classes['wrapper']}
									style={{
										width: '100%',
										height: '100%',
										marginTop: '0.5rem',
									}}
								>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{job.description}</Typography>
								</div>
							</div>
						</div>
						<div
							style={{
								width: width <= 623 ? '100%' : '33.33%',
								marginTop: width <= 623 ? 32 : 0,
								height: '100%',
								minWidth: 300,
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Details: </Typography>
								<div
									className={classes['wrapper']}
									style={{
										width: '100%',
										height: '100%',
										marginTop: '0.5rem',
										overflowY: 'scroll',
									}}
								>
									{attributesDetails.map((element, index) => (
										<React.Fragment key={index}>
											<Typography variant="body2" weight="bold">
												{element.title}
											</Typography>
											{element.element}
										</React.Fragment>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className={classes['row']} style={{ marginTop: '1rem' }}>
						<div
							style={{
								width: width <= 623 ? '100%' : '66.66%',
								height: '100%',
								minWidth: 300,
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<div className={classes['jobServices']}>
									<Typography variant="h6" weight="bold">
										Services Provided for Job
									</Typography>
									<div className={classes['jobServices-table']}>
										<div className={classes['jobServices-tableHeader']}>
											<div className={classes['jobServices-tableHeaderItem']}>
												<Typography color="darkgrey" variant="h6">
													Title
												</Typography>
												<Image src="icCaretBlack" />
											</div>
											<div className={classes['jobServices-tableHeaderItem']}>
												<Typography color="darkgrey" variant="h6">
													Description
												</Typography>
												<Image src="icCaretBlack" />
											</div>
										</div>
										<Scrollbars
											renderThumbVertical={(props) => (
												<div {...props} className={classes['vertical-scroll-thumb']} />
											)}
										>
											<div className={classes['jobServices-tableBody']}>
												{job.services.map(({ serviceId, name, description }) => (
													<div
														key={serviceId}
														className={classes['jobServices-tableRow']}
														style={{ margin: '0.5rem 0' }}
													>
														<div>
															<Typography variant="h6">{name}</Typography>
														</div>
														<div>
															<Typography variant="h6">{description}</Typography>
														</div>
													</div>
												))}
											</div>
										</Scrollbars>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								width: width <= 623 ? '100%' : '33.33%',
								height: '100%',
								minWidth: 300,
								marginTop: '2rem',
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography
									variant={isSmallerDevice ? 'h5' : 'h6'}
									weight="bold"
									style={{
										marginTop: isSmallerDevice ? '2rem' : '1rem',
									}}
								>
									Custom Cost Amplifiers:{' '}
								</Typography>
								<JobCustomCostList
									items={job.customCosts}
									updateCustomCosts={() => {}}
									defaultCheckedIds={job.customCosts.map(({ customCostId }) => customCostId)}
								/>
							</div>
						</div>
					</div>
					<div className={classes['row']} style={{ margin: '1rem 0' }}>
						<div style={{ width: width <= 623 ? '100%' : '33.33%', minWidth: 300, marginTop: '2rem' }}>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Requirements: </Typography>
								<div
									className={classes['wrapper']}
									style={{
										width: '100%',
										height: '100%',
										marginTop: '0.5rem',
									}}
								>
									<Scrollbars>
										<div className={classes['requirement-tags']}>
											{job.requirements.map(({ id, name }) => (
												<Tag
													key={id}
													initialTitle={name}
													onValueChanged={(value) => console.log(value)}
													editable={false}
												/>
											))}
										</div>
									</Scrollbars>
								</div>
							</div>
						</div>
						<div style={{ width: width <= 623 ? '100%' : '33.33%', minWidth: 300, marginTop: '2rem' }}>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Nurse notes: </Typography>
								<Notes items={job.notes} />
							</div>
						</div>
						<div
							style={{
								width: width <= 623 ? '100%' : '33.33%',
								height: '100%',
								minWidth: 300,
								marginTop: '2rem',
							}}
						>
							<div
								style={{
									width: width <= 623 ? '100%' : '96%',
									height: '15rem',
								}}
							>
								<Typography
									variant={isSmallerDevice ? 'h5' : 'h6'}
									style={{
										marginTop: isSmallerDevice ? '2rem' : '1rem',
									}}
								>
									Downloads:{' '}
								</Typography>
								<div
									style={{
										width: '100%',
										height: '100%',
										marginTop: '0.2rem',
									}}
								>
									<FileList.Downloadable files={job.files ?? []} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobScreen;
