import React from 'react';
import classes from './index.module.scss';
import Typography from 'lib/components/Typography';
import Image from 'lib/components/Image';
import { InvoiceLog } from 'types';

interface Props {
	logs: InvoiceLog[];
}

const HEADER_ITEMS = ['Time', 'Descrption', 'User by'];

const InvoiceLogDataTable = ({ logs }: Props) => {
	return (
		<div className={classes['invoceLogTable']}>
			<div className={classes['invoceLogTable-header']}>
				<div className={classes['invoceLogTable-headerRow']}>
					{HEADER_ITEMS.map((label, index) => (
						<div className={classes['invoceLogTable-col']} key={index}>
							<Typography weight="light">{label}</Typography>
							<Image
								src="icArrowDown"
								style={{
									marginLeft: '0.5rem',
								}}
							/>
						</div>
					))}
				</div>
			</div>
			<div className={classes['invoceLogTable-body']}>
				{logs.map((record) => (
					<div key={record.id} className={classes['invoceLogTable-row']}>
						<div className={classes['invoceLogTable-col']}>
							<Typography>{record.createdAt}</Typography>
						</div>
						<div className={classes['invoceLogTable-col']}>
							<Typography>{record.message}</Typography>
						</div>
						<div className={classes['invoceLogTable-col']}>
							<Typography>{record.user?.name}</Typography>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default InvoiceLogDataTable;
