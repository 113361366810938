import React, { useState, useCallback, useEffect } from 'react';
import { Checkbox } from 'lib/components/FormFields';
import Scrollbars from 'react-custom-scrollbars';
import { Vendor } from 'types';
import Typography from 'lib/components/Typography';
import Image from 'lib/components/Image';
import { useWindowResize } from 'hooks/useWindowResize';
import { useSelectTotalVendorItems } from 'store';
import VendorRow from './VendorRow';
import { HeaderItem, Sort, SortField } from './types';
import { vendorNameCompareFn, inventoryItemsCompareFn } from './sort';
import classes from './index.module.scss';

interface Props {
	vendors: Vendor[];
	checkedId: number;
	onRowClick: (vendor: Vendor) => void;
	checkAction: (id: number, isChecked: boolean) => void;
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'Vendor', field: 'vendor', sort: 'asc' },
	{ label: 'Inventory', field: 'items', sort: 'asc' },
	{ label: 'Contact' },
	{ label: 'Last Updated' },
	{ label: 'Created' },
];

const VendorsDataTable = ({ vendors, checkedId, checkAction, onRowClick }: Props) => {
	const [allChecked, setAllChecked] = useState(false);
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'vendor',
	});
	const [sortedVendors, setSortedVendors] = useState<Vendor[]>(vendors);

	const { isSmallerDevice } = useWindowResize();

	const getTotalItems = useSelectTotalVendorItems();

	const sortVendors = useCallback(
		(vendors: Vendor[]) => {
			const sorted = [...vendors].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'vendor') {
							return vendorNameCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'items') {
							return inventoryItemsCompareFn(getTotalItems(a.id), getTotalItems(b.id), 'asc');
						}

						return 0;

					case 'desc':
						if (currentSortField?.field === 'vendor') {
							return vendorNameCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'items') {
							return inventoryItemsCompareFn(getTotalItems(a.id), getTotalItems(b.id), 'desc');
						}

						return 0;

					default:
						return 0;
				}
			});
			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedVendors(sortVendors(vendors));
	}, [vendors, sortVendors]);

	useEffect(() => {
		const sorted = sortVendors(sortedVendors);

		setSortedVendors(sorted);
	}, [sortVendors, setSortedVendors]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={classes['inventories-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={() => setAllChecked(!allChecked)}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{HEADER_ITEMS.map((item) => renderHeaderItem(item))}
					</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedVendors.map((vendor) => (
								<VendorRow
									key={vendor.id}
									vendor={vendor}
									totalItems={getTotalItems(vendor.id)}
									checkedId={checkedId}
									checkAction={checkAction}
									onRowClick={onRowClick}
								/>
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VendorsDataTable;
