import { useAddressInput, useComprehensiveTopNav, useCustomToast, useWindowResize } from 'hooks';
import routes from 'lib/api/routes';
import { Button, Image, LoadingIndicator, Typography } from 'lib/components';
import CustomSelectDatePickerModal from 'lib/components/CustomSelectDatePickerModal';
import { EmployeeTypeSelect, TextField } from 'lib/components/FormFields';
import Tag from 'lib/components/Tag';
import AddTag from 'lib/components/Tag/AddMore';
import { getLocation, NurseEmployeeType, Timing } from 'lib/util';
import selectImageHandler from 'lib/util/selectImageHandler';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { nanoid as ID } from 'nanoid';
import { useParams } from 'react-router';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectNurse,
	useSelectNurses,
	useSelectRequest,
	useSelectSettings,
} from 'store';
// import AddSkillInput from './add-skill-input';
import { BuddyPunchEmployee, Nurse } from 'types';
import useNurseHoursInput from '../../hooks/useNurseHoursInput';
import adjustTime from '../../lib/adjustTime';
import TimePicker from '../../lib/components/FormFields/TimePicker';
import classes from './index.module.scss';
import { useSelectBuddyPunchEmployeeState } from '../../store/hooks';
import BuddyPunchEmployeeSelect from '../../lib/components/FormFields/CustomSelect/BuddyPunchEmployeeSelect';

interface InfoProps {
	label: string;
	value: string | number;
}

interface Props {
	nurse: Nurse;
}

const Info = ({ label, value }: InfoProps) => {
	const { isSmallerDevice } = useWindowResize();

	return (
		<>
			<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{label}: </Typography>
			<Typography variant={isSmallerDevice ? 'h5' : 'h6'} style={{ marginLeft: 6, color: '#0CB9C3' }}>
				{value}&nbsp;
			</Typography>
		</>
	);
};

const TABS = [
	{ id: '1', label: 'Profile' },
	// { id: '2', label: 'Jobs' },
];

const NurseScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const nurses = useSelectNurses();
	const getNurseById = useSelectNurse();

	const [nurse, setNurse] = useState<Nurse>();

	useEffect(() => {
		dispatch(actions.fetchNurse(parseInt(id)));
	}, []);

	useEffect(() => {
		const nurse = getNurseById(parseInt(id));

		if (nurse) {
			setNurse(nurse);
			return;
		}
	}, [nurses.updatedAt]);

	return nurse ? <NurseScreenView nurse={nurse} /> : <LoadingIndicator />;
};

const NurseScreenView = ({ nurse }: Props) => {
	const settingsState = useSelectSettings();

	const toast = useCustomToast();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();
	const [tabIndex, setTabIndex] = useState('1');
	// const [step] = useState(3);
	const [editView, setEditView] = useState(false);

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canUpdateNurse } = useSelectAuthorization();
	const buddyPunchEmployeeState = useSelectBuddyPunchEmployeeState();

	const [name, setName] = useState(nurse.user.name);
	const [email, setEmail] = useState(nurse.user.email);
	const [employeeType, setEmployeeType] = useState(nurse.employeeType);
	const [notes, setNotes] = useState(nurse.notes ?? '');
	const [buddyPunchId, setBuddyPunchId] = useState<BuddyPunchEmployee | undefined>(
		nurse.buddyPunchId !== undefined
			? buddyPunchEmployeeState.list.find(
					(element) => parseInt(element.id as unknown as string, 10) === parseInt(nurse.buddyPunchId!, 10)
			  )
			: undefined
	);
	const [yearsOfExperience, setYearsOfExperience] = useState<string>(
		nurse.yearsOfExperience ? nurse.yearsOfExperience.toString() : '0'
	);
	const [dateOfBirth, setDateOfBirth] = useState<Date>(Timing.toDate(nurse.user.dateOfBirth));
	const [phoneNumber, setPhoneNumber] = useState(nurse.user.phoneNumber);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [skills, setSkills] = useState<Record<string, boolean>>({});
	// const [isSkillInputOpen, setIsSkillInputOpen] = useState(false);
	const [profileImageString, setProfileImageString] = useState<any>('plAvatar');
	const nurseProfileImageRef = useRef<HTMLInputElement>(null);
	const [showDOBModal, setShowDOBModal] = useState(false);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.NURSE_ID(nurse.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setEditView(false);
			}
		}

		if (RM.req(routes.UPLOAD_ID(nurse.user.id), 'POST')) {
			if (RM.pending()) {
				toast('Uploading avatar...', 'info');
			} else if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	const {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	} = useAddressInput(nurse.address);

	const {
		monFrom,
		setMonFrom,
		monTo,
		setMonTo,
		tueFrom,
		setTueFrom,
		tueTo,
		setTueTo,
		wedFrom,
		setWedFrom,
		wedTo,
		setWedTo,
		thuFrom,
		setThuFrom,
		thuTo,
		setThuTo,
		friFrom,
		setFriFrom,
		friTo,
		setFriTo,
		satFrom,
		setSatFrom,
		satTo,
		setSatTo,
		sunFrom,
		setSunFrom,
		sunTo,
		setSunTo,
	} = useNurseHoursInput(nurse.hours);

	const handleCallButtonClick = useCallback(() => {
		const link = document.createElement('a');
		link.href = `tel:${nurse.user.phoneNumber}`;
		link.click();
	}, [nurse.id]);

	const handleEmailButtonClick = useCallback(() => {
		const link = document.createElement('a');
		link.href = `mailto:${nurse.user.email}`;
		link.click();
	}, [nurse.id]);

	const openDOBModal = useCallback(() => {
		if (showDOBModal === false) {
			setShowDOBModal(true);
		}
	}, [showDOBModal, setShowDOBModal]);

	const closeDOBModal = () => setShowDOBModal(false);

	// const setSkillsFromArray = useCallback(
	// 	(skills_: string[]) => {
	// 		const skillsMap = skills_.reduce<Record<string, boolean>>((acc, cur) => {
	// 			acc[cur] = true;
	// 			return acc;
	// 		}, {});
	// 		setSkills(skillsMap);
	// 	},
	// 	[setSkills]
	// );
	useEffect(() => {
		if (nurse.skills.length <= 0) {
			return;
		}
		const skillsMap = nurse.skills
			.map((skill) => skill.name)
			.reduce<Record<string, boolean>>((acc, cur) => {
				acc[cur] = true;
				return acc;
			}, {});
		setSkills(skillsMap);
	}, [nurse]);

	const skillsToArray = (skills_: Record<string, boolean>) => {
		return Object.entries(skills_)
			.filter(([, value]) => value)
			.map(([skill]) => skill);
	};

	const addSkill = (skill: string) => {
		setSkills((skills_) => ({ ...skills_, [skill]: true }));
	};

	const removeSkill = (skill: string) => {
		setSkills((skills_) => ({ ...skills_, [skill]: false }));
	};

	const updateSkill = (prev: string, cur: string) => {
		setSkills((skills_) => {
			const temp = { ...skills_ };
			delete temp[prev];
			temp[cur] = true;
			return temp;
		});
	};

	const updateNurse = () => {
		dispatch(
			actions.updateNurse(nurse.id, {
				name,
				email,
				dateOfBirth: Timing.formatDate(dateOfBirth),
				employeeType,
				addressLine1,
				addressLine2: addressLine2 === '' ? undefined : addressLine2,
				city,
				state,
				postalCode,
				skills: skillsToArray(skills),
				notes,
				buddyPunchId: buddyPunchId !== undefined ? buddyPunchId.id.toString() : null,
				buddyPunchGPSPermissions: buddyPunchId !== undefined ? buddyPunchId.gpsPermissions : null,
				yearsOfExperience: isNaN(parseInt(yearsOfExperience)) ? 0 : parseInt(yearsOfExperience),
				phoneNumber,
				password,
				passwordConfirmation,
				hours: {
					monFrom,
					monTo,
					tueFrom,
					tueTo,
					wedFrom,
					wedTo,
					thuFrom,
					thuTo,
					friFrom,
					friTo,
					satFrom,
					satTo,
					sunFrom,
					sunTo,
				},
			})
		);
	};

	const handleFileSelect = selectImageHandler((file, fileURL) => {
		setProfileImageString(fileURL);
		dispatch(actions.updateNurseAvatar(nurse.user.id, file));
	});

	useComprehensiveTopNav(`${nurse.user.name} - Profile`);

	if (!nurse) return <LoadingIndicator />;

	if (editView) {
		return (
			<>
				<CustomSelectDatePickerModal
					isOpen={showDOBModal}
					onClose={closeDOBModal}
					onDateSet={(date) => setDateOfBirth(date)}
				/>
				<div
					className={classes['nurses-container']}
					style={{
						flexDirection: 'column',
					}}
				>
					<div className={classes['top-container']}>
						<div className={classes['image-area']}>
							<div
								className={classes['profile-image-container']}
								style={{
									margin: '2rem',
									border: 'none',
								}}
							>
								<div className={classes['small-circle']}>
									<div
										onClick={() => nurseProfileImageRef.current?.click()}
										className={classes['inner-circle']}
										style={{ borderColor: '#0CB9C3' }}
									>
										<Image
											src={'icImageUpload'}
											style={{ width: 12, height: 12, objectFit: 'contain' }}
										/>
										<input
											style={{ display: 'none' }}
											onChange={handleFileSelect}
											ref={nurseProfileImageRef}
											type="file"
											id="nurseProfileImage"
											name="nurseProfileImage"
											accept="image/*"
										/>
									</div>
								</div>
								<Image
									src={nurse.user.avatar ?? profileImageString}
									style={{ width: '100%', height: '100%' }}
								/>
							</div>
						</div>
						<div className={classes['profile-details']}>
							<div>
								<div className={classes['profile-details-group']}>
									<TextField
										placeholder="Regina Smith"
										label="Name:"
										variant="labelled"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
									<div>
										<EmployeeTypeSelect value={employeeType} setValue={setEmployeeType} />
									</div>
									<TextField
										placeholder="Address 1"
										label="Address Line 1:"
										variant="labelled"
										value={addressLine1}
										onChange={(e) => setAddressLine1(e.target.value)}
									/>
									<TextField
										placeholder="Address 2"
										label="Address Line 2:"
										variant="labelled"
										value={addressLine2}
										onChange={(e) => setAddressLine2(e.target.value)}
									/>
								</div>
								<div className={classes['profile-details-group']}>
									<TextField
										placeholder="Ohara"
										label="City:"
										variant="labelled"
										value={city}
										onChange={(e) => setCity(e.target.value)}
									/>
									<TextField
										placeholder="Texas"
										label="State:"
										variant="labelled"
										value={state}
										onChange={(e) => setState(e.target.value)}
									/>
									<TextField
										placeholder="00000"
										label="Postal Code:"
										variant="labelled"
										value={postalCode}
										onChange={(e) => setPostalCode(e.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						className={classes['bottom-container']}
						style={{
							padding: isSmallDevice ? '1rem' : undefined,
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<div style={{ marginBottom: '1.5rem' }}>
								<Typography weight="bold" variant={isSmallerDevice ? 'h5' : undefined}>
									Basic info:
								</Typography>
							</div>
							<div className={classes['textField-group']}>
								<TextField
									placeholder="+122 3393 894"
									label="Mobile number:"
									variant="labelled"
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
								<TextField
									placeholder="contact@example.com"
									label="Email:"
									variant="labelled"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<TextField
									placeholder=""
									label="Date of Birth:"
									variant="labelled"
									onFocus={openDOBModal}
									onClick={openDOBModal}
									value={Timing.formatDate(dateOfBirth)}
								/>
								<TextField
									placeholder=""
									label="Years of experience:"
									variant="labelled"
									value={yearsOfExperience}
									onChange={(e) => setYearsOfExperience(e.target.value)}
								/>
								<div>
									<BuddyPunchEmployeeSelect value={buddyPunchId} setValue={setBuddyPunchId} />
								</div>
							</div>
							<div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
								<Typography weight="bold" variant={isSmallerDevice ? 'h5' : undefined}>
									Working Hours:
								</Typography>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Monday"
									value={monFrom ? moment(`1970-01-01 ${monFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setMonFrom(value)}
								/>
								<TimePicker
									label=""
									value={monTo ? moment(`1970-01-01 ${monTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setMonTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Tuesday"
									value={tueFrom ? moment(`1970-01-01 ${tueFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setTueFrom(value)}
								/>
								<TimePicker
									label=""
									value={tueTo ? moment(`1970-01-01 ${tueTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setTueTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Wednesday"
									value={wedFrom ? moment(`1970-01-01 ${wedFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setWedFrom(value)}
								/>
								<TimePicker
									label=""
									value={wedTo ? moment(`1970-01-01 ${wedTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setWedTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Thursday"
									value={thuFrom ? moment(`1970-01-01 ${thuFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setThuFrom(value)}
								/>
								<TimePicker
									label=""
									value={thuTo ? moment(`1970-01-01 ${thuTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setThuTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Friday"
									value={friFrom ? moment(`1970-01-01 ${friFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setFriFrom(value)}
								/>
								<TimePicker
									label=""
									value={friTo ? moment(`1970-01-01 ${friTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setFriTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Saturday"
									value={satFrom ? moment(`1970-01-01 ${satFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setSatFrom(value)}
								/>
								<TimePicker
									label=""
									value={satTo ? moment(`1970-01-01 ${satTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setSatTo(value)}
								/>
							</div>
							<div className={classes['textField-group']}>
								<TimePicker
									label="Sunday"
									value={sunFrom ? moment(`1970-01-01 ${sunFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setSunFrom(value)}
								/>
								<TimePicker
									label=""
									value={sunTo ? moment(`1970-01-01 ${sunTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
									onChange={(value) => setSunTo(value)}
								/>
							</div>
							<div className={classes['textArea-group']}>
								<TextField
									placeholder="Enter the description"
									rows={6}
									label="Private notes:"
									variant="labelled"
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
									textArea
								/>
							</div>

							<div className={classes['row']} style={{ marginTop: '1rem' }}>
								<div style={{ margin: '0rem 1rem 1rem 0' }}>
									<Typography
										variant={isSmallerDevice ? 'h5' : 'subheading2'}
										style={{ opacity: '0.5' }}
										className={classes['margin-top']}
									>
										Skills:
									</Typography>
									<div className={classes['skills']}>
										{skillsToArray(skills).map((skill) => (
											<Tag
												initialTitle={skill}
												key={skill}
												onValueChanged={(value) => updateSkill(value.prev, value.cur)}
												onRemove={(skill_) => removeSkill(skill_)}
											/>
										))}
										<AddTag onAddMore={(skill) => addSkill(skill)} />
										{/* {skillsToArray(skills).map((skill) => (
											<div className={classes['skill']} key={ID()} style={{ marginRight: 12 }}>
												<p className={classes['info-label']}>{skill}</p>
											</div>
										))}
										{!isSkillInputOpen && (
											<button
												className={classes['skill']}
												style={{ color: '#000' }}
												onClick={() => setIsSkillInputOpen(true)}
											>
												<p className={classes['info-label']}>+ Add more</p>
											</button>
										)}
										{isSkillInputOpen && (
											<AddSkillInput setIsOpen={setIsSkillInputOpen} action={addSkill} />
										)} */}
									</div>
								</div>
							</div>
							<div className={classes['textField-group']}>
								<TextField
									placeholder="***********"
									label="Password:"
									variant="labelled"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<TextField
									placeholder="***********"
									label="Confirm Password:"
									variant="labelled"
									value={passwordConfirmation}
									onChange={(e) => setPasswordConfirmation(e.target.value)}
								/>
							</div>
						</div>
						{/* <div className={classes['extra-settings']}>
							<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>Extra Settings:</Typography>
							<div className={classes['extra-setting']}>
								<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Allow Nurse to lorem
								</Typography>
								<Switch id="id" />
							</div>
							<div className={classes['extra-setting']}>
								<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Allow Nurse to lorem
								</Typography>
								<Switch id="id" />
							</div>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'subheading2'}
								className={classes['margin-top']}
							>
								Skills:
							</Typography>
							<div className={classes['skills']}>
								{nurse.skills.map(({ id, name }) => (
									<div className={classes['skill']} key={id} style={{ marginRight: 12 }}>
										<p className={classes['info-label']}>{name}</p>
									</div>
								))}
								<button className={classes['skill']} style={{ color: '#000' }}>
									<p className={classes['info-label']}>+ Add more</p>
								</button>
							</div>
						</div> */}
					</div>
					<div className={classes['footButtons']}>
						<Button variant="standard" title="Update Profile" onClick={updateNurse} />
						<Button variant="standard" title="Cancel" onClick={() => setEditView(false)} />
					</div>
				</div>
			</>
		);
	}

	return (
		<div className={classes['nurses-container']}>
			<>
				<div className={classes['left-container']}>
					<div className={classes['profile-image-container']}>
						<div className={classes['small-circle']}>
							<div className={classes['inner-circle']}></div>
						</div>
						<Image src={nurse.user.avatar ?? 'plAvatar'} />
					</div>
					<Typography variant="h3">{nurse.user.name}</Typography>
					{nurse.address && (
						<div
							className={classes['row']}
							style={{
								marginLeft: '-0.3rem',
								marginTop: '0.2rem',
							}}
						>
							<Image src={'icLocation'} />
							<Typography
								variant={isSmallerDevice ? 'h5' : 'subheading2'}
								color="darkgrey"
								className={classes['margin']}
							>
								{getLocation(nurse.address)}
							</Typography>
						</div>
					)}
					{((nurse.user.phoneNumber !== undefined && nurse.user.phoneNumber) ||
						(nurse.user.email !== undefined && nurse.user.email !== null)) && (
						<Typography className={classes['contact-label']}>Contact:</Typography>
					)}
					<div className={classes['row']}>
						{nurse.user.phoneNumber !== undefined && nurse.user.phoneNumber !== null && (
							<Button
								onClick={handleCallButtonClick}
								title="Call"
								variant="standard"
								end={
									<Image
										style={{
											width: '1rem',
											height: '1rem',
										}}
										src="icTelephonePrimary"
									/>
								}
								style={{
									margin: '0.5rem 0.5rem 0 0',
								}}
								className={classes['btn']}
							/>
						)}
						{nurse.user.email !== undefined && nurse.user.email !== null && (
							<Button
								onClick={handleEmailButtonClick}
								title="Email"
								variant="standard"
								end={
									<Image
										style={{
											width: '1rem',
											height: '1rem',
										}}
										src="icMailPrimary"
									/>
								}
								style={{
									margin: '0.5rem 0 0 0',
								}}
								className={classes['btn']}
							/>
						)}
					</div>
				</div>
				<div className={classes['right-container']}>
					{canUpdateNurse && (
						<button
							className={classes['button']}
							onClick={() => setEditView(true)}
							style={{
								alignSelf: 'flex-end',
							}}
						>
							<Image
								src={'icEdit'}
								style={{
									height: '0.9rem',
									width: '0.9rem',
								}}
							/>
							<p>Edit</p>
							<Image
								src={'icChevronDown'}
								style={{
									marginTop: '0.1rem',
									marginLeft: '0.2rem',
									height: '0.75rem',
									width: '0.75rem',
								}}
							/>
						</button>
					)}
					<div className={classes['tabs']}>
						{TABS.map(({ label, id }) => (
							<button
								key={id}
								style={{
									borderBottom: tabIndex === id ? '2px solid #0CB9C3' : '2px solid transparent',
								}}
								onClick={() => setTabIndex(id)}
							>
								<Typography variant={isSmallerDevice ? 'h4' : 'h5'}>{label}</Typography>
							</button>
						))}
					</div>
					<div className={classes['content']}>
						{/* <Typography className={classes['margin-vertical']} variant={isSmallerDevice ? 'h4' : 'h5'}>
							Current Status:{' '}
						</Typography>
						<div className={classes['status-container']}>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'subheading2'}
								color="primary"
								className={classes['status-text']}
							>
								Started in <span style={{ fontWeight: 'bold' }}>3 hours Ago, Jobs #003954</span>
							</Typography>
							<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
								<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>On Location</Typography>
								<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>{step} of 6</Typography>
							</div>
							<div className={classes['progress-container']}>
								<div className={classes['progress']}></div>
								{Array(step)
									.fill('0')
									.map((_, index) => (
										<div
											className={classes['point']}
											style={{
												left: +index === 0 ? 0 : `${(+index / 6) * 100}%`,
											}}
											key={index}
										></div>
									))}
								{step === 6 && (
									<div
										className={classes['point']}
										style={{
											left: '100%',
										}}
									></div>
								)}
								<div
									className={classes['connector']}
									style={{
										width: step === 6 ? '100%' : `${((step - 1) / 6) * 100}%`,
									}}
								></div>
							</div>
						</div> */}
						{nurse.notes && (
							<>
								<Typography
									className={classes['margin-vertical']}
									variant={isSmallerDevice ? 'h4' : 'h5'}
								>
									Private Notes:{' '}
								</Typography>
								<div className={classes['wrapper']}>
									<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>
										{nurse.notes}
									</Typography>
								</div>
							</>
						)}
						<Typography className={classes['margin-vertical']} variant={isSmallerDevice ? 'h4' : 'h5'}>
							Basic info:{' '}
						</Typography>
						<div className={classes['wrapper']}>
							<div className={classes['row']} style={{ flexWrap: 'wrap' }}>
								<Info label="Employee Type" value={NurseEmployeeType.text(nurse.employeeType)} />
								<Info label="Age" value={moment().diff(moment(nurse?.user.dateOfBirth), 'years')} />
								<Info label="Years of Experience" value={nurse.yearsOfExperience} />
								<Info label="Email" value={nurse.user.email} />
								<Info label="Mobile" value={nurse.user.phoneNumber ?? 'N/A'} />
								<Info
									label="Registered"
									value={adjustTime(settingsState, nurse.createdAt).format('MM/DD/YYYY')}
								/>
							</div>
						</div>
						{nurse.skills.length > 0 && (
							<>
								<Typography
									className={classes['margin-vertical']}
									variant={isSmallerDevice ? 'h4' : 'h5'}
								>
									Skills:{' '}
								</Typography>
								<div
									className={classes['wrapper']}
									style={{
										paddingBottom: 0,
									}}
								>
									<div className={classes['row']} style={{ flexWrap: 'wrap' }}>
										{nurse.skills.map(({ id, name }) => (
											<div className={classes['skill']} key={id} style={{ marginRight: 12 }}>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{name}</Typography>
											</div>
										))}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</>
		</div>
	);
};

export default NurseScreen;
