import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Inventory, InventoryInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Inventory> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'inventory',
	initialState,
	reducers: {
		itemsFetched: (state, { payload }: PA<Inventory[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		itemFetched: (state, { payload }: PA<Inventory>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		itemDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const addInventory = (input: InventoryInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVENTORY, method: 'POST' }));

		const { data, error } = await Api.inventory.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVENTORY,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.INVENTORY,
					method: 'POST',
					message: 'Inventory added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchInventory());
		}
	};
};

const updateInventory = (id: number, input: InventoryInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVENTORY_ID(id), method: 'PUT' }));

		const { data, error } = await Api.inventory.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVENTORY_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.itemFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVENTORY_ID(id),
					method: 'PUT',
					message: 'Inventory updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteInventory = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVENTORY_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.inventory.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVENTORY_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.itemDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.INVENTORY_ID(id),
					method: 'DELETE',
					message: 'Inventory deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

const fetchInventory = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVENTORY, method: 'GET' }));

		const { data, error } = await Api.inventory.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVENTORY,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.itemsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVENTORY,
					method: 'GET',
					message: 'Inventory fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchInventoryById = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVENTORY_ID(id), method: 'GET' }));

		const { data, error } = await Api.inventory.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVENTORY_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.itemFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVENTORY_ID(id),
					method: 'GET',
					message: 'Inventory item fetched successfully',
					payload: {},
				})
			);
		}
	};
};

export const inventoryThunks = {
	addInventory,
	updateInventory,
	deleteInventory,
	fetchInventory,
	fetchInventoryById,
};
