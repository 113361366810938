import { useOnOutClick } from 'hooks';
import React, { useRef, useState } from 'react';
import ActionButton from './ActionButton';
import classes from './index.module.scss';
import { InvoicePaymentStatus, InvoicePaymentStatusType } from 'lib/util';

interface Props {
	onSelectStatus: (status: InvoicePaymentStatusType) => any;
	currentStatus: InvoicePaymentStatusType;
}

const PaymentStatusDropdown = ({ onSelectStatus, currentStatus }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const closeDropdown = () => setIsOpen(false);
	const toggleDropdown = () => setIsOpen((prev) => !prev);

	useOnOutClick({ ref, onOutClick: closeDropdown });

	const handleSelect = (status: InvoicePaymentStatusType) => {
		onSelectStatus(status);
		closeDropdown();
	};

	return (
		<div ref={ref} className={classes['paymentStatusDropdown-container']}>
			<ActionButton
				onClick={toggleDropdown}
				title={InvoicePaymentStatus.text(currentStatus)}
				status={InvoicePaymentStatus.color(currentStatus)}
				rightIconSrc="icDownCaret"
			/>
			{isOpen && (
				<ul className={classes['paymentStatusDropdown-list']}>
					<li className={classes['paymentStatusDropdown-listItem']}>
						<a onClick={() => handleSelect(InvoicePaymentStatusType.PAID)}>
							{InvoicePaymentStatus.text(InvoicePaymentStatusType.PAID)}
						</a>
					</li>
					<li className={classes['paymentStatusDropdown-listItem']}>
						<a onClick={() => handleSelect(InvoicePaymentStatusType.UNPAID)}>
							{InvoicePaymentStatus.text(InvoicePaymentStatusType.UNPAID)}
						</a>
					</li>
					<li className={classes['paymentStatusDropdown-listItem']}>
						<a onClick={() => handleSelect(InvoicePaymentStatusType.REFUNDED)}>
							{InvoicePaymentStatus.text(InvoicePaymentStatusType.REFUNDED)}
						</a>
					</li>
				</ul>
			)}
		</div>
	);
};

export default PaymentStatusDropdown;
