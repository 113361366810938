import React from 'react';
import { Typography, Image } from 'lib/components';
import classes from './index.module.scss';

interface Props {
	title: string;
	value: number;
	isSmallerDevice: boolean;
}

const QuickAccessItem = ({ title, value, isSmallerDevice }: Props) => (
	<a className={classes['dashboard-quickAccess-item']}>
		<span className={classes['dashboard-quickAccess-item-icon']}>
			<Image src={'icInvoicePrimary'} alt="invoice icon" />
		</span>
		<Typography
			className={classes['dashboard-quickAccess-item-title']}
			weight="medium"
			variant={isSmallerDevice ? 'h5' : 'p'}
		>
			{title}
		</Typography>
		<Typography className={classes['dashboard-quickAccess-item-value']} weight="bold">
			{value}
		</Typography>
	</a>
);

export default QuickAccessItem;
