import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { Hospital } from 'types';
import { useSelectAuthorization, useSelectSettings } from 'store';
import { Timing } from 'lib/util';
import classes from './index.module.scss';
import adjustTime from '../../../adjustTime';

interface Props {
	hospital: Hospital;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
}

const NurseRow = ({ hospital, checkedId, checkAction }: Props) => {
	const settingsState = useSelectSettings();

	const { push } = useHistory();
	const [checked, setChecked] = useState(false);

	const { canReadHospital } = useSelectAuthorization();

	useEffect(() => {
		if (checkedId === hospital.id) return;
		if (checkedId !== hospital.id || checkedId === -1) setChecked(false);
	}, [checkedId]);

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(hospital.id, e.target.checked);
	};

	const toHospitalView = useCallback(() => {
		if (canReadHospital) push(`/hospitals/${hospital.id}`);
	}, []);

	return (
		<div key={hospital.id} className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleAction}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div className={classes['col']} onClick={toHospitalView}>
				<Typography variant={'h6'}>{hospital.name}</Typography>
			</div>
			<div className={classes['col']} onClick={toHospitalView}>
				<Typography variant={'h6'}>{hospital.email ?? 'N/A'}</Typography>
			</div>
			<div className={classes['col']} onClick={toHospitalView}>
				<Typography variant={'h6'}>{hospital.phoneNumber ?? 'N/A'}</Typography>
			</div>
			<div className={classes['col']} onClick={toHospitalView}>
				<Typography variant={'h6'}>{hospital.employeesAmount}</Typography>
			</div>
			<div className={classes['col']} onClick={toHospitalView}>
				<Typography variant={'h6'}>
					{adjustTime(settingsState, hospital.createdAt).format('MM/DD/YYYY')}
				</Typography>
			</div>
		</div>
	);
};

export default NurseRow;
