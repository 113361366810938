import { Invoice } from 'types';
import { Sort } from './types';

type CompareFunction = (a: Invoice, b: Invoice, sort: Sort) => 1 | -1 | 0;

export const invoicesStatusCompareFn: CompareFunction = (a, b, sort) => {
	if (a.paymentStatus > b.paymentStatus) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.status < b.status) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const invoicesHospitalCompareFn = (a: string, b: string, sort: Sort) => {
	if (sort === 'asc') {
		return a.localeCompare(b) === -1 ? 1 : -1;
	} else {
		return a.localeCompare(b) === -1 ? -1 : 1;
	}
};

export const invoicesIdCompareFun: CompareFunction = (a, b, sort) => {
	if (a.id > b.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.id < b.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const invoicesPriceCompareFun: CompareFunction = (a, b, sort) => {
	if (a.total > b.total) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.total < b.total) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const invoicesCreatedAtCompareFun: CompareFunction = (a, b, sort) => {
	const valueA = new Date(a.createdAt);
	const valueB = new Date(b.createdAt);

	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
