import { Button, DropdownButton } from 'lib/components';
import CustomDatePicker from 'lib/components/CustomDatePicker';
import React from 'react';
import ReactDom from 'react-dom';
import { useInvoicePagination } from '../../hooks';
import { DropdownItem } from '../../lib/components/types';
import { actions, useAppDispatch, useSelectHospitals, useSelectInvoices } from '../../store';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => any;
	onAccrueInvoice: (hospitalId: number) => any;
	startDate: Date;
	endDate: Date;
	setStartDate: (date: Date) => any;
	setEndDate: (date: Date) => any;
}

const AccrueInvoiceModal = ({
	isOpen,
	onClose,
	onAccrueInvoice,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
}: Props) => {
	const dispatch = useAppDispatch();
	const invoices = useSelectInvoices();
	const hospitals = useSelectHospitals();

	const [hospitalDropdownItems, setHospitalDropdownItems] = React.useState<DropdownItem<number>[]>([]);
	const [selectedHospitalDropdownItem, setSelectedHospitalDropdownItem] = React.useState<number>(-1);

	const { isDataFetched, hospitalListing } = useInvoicePagination(invoices, hospitals);

	const handleHospitalDropdownItemSelect = (value: number) => {
		setSelectedHospitalDropdownItem(value);
	};

	React.useEffect(() => {
		if (isDataFetched) {
			setHospitalDropdownItems(() => hospitalListing.map(({ id, name }) => ({ label: name, value: id })));
		}
	}, [isDataFetched]);

	React.useEffect(() => {
		// dispatch(actions.fetchInvoices());
		dispatch(actions.fetchHospitals());
	}, []);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div className={classes['modal-form']}>
						<div className={classes['modal-fields']}>
							<CustomDatePicker label="From" value={startDate} onChange={setStartDate} />
							<CustomDatePicker label="To" value={endDate} onChange={setEndDate} />
						</div>
						<div className={classes['modal-fields']}>
							<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
								<DropdownButton
									css={{ width: '100%' }}
									title="Hospital"
									list={hospitalDropdownItems}
									onItemSelect={handleHospitalDropdownItemSelect}
								/>
							</div>
						</div>
						{/* <Typography className={classes['modal-fields-helperText']}>
							13 Invoices Found between those dates
						</Typography> */}
					</div>
					<div className={classes['modal-footer']}>
						<Button
							disabled={selectedHospitalDropdownItem === -1}
							variant="standard"
							color="primary"
							title="Accrue Invoice"
							style={{
								marginRight: '1rem',
								justifyContent: 'center',
								flexBasis: '50%',
							}}
							onClick={() => {
								onAccrueInvoice(selectedHospitalDropdownItem);
								onClose();
							}}
						/>
						<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default AccrueInvoiceModal;
