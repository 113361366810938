import { useEffect } from 'react';

interface Props {
	ref: any;
	onOutClick: () => any;
}

const useOnOutClick = ({ ref, onOutClick }: Props) => {
	useEffect(() => {
		function handleClickOutside(event: Event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onOutClick();
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};

export default useOnOutClick;
