import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Service, ServiceInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Service> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'services',
	initialState,
	reducers: {
		servicesFetched: (state, { payload }: PA<Service[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		serviceFetched: (state, { payload }: PA<Service>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({ ...payload });
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		serviceDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchServices = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SERVICE, method: 'GET' }));

		const { data, error } = await Api.services.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SERVICE,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.servicesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.SERVICE,
					method: 'GET',
					message: 'Services fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchService = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SERVICE_ID(id), method: 'GET' }));

		const { data, error } = await Api.services.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SERVICE_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.serviceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.SERVICE_ID(id),
					method: 'GET',
					message: 'Service fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addService = (input: ServiceInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SERVICE, method: 'POST' }));

		const { data, error } = await Api.services.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SERVICE,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.SERVICE,
					method: 'POST',
					message: 'Service added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchServices());
		}
	};
};

const updateService = (id: number, input: ServiceInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SERVICE_ID(id), method: 'PUT' }));

		const { data, error } = await Api.services.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SERVICE_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.serviceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.SERVICE_ID(id),
					method: 'PUT',
					message: 'Service updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteService = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SERVICE_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.services.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SERVICE_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.serviceDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.SERVICE_ID(id),
					method: 'DELETE',
					message: 'Service deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const serviceThunks = {
	fetchServices,
	fetchService,
	addService,
	updateService,
	deleteService,
};
