import { useAddressInput, useComprehensiveTopNav, useCustomToast, usePagination, useSearch } from 'hooks';
import { routes } from 'lib/api';
import { Button, Image, LoadingIndicator, Pagination, SearchBar, Typography } from 'lib/components';
import { EmployeesDataTable } from 'lib/components/DataTables';
import { TextField, TimezoneSelect } from 'lib/components/FormFields';
import WorkingHourInputField from 'lib/components/WorkingHourInputField';
import { Day, DayIndex, DayItem, Time, WorkingHoursFactory } from 'lib/components/WorkingHourInputField/types';
import WorkingHourModal from 'lib/components/WorkingHourModal';
import { RateType } from 'lib/util';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectBuddyPunchLocationState,
	useSelectEmployees,
	useSelectHospital,
	useSelectHospitals,
	useSelectRequest,
} from 'store';
import { Hospital, HospitalWorkingHours } from 'types';
import classes from './index.module.scss';
import { BuddyPunchLocation } from '../../types/entity';
import BuddyPunchLocationSelect from '../../lib/components/FormFields/CustomSelect/BuddyPunchLocationSelect';

const NUMBER_SYMBOL = ['$', '%'];
const WORKING_HOURS_FACTORY_INIT = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
].reduce<WorkingHoursFactory>(
	(acc, cur) => {
		acc.days.push({
			day: cur as Day,
			isOperating: false,
			startTime: { hr: '08', min: '00', pm: false },
			endTime: { hr: '08', min: '00', pm: true },
		});
		return acc;
	},
	{ days: [], selectedIndex: 0 }
);

interface Props {
	hospital: Hospital;
}

const HospitalEditScreen: React.FC = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const hospitals = useSelectHospitals();
	const buddyPunchLocation = useSelectBuddyPunchLocationState();
	const getHospitalById = useSelectHospital();

	const [hospital, setHospital] = useState<Hospital>();

	useEffect(() => {
		dispatch(actions.fetchHospital(parseInt(id)));
		dispatch(actions.getBuddyPunchLocations());
	}, []);

	useEffect(() => {
		const hospital = getHospitalById(parseInt(id));

		if (hospital) {
			setHospital(hospital);
		}
	}, [hospitals.updatedAt, buddyPunchLocation.updatedAt]);

	return hospital ? <HospitalEditScreenView hospital={hospital} /> : <LoadingIndicator />;
};

const HospitalEditScreenView = ({ hospital }: Props) => {
	const toast = useCustomToast();
	const { push } = useHistory();

	const dispatch = useAppDispatch();
	const { canCreateEmployee, canListEmployee } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const hospitals = useSelectHospitals();
	const buddyPunchLocationState = useSelectBuddyPunchLocationState();
	const employees = useSelectEmployees();

	const [name, setName] = useState(hospital.name);
	const [timezone, setTimeZone] = useState<string | undefined>(hospital.timezone);
	const [email, setEmail] = useState(hospital.email);
	const [employeesAmount, setEmployeesAmount] = useState(hospital.employeesAmount ?? 0);
	const [phoneNumber, setPhoneNumber] = useState(hospital.phoneNumber ?? '');
	const [payoutId, setPayoutId] = useState(hospital.payoutId ?? '');
	const [regularRate, setRegularRate] = useState(hospital.regularRate ?? 0);
	const [overtimeRate, setOvertimeRate] = useState(hospital.overtimeRate ?? 0);
	const [weekendRate, setWeekendRate] = useState(hospital.weekendRate ?? 0);
	const [holidayRate, setHolidayRate] = useState(hospital.holidayRate ?? 0);
	const [overtimeRateTypeId, setOvertimeRateTypeId] = useState<RateType>(hospital.overtimeRateTypeId ?? 0);
	const [weekendRateTypeId, setWeekendRateTypeId] = useState<RateType>(hospital.weekendRateTypeId ?? 0);
	const [holidayRateTypeId, setHolidayRateTypeId] = useState<RateType>(hospital.holidayRateTypeId ?? 0);
	const [buddyPunchId, setBuddyPunchId] = useState<BuddyPunchLocation | undefined>(
		hospital.buddyPunchId !== undefined
			? buddyPunchLocationState.list.find(
					(element) => parseInt(element.id as unknown as string, 10) === parseInt(hospital.buddyPunchId!, 10)
			  )
			: undefined
	);

	const [beforeDispatch, setBeforeDispatch] = useState<number>(hospital.cancellationPolicy.beforeDispatch);
	const [afterDispatch, setAfterDispatch] = useState<number>(hospital.cancellationPolicy.afterDispatch);
	const [beforeTreatment, setBeforeTreatment] = useState<number>(hospital.cancellationPolicy.beforeTreatment);
	const [duringTreatment, setDuringTreatment] = useState<number>(hospital.cancellationPolicy.duringTreatment);

	const [query, setQuery] = useState('');

	useEffect(() => {
		dispatch(actions.fetchEmployees(hospital.id));
	}, []);

	React.useEffect(() => {
		if (buddyPunchId === undefined) {
			setBuddyPunchId(
				hospital.buddyPunchId !== undefined
					? buddyPunchLocationState.list.find(
							(element) =>
								parseInt(element.id as unknown as string, 10) === parseInt(hospital.buddyPunchId!, 10)
					  )
					: undefined
			);
		}
	}, [hospitals.updatedAt, buddyPunchLocationState.updatedAt]);

	const { isDataFetched, setData, listing, paginatedData, paginationProps } = usePagination(employees, {
		itemsPerPage: 3,
	});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(employee) =>
					employee.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					employee.email.toLowerCase().search(query.toLowerCase()) !== -1 ||
					employee.phoneNumber.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL_ID(hospital.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	const [workingHoursFactory, setWorkingHoursFactory] = useState<WorkingHoursFactory>(WORKING_HOURS_FACTORY_INIT);
	const [workingHourModalOpen, setWorkingHourModalOpen] = useState(false);

	const nextWorkingDay = () => {
		setWorkingHoursFactory((prev) => {
			const newIndex = prev.selectedIndex + 1 > 6 ? 0 : prev.selectedIndex + 1;
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: newIndex as DayIndex,
			};
		});
	};

	const prevWorkingDay = () =>
		setWorkingHoursFactory((prev) => {
			const newIndex = prev.selectedIndex - 1 < 0 ? 6 : prev.selectedIndex - 1;
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: newIndex as DayIndex,
			};
		});

	const handleWorkingHourModalOnConfirmed = (dayItem: DayItem) => {
		setWorkingHoursFactory((prev) => {
			const newDays = [...prev.days].map((item) => {
				if (item.day === dayItem.day) {
					return { ...dayItem };
				}
				return item;
			});
			return {
				...prev,
				days: newDays,
			};
		});
	};

	const setWorkingHoursDayIndex = (index: DayIndex) => {
		setWorkingHoursFactory((prev) => {
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: index,
			};
		});
	};

	const handleWorkingHourFieldClick = (index: number) => {
		setWorkingHourModalOpen((prev) => {
			setWorkingHoursDayIndex(index as DayIndex);
			return !prev;
		});
	};

	const {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	} = useAddressInput(hospital.address);

	const parseDay = useCallback(
		(entry: string | null, defaultIsPM: boolean) => {
			if (entry) {
				const time = moment.utc(entry, 'hh:mm A');
				return {
					hr: time.format('hh'),
					min: time.format('mm'),
					pm: entry.includes('PM'),
				};
			}

			return {
				hr: '09',
				min: '00',
				pm: defaultIsPM,
			};
		},
		[hospital.workingHours]
	);

	useEffect(() => {
		const workingHours: WorkingHoursFactory = {
			days: [
				{
					day: 'Monday',
					isOperating: hospital.workingHours.monFrom !== null && hospital.workingHours.monTo !== null,
					startTime: parseDay(hospital.workingHours.monFrom, false),
					endTime: parseDay(hospital.workingHours.monTo, true),
				},
				{
					day: 'Tuesday',
					isOperating: hospital.workingHours.tueFrom !== null && hospital.workingHours.tueTo !== null,
					startTime: parseDay(hospital.workingHours.tueFrom, false),
					endTime: parseDay(hospital.workingHours.tueTo, true),
				},
				{
					day: 'Wednesday',
					isOperating: hospital.workingHours.wedFrom !== null && hospital.workingHours.wedTo !== null,
					startTime: parseDay(hospital.workingHours.wedFrom, false),
					endTime: parseDay(hospital.workingHours.wedTo, true),
				},
				{
					day: 'Thursday',
					isOperating: hospital.workingHours.thuFrom !== null && hospital.workingHours.thuTo !== null,
					startTime: parseDay(hospital.workingHours.thuFrom, false),
					endTime: parseDay(hospital.workingHours.thuTo, true),
				},
				{
					day: 'Friday',
					isOperating: hospital.workingHours.friFrom !== null && hospital.workingHours.friTo !== null,
					startTime: parseDay(hospital.workingHours.friFrom, false),
					endTime: parseDay(hospital.workingHours.friTo, true),
				},
				{
					day: 'Saturday',
					isOperating: hospital.workingHours.satFrom !== null && hospital.workingHours.satTo !== null,
					startTime: parseDay(hospital.workingHours.satFrom, false),
					endTime: parseDay(hospital.workingHours.satTo, true),
				},
				{
					day: 'Sunday',
					isOperating: hospital.workingHours.sunFrom !== null && hospital.workingHours.sunTo !== null,
					startTime: parseDay(hospital.workingHours.sunFrom, false),
					endTime: parseDay(hospital.workingHours.sunTo, true),
				},
			],
			selectedIndex: workingHoursFactory.selectedIndex,
		};

		setWorkingHoursFactory(workingHours);
	}, [hospital.workingHours]);

	const formatDay = useCallback(
		(entry: Time, isOperating: boolean) => {
			return isOperating ? `${entry.hr}:${entry.min} ${entry.pm ? 'PM' : 'AM'}` : null;
		},
		[workingHoursFactory]
	);

	const updateHospital = () => {
		const workingHours: HospitalWorkingHours = {
			monFrom: formatDay(workingHoursFactory.days[0].startTime, workingHoursFactory.days[0].isOperating),
			monTo: formatDay(workingHoursFactory.days[0].endTime, workingHoursFactory.days[0].isOperating),
			tueFrom: formatDay(workingHoursFactory.days[1].startTime, workingHoursFactory.days[1].isOperating),
			tueTo: formatDay(workingHoursFactory.days[1].endTime, workingHoursFactory.days[1].isOperating),
			wedFrom: formatDay(workingHoursFactory.days[2].startTime, workingHoursFactory.days[2].isOperating),
			wedTo: formatDay(workingHoursFactory.days[2].endTime, workingHoursFactory.days[2].isOperating),
			thuFrom: formatDay(workingHoursFactory.days[3].startTime, workingHoursFactory.days[3].isOperating),
			thuTo: formatDay(workingHoursFactory.days[3].endTime, workingHoursFactory.days[3].isOperating),
			friFrom: formatDay(workingHoursFactory.days[4].startTime, workingHoursFactory.days[4].isOperating),
			friTo: formatDay(workingHoursFactory.days[4].endTime, workingHoursFactory.days[4].isOperating),
			satFrom: formatDay(workingHoursFactory.days[5].startTime, workingHoursFactory.days[5].isOperating),
			satTo: formatDay(workingHoursFactory.days[5].endTime, workingHoursFactory.days[5].isOperating),
			sunFrom: formatDay(workingHoursFactory.days[6].startTime, workingHoursFactory.days[6].isOperating),
			sunTo: formatDay(workingHoursFactory.days[6].endTime, workingHoursFactory.days[6].isOperating),
		};

		dispatch(
			actions.updateHospital(hospital.id, {
				name,
				email,
				addressLine1,
				addressLine2: addressLine2 === '' ? undefined : addressLine2,
				city,
				state,
				postalCode,
				phoneNumber,
				payoutId: payoutId !== '' ? payoutId : undefined,
				buddyPunchId: buddyPunchId !== undefined ? buddyPunchId.id.toString() : undefined,
				regularRate,
				overtimeRate,
				weekendRate,
				holidayRate,
				overtimeRateTypeId,
				weekendRateTypeId,
				holidayRateTypeId,
				employeesAmount: employeesAmount !== 0 ? employeesAmount : undefined,
				timezone: timezone!,
				workingHours,
				cancellationPolicy: {
					beforeDispatch,
					afterDispatch,
					beforeTreatment,
					duringTreatment,
				},
			})
		);
	};

	const handleSymbolChange = (field: 'overtime' | 'weekend' | 'holiday', symbol: RateType) => {
		if (field === 'overtime') setOvertimeRateTypeId(symbol);
		else if (field === 'weekend') setWeekendRateTypeId(symbol);
		else setHolidayRateTypeId(symbol);
	};

	// const handleCheckAction = (id: number, isChecked: boolean) => {
	// 	if (isChecked && !checked.includes(id)) {
	// 		setChecked([id]);
	// 		return;
	// 	}

	// 	if (!isChecked || checked.includes(id)) {
	// 		setChecked([]);
	// 	}
	// };

	// const handleDelete = () => {
	// 	dispatch(actions.deleteEmployee(hospital.id, checked[0]));
	// };

	useComprehensiveTopNav(`${name} - Edit`);

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<WorkingHourModal
				isOpen={workingHourModalOpen}
				workingHoursFactory={workingHoursFactory}
				onClose={() => setWorkingHourModalOpen(false)}
				onNextDay={nextWorkingDay}
				onPrevDay={prevWorkingDay}
				onConfirmed={handleWorkingHourModalOnConfirmed}
			/>
			<div className={classes['editHospitalNew']}>
				<div className={classes['editHospitalNew-section']}>
					<div
						className={
							classes['editHospitalNew-sectionFields'] +
							' ' +
							classes['editHospitalNew-spacingTextFields']
						}
					>
						<div className={classes['editHospitalNew-hospitalNameField']}>
							<TextField
								value={name}
								onChange={(e) => setName(e.target.value)}
								color="primary"
								variant="labelled"
								label="Hospital Name:"
							/>
						</div>
						<TimezoneSelect value={hospital.timezone} setValue={setTimeZone} />
						<TextField
							placeholder="Address 1"
							label="Address Line 1:"
							variant="labelled"
							value={addressLine1}
							onChange={(e) => setAddressLine1(e.target.value)}
						/>
						<TextField
							placeholder="Address 2"
							label="Address Line 2:"
							variant="labelled"
							value={addressLine2}
							onChange={(e) => setAddressLine2(e.target.value)}
						/>
						<TextField
							placeholder="Ohara"
							label="City:"
							variant="labelled"
							value={city}
							onChange={(e) => setCity(e.target.value)}
						/>
						<TextField
							placeholder="Texas"
							label="State:"
							variant="labelled"
							value={state}
							onChange={(e) => setState(e.target.value)}
						/>
						<TextField
							placeholder="00000"
							label="Postal Code:"
							variant="labelled"
							value={postalCode}
							onChange={(e) => setPostalCode(e.target.value)}
						/>
					</div>
				</div>
				<div className={classes['editHospitalNew-section']}>
					<Typography weight="bold" className={classes['editHospitalNew-sectionTitle']}>
						Basic Info
					</Typography>
					<div className={classes['editHospitalNew-sectionFields']}>
						<div className={classes['editHospitalNew-phoneField']}>
							<TextField
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								variant="labelled"
								label="Phone:"
								placeholder="+1 55 55 55 555"
							/>
						</div>
						<div className={classes['editHospitalNew-employeeField']}>
							<TextField
								value={employeesAmount}
								onChange={(e) => setEmployeesAmount(e.target.valueAsNumber)}
								variant="labelled"
								label="Employees:"
								type="number"
							/>
						</div>
						<div className={classes['editHospitalNew-emailField']}>
							<TextField
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								variant="labelled"
								type="email"
								label="Email:"
								placeholder="contact@example.com"
							/>
						</div>
						<div>
							<BuddyPunchLocationSelect value={buddyPunchId} setValue={setBuddyPunchId} />
						</div>
					</div>
				</div>
				<div className={classes['editHospitalNew-section']}>
					<Typography weight="bold" className={classes['editHospitalNew-sectionTitle']}>
						Cancellation Policy
					</Typography>
					<div className={classes['editHospitalNew-sectionFields']}>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Before Dispatch:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={beforeDispatch}
								onChange={(e) => setBeforeDispatch(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="After Dispatch:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={afterDispatch}
								onChange={(e) => setAfterDispatch(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Before Treatment:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={beforeTreatment}
								onChange={(e) => setBeforeTreatment(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="During Treatment:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={duringTreatment}
								onChange={(e) => setDuringTreatment(e.target.valueAsNumber)}
							/>
						</div>
					</div>
				</div>
				<div className={classes['editHospitalNew-section']}>
					<Typography weight="bold" className={classes['editHospitalNew-sectionTitle']}>
						Pricing Per Hour
					</Typography>
					<div className={classes['editHospitalNew-sectionFields']}>
						<div className={classes['editHospitalNew-quickbooksField']}>
							<TextField
								variant="labelled"
								label="Quickbooks ID"
								placeholder="123465798"
								value={payoutId}
								onChange={(e) => setPayoutId(e.target.value)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Pricing per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={regularRate}
								onChange={(e) => setRegularRate(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-overtimePerHourField']}>
							<TextField
								variant="labelled"
								label="Overtime per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={overtimeRate}
								defaultSymbolIndex={overtimeRateTypeId}
								onChange={(e) => setOvertimeRate(e.target.valueAsNumber)}
								onSymbolChange={(value) => handleSymbolChange('overtime', value)}
							/>
						</div>
						<div className={classes['editHospitalNew-weekendPerHourField']}>
							<TextField
								variant="labelled"
								label="Weekend per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={weekendRate}
								defaultSymbolIndex={weekendRateTypeId}
								onChange={(e) => setWeekendRate(e.target.valueAsNumber)}
								onSymbolChange={(value) => handleSymbolChange('weekend', value)}
							/>
						</div>
						<div className={classes['editHospitalNew-holidayPerHourField']}>
							<TextField
								variant="labelled"
								label="Holiday per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={holidayRate}
								defaultSymbolIndex={holidayRateTypeId}
								onChange={(e) => setHolidayRate(e.target.valueAsNumber)}
								onSymbolChange={(value) => handleSymbolChange('holiday', value)}
							/>
						</div>
					</div>
				</div>
				<div className={classes['editHospitalNew-section']}>
					<Typography weight="bold" className={classes['editHospitalNew-sectionTitle']}>
						Working Hours
					</Typography>
					<div className={classes['editHospitalNew-sectionFields']}>
						{workingHoursFactory.days.map((item, index) => (
							<div
								onClick={() => handleWorkingHourFieldClick(index)}
								className={classes['createHospitalNew-workingHoursField']}
								key={item.day}
								style={{ cursor: 'pointer' }}
							>
								<WorkingHourInputField
									day={item.day}
									start={item.startTime}
									end={item.endTime}
									onChange={(value) => {
										const workingHours = { ...workingHoursFactory };
										workingHours.days[index].startTime = value.start;
										workingHours.days[index].endTime = value.end;

										setWorkingHoursFactory(workingHours);
									}}
									disabled
									prefix
								/>
							</div>
						))}
					</div>
				</div>

				{canListEmployee && (
					<div className={classes['editHospitalNew-employees']}>
						<div className={classes['editHospitalNew-employeesTop']}>
							<Typography weight="bold">Employees:</Typography>
							<div className={classes['editHospitalNew-employeesTop-controls']}>
								<SearchBar
									query={query}
									setQuery={setQuery}
									className={classes['editHospitalNew-employeesTop-search']}
									placeholder="Search..."
									variant="outline"
								/>
								{canCreateEmployee && (
									<a
										className={classes['editHospitalNew-employeesTop-add']}
										onClick={() => push(`/employees/${hospital.id}/new`)}
									>
										<Typography>Add New Employee</Typography>
										<span>
											<Image src="icPlusPrimary" />
										</span>
									</a>
								)}
							</div>
							<EmployeesDataTable
								hospitalId={hospital.id}
								employees={paginatedData}
								checkAction={() => {}}
								checkedId={-1}
							/>
							<Pagination {...paginationProps} />
						</div>
					</div>
				)}
				<div className={classes['editHospitalNew-footer']}>
					<Button
						variant="standard"
						color="primary"
						title="Update Hospital"
						style={{
							marginRight: '1rem',
						}}
						onClick={updateHospital}
					/>
					<Button variant="standard" color="gray" title="Cancel" onClick={() => push('/hospitals')} />
				</div>
			</div>
		</>
	);
};

export default HospitalEditScreen;
