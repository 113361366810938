import React, { useCallback, useState } from 'react';
import ReactDom from 'react-dom';
import classes from './index.module.scss';
import DatePicker from 'react-datepicker';
import { Image, Typography } from '..';
import Button from '../Button';
import Dropdown from './Dropdown';

interface BodyProps {
	selectedDate: Date | null;
	setSelectedDate: (date: Date) => any;
}

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const generateYears = () => {
	const max = new Date().getFullYear();
	const min = max - 80;
	const years = [];

	for (let year = max; year >= min; year--) {
		years.push(year);
	}

	return years;
};

const CustomDatePickerBody = ({ selectedDate, setSelectedDate }: BodyProps) => {
	const onChange = (date: Date) => {
		setSelectedDate(date);
	};

	const customHeader = useCallback(
		({
			date,
			decreaseMonth,
			increaseMonth,
			changeYear,
			changeMonth,
		}: {
			date: Date;
			decreaseMonth: () => any;
			increaseMonth: () => any;
			changeYear: (year: number) => void;
			changeMonth: (month: number) => void;
		}) => (
			<div
				style={{
					margin: 10,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div onClick={decreaseMonth} className={classes['dpc-btn']}>
					<Image src="icChevronLeft" />
				</div>
				<div className={classes['dpc-dateText']}>
					<div className={classes['dpc-dateText-content']}>
						<Dropdown
							label={MONTHS[(date as Date).getMonth()]}
							items={MONTHS}
							onItemClick={(month: string) => changeMonth(MONTHS.indexOf(month))}
						/>
					</div>
					<div className={classes['dpc-dateText-content']}>
						<Dropdown
							label={(date as Date).getFullYear().toString()}
							items={generateYears()}
							onItemClick={(year: number) => changeYear(year)}
						/>
					</div>
				</div>
				<div onClick={increaseMonth} className={classes['dpc-btn']}>
					<Image src="icCheveronRight" />
				</div>
			</div>
		),
		[]
	);

	return (
		<DatePicker
			autoFocus
			selected={selectedDate}
			onChange={onChange}
			renderCustomHeader={customHeader}
			dateFormat="dd/MM/yyyy"
			calendarClassName={classes['dpc']}
			dayClassName={() => classes['dpc-dayName']}
			weekDayClassName={() => classes['dpc-weekName']}
			timeClassName={() => classes['dpc-timeName']}
			customInput={<input style={{ display: 'none' }} />}
			inline
		/>
	);
};

interface ModalProps {
	isOpen: boolean;
	onClose: () => any;
	onDateSet: (date: Date) => any;
}

const CustomSelectDatePickerModal = ({ isOpen, onClose, onDateSet }: ModalProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-heading']}>
					<Typography>Pick Invoice Dates</Typography>
				</div>
				<div className={classes['modal-calendar']}>
					<CustomDatePickerBody selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
				</div>
				<div className={classes['modal-footer']}>
					<Button
						variant="standard"
						color="primary"
						title="Select Date"
						style={{
							justifyContent: 'center',
						}}
						disabled={!selectedDate}
						onClick={() => {
							onDateSet(selectedDate!);
							onClose();
						}}
					/>
					<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default CustomSelectDatePickerModal;
