import React, { useState } from 'react';
import Select from 'react-select';
import { useSelectNurses } from 'store';
import { SelectOption } from 'lib/components/types';
import classes from './index.module.scss';

interface Props {
	value?: number;
	defaultValue?: SelectOption<number>;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const NurseSelect = ({ value, setValue, defaultValue }: Props) => {
	const nurses = useSelectNurses();

	const options: SelectOption<number>[] = nurses.list.map(({ id, user: { name } }) => ({
		value: id,
		label: name,
	}));

	const [option, setOption] = useState<SelectOption<number> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
		setValue(option?.value);
	};

	return (
		<Select
			placeholder="Assign a Nurse"
			defaultValue={defaultValue}
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			options={options}
			isClearable={true}
			isSearchable={true}
			value={option}
			onChange={handleChange}
		/>
	);
};

export default NurseSelect;
