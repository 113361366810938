import { Job } from 'types';
import { Sort } from './types';

export const jobIdCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (a.id > b.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.id < b.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobStartTimeCompareFn = (a: Job, b: Job, sort: Sort) => {
	const valueA = new Date(a.startingAt);
	const valueB = new Date(b.startingAt);
	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobEndTimeCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (!a.nurseFinishedAt || !b.nurseFinishedAt) {
		return 0;
	}
	const valueA = new Date(a.nurseFinishedAt);
	const valueB = new Date(b.nurseFinishedAt);
	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobRoomNoCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (!a.invoice || !b.invoice) {
		return 0;
	}
	if (a.invoice?.id > b.invoice?.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.invoice?.id > b.invoice?.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobStatusCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (a.status > b.status) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.status < b.status) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
