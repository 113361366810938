import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import moment from 'moment';
import { actions, AppThunk, SliceState } from '..';

const initialState: SliceState<number> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'restricted_inventory',
	initialState,
	reducers: {
		restrictedInventoryFetched: (state, { payload }: PA<number[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchRestrictedInventory = (hospitalId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(
			actions.requestStarted({ name: routes.HOSPITAL_INVENTORY_LIST_RESTRICTED(hospitalId), method: 'GET' })
		);

		const { data, error } = await Api.hospitals.inventory.listRestricted(hospitalId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_INVENTORY_LIST_RESTRICTED(hospitalId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.restrictedInventoryFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_INVENTORY_LIST_RESTRICTED(hospitalId),
					method: 'GET',
					message: 'Restricted inventory fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const restrictInventory = (hospitalId: number, inventory: number[]): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_INVENTORY_RESTRICT(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.inventory.restrict(hospitalId, inventory);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_INVENTORY_RESTRICT(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_INVENTORY_RESTRICT(hospitalId),
					method: 'POST',
					message: 'Added Inventory(s) to restricted list',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchRestrictedInventory(hospitalId));
		}
	};
};

const unRestrictInventory = (hospitalId: number, inventory: number[]): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_INVENTORY_UNRESTRICT(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.inventory.unrestrict(hospitalId, inventory);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_INVENTORY_UNRESTRICT(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_INVENTORY_UNRESTRICT(hospitalId),
					method: 'POST',
					message: 'Removed Inventory(s) from restricted list',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchRestrictedInventory(hospitalId));
		}
	};
};

export const restrictedInventoryThunks = {
	fetchRestrictedInventory,
	restrictInventory,
	unRestrictInventory,
};
