const getJobAttributeName = (name: string) => {
	switch (name) {
		case 'hemodialysis':
			return 'Hemodialysis';

		case 'sled':
			return 'SLED';

		case 'puf':
			return 'PUF';

		case 'covid':
			return 'COVID';

		case 'ct':
			return 'CT';

		case 'dropup':
			return 'DROPUP';

		case 'airborne':
			return 'AIRBORNE';

		case 'contact':
			return 'CONTACT';

		case 'access':
			return 'ACCESS';

		case 'n_ttc':
			return 'Nontunelled Dialysis Catheter';

		case 'ttc':
			return 'Tunelled Dialysis Catheter';

		case 'avf':
			return 'AVF';

		case 'avg':
			return 'AVG';

		case 'tx_time_ordered':
			return 'Tx Time Ordered';

		case 'bfr_ordered':
			return 'BFR Ordered';

		case 'dfr_ordered':
			return 'DFR Ordered';

		case 'dialyzer':
			return 'Dialyzer';

		case 'k':
			return 'K';

		case 'na':
			return 'Na';

		case 'ufgoal':
			return 'UFGoal';

		case 'needle_gauge':
			return 'Needle Gauge';

		case 'treat_preheat':
			return 'Treat Preheat';

		case 'prerinse':
			return 'Prerinse';

		case 'bleach_disinfection':
			return 'Bleach Disinfection';

		case 'rapid_response':
			return 'Rapid Response';

		case 'no_orders':
			return 'No Orders';

		case 'code_blue':
			return 'Code Blue';

		case 'patient_not_ready':
			return 'Patient not Ready';

		case 'patient_not_ready_text':
			return 'Patient not Ready Details';

		case 'change_lines':
			return 'Change Lines';

		case 'patient_not_in_room':
			return 'Patient not In Room';

		case 'patient_not_in_room_text':
			return 'Patient not In Room Details';

		case 'access_p':
			return 'Access P...?';

		case 'access_p_text':
			return 'Access P...? Details';

		case 'procedure':
			return 'Procedure';

		case 'procedure_text':
			return 'Procedure Details';

		case 'difficult_access':
			return 'Difficult Access';

		case 'difficult_access_text':
			return 'Difficult Access Details';

		case 'troubleshoot_access':
			return 'Troubleshoot Access';

		case 'troubleshoot_access_text':
			return 'Troubleshoot Access Details';

		case 'waiting_other':
		case 'waiting_other_text':
			return 'Other';

		case 'patient_refused':
			return 'Patient Refused';

		case 'patient_discharged':
			return 'Patient Discharged';

		case 'hospice_':
			return 'Hospice...?';

		case 'no_access':
			return 'No Access';

		case 'patient_unstable':
			return 'Patient Unstable';

		case 'md_cancelled':
			return 'Md Cancelled';

		case 'other':
		case 'other_text':
			return 'Other';

		case 'post_heat':
			return 'Post Heat';

		case 'rinse':
			return 'Rinse';

		case 'chemical_disinfection':
			return 'Chemical Disinfection';

		default:
			return `unknown (${name})`;
	}
};

export default getJobAttributeName;
