import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useAppDispatch, useSelectAuthentication, actions, useSelectCurrentUser, useSelectRequest } from 'store';
import { LoadingIndicator } from 'lib/components';
import { redirectToAuthPortal } from 'lib/redirect';
import { useAuthenticationStorage } from 'hooks/useLocalStorage';
import { Timing, removeAuth } from 'lib/util';
import routes from 'lib/api/routes';

const Auth = () => {
	const { search } = useLocation();
	const uniqueId = new URLSearchParams(search).get('uniqueId');
	const redirectTo = new URLSearchParams(search).get('redirectTo');
	const [isAuth, setIsAuth] = useState(false);
	const [auth, setAuth] = useAuthenticationStorage();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const authentication = useSelectAuthentication();
	const me = useSelectCurrentUser();

	// User details request status
	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}
		// Clear auth if request fails
		if (active.name === routes.USER && active.method === 'GET') {
			if (active.status === 'failed') {
				removeAuth();
				redirectToAuthPortal();
			}

			if (active.status === 'succeeded') {
				const { accessToken, expiryAt } = auth!;

				// Add auth data to redux state
				dispatch(actions.authenticationFinished({ accessToken, expiryAt }));
			}
		}
	}, [active]);

	useEffect(() => {
		if (!uniqueId) {
			// On page refresh or if route is entered manually
			if (auth && !Timing.isExpired(auth.expiryAt)) {
				// Ask server for user details
				dispatch(actions.getCurrentUser());
			} else {
				redirectToAuthPortal();
			}
		} else {
			// From login portal
			dispatch(actions.getAccessToken(uniqueId));
		}
	}, [uniqueId]);

	// On auth successful and user fetched
	useEffect(() => {
		console.log({ active, me });
		if (!authentication.isAuthenticated) return;

		if (authentication.isAuthenticated) {
			const { accessToken, expiryAt } = authentication;
			setAuth({ accessToken, expiryAt, user: me });
			setIsAuth(true);
		}
	}, [authentication, me]);

	return isAuth ? <Redirect to={redirectTo ?? 'dashboard'} /> : <LoadingIndicator />;
};

export default Auth;
