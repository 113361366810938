import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import InventorySelect from './InventorySelect';
import { Inventory, JobInventory } from 'types';
import classes from '../index.module.scss';

interface Props {
	items: Array<Inventory>;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => any;
	addInventory: (inventory: JobInventory) => any;
	editInventory: (inventory: JobInventory) => any;
	item?: JobInventory;
	selected: Array<number>;
}

const InventoryInputModal = ({
	items,
	item,
	selected,
	isOpen,
	setIsOpen,
	addInventory,
	editInventory,
	onClose,
}: Props) => {
	const [inventoryId, setInventoryId] = useState<number | undefined>();
	const [name, setName] = useState<string | undefined>();
	const [amount, setAmount] = useState(0);

	useEffect(() => {
		if (item) {
			setInventoryId(item.inventoryId);
			setName(item.name);
			setAmount(item.amount);
		} else {
			setInventoryId(undefined);
			setName('');
			setAmount(0);
			setIsOpen(false);
		}
	}, [item]);

	const addItem = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Escape') {
			setIsOpen(false);
		}

		if (e.key === 'Enter') {
			if (item) {
				editInventory({ amount, inventoryId: inventoryId as number, name: name as string });
			} else {
				addInventory({ amount, inventoryId: inventoryId as number, name: name as string });
			}
			setInventoryId(undefined);
			setName('');
			setAmount(0);
			setIsOpen(false);
		}
	};

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div style={{ padding: '4rem 2rem 0rem' }}>
					<div className={classes['inventory-input-container']}>
						<InventorySelect
							items={items}
							disabled={item ? true : false}
							selected={selected}
							value={inventoryId}
							setValue={setInventoryId}
							setLabel={setName}
						/>
						<div className={classes['amount-input-container']}>
							<input
								type="number"
								className={classes['amount-input']}
								value={amount}
								onChange={(e) => setAmount(e.target.valueAsNumber)}
								onKeyDown={addItem}
							/>
						</div>
					</div>
				</div>
				<div className={classes['modal-buttons']}>
					<Button
						variant="standard"
						title={item ? 'Update' : 'Add'}
						onClick={() => {
							if (name && amount && inventoryId) {
								if (item) {
									editInventory({ amount, inventoryId, name });
								} else {
									addInventory({ amount, inventoryId, name });
								}
								setInventoryId(undefined);
								setName('');
								setAmount(0);
								setIsOpen(false);
							}
						}}
						disabled={!(name && amount && inventoryId)}
					/>
					<Button color="gray" variant="standard" title="Cancel" onClick={onClose} />
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default InventoryInputModal;
