export enum Roles {
	UNASSIGNED = 0,
	SUPER_ADMIN = 1,
	ADMIN = 2,
	MANAGER = 3,
}

export enum EmployeeType {
	FULL_TIME = 0,
	PART_TIME = 1,
	CONTRACTOR = 2,
}

export enum InventoryType {
	UNIT = 0,
	PACKAGE = 1,
	LIQUID = 2,
}

export enum InventoryUnitType {
	ML = 0,
	L = 1,
	G = 2,
	MG = 3,
}

export enum JobStatusType {
	PENDING = 0,
	ACCEPTED = 1,
	ACKNOWLEDGED = 2,
	ARRIVED = 3,
	NURSE_ACCEPTED = 4,
	NURSE_REJECTED = 5,
	TREATMENT_INITIATED = 6,
	TREATMENT_COMPLETED = 7,
	COMPLETED = 8,
	ABORTED = 9,
	CANCELLED = 10,
	VOIDED = 11,
}

export enum JobTreatmentType {
	HD = 0,
	PD = 1,
	SLED = 2,
}

export enum RateType {
	FIXED = 0,
	PERCENTAGE = 1,
}

export enum PermissionType {
	CREATE_HOSPITAL = 0,
	READ_HOSPITAL = 1,
	UPDATE_HOSPITAL = 2,
	DELETE_HOSPITAL = 3,
	LIST_HOSPITAL = 4,
	CREATE_NURSE = 10,
	READ_NURSE = 11,
	UPDATE_NURSE = 12,
	DELETE_NURSE = 13,
	LIST_NURSE = 14,
	CREATE_VENDOR = 20,
	READ_VENDOR = 21,
	UPDATE_VENDOR = 22,
	DELETE_VENDOR = 23,
	LIST_VENDOR = 24,
	CREATE_INVENTORY = 30,
	READ_INVENTORY = 31,
	UPDATE_INVENTORY = 32,
	DELETE_INVENTORY = 33,
	LIST_INVENTORY = 34,
	CREATE_JOB = 40,
	READ_JOB = 41,
	UPDATE_JOB = 42,
	DELETE_JOB = 43,
	LIST_JOB = 44,
	VOID_INVOICE = 50,
	LIST_INVOICE = 51,
	READ_INVOICE = 52,
	CREATE_EMPLOYEE = 60,
	READ_EMPLOYEE = 61,
	UPDATE_EMPLOYEE = 62,
	DELETE_EMPLOYEE = 63,
	LIST_EMPLOYEE = 64,
	CREATE_SERVICE = 70,
	READ_SERVICE = 71,
	UPDATE_SERVICE = 72,
	DELETE_SERVICE = 73,
	LIST_SERVICE = 74,
	CREATE_CUSTOM_COST = 80,
	READ_CUSTOM_COST = 81,
	UPDATE_CUSTOM_COST = 82,
	DELETE_CUSTOM_COST = 83,
	LIST_CUSTOM_COST = 84,
}

export enum InvoiceStatusType {
	CREATED = 0,
	VOIDED = 1,
}

export enum InvoicePaymentStatusType {
	UNPAID = 0,
	PAID = 1,
	REFUNDED = 2,
}

export enum LogType {
	NORMAL = 0,
	STATUS_UPDATE = 1,
}

export enum MessageStatusType {
	CREATED = 0,
	DELIVERED = 1,
	READ = 2,
	NOT_DELIVERED = 3,
}

export enum ConversationStatusType {
	CREATED = 0,
	ACTIVE = 1,
	FINISHED = 2,
	ARCHIVED = 3,
}

export enum JobLogType {
	NORMAL = 0,
	STATUS_UPDATE = 1,
}
