import { useComprehensiveTopNav } from 'hooks';
import { routes } from 'lib/api';
import { Button, Image, LoadingIndicator, Typography } from 'lib/components';
import { getLocation, PermissionType as P, JobStatus } from 'lib/util';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Authorized from 'routes/authorized';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectHospital,
	useSelectRequest,
	useSelectSettings,
	useSelectJobs,
} from 'store';
import { Hospital } from 'types';
import adjustTime from '../../lib/adjustTime';
import EmployeesTab from './EmployeesTab';
import classes from './index.module.scss';
import InvoicesTab from './InvoicesTab';
import JobsTab from './JobsTab';
import ItemsTab from './RestrictedItemsTab';
import ServicesTab from './ServicesTab';
import VendorsTab from './VendorsTab';

interface Props {
	hospital: Hospital;
}

const HospitalDetailScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const getHospitalById = useSelectHospital();

	const [hospital, setHospital] = useState<Hospital>();

	useEffect(() => {
		dispatch(actions.fetchHospital(parseInt(id)));
		dispatch(actions.getBuddyPunchLocations());
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL_ID(parseInt(id)), 'GET')) {
			if (RM.succeeded()) {
				const hospital = getHospitalById(parseInt(id));

				if (hospital) {
					setHospital(hospital);
				}
			}
		}
	}, [active]);

	return hospital ? <HospitalDetailScreenView hospital={hospital} /> : <LoadingIndicator />;
};

const HospitalDetailScreenView = ({ hospital }: Props) => {
	const settingsState = useSelectSettings();

	const dispatch = useAppDispatch();
	const jobs = useSelectJobs();

	const { push } = useHistory();
	const { url, path } = useRouteMatch();

	const { canUpdateHospital, canListJob, canListEmployee, canListInvoice, canListVendor, canListService } =
		useSelectAuthorization();

	useEffect(() => {
		if (canListJob) {
			dispatch(actions.fetchJobsForHospital(hospital.id));
		}
	}, [canListJob]);

	const redirect = () => {
		if (canListJob) return `${url}/jobs`;
		if (canListEmployee) return `${url}/employees`;
		if (canListVendor) return `${url}/vendors`;
		if (canListInvoice) return `${url}/invoices`;
		if (canListService) return `${url}/services`;
		return '';
	};

	useComprehensiveTopNav(`${hospital.name} - Profile Page`);

	const handleCallButtonClick = useCallback(() => {
		const link = document.createElement('a');
		link.href = `tel:${hospital.phoneNumber}`;
		link.click();
	}, [hospital.id]);

	const handleEmailButtonClick = useCallback(() => {
		const link = document.createElement('a');
		link.href = `mailto:${hospital.email}`;
		link.click();
	}, [hospital.id]);

	return (
		<div className={classes['hospitalDetail']}>
			<div
				className={classes['row']}
				style={{
					justifyContent: 'space-between',
					paddingBottom: '0.5rem',
				}}
			>
				<div
					style={{
						marginTop: '0.5rem',
					}}
				>
					<Typography variant="h4">{hospital.name}</Typography>
					<div
						className={classes['row']}
						style={{
							marginTop: '0.5rem',
						}}
					>
						<Image src={'icLocationSmGray'} />
						<Typography color="gray">{getLocation(hospital.address)}</Typography>
					</div>
				</div>
				{canUpdateHospital && (
					<Button
						className={classes['btn']}
						title="Edit"
						variant="standard"
						start={<Image src="icEdit" />}
						end={<Image src="icDownCaretSm" />}
						onClick={() => push(`/hospitals/edit/${hospital.id}`)}
					/>
				)}
			</div>
			<div
				className={classes['row']}
				style={{
					justifyContent: 'space-between',
				}}
			>
				<div className={classes['row']}>
					<Typography
						color="gray"
						style={{
							marginRight: '0.75rem',
						}}
					>
						Registered: &nbsp;
						<em>{adjustTime(settingsState, hospital.createdAt).format('MM/DD/YYYY')}</em>
					</Typography>
					<Typography
						color="gray"
						style={{
							marginRight: '0.75rem',
						}}
					>
						Employees: &nbsp;
						<em>{hospital.employeesAmount}</em>
					</Typography>
					{canListJob && (
						<Typography color="gray">
							Active Jobs: &nbsp;
							<em> {jobs.list.filter((job) => JobStatus.isActive(job.status)).length} Active</em>
						</Typography>
					)}
				</div>
				<div className={classes['row']}>
					{hospital.phoneNumber !== undefined && hospital.phoneNumber !== null && (
						<Button
							onClick={handleCallButtonClick}
							title="Call"
							variant="standard"
							end={
								<Image
									style={{
										width: '1rem',
										height: '1rem',
									}}
									src="icTelephonePrimary"
								/>
							}
							style={{
								margin: '0.5rem 0.5rem 0 0',
							}}
							className={classes['btn']}
						/>
					)}
					{hospital.email !== undefined && hospital.email !== null && (
						<Button
							onClick={handleEmailButtonClick}
							title="Email"
							variant="standard"
							end={
								<Image
									style={{
										width: '1rem',
										height: '1rem',
									}}
									src="icMailPrimary"
								/>
							}
							style={{
								margin: '0.5rem 0 0 0',
							}}
							className={classes['btn']}
						/>
					)}
				</div>
			</div>
			<div className={classes['hospitalDetail-tabs']}>
				<div className={classes['hospitalDetail-tabs-top']}>
					<div className={classes['hospitalDetail-tabs-headers']}>
						{canListJob && (
							<NavLink
								to={`${url}/jobs`}
								activeClassName={classes['hospitalDetail-tabs-link-active']}
								className={classes['hospitalDetail-tabs-link']}
							>
								<Typography
									className={
										classes['hospitalDetail-tabs-headerTitle'] +
										' ' +
										classes['hospitalDetail-tabs-headerTitle-active']
									}
								>
									Jobs
								</Typography>
							</NavLink>
						)}
						{canListEmployee && (
							<NavLink
								to={`${url}/employees`}
								activeClassName={classes['hospitalDetail-tabs-link-active']}
								className={classes['hospitalDetail-tabs-link']}
							>
								<Typography className={classes['hospitalDetail-tabs-headerTitle']}>
									Employees
								</Typography>
							</NavLink>
						)}
						{canListVendor && (
							<NavLink
								to={`${url}/vendors`}
								activeClassName={classes['hospitalDetail-tabs-link-active']}
								className={classes['hospitalDetail-tabs-link']}
							>
								<Typography className={classes['hospitalDetail-tabs-headerTitle']}>
									Restricted Vendors
								</Typography>
							</NavLink>
						)}
						<NavLink
							to={`${url}/items`}
							activeClassName={classes['hospitalDetail-tabs-link-active']}
							className={classes['hospitalDetail-tabs-link']}
						>
							<Typography className={classes['hospitalDetail-tabs-headerTitle']}>
								Restricted Items
							</Typography>
						</NavLink>
						{canListService && (
							<NavLink
								to={`${url}/services`}
								activeClassName={classes['hospitalDetail-tabs-link-active']}
								className={classes['hospitalDetail-tabs-link']}
							>
								<Typography className={classes['hospitalDetail-tabs-headerTitle']}>Services</Typography>
							</NavLink>
						)}
						{canListInvoice && (
							<NavLink
								to={`${url}/invoices`}
								activeClassName={classes['hospitalDetail-tabs-link-active']}
								className={classes['hospitalDetail-tabs-link']}
							>
								<Typography className={classes['hospitalDetail-tabs-headerTitle']}>Invoices</Typography>
							</NavLink>
						)}
					</div>
				</div>
				<div className={classes['hospitalDetail-tabs-body']}>
					<Switch>
						<Authorized permission={P.LIST_JOB} exact path={`${path}/jobs`} component={JobsTab} />
						<Authorized
							permission={P.LIST_EMPLOYEE}
							exact
							path={`${path}/employees`}
							component={EmployeesTab}
						/>
						<Authorized permission={P.LIST_VENDOR} exact path={`${path}/vendors`} component={VendorsTab} />
						<Authorized permission={P.LIST_VENDOR} exact path={`${path}/items`} component={ItemsTab} />
						<Authorized
							permission={P.LIST_SERVICE}
							exact
							path={`${path}/services`}
							component={ServicesTab}
						/>
						<Authorized
							permission={P.LIST_INVOICE}
							exact
							path={`${path}/invoices`}
							component={InvoicesTab}
						/>
						{redirect() !== '' && <Redirect from="/" to={redirect()} />}
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default HospitalDetailScreen;
