import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useSelectAuthentication, useSelectAuthorization } from 'store';
import { Timing } from 'lib/util';

const SuperAdminRoute = ({ component, ...rest }: RouteProps) => {
	const { isAuthenticated, expiryAt } = useSelectAuthentication();
	const { isSuperAdmin } = useSelectAuthorization();
	const { pathname } = useLocation();

	return isAuthenticated && !Timing.isExpired(expiryAt) ? (
		isSuperAdmin ? (
			<Route {...rest} component={component} />
		) : (
			<Redirect to="/403" />
		)
	) : (
		<Redirect to={`/login?redirectTo=${pathname}`} />
	);
};

export default SuperAdminRoute;
