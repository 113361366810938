import { useAddressInput, useComprehensiveTopNav, useCustomToast } from 'hooks';
import routes from 'lib/api/routes';
import { Button, Typography } from 'lib/components';
import { TextField, TimezoneSelect } from 'lib/components/FormFields';
import WorkingHourInputField from 'lib/components/WorkingHourInputField';
import { Day, DayIndex, DayItem, Time, WorkingHoursFactory } from 'lib/components/WorkingHourInputField/types';
import WorkingHourModal from 'lib/components/WorkingHourModal';
import { RateType } from 'lib/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actions, RequestManager, useAppDispatch, useSelectRequest } from 'store';
import BuddyPunchLocationSelect from '../../lib/components/FormFields/CustomSelect/BuddyPunchLocationSelect';
import { BuddyPunchLocation, HospitalWorkingHours } from '../../types/entity';
import classes from './index.module.scss';

const NUMBER_SYMBOL = ['$', '%'];
const WORKING_HOURS_FACTORY_INIT = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
].reduce<WorkingHoursFactory>(
	(acc, cur) => {
		acc.days.push({
			day: cur as Day,
			isOperating: false,
			startTime: { hr: '09', min: '00', pm: false },
			endTime: { hr: '09', min: '00', pm: true },
		});
		return acc;
	},
	{ days: [], selectedIndex: 0 }
);

const HospitalNewScreen = () => {
	const toast = useCustomToast();
	const { push } = useHistory();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState('');
	const [timezone, setTimeZone] = useState<string>();
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [employeesAmount, setEmployeesAmount] = useState(0);
	const [payoutId, setPayoutId] = useState('');
	const [regularRate, setRegularRate] = useState(0);
	const [overtimeRate, setOvertimeRate] = useState(0);
	const [weekendRate, setWeekendRate] = useState(0);
	const [holidayRate, setHolidayRate] = useState(0);
	const [overtimeRateTypeId, setOvertimeRateTypeId] = useState<RateType>(0);
	const [weekendRateTypeId, setWeekendRateTypeId] = useState<RateType>(0);
	const [holidayRateTypeId, setHolidayRateTypeId] = useState<RateType>(0);
	const [buddyPunchId, setBuddyPunchId] = useState<BuddyPunchLocation | undefined>(undefined);

	const [beforeDispatch, setBeforeDispatch] = useState<number>(0);
	const [afterDispatch, setAfterDispatch] = useState<number>(25);
	const [beforeTreatment, setBeforeTreatment] = useState<number>(50);
	const [duringTreatment, setDuringTreatment] = useState<number>(100);

	const [workingHoursFactory, setWorkingHoursFactory] = useState<WorkingHoursFactory>(WORKING_HOURS_FACTORY_INIT);
	const [workingHourModalOpen, setWorkingHourModalOpen] = useState(false);

	const nextWorkingDay = () => {
		setWorkingHoursFactory((prev) => {
			const newIndex = prev.selectedIndex + 1 > 6 ? 0 : prev.selectedIndex + 1;
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: newIndex as DayIndex,
			};
		});
	};

	const prevWorkingDay = () =>
		setWorkingHoursFactory((prev) => {
			const newIndex = prev.selectedIndex - 1 < 0 ? 6 : prev.selectedIndex - 1;
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: newIndex as DayIndex,
			};
		});

	const handleWorkingHourModalOnConfirmed = (dayItem: DayItem) => {
		setWorkingHoursFactory((prev) => {
			const newDays = [...prev.days].map((item) => {
				if (item.day === dayItem.day) {
					return { ...dayItem };
				}
				return item;
			});
			return {
				...prev,
				days: newDays,
			};
		});
	};

	const setWorkingHoursDayIndex = (index: DayIndex) => {
		setWorkingHoursFactory((prev) => {
			return {
				days: prev.days.map((day) => ({ ...day })),
				selectedIndex: index,
			};
		});
	};

	const handleWorkingHourFieldClick = (index: number) => {
		setWorkingHourModalOpen((prev) => {
			setWorkingHoursDayIndex(index as DayIndex);
			return !prev;
		});
	};

	const {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	} = useAddressInput();

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/hospitals');
			}
		}
	}, [active]);

	const formatDay = useCallback(
		(entry: Time, isOperating: boolean) => {
			return isOperating ? `${entry.hr}:${entry.min} ${entry.pm ? 'PM' : 'AM'}` : null;
		},
		[workingHoursFactory]
	);

	const createHospital = () => {
		const workingHours: HospitalWorkingHours = {
			monFrom: formatDay(workingHoursFactory.days[0].startTime, workingHoursFactory.days[0].isOperating),
			monTo: formatDay(workingHoursFactory.days[0].endTime, workingHoursFactory.days[0].isOperating),
			tueFrom: formatDay(workingHoursFactory.days[1].startTime, workingHoursFactory.days[1].isOperating),
			tueTo: formatDay(workingHoursFactory.days[1].endTime, workingHoursFactory.days[1].isOperating),
			wedFrom: formatDay(workingHoursFactory.days[2].startTime, workingHoursFactory.days[2].isOperating),
			wedTo: formatDay(workingHoursFactory.days[2].endTime, workingHoursFactory.days[2].isOperating),
			thuFrom: formatDay(workingHoursFactory.days[3].startTime, workingHoursFactory.days[3].isOperating),
			thuTo: formatDay(workingHoursFactory.days[3].endTime, workingHoursFactory.days[3].isOperating),
			friFrom: formatDay(workingHoursFactory.days[4].startTime, workingHoursFactory.days[4].isOperating),
			friTo: formatDay(workingHoursFactory.days[4].endTime, workingHoursFactory.days[4].isOperating),
			satFrom: formatDay(workingHoursFactory.days[5].startTime, workingHoursFactory.days[5].isOperating),
			satTo: formatDay(workingHoursFactory.days[5].endTime, workingHoursFactory.days[5].isOperating),
			sunFrom: formatDay(workingHoursFactory.days[6].startTime, workingHoursFactory.days[6].isOperating),
			sunTo: formatDay(workingHoursFactory.days[6].endTime, workingHoursFactory.days[6].isOperating),
		};

		dispatch(
			actions.addHospital({
				name,
				email,
				addressLine1,
				addressLine2: addressLine2 === '' ? undefined : addressLine2,
				city,
				state,
				postalCode,
				phoneNumber,
				payoutId: payoutId !== '' ? payoutId : undefined,
				buddyPunchId: buddyPunchId !== undefined ? buddyPunchId.id.toString() : undefined,
				regularRate,
				overtimeRate,
				weekendRate,
				holidayRate,
				overtimeRateTypeId,
				weekendRateTypeId,
				holidayRateTypeId,
				employeesAmount: employeesAmount !== 0 ? employeesAmount : undefined,
				timezone: timezone!,
				workingHours,
				cancellationPolicy: {
					beforeDispatch,
					afterDispatch,
					beforeTreatment,
					duringTreatment,
				},
			})
		);
	};

	const handleSymbolChange = (field: 'overtime' | 'weekend' | 'holiday', symbol: RateType) => {
		if (field === 'overtime') setOvertimeRateTypeId(symbol);
		else if (field === 'weekend') setWeekendRateTypeId(symbol);
		else setHolidayRateTypeId(symbol);
	};

	useComprehensiveTopNav('Create New Hospital Page');

	React.useEffect(() => {
		dispatch(actions.getBuddyPunchLocations());
	}, []);

	return (
		<>
			<WorkingHourModal
				isOpen={workingHourModalOpen}
				workingHoursFactory={workingHoursFactory}
				onClose={() => setWorkingHourModalOpen(false)}
				onNextDay={nextWorkingDay}
				onPrevDay={prevWorkingDay}
				onConfirmed={handleWorkingHourModalOnConfirmed}
			/>
			<div className={classes['createHospitalNew']}>
				<div className={classes['createHospitalNew-section']}>
					<div
						className={
							classes['createHospitalNew-sectionFields'] +
							' ' +
							classes['createHospitalNew-spacingTextFields']
						}
					>
						<div className={classes['createHospitalNew-hospitalNameField']}>
							<TextField
								value={name}
								onChange={(e) => setName(e.target.value)}
								color="primary"
								variant="labelled"
								label="Hospital Name:"
							/>
						</div>
						<TimezoneSelect setValue={setTimeZone} />
						<TextField
							placeholder="Address 1"
							label="Address Line 1:"
							variant="labelled"
							value={addressLine1}
							onChange={(e) => setAddressLine1(e.target.value)}
						/>
						<TextField
							placeholder="Address 2"
							label="Address Line 2:"
							variant="labelled"
							value={addressLine2}
							onChange={(e) => setAddressLine2(e.target.value)}
						/>
						<TextField
							placeholder="Ohara"
							label="City:"
							variant="labelled"
							value={city}
							onChange={(e) => setCity(e.target.value)}
						/>
						<TextField
							placeholder="Texas"
							label="State:"
							variant="labelled"
							value={state}
							onChange={(e) => setState(e.target.value)}
						/>
						<TextField
							placeholder="P. O. Box 1102"
							label="Postal Code:"
							variant="labelled"
							value={postalCode}
							onChange={(e) => setPostalCode(e.target.value)}
						/>
					</div>
				</div>
				<div className={classes['createHospitalNew-section']}>
					<Typography weight="bold" className={classes['createHospitalNew-sectionTitle']}>
						Basic Info
					</Typography>
					<div className={classes['createHospitalNew-sectionFields']}>
						<div className={classes['createHospitalNew-phoneField']}>
							<TextField
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								variant="labelled"
								label="Phone:"
								placeholder="+1 55 55 55 555"
							/>
						</div>
						<div className={classes['createHospitalNew-employeeField']}>
							<TextField
								value={employeesAmount}
								onChange={(e) => setEmployeesAmount(e.target.valueAsNumber)}
								variant="labelled"
								label="Employees:"
								type="number"
							/>
						</div>
						<div className={classes['createHospitalNew-emailField']}>
							<TextField
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								variant="labelled"
								type="email"
								label="Email:"
								placeholder="contact@example.com"
							/>
						</div>
						<div>
							<BuddyPunchLocationSelect value={buddyPunchId} setValue={setBuddyPunchId} />
						</div>
					</div>
				</div>
				<div className={classes['createHospitalNew-section']}>
					<Typography weight="bold" className={classes['editHospitalNew-sectionTitle']}>
						Cancellation Policy
					</Typography>
					<div className={classes['editHospitalNew-sectionFields']}>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Before Dispatch:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={beforeDispatch}
								onChange={(e) => setBeforeDispatch(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="After Dispatch:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={afterDispatch}
								onChange={(e) => setAfterDispatch(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Before Treatment:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={beforeTreatment}
								onChange={(e) => setBeforeTreatment(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['editHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="During Treatment:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								defaultSymbolIndex={1}
								min={0}
								value={duringTreatment}
								onChange={(e) => setDuringTreatment(e.target.valueAsNumber)}
							/>
						</div>
					</div>
				</div>
				<div className={classes['createHospitalNew-section']}>
					<Typography weight="bold" className={classes['createHospitalNew-sectionTitle']}>
						Pricing Per Hour
					</Typography>
					<div className={classes['createHospitalNew-sectionFields']}>
						<div className={classes['createHospitalNew-quickbooksField']}>
							<TextField
								variant="labelled"
								label="Quickbooks ID"
								placeholder="123465798"
								value={payoutId}
								onChange={(e) => setPayoutId(e.target.value)}
							/>
						</div>
						<div className={classes['createHospitalNew-pricingPerHourField']}>
							<TextField
								variant="labelled"
								label="Pricing per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={regularRate}
								onChange={(e) => setRegularRate(e.target.valueAsNumber)}
							/>
						</div>
						<div className={classes['createHospitalNew-overtimePerHourField']}>
							<TextField
								variant="labelled"
								label="Overtime per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={overtimeRate}
								onChange={(e) => setOvertimeRate(e.target.valueAsNumber)}
								defaultSymbolIndex={overtimeRateTypeId}
								onSymbolChange={(value) => handleSymbolChange('overtime', value)}
							/>
						</div>
						<div className={classes['createHospitalNew-weekendPerHourField']}>
							<TextField
								variant="labelled"
								label="Weekend per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={weekendRate}
								defaultSymbolIndex={weekendRateTypeId}
								onChange={(e) => setWeekendRate(e.target.valueAsNumber)}
								onSymbolChange={(value) => handleSymbolChange('weekend', value)}
							/>
						</div>
						<div className={classes['createHospitalNew-holidayPerHourField']}>
							<TextField
								variant="labelled"
								label="Holiday per Hour:"
								type="number"
								numberSymbol={NUMBER_SYMBOL}
								min={0}
								value={holidayRate}
								defaultSymbolIndex={holidayRateTypeId}
								onChange={(e) => setHolidayRate(e.target.valueAsNumber)}
								onSymbolChange={(value) => handleSymbolChange('holiday', value)}
							/>
						</div>
					</div>
				</div>
				<div className={classes['createHospitalNew-section']} style={{ borderBottom: 'none' }}>
					<Typography weight="bold" className={classes['createHospitalNew-sectionTitle']}>
						Working Hours
					</Typography>
					<div className={classes['createHospitalNew-sectionFields']}>
						{workingHoursFactory.days.map((item, index) => (
							<div
								onClick={() => handleWorkingHourFieldClick(index)}
								className={classes['createHospitalNew-workingHoursField']}
								key={item.day}
								style={{ cursor: 'pointer' }}
							>
								<WorkingHourInputField
									day={item.day}
									start={item.startTime}
									end={item.endTime}
									onChange={(value) => {
										const workingHours = { ...workingHoursFactory };
										workingHours.days[index].startTime = value.start;
										workingHours.days[index].endTime = value.end;

										setWorkingHoursFactory(workingHours);
									}}
									disabled
									prefix
								/>
							</div>
						))}
					</div>
				</div>

				<div className={classes['createHospitalNew-footer']}>
					<Button
						variant="standard"
						color="primary"
						title="Create Hospital"
						style={{
							marginRight: '1rem',
						}}
						onClick={createHospital}
					/>
				</div>
			</div>
		</>
	);
};

export default HospitalNewScreen;
