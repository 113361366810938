import { User } from 'types';
import { Sort } from './types';

export const adminNameCompareFn = (a: User, b: User, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const adminEmailCompareFn = (a: User, b: User, sort: Sort) => {
	if (sort === 'asc') {
		return a.email.localeCompare(b.email) === -1 ? 1 : -1;
	} else {
		return a.email.localeCompare(b.email) === -1 ? -1 : 1;
	}
	// return 0;
};
