import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Image, ProgressBar } from 'lib/components';
import Avatar from 'lib/components/Avatar';
import { Job } from 'types';
import useAddIdPrefix from 'hooks/useAddIdPrefix';
import { HeaderItem, Sort, SortField } from './types';
import { JobStatus } from 'lib/util';
import classes from '../index.module.scss';
import { useSelectAuthorization } from 'store';

interface Props {
	jobs: Job[];
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'OrderId', sort: 'asc', field: 'id' },
	{ label: 'Current Status' },
	{ label: 'Start Time', sort: 'asc', field: 'startingAt' },
	{ label: 'Nurse' },
	{ label: 'Chat' },
	{ label: 'Edit' },
];

const ActiveJobsTable = ({ jobs }: Props) => {
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'id',
	});
	const [sortedJobs, setSortedJobs] = useState<Job[]>(jobs);

	console.log('currentSortField', currentSortField);

	const addPrefix = useAddIdPrefix();
	const { push } = useHistory();
	const isSmallerDevice = window.innerWidth <= 500;
	const { canReadJob, canReadNurse, canUpdateJob } = useSelectAuthorization();

	const toViewNurse = useCallback((job: Job) => {
		if (canReadNurse) push(`/nurses/view/${job.nurse?.id}`);
	}, []);

	const toViewJob = useCallback((job: Job, openChat: boolean = false) => {
		if (canReadJob) push(`/jobs/view/${job.id}${openChat ? '?chat=open' : ''}`);
	}, []);

	const toEditJob = useCallback((job: Job) => {
		if (canUpdateJob) push(`/jobs/edit/${job.id}`);
	}, []);

	useEffect(() => {
		if (!sortedJobs.length) {
			setSortedJobs(jobs);
			return;
		}
		const sorted = [...jobs].sort((a, b) => {
			switch (currentSortField?.sort) {
				case 'asc':
					if (currentSortField?.field === 'id') {
						if (a.id > b.id) {
							return 1;
						} else if (a.id < b.id) {
							return -1;
						}
					} else if (currentSortField?.field === 'startingAt') {
						const valueA = new Date(a.startingAt);
						const valueB = new Date(b.startingAt);
						if (valueA > valueB) {
							return 1;
						} else if (valueA < valueB) {
							return -1;
						}
					}
					return 0;

				case 'desc':
					if (currentSortField?.field === 'id') {
						console.log('desc-sort');
						if (a.id > b.id) {
							return -1;
						} else if (a.id < b.id) {
							return 1;
						}
					} else if (currentSortField?.field === 'startingAt') {
						const valueA = new Date(a.startingAt);
						const valueB = new Date(b.startingAt);
						if (valueA > valueB) {
							return -1;
						} else if (valueA < valueB) {
							return 1;
						}
					}
					return 0;

				default:
					return 0;
			}
		});

		console.log(sorted);

		setSortedJobs(sorted);
	}, [currentSortField, setSortedJobs, jobs]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['dashboard-dataTable-grid-headerContent']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['dashboard-dataTable-grid-headerContent']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	console.log({ jobs });

	return (
		<div className={`${classes['dashboard-dataTable']} ${classes['dashboard-dataTable-activeDispatch']}`}>
			<div className={classes['dashboard-dataTable-header']}>
				<div className={classes['dashboard-dataTable-grid-activeDispatch']}>
					{HEADER_ITEMS.map((item) => renderHeaderItem(item))}
				</div>
			</div>
			<div className={classes['dashboard-dataTable-rows']}>
				{sortedJobs?.map((job) => (
					<div
						key={job.id}
						onClick={() => toViewJob(job)}
						className={classes['dashboard-dataTable-grid-activeDispatch']}
					>
						<div>
							<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{addPrefix(job.id)}
							</Typography>
						</div>
						<div>
							<div className={classes['dashboard-activeDispatches-currentStatus']}>
								<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
									{JobStatus.progressText(job.status)}
								</Typography>
								<ProgressBar valuePercentage={JobStatus.progressPercentage(job.status)} />
							</div>
						</div>
						<div>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{job.startingAt}</Typography>
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								toViewNurse(job);
							}}
						>
							<Avatar size="1.6rem" src={job.nurse?.user?.avatar ?? 'plAvatar'} />
						</div>
						<div>
							{JobStatus.isChatOpen(job.status) ? (
								<div
									onClick={(e) => {
										e.stopPropagation();
										toViewJob(job, true);
									}}
									className={classes['dashboard-activeDispatches-chatItem']}
								>
									<Image src={'icComment'} />
									{/* <span>
										<Typography color="danger" variant={isSmallerDevice ? 'h5' : 'h6'}>
											{5}
										</Typography>
									</span> */}
								</div>
							) : (
								<span>-</span>
							)}
						</div>
						<div
							style={{ cursor: 'pointer', opacity: canUpdateJob ? '100%' : '30%' }}
							onClick={(e) => {
								e.stopPropagation();
								toEditJob(job);
							}}
						>
							<Image src={'icMore'} />
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ActiveJobsTable;
