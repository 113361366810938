import React from 'react';
import { Typography } from 'lib/components';
import { Message } from 'types';
import classes from '../index.module.scss';
import moment from 'moment';

interface Props {
	chat: Message;
	isSender: boolean;
}

const ChatMessage = ({ chat, isSender }: Props) => (
	<div
		className={`${classes['chatPopup-chatMessage']} ${
			isSender ? classes['chatPopup-chatMessage-sender'] : classes['chatPopup-chatMessage-receiver']
		}`}
	>
		<div>
			<Typography>{chat.message}</Typography>
		</div>
		<Typography className={classes['chatPopup-chatPeriod-label']}>
			{moment.utc(chat.createdAt, 'MM/DD/YYYY hh:mm A').local().format('MM/DD/YYYY hh:mm A')}
		</Typography>
	</div>
);

export default ChatMessage;
