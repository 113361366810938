import React, { Dispatch, SetStateAction } from 'react';
import { useWindowResize } from 'hooks/useWindowResize';
import Typography from '../Typography';
import classes from './index.module.scss';

interface Props {
	showPagination: boolean;
	pages: number[];
	currentPage: number;
	totalPages: number;
	incrementPage: () => void;
	decrementPage: () => void;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setPage: (page: number) => void;
}

const Pagination = ({
	showPagination,
	pages,
	currentPage,
	totalPages,
	incrementPage,
	decrementPage,
	setCurrentPage,
	setPage,
}: Props) => {
	const { isSmallerDevice } = useWindowResize();

	return (
		<div style={{ display: !showPagination ? 'none' : 'flex' }} className={classes['bottom-toolbar']}>
			<button
				className={classes['badge']}
				onClick={() => setCurrentPage(1)}
				style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					First
				</Typography>
			</button>
			<button
				onClick={decrementPage}
				className={classes['badge']}
				style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Prev.
				</Typography>
			</button>
			{pages?.map((page) => {
				const classname =
					page === currentPage ? `${classes['badge']} ${classes['active']}` : `${classes['badge']}`;
				return (
					<button className={classname} key={page.toString()} onClick={() => setPage(page)}>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							color={page === currentPage ? 'primary' : 'darkgrey'}
						>
							{page}
						</Typography>
					</button>
				);
			})}

			<button
				onClick={incrementPage}
				className={classes['badge']}
				style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Next
				</Typography>
			</button>
			<button
				onClick={() => setCurrentPage(totalPages)}
				className={classes['badge']}
				style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Last
				</Typography>
			</button>
		</div>
	);
};

export default Pagination;
