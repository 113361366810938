import React, { useRef, useState } from 'react';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { Typography } from 'lib/components';
import classes from './index.module.scss';
import Image from 'lib/components/Image';
import { useHistory } from 'react-router-dom';
import ChatScreen from './ChatScreen';
import Overview from './Overview';
import Log from './Log';
import Invoices from './Invoices';
import { useWindowResize } from 'hooks/useWindowResize';

const ActiveJobScreen = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { push } = useHistory();
	const [tabIndex, setTabIndex] = useState('0');
	const [showChatScreen, setShowChatScreen] = useState(false);
	const { isSmallerDevice, isSmallDevice } = useWindowResize();
	// const [chatPopupOpen, setChatPopupOpen] = useState(true);

	const tabs = [
		{ id: '0', label: 'Overview', component: <Overview /> },
		{ id: '1', label: 'Log', component: <Log /> },
		{ id: '2', label: 'Invoices', component: <Invoices /> },
	];

	useComprehensiveTopNav('Jobs');

	return (
		<>
			{/* <ChatPopup isOpen={chatPopupOpen} onClose={() => setChatPopupOpen(false)} /> */}
			<div className={classes['dispatch-view-container']} ref={containerRef}>
				<div
					className={classes['tabs']}
					style={{
						padding: isSmallDevice ? '0 1rem' : undefined,
					}}
				>
					{tabs.map(({ id, label }) => (
						<button
							className={classes['tab']}
							key={id}
							style={{
								borderColor: tabIndex === id ? ' #0CB9C3' : 'transparent',
							}}
							onClick={() => setTabIndex(id)}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{label}</Typography>
						</button>
					))}
					<div
						className={classes['row']}
						style={{
							marginLeft: 'auto',
							paddingBottom: '0.5rem',
						}}
					>
						<button
							className={classes['row']}
							style={{
								marginTop: '0.5rem',
								marginRight: '1.5rem',
							}}
							onClick={() => setShowChatScreen(!showChatScreen)}
						>
							<Image
								src="icChat"
								style={{
									marginRight: '0.5rem',
								}}
							/>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Chat</Typography>
							<div className={classes['chat-notifications-number']}>
								<p>4</p>
							</div>
						</button>
						<button
							className={classes['row']}
							style={{
								marginRight: '1.5rem',
								marginTop: '0.5rem',
							}}
						>
							<Image src="icExportBlack" />
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Export
							</Typography>
						</button>
						<button
							className={classes['row']}
							style={{
								marginTop: '0.5rem',
							}}
							onClick={() => push('/jobs/edit/hdyeh')}
						>
							<Image src="icEdit" />
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Edit
							</Typography>
						</button>
					</div>
				</div>
				{showChatScreen ? <ChatScreen /> : <>{tabs[+tabIndex].component}</>}
			</div>
		</>
	);
};

export default ActiveJobScreen;
