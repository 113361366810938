import SocketContext from 'context/SocketContext';
import { Timing } from 'lib/util';
import React, { ReactNode, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { actions, useAppDispatch, useSelectAuthentication, useSelectConversations } from 'store';
import { Message } from 'types';

interface Props {
	children: ReactNode;
}

const API_HOST = process.env.REACT_APP_API_URL!;

const SocketManager = ({ children }: Props) => {
	const dispatch = useAppDispatch();
	const { isAuthenticated, accessToken, expiryAt } = useSelectAuthentication();
	const conversations = useSelectConversations();

	const [socket, setSocket] = useState<Socket | null>(null);

	useEffect(() => {
		if (!isAuthenticated || accessToken === '' || Timing.isExpired(expiryAt)) {
			return;
		}

		const newSocket = io(API_HOST, {
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 10000,
			// jsonp: false,
			transports: ['websocket'],
			query: {
				Authorization: accessToken,
			},
		});

		newSocket.on('connect', () => {
			console.log(`SocketState: Connected to the server at ${API_HOST}`);

			newSocket.on(`conversation:message:finished`, (temporaryId: string, message: Message) => {
				console.log('conversation:message:finished', temporaryId, message);
				dispatch(actions.messageUpdated({ temporaryId, message }));
			});

			newSocket.on(`conversation:message:received`, (message: Message) => {
				console.log('conversation:message:received', message);
				dispatch(actions.messageReceived(message));
			});

			newSocket.onAny((eventName, ...args) => {
				console.log(`Received event: '${eventName}' with args:`, args);
			});
		});
		newSocket.on('connect_error', (error: Error) => {
			console.error('SocketState: connect_error', error);

			newSocket.off('conversation:message:finished');
			newSocket.off('conversation:message:received');
			newSocket.offAny();
		});
		newSocket.on('disconnect', () => {
			console.log('SocketState: Disconnected from the server.');

			newSocket.off('conversation:message:finished');
			newSocket.off('conversation:message:received');
			newSocket.offAny();
		});

		setSocket(newSocket);

		return () => {
			if (socket && socket.connected) {
				socket.disconnect();
				socket.close();
				setSocket(null);
			}
		};
	}, [isAuthenticated, accessToken, expiryAt]);

	// useEffect(() => {
	// 	if (!socket || !socket.connected) {
	// 		return;
	// 	}

	// 	for (const conversation of conversations.list) {
	// 		socket.emit('user:register', conversation.id);
	// 	}

	// 	return () => {
	// 		for (const conversation of conversations.list) {
	// 			socket.emit('user:un-register', conversation.id);
	// 		}
	// 	};
	// }, [conversations.updatedAt, socket]);

	// useEffect(() => {
	// 	if (!socket) {
	// 		return;
	// 	}

	// 	console.log('start listening');

	// 	socket.on(`conversation:message:finished`, (temporaryId: string, message: Message) => {
	// 		console.log('conversation:message:finished', temporaryId, message);
	// 		dispatch(actions.messageUpdated({ temporaryId, message }));
	// 	});

	// 	socket.on(`conversation:message:received`, (message: Message) => {
	// 		console.log('conversation:message:received', message);
	// 		dispatch(actions.messageReceived(message));
	// 	});

	// 	return () => {
	// 		console.log('end listening');

	// 		socket.off('conversation:message:finished');
	// 		socket.off('conversation:message:received');
	// 	};
	// }, [socket]);

	return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export default SocketManager;
