const mapboxgl = require('!mapbox-gl'); // eslint-disable-line import/no-webpack-loader-syntax
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import SocketContext from '../../../context/SocketContext';
import R from '../../../res/R';
import { actions, useSelectJobs } from '../../../store';
import { Job } from '../../../types';
import { JobStatusType } from '../../util';
import { ImageSource } from '../types';
import CustomMarker from './CustomMarker';
import styles from './index.module.scss';

interface MapProps {
	latitude: number;
	longitude: number;
	zoom?: number;
	className?: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	fullscreenControl?: any;
	jobId?: number;
}

interface ViewPort {
	latitude: number;
	longitude: number;
	width: string;
	height: string;
	zoom: number;
}

interface MarkerEntry {
	id: number;
	marker: mapboxgl.Marker;
}

const navControl = new mapboxgl.NavigationControl();

const MapView = ({
	latitude,
	longitude,
	zoom = 8,
	className = '',
	style = {},
	fullscreenControl = new mapboxgl.FullscreenControl(),
	...props
}: MapProps) => {
	const dispatch = useDispatch();

	const { socket } = useContext(SocketContext);

	const mapContainer = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<mapboxgl.Map>();
	const [mapLoaded, setMapLoaded] = useState<boolean>(false);
	const [jobs, setJobs] = useState<Array<Job>>([]);
	const [markers, setMarkers] = useState<Array<MarkerEntry>>([]);

	const jobsState = useSelectJobs();

	const [viewport, setViewport] = useState<ViewPort>({
		latitude,
		longitude,
		width: '100%',
		height: '100%',
		zoom: 8,
	});

	const renderMarker = useCallback(
		(profilePicture: ImageSource | null, name: string, longitude: number, latitude: number) => {
			if (!map) {
				return;
			}

			const markerEl = document.createElement('div');
			markerEl.className = 'marker';
			ReactDOM.render(
				<CustomMarker src={profilePicture ? profilePicture : R.images.default.plAvatar} />,
				markerEl
			);

			const marker = new mapboxgl.Marker(markerEl)
				.setLngLat([longitude, latitude])
				.setPopup(
					new mapboxgl.Popup({ offset: 30 }).setHTML(
						`
			<h4 class='label'>${name}</h4>
			<p class='value'>On Route -  Dispatch #002314</p>
			`
					)
				)
				.addTo(map);

			return marker;
		},
		[map]
	);

	useEffect(() => {
		console.log({ socket });
		if (!socket) {
			return;
		}

		socket.on(
			'nurse:location:updates',
			(nurseId: number, jobId: number, jobStatus: JobStatusType, longitude: number, latitude: number) => {
				console.log('nurse:location:updates', nurseId, jobId, jobStatus, longitude, latitude);
				const findJob = jobsState.list.find((element) => element.id === jobId);
				console.log({ findJob });
				if (!findJob) {
					dispatch(actions.fetchJobs());
					return;
				} else {
					actions.statusUpdated({ jobId, jobStatus });
				}

				dispatch(actions.pathUpdated({ nurseId, jobId, jobStatus, longitude, latitude }));
			}
		);

		return () => {
			socket.off('nurse:location:updates');
		};
	}, [socket]);

	useEffect(() => {
		let newJobs: Array<Job> = [];
		if (props.jobId) {
			newJobs = [
				...jobsState.list.filter(
					(element) =>
						(element.status === JobStatusType.ACKNOWLEDGED ||
							element.status === JobStatusType.ARRIVED ||
							element.status === JobStatusType.NURSE_ACCEPTED ||
							element.status === JobStatusType.TREATMENT_INITIATED ||
							element.status === JobStatusType.TREATMENT_COMPLETED) &&
						element.id === props.jobId
				),
			];
		} else {
			newJobs = [
				...jobsState.list.filter(
					(element) =>
						element.status === JobStatusType.ACKNOWLEDGED ||
						element.status === JobStatusType.ARRIVED ||
						element.status === JobStatusType.NURSE_ACCEPTED ||
						element.status === JobStatusType.TREATMENT_INITIATED ||
						element.status === JobStatusType.TREATMENT_COMPLETED
				),
			];
		}

		setJobs(newJobs);
	}, [jobsState.updatedAt, props.jobId]);

	useEffect(() => {
		if (!mapContainer.current) return;

		const tempMap = new mapboxgl.Map({
			container: mapContainer.current as HTMLElement,
			style: 'mapbox://styles/mapbox/light-v9',
			center: [longitude, latitude],
			zoom: zoom,
			attributionControl: false,
		});

		tempMap.on('moveend', () => {
			setViewport({
				...viewport,
				longitude: tempMap.getCenter().lng,
				latitude: tempMap.getCenter().lat,
				zoom: tempMap.getZoom(),
			});
		});

		tempMap.on('load', () => {
			setMapLoaded(true);
		});

		tempMap.addControl(navControl, 'top-left');
		tempMap.addControl(fullscreenControl);

		setMap(tempMap);

		(function fullscreenFixForSafari() {
			document.addEventListener('webkitfullscreenchange', () => setTimeout(() => tempMap.resize(), 0));
		})();

		return () => {
			document.removeEventListener('webkitfullscreenchange', () => {});
		};
	}, [mapContainer.current]);

	useEffect(() => {
		if (!map || !mapLoaded) {
			return;
		}

		const newMarkers = [...markers];
		for (const job of jobs) {
			if (
				job.status !== JobStatusType.ACKNOWLEDGED &&
				job.status !== JobStatusType.ARRIVED &&
				job.status !== JobStatusType.NURSE_ACCEPTED &&
				job.status !== JobStatusType.TREATMENT_INITIATED &&
				job.status !== JobStatusType.TREATMENT_COMPLETED
			) {
				continue;
			}

			if (!job.nurse || job.path.length === 0) {
				continue;
			}

			console.log('here');
			console.log(job);

			const findMarker = markers.findIndex((element) => element.id === job.id);
			const lastPath = job.path[job.path.length - 1];
			if (findMarker === -1) {
				const marker = {
					id: job.id,
					marker: renderMarker(
						job.nurse.user.avatar,
						job.nurse.user.name,
						lastPath.longitude,
						lastPath.latitude
					),
				};

				newMarkers.push(marker);

				map.addSource(`route-job-${job.id}`, {
					type: 'geojson',
					data: {
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'LineString',
							coordinates: job.path.map((element) => [element.longitude, element.latitude]),
						},
					},
				});
				map.addLayer({
					id: `route-job-${job.id}`,
					type: 'line',
					source: `route-job-${job.id}`,
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': '#0CB9C3',
						'line-width': 2,
					},
				});
			} else {
				markers[findMarker].marker.setLngLat([lastPath.longitude, lastPath.latitude]);

				if (!map || !map.getSource(`route-job-${job.id}`)) {
					continue;
				}

				// @ts-ignore
				map.getSource(`route-job-${job.id}`).setData({
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'LineString',
						coordinates: job.path.map((element) => [element.longitude, element.latitude]),
					},
				});
				// map.panTo([lastPath.longitude, lastPath.latitude]);
			}
		}

		setMarkers(newMarkers);
	}, [map, mapLoaded, jobs]);

	return (
		<div className={`${styles.map} ${className}`} style={style}>
			<div
				ref={mapContainer}
				style={{
					width: '100%',
					height: '100%',
				}}
				className="map-container"
			/>
		</div>
	);
};

export default MapView;
