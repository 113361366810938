import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import { MessageStatusType } from 'lib/util';
import moment from 'moment';
import { Conversation, Message } from 'types';
import { actions, AppThunk, SliceState } from '..';

const initialState: SliceState<Conversation> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

interface MessageReadPayload {
	conversationId: number;
	userId: number;
}

interface CreateMessagePayload {
	temporaryId: string;
	userId: number;
	conversationId: number;
	message: string;
}

interface UpdateMessagePayload {
	temporaryId: string;
	message: Message;
}

export default createSlice({
	name: 'conversations',
	initialState,
	reducers: {
		conversationsFetched: (state, { payload }: PA<Conversation[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		conversationFetched: (state, { payload }: PA<Conversation>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1, payload);
			} else {
				list.push(payload);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		messagesFetched: (state, { payload }: PA<Message[]>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload[0].conversationId);

			if (index !== -1) {
				list[index].messages = payload;
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		messageCreated: (state, { payload }: PA<CreateMessagePayload>) => {
			const findIndex = state.list.findIndex((element) => element.id === payload.conversationId);

			if (findIndex !== -1) {
				const newMessage: Message = {
					id: payload.temporaryId,
					userId: payload.userId,
					conversationId: payload.conversationId,
					status: MessageStatusType.CREATED,
					message: payload.message,
					createdAt: moment.utc().format('MM/DD/YYYY hh:mm A'),
				};

				state.list[findIndex].messages.push(newMessage);
				state.updatedAt = moment.utc().valueOf();
			}
		},
		messageUpdated: (state, { payload }: PA<UpdateMessagePayload>) => {
			const findIndex = state.list.findIndex((element) => element.id === payload.message.conversationId);

			if (findIndex !== -1) {
				const findMessageIndex = state.list[findIndex].messages.findIndex(
					(element) => element.id === payload.temporaryId
				);

				if (findMessageIndex !== -1) {
					const newMessage = {
						...state.list[findIndex].messages[findMessageIndex],
						id: payload.message.id,
						status: payload.message.status,
						message: payload.message.message,
						createdAt: payload.message.createdAt,
					};

					state.list[findIndex].messages.splice(findMessageIndex, 1, newMessage);
					state.updatedAt = moment.utc().valueOf();
				}
			}
		},
		messageReceived: (state, { payload }: PA<Message>) => {
			const findIndex = state.list.findIndex((element) => element.id === payload.conversationId);
			if (findIndex !== -1) {
				const findMessageIndex = state.list[findIndex].messages.findIndex(
					(element) => element.id === payload.id
				);
				if (findMessageIndex === -1) {
					state.list[findIndex].messages.push(payload);
					state.updatedAt = moment.utc().valueOf();
				}

				// if (state.list[findIndex].messages.every((e) => typeof e.id === 'number')) {
				// 	state.list[findIndex].messages.sort((a, b) => (a.id as number) - (b.id as number));
				// }
			}
		},
		messagesRead: (state, { payload }: PA<MessageReadPayload>) => {
			const findIndex = state.list.findIndex((element) => element.id === payload.conversationId);

			if (findIndex !== -1) {
				const newMessages = [...state.list[findIndex].messages];
				for (let i = 0; i < newMessages.length; i++) {
					if (
						newMessages[i].userId !== payload.userId &&
						newMessages[i].status === MessageStatusType.DELIVERED
					) {
						newMessages[i].status = MessageStatusType.READ;
					}
				}

				state.list[findIndex].messages = newMessages;
				state.updatedAt = moment.utc().valueOf();
			}
		},
	},
});

const fetchConversations = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CONVERSATION, method: 'GET' }));

		const { data, error } = await Api.conversations.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CONVERSATION,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.conversationsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CONVERSATION,
					method: 'GET',
					message: 'Conversations fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchConversation = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CONVERSATION_ID(id), method: 'GET' }));

		const { data, error } = await Api.conversations.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CONVERSATION_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.conversationFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CONVERSATION_ID(id),
					method: 'GET',
					message: 'Conversation fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchMessages = (conversationId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.MESSAGE_ID(conversationId), method: 'GET' }));

		const { data, error } = await Api.conversations.getMessages(conversationId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.MESSAGE_ID(conversationId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.messagesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.MESSAGE_ID(conversationId),
					method: 'GET',
					message: 'Messages fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

export const conversationThunks = {
	fetchConversations,
	fetchConversation,
	fetchMessages,
};
