import React from 'react';
import Select, { OptionsType } from 'react-select';
import { SelectOption } from 'lib/components/types';
import classes from './index.module.scss';

interface Props {
	items: any[];
	value: number[];
	setValue: React.Dispatch<React.SetStateAction<number[]>>;
}

const ItemsSelect = ({ items, value, setValue }: Props) => {
	const options: SelectOption<number>[] = items.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const handleChange = (options: OptionsType<SelectOption<number>>) => {
		setValue(Array.isArray(options) ? options.map((option) => option.value) : []);
	};

	return (
		<Select
			placeholder="Select Items"
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			isMulti
			options={options}
			isClearable={true}
			isSearchable={true}
			value={options.filter((option) => value.includes(option.value))}
			onChange={handleChange}
		/>
	);
};

export default ItemsSelect;
