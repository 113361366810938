const formatJobAttributeValue = (value: string) => {
	if (value === 'true') {
		return 'YES';
	} else if (value === 'false') {
		return 'NO';
	}

	return value;
};

export default formatJobAttributeValue;
