import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { CustomCost, CustomCostInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<CustomCost> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'custom_costs',
	initialState,
	reducers: {
		customCostsFetched: (state, { payload }: PA<CustomCost[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		customCostFetched: (state, { payload }: PA<CustomCost>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({ ...payload });
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		customCostDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchCustomCosts = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CUSTOM_COST, method: 'GET' }));

		const { data, error } = await Api.customCosts.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CUSTOM_COST,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.customCostsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST,
					method: 'GET',
					message: 'Custom costs fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchCustomCost = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CUSTOM_COST_ID(id), method: 'GET' }));

		const { data, error } = await Api.customCosts.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CUSTOM_COST_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.customCostFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST_ID(id),
					method: 'GET',
					message: 'Custom cost fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addCustomCost = (input: CustomCostInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CUSTOM_COST, method: 'POST' }));

		const { data, error } = await Api.customCosts.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CUSTOM_COST,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST,
					method: 'POST',
					message: 'Custom cost added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchCustomCosts());
		}
	};
};

const updateCustomCost = (id: number, input: CustomCostInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CUSTOM_COST_ID(id), method: 'PUT' }));

		const { data, error } = await Api.customCosts.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CUSTOM_COST_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.customCostFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST_ID(id),
					method: 'PUT',
					message: 'Custom cost updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteCustomCost = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.CUSTOM_COST_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.customCosts.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.CUSTOM_COST_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.customCostDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST_ID(id),
					method: 'DELETE',
					message: 'Custom cost deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const customCostThunks = {
	fetchCustomCosts,
	fetchCustomCost,
	addCustomCost,
	updateCustomCost,
	deleteCustomCost,
};
