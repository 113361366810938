import { Color } from 'lib/components/types';
import React from 'react';
import classes from './index.module.scss';

interface Input extends React.InputHTMLAttributes<HTMLInputElement> {}
interface Props extends Omit<Input, 'value' | 'checked' | 'onChange' | 'className' | 'type'> {
	color?: Color | 'primary2';
	checked: boolean;
	handleChecked: (value: boolean) => void;
}

const Switch = ({ id, color = 'primary', checked, handleChecked, ...props }: Props) => {
	const colorClassName = color ? classes[`switch-color-${color}`] : '';
	const combinedClassName = `${classes['switch-label']} ${colorClassName}`;

	return (
		<>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={(e) => handleChecked(e.target.checked)}
				className={classes['switch-checkbox']}
				{...props}
			/>
			<label className={combinedClassName} htmlFor={id}>
				<span className={classes['switch-button']} />
			</label>
		</>
	);
};

export default Switch;
