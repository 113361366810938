import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import { useCustomToast } from 'hooks';
import classes from './index.module.scss';
import ItemsSelect from './ItemsSelect';

interface Props {
	items: any[];
	restricted: number[];
	isOpen: boolean;
	action: (items: number[]) => void;
	onClose: () => any;
}

const RestrictItemsModal = ({ items, action, isOpen, onClose, restricted }: Props) => {
	const toast = useCustomToast();
	const [restrictedItems, setRestrictedItems] = useState<number[]>([]);

	const handleAction = () => {
		if (restrictedItems.length === 0) {
			toast('Please select at least one (1) Item', 'error');
		} else {
			action(restrictedItems);
		}
	};

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div className={classes['modal-body']}>
						<ItemsSelect
							value={restrictedItems}
							setValue={setRestrictedItems}
							items={items.filter((item) => !restricted.includes(item.id))}
						/>
					</div>
					<div className={classes['modal-footer']}>
						<Button
							variant="standard"
							color="primary"
							title="Restrict Items"
							style={{
								marginRight: '1rem',
							}}
							onClick={handleAction}
						/>
						<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default RestrictItemsModal;
