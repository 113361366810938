import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOption } from 'lib/components/types';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import classes from './index.module.scss';

enum AdminType {
	SuperAdmin = 1,
	Admin = 2,
}

interface Props {
	value?: AdminType;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
	style?: React.CSSProperties;
}

const options: SelectOption<AdminType>[] = [
	{ label: 'Super Administrator', value: 1 },
	{ label: 'Administrator', value: 2 },
];

const AdminTypeSelect = ({ value, setValue, style }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [option, setOption] = useState<SelectOption<AdminType> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<AdminType> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	return (
		<div className={classes['textfield-container']} style={style}>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				Role:
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</div>
	);
};

export default AdminTypeSelect;
