import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { JobFile } from 'types';
import FileDownload from './FileDownload';
import FileDelete from './FileDelete';
import classes from './index.module.scss';

interface Props {
	files: JobFile[];
}

const Downloadable = ({ files }: Props) => (
	<Scrollbars renderThumbVertical={(props) => <div {...props} className={classes['vertical-scroll-thumb']} />}>
		{files.map((file) => (
			<FileDownload key={file.id} file={file} />
		))}
	</Scrollbars>
);

const Deletable = ({ files, jobId }: Props & { jobId: number }) => (
	<Scrollbars renderThumbVertical={(props) => <div {...props} className={classes['vertical-scroll-thumb']} />}>
		{files.map((file) => (
			<FileDelete key={file.id} file={file} jobId={jobId} />
		))}
	</Scrollbars>
);

export default {
	Downloadable,
	Deletable,
};
