import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// import { nanoid as ID } from 'nanoid';
import { Typography, Image, Button } from 'lib/components';
import { EmployeeTypeSelect, TextField } from 'lib/components/FormFields';
import CustomSelectDatePickerModal from 'lib/components/CustomSelectDatePickerModal';
import { useAppDispatch, actions, useSelectRequest, RequestManager } from 'store';
// import AddSkillInput from './add-skill-input';
import { Timing } from 'lib/util';
import routes from 'lib/api/routes';
import { useComprehensiveTopNav, useWindowResize, useAddressInput, useCustomToast } from 'hooks';
import classes from './index.module.scss';
import Tag from 'lib/components/Tag';
import AddTag from 'lib/components/Tag/AddMore';
import useNurseHoursInput from '../../hooks/useNurseHoursInput';
import TimePicker from '../../lib/components/FormFields/TimePicker';
import moment from 'moment';
import BuddyPunchEmployeeSelect from '../../lib/components/FormFields/CustomSelect/BuddyPunchEmployeeSelect';
import { BuddyPunchEmployee } from '../../types';

const NurseCreateScreen = () => {
	const toast = useCustomToast();
	const { push, goBack } = useHistory();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [employeeType, setEmployeeType] = useState(0);
	const [yearsOfExperience, setYearsOfExperience] = useState<string>('0');
	const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());
	const [phoneNumber, setPhoneNumber] = useState('');
	const [notes, setNotes] = useState('');
	const [buddyPunchId, setBuddyPunchId] = useState<BuddyPunchEmployee>();

	// const [isSkillInputOpen, setIsSkillInputOpen] = useState(false);
	const [skills, setSkills] = useState<Record<string, boolean>>({});
	const [showDOBModal, setShowDOBModal] = useState(false);

	const {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	} = useAddressInput();

	const {
		monFrom,
		setMonFrom,
		monTo,
		setMonTo,
		tueFrom,
		setTueFrom,
		tueTo,
		setTueTo,
		wedFrom,
		setWedFrom,
		wedTo,
		setWedTo,
		thuFrom,
		setThuFrom,
		thuTo,
		setThuTo,
		friFrom,
		setFriFrom,
		friTo,
		setFriTo,
		satFrom,
		setSatFrom,
		satTo,
		setSatTo,
		sunFrom,
		setSunFrom,
		sunTo,
		setSunTo,
	} = useNurseHoursInput();

	const skillsToArray = (skills_: Record<string, boolean>) => {
		return Object.entries(skills_)
			.filter(([, value]) => value)
			.map(([skill]) => skill);
	};

	const addSkill = (skill: string) => {
		setSkills((skills_) => ({ ...skills_, [skill]: true }));
	};

	const removeSkill = (skill: string) => {
		setSkills((skills_) => ({ ...skills_, [skill]: false }));
	};

	const updateSkill = (prev: string, cur: string) => {
		setSkills((skills_) => {
			const temp = { ...skills_ };
			delete temp[prev];
			temp[cur] = true;
			return temp;
		});
	};

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.NURSE, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/nurses');
			}
		}
	}, [active]);

	const createNurse = () => {
		dispatch(
			actions.addNurse({
				name,
				email,
				dateOfBirth: Timing.formatDate(dateOfBirth),
				notes,
				buddyPunchId: buddyPunchId !== undefined ? buddyPunchId.id.toString() : null,
				buddyPunchGPSPermissions: buddyPunchId !== undefined ? buddyPunchId.gpsPermissions : null,
				skills: skillsToArray(skills),
				employeeType,
				addressLine1,
				addressLine2: addressLine2 === '' ? undefined : addressLine2,
				city,
				state,
				postalCode,
				yearsOfExperience: isNaN(parseInt(yearsOfExperience)) ? 0 : parseInt(yearsOfExperience),
				phoneNumber,
				hours: {
					monFrom,
					monTo,
					tueFrom,
					tueTo,
					wedFrom,
					wedTo,
					thuFrom,
					thuTo,
					friFrom,
					friTo,
					satFrom,
					satTo,
					sunFrom,
					sunTo,
				},
			})
		);
	};

	useComprehensiveTopNav('Create a new Profile');

	const openDOBModal = useCallback(() => {
		if (showDOBModal === false) {
			setShowDOBModal(true);
		}
	}, [showDOBModal, setShowDOBModal]);

	const closeDOBModal = () => setShowDOBModal(false);

	return (
		<>
			<CustomSelectDatePickerModal
				isOpen={showDOBModal}
				onClose={closeDOBModal}
				onDateSet={(date) => setDateOfBirth(date)}
			/>
			<div
				className={classes['nurses-container']}
				style={{
					flexDirection: 'column',
				}}
			>
				<div className={classes['top-container']}>
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<Image src={'plAvatar'} />
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div>
							<div className={classes['profile-details-group']}>
								<TextField
									placeholder="Regina Smith"
									label="Name:"
									variant="labelled"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<div>
									<EmployeeTypeSelect setValue={setEmployeeType} />
								</div>
								<TextField
									placeholder="Address 1"
									label="Address Line 1:"
									variant="labelled"
									value={addressLine1}
									onChange={(e) => setAddressLine1(e.target.value)}
								/>
								<TextField
									placeholder="Address 2"
									label="Address Line 2:"
									variant="labelled"
									value={addressLine2}
									onChange={(e) => setAddressLine2(e.target.value)}
								/>
							</div>
							<div className={classes['profile-details-group']}>
								<TextField
									placeholder="Ohara"
									label="City:"
									variant="labelled"
									value={city}
									onChange={(e) => setCity(e.target.value)}
								/>
								<TextField
									placeholder="Texas"
									label="State:"
									variant="labelled"
									value={state}
									onChange={(e) => setState(e.target.value)}
								/>
								<TextField
									placeholder="P. O. Box 1102"
									label="Postal Code:"
									variant="labelled"
									value={postalCode}
									onChange={(e) => setPostalCode(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={classes['bottom-container']}
					style={{
						padding: isSmallDevice ? '1rem' : undefined,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ marginBottom: '1.5rem' }}>
							<Typography weight="bold" variant={isSmallerDevice ? 'h5' : undefined}>
								Basic info:
							</Typography>
						</div>
						<div className={classes['textField-group']}>
							<TextField
								placeholder="+122 3393 894"
								label="Mobile number:"
								variant="labelled"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							<TextField
								placeholder="contact@example.com"
								label="Email:"
								variant="labelled"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<TextField
								placeholder=""
								label="Date of Birth:"
								variant="labelled"
								onFocus={openDOBModal}
								onClick={openDOBModal}
								value={Timing.formatDate(dateOfBirth)}
							/>
							<TextField
								placeholder=""
								label="Years of experience:"
								variant="labelled"
								value={yearsOfExperience}
								onChange={(e) => setYearsOfExperience(e.target.value)}
							/>
							<div>
								<BuddyPunchEmployeeSelect value={buddyPunchId} setValue={setBuddyPunchId} />
							</div>
						</div>
						<div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
							<Typography weight="bold" variant={isSmallerDevice ? 'h5' : undefined}>
								Working Hours:
							</Typography>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Monday"
								value={monFrom ? moment(`1970-01-01 ${monFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setMonFrom(value)}
							/>
							<TimePicker
								label=""
								value={monTo ? moment(`1970-01-01 ${monTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setMonTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Tuesday"
								value={tueFrom ? moment(`1970-01-01 ${tueFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setTueFrom(value)}
							/>
							<TimePicker
								label=""
								value={tueTo ? moment(`1970-01-01 ${tueTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setTueTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Wednesday"
								value={wedFrom ? moment(`1970-01-01 ${wedFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setWedFrom(value)}
							/>
							<TimePicker
								label=""
								value={wedTo ? moment(`1970-01-01 ${wedTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setWedTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Thursday"
								value={thuFrom ? moment(`1970-01-01 ${thuFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setThuFrom(value)}
							/>
							<TimePicker
								label=""
								value={thuTo ? moment(`1970-01-01 ${thuTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setThuTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Friday"
								value={friFrom ? moment(`1970-01-01 ${friFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setFriFrom(value)}
							/>
							<TimePicker
								label=""
								value={friTo ? moment(`1970-01-01 ${friTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setFriTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Saturday"
								value={satFrom ? moment(`1970-01-01 ${satFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setSatFrom(value)}
							/>
							<TimePicker
								label=""
								value={satTo ? moment(`1970-01-01 ${satTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setSatTo(value)}
							/>
						</div>
						<div className={classes['textField-group']}>
							<TimePicker
								label="Sunday"
								value={sunFrom ? moment(`1970-01-01 ${sunFrom}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setSunFrom(value)}
							/>
							<TimePicker
								label=""
								value={sunTo ? moment(`1970-01-01 ${sunTo}`, 'YYYY-MM-DD hh:mm A') : undefined}
								onChange={(value) => setSunTo(value)}
							/>
						</div>
						<div className={classes['textArea-group']}>
							<TextField
								placeholder="Enter the description"
								label="Private notes:"
								variant="labelled"
								value={notes}
								onChange={(e) => setNotes(e.target.value)}
								textArea
							/>
						</div>

						<div className={classes['row']} style={{ marginTop: '1rem' }}>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography
									variant={isSmallerDevice ? 'h5' : 'subheading2'}
									style={{ opacity: '0.5' }}
									className={classes['margin-top']}
								>
									Skills:
								</Typography>
								<div className={classes['skills']}>
									{skillsToArray(skills).map((skill) => (
										<Tag
											initialTitle={skill}
											key={skill}
											onValueChanged={(value) => updateSkill(value.prev, value.cur)}
											onRemove={(skill_) => removeSkill(skill_)}
										/>
									))}
									<AddTag onAddMore={(skill) => addSkill(skill)} />

									{/* {skillsToArray(skills).map((skill) => (
										<div className={classes['skill']} key={ID()} style={{ marginRight: 12 }}>
											<p className={classes['info-label']}>{skill}</p>
										</div>
									))}
									{!isSkillInputOpen && (
										<button
											className={classes['skill']}
											style={{ color: '#000' }}
											onClick={() => setIsSkillInputOpen(true)}
										>
											<p className={classes['info-label']}>
												{skillsToArray(skills).length === 0 ? '+ Add Skills' : '+ Add more'}
											</p>
										</button>
									)}
									{isSkillInputOpen && (
										<AddSkillInput setIsOpen={setIsSkillInputOpen} action={addSkill} />
									)} */}
								</div>
							</div>
						</div>
					</div>
					{/* <div className={classes['extra-settings']}>
						<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>Extra Settings:</Typography>
						<div className={classes['extra-setting']}>
							<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Allow Nurse to lorem
							</Typography>
							<Switch id="id" />
						</div>
						<div className={classes['extra-setting']}>
							<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Allow Nurse to lorem
							</Typography>
							<Switch id="id" />
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'subheading2'} className={classes['margin-top']}>
							Skills:
						</Typography>
						<div className={classes['skills']}>
							{skills.map((skill) => (
								<div className={classes['skill']} key={ID()} style={{ marginRight: 12 }}>
									<p className={classes['info-label']}>{skill}</p>
								</div>
							))}
							<button className={classes['skill']} style={{ color: '#000' }}>
								<p className={classes['info-label']}>+ Add more</p>
							</button>
						</div>
					</div> */}
				</div>
				<div className={classes['footButtons']}>
					<Button variant="standard" title="Create New Profile" onClick={createNurse} />
					<Button variant="standard" title="Cancel" onClick={goBack} />
				</div>
			</div>
		</>
	);
};

export default NurseCreateScreen;
