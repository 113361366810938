import { combineReducers } from 'redux';
import admins, { adminThunks } from './slices/admins.slice';
import auth, { authThunks } from './slices/auth.slice';
import buddyPunchEmployee, { buddyPunchEmployeeThunks } from './slices/bp-employee.slice';
import buddyPunchLocation, { buddyPunchLocationThunks } from './slices/bp-location.slice';
import conversations, { conversationThunks } from './slices/conversations.slice';
import customCosts, { customCostThunks } from './slices/custom-cost.slice';
import employees, { employeeThunks } from './slices/employees.slice';
import hospitalServices, { hospitalServiceThunks } from './slices/hospital-services.slice';
import hospitals, { hospitalThunks } from './slices/hospitals.slice';
import inventory, { inventoryThunks } from './slices/inventory.slice';
import invoices, { invoiceThunks } from './slices/invoices.slice';
import jobs, { jobThunks } from './slices/jobs.slice';
import nav from './slices/nav.slice';
import nurses, { nursesThunks } from './slices/nurses.slice';
import request from './slices/request.slice';
import restrictedInventory, { restrictedInventoryThunks } from './slices/restricted-inventory.slice';
import restrictedVendors, { restrictedVendorThunks } from './slices/restricted-vendors.slice';
import services, { serviceThunks } from './slices/services.slice';
import setting, { settingThunks } from './slices/setting.slice';
import user, { userThunks } from './slices/user.slice';
import users, { usersThunks } from './slices/users.slice';
import vendors, { vendorsThunks } from './slices/vendors.slice';

export const actions = {
	...authThunks,
	...buddyPunchEmployeeThunks,
	...buddyPunchLocationThunks,
	...userThunks,
	...usersThunks,
	...adminThunks,
	...nursesThunks,
	...vendorsThunks,
	...inventoryThunks,
	...restrictedInventoryThunks,
	...hospitalThunks,
	...restrictedVendorThunks,
	...employeeThunks,
	...jobThunks,
	...conversationThunks,
	...invoiceThunks,
	...serviceThunks,
	...hospitalServiceThunks,
	...customCostThunks,
	...settingThunks,
	...request.actions,
	...buddyPunchEmployee.actions,
	...buddyPunchLocation.actions,
	...nav.actions,
	...auth.actions,
	...user.actions,
	...users.actions,
	...admins.actions,
	...nurses.actions,
	...inventory.actions,
	...restrictedInventory.actions,
	...vendors.actions,
	...restrictedVendors.actions,
	...hospitals.actions,
	...employees.actions,
	...jobs.actions,
	...conversations.actions,
	...invoices.actions,
	...services.actions,
	...hospitalServices.actions,
	...customCosts.actions,
	...setting.actions,
};

export default combineReducers({
	nav: nav.reducer,
	request: request.reducer,
	authentication: auth.reducer,
	user: user.reducer,
	users: users.reducer,
	admins: admins.reducer,
	nurses: nurses.reducer,
	hospitals: hospitals.reducer,
	employees: employees.reducer,
	inventory: inventory.reducer,
	restrictedInventory: restrictedInventory.reducer,
	vendors: vendors.reducer,
	restrictedVendors: restrictedVendors.reducer,
	jobs: jobs.reducer,
	conversations: conversations.reducer,
	invoices: invoices.reducer,
	services: services.reducer,
	hospitalServices: hospitalServices.reducer,
	customCosts: customCosts.reducer,
	setting: setting.reducer,
	buddyPunchEmployee: buddyPunchEmployee.reducer,
	buddyPunchLocation: buddyPunchLocation.reducer,
});
