import React, { useEffect, useState } from 'react';
import classes from './index.module.scss';
import { Time } from './types';

export interface TimeFieldProps extends Time {
	onChange: (time: Time) => any;
	disabled?: boolean;
	bare?: boolean;
	textClassName?: string;
	prefix?: boolean;
}

const TimeField = ({ min, hr, pm, disabled, bare, prefix = false, textClassName, onChange }: TimeFieldProps) => {
	const [curMin, setCurMin] = useState(min);
	const [curHr, setCurHr] = useState(hr);
	const [curPm, setCurPm] = useState(pm);

	useEffect(() => {
		setCurMin(min);
	}, [min]);
	useEffect(() => {
		setCurHr(hr);
	}, [hr]);
	useEffect(() => {
		setCurPm(pm);
	}, [pm]);

	useEffect(() => {
		onChange({ min: curMin, hr: curHr, pm: curPm });
	}, [curMin, curHr]);

	return (
		<div className={classes['timeField'] + ` ${bare ? classes['timeField-bare'] : ''}`}>
			<input
				className={`${classes['timeField-input']} ${textClassName ? textClassName : ''}`}
				type="text"
				pattern="[0-9]"
				// min={0}
				// max={23}
				value={hr}
				// value={curHr.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
				onChange={(e) => setCurHr(e.target.value)}
				disabled={disabled}
			/>
			<span>:</span>
			<input
				className={`${classes['timeField-input']} ${textClassName ? textClassName : ''}`}
				type="text"
				pattern="[0-9]"
				// min={0}
				// max={60}
				value={min}
				// value={curMin.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
				onChange={(e) => setCurMin(e.target.value)}
				disabled={disabled}
			/>
			{prefix && <span className={classes.textFieldSpan}>{pm ? 'PM' : 'AM'}</span>}
		</div>
	);
};

export default TimeField;
