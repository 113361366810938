import React, { useState, useEffect } from 'react';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { AllCheckedStatus, HospitalService } from 'types';
import classes from './index.module.scss';
import { RateType } from '../../../util';

interface Props {
	item: HospitalService;
	allCheckedStatus: AllCheckedStatus;
	checkAction: (id: number, isChecked: boolean) => void;
	onClick: (id: number) => void;
}

const ItemRow = ({ item, allCheckedStatus, checkAction, onClick }: Props) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		if (allCheckedStatus === 'all') setChecked(true);
		if (allCheckedStatus === 'none') setChecked(false);
	}, [allCheckedStatus]);

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(item.id, e.target.checked);
	};

	const getSymbol = (amount: number, rateType: RateType) => {
		if (rateType === RateType.FIXED) {
			return (amount < 0 ? '-' : '') + `\$${amount.toFixed(2)}`;
		}

		return (amount < 0 ? '-' : '') + `${amount}%`;
	};

	return (
		<div className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleChecked}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div onClick={() => onClick(item.id)} className={classes['col']} style={{ cursor: 'pointer' }}>
				<Typography variant={'h6'}>{item.id}</Typography>
			</div>
			<div onClick={() => onClick(item.id)} className={classes['col']} style={{ cursor: 'pointer' }}>
				<Typography variant={'h6'}>{item.service.name}</Typography>
			</div>
			<Typography variant={'h6'} className={classes['col']}>
				{item.hoursIncluded}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{getSymbol(item.baseCost, RateType.FIXED)}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{getSymbol(item.regularRate, item.regularRateTypeId)}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{getSymbol(item.overtimeRate, item.overtimeRateTypeId)}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{getSymbol(item.weekendRate, item.weekendRateTypeId)}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{getSymbol(item.holidayRate, item.holidayRateTypeId)}
			</Typography>
		</div>
	);
};

export default ItemRow;
