import React from 'react';
import { Image } from '..';
import { Color, ImageSource } from '../types';
import classes from './index.module.scss';

type Variant = 'normal' | 'outline';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	query: string;
	setQuery: React.Dispatch<React.SetStateAction<string>>;
	className?: string;
	onSearch?: () => void;
	searchIconColor?: Color;
	isLoading?: boolean;
	variant?: Variant;
}

const SearchBar = ({
	query,
	setQuery,
	className = '',
	variant = 'normal',
	onSearch,
	searchIconColor,
	isLoading,
	...props
}: Props) => {
	const variantClass = variant !== 'normal' ? classes[`searchBar-${variant}`] : '';
	const combinedClassName = `${classes['searchBar']} ${variantClass} ${className}`;
	const searchIcon: ImageSource = searchIconColor === 'primary' ? 'icSearchPrimary' : 'icSearch';

	return (
		<div className={combinedClassName}>
			<input
				name="query"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				className={classes['searchBar-input']}
				{...props}
			/>
			<a onClick={() => onSearch && onSearch()} type="submit" className={classes['searchBar-endIcon']}>
				{!isLoading ? <Image src={searchIcon} /> : <span>loading indicator</span>}
			</a>
		</div>
	);
};

export default SearchBar;
