import React, { useState } from 'react';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { JobCustomCost } from 'types';
import classes from './index.module.scss';

interface Props {
	items: Array<JobCustomCost>;
	updateCustomCosts: (cost: JobCustomCost, checked: boolean) => void;
	defaultCheckedIds?: Array<number>;
}

interface ItemProps {
	item: JobCustomCost;
	updateCustomCosts: (cost: JobCustomCost, checked: boolean) => void;
	defaultChecked?: boolean;
}

const JobCustomCostItem = ({ item, updateCustomCosts, defaultChecked = false }: ItemProps) => {
	const [checked, setChecked] = useState(defaultChecked);

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked;
		setChecked(value);
		updateCustomCosts(item, value);
	};

	return (
		<div key={item.customCostId} className={classes['costAmplifiers-listItem']}>
			<Typography>{item.name}</Typography>
			<Checkbox checked={checked} onChange={handleChecked} />
		</div>
	);
};

const JobCustomCostList = ({ items, updateCustomCosts, defaultCheckedIds }: Props) => {
	return (
		<div className={classes['costAmplifiers-list']}>
			{items.map((item) => (
				<JobCustomCostItem
					key={item.customCostId}
					item={item}
					updateCustomCosts={updateCustomCosts}
					defaultChecked={defaultCheckedIds?.includes(item.customCostId)}
				/>
			))}
		</div>
	);
};

export default JobCustomCostList;
