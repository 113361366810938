import React from 'react';
import classes from './index.module.scss';

interface Props {
	valuePercentage: number;
}

const ProgressBar = ({ valuePercentage }: Props) => {
	return (
		<div className={classes['progressBar']}>
			<div style={{ width: `${valuePercentage}%` }} className={classes['progressBar-inner']}></div>
		</div>
	);
};

export default ProgressBar;
