import React from 'react';
import { Image, Typography } from 'lib/components';
import { ErrorItem } from 'lib/components/types';
import { useHistory } from 'react-router';
import classes from './index.module.scss';

interface Props {
	error: ErrorItem;
}

const ErrorScreen = ({ error }: Props) => {
	const { goBack } = useHistory();

	return (
		<div className={classes['error']}>
			<div className={classes['error-content']}>
				<div className={classes['error-info']}>
					<Image src={error.icon ?? 'icErrorLock'} />
					<Typography className={classes['error-code']} variant="h1" color="primary" weight="bold">
						{error.code}
					</Typography>
					<Typography className={classes['error-title']}>{error.title}</Typography>
					<Typography>{error.message}</Typography>
				</div>
				<a onClick={goBack} className={classes['error-goBack']}>
					<Typography color="primary">← Go Back</Typography>
				</a>
			</div>
		</div>
	);
};

export default ErrorScreen;
