import React, { useCallback, useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Typography, Image } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import ItemRow from './ItemRow';
import { useWindowResize } from 'hooks/useWindowResize';
import { AllCheckedStatus } from 'types';
import classes from './index.module.scss';
import { HeaderItem, Sort, SortField } from './types';

import { itemIDCompareFn, itemNameCompareFn } from './sort';

interface Props {
	items: any[];
	allChecked: boolean;
	allCheckedStatus: AllCheckedStatus;
	handleAllCheckAction: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkAction: (id: number, isChecked: boolean) => void;
	onClick: (id: number) => void;
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'ID', field: 'id', sort: 'asc' },
	{ label: 'Name', field: 'name', sort: 'asc' },
	{ label: 'Hours Included', field: 'hoursIncluded', sort: 'asc' },
	{ label: 'Base Price', field: 'basePrice', sort: 'asc' },
	{ label: 'Business/h', field: 'businessCost', sort: 'asc' },
	{ label: 'Overtime/h', field: 'overtimeCost', sort: 'asc' },
	{ label: 'Weekend/h', field: 'weekendCost', sort: 'asc' },
	{ label: 'Holiday/h', field: 'holidayCost', sort: 'asc' },
];

const HospitalServicesDataTable = ({
	items,
	allChecked,
	allCheckedStatus,
	handleAllCheckAction,
	checkAction,
	onClick,
}: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'id',
	});
	const [sortedItems, setSortedItems] = useState<any[]>(items);

	const sortItems = useCallback(
		(items: any[]) => {
			const sorted = [...items].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'name') {
							return itemNameCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'id') {
							return itemIDCompareFn(a, b, 'asc');
						}

						return 0;

					case 'desc':
						if (currentSortField?.field === 'name') {
							return itemNameCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'id') {
							return itemIDCompareFn(a, b, 'desc');
						}

						return 0;

					default:
						return 0;
				}
			});
			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedItems(sortItems(items));
	}, [items, sortItems]);

	useEffect(() => {
		const sorted = sortItems(sortedItems);

		setSortedItems(sorted);
	}, [sortItems, setSortedItems]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={classes['items-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={handleAllCheckAction}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{HEADER_ITEMS.map((item) => renderHeaderItem(item))}
					</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedItems.map((item) => (
								<ItemRow
									key={item.id}
									item={item}
									checkAction={checkAction}
									onClick={onClick}
									allCheckedStatus={allCheckedStatus}
								/>
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HospitalServicesDataTable;
