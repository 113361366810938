import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Image } from 'lib/components';
import { AdminTypeSelect, TextField } from 'lib/components/FormFields';
import PermissionRow from './PermissionRow';
import { useAppDispatch, actions, useSelectRequest, RequestManager } from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { Permission, PermissionType, PERMISSIONS, Timing } from 'lib/util';
import { AllCheckedStatus } from 'types';
import classes from './index.module.scss';

const AdminCreate = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState('');
	const [adminType, setAdminType] = useState<number | undefined>();
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [permissions, setPermissions] = useState<PermissionType[]>([]);
	const [allCheckedStatus, setAllCheckedStatus] = useState<AllCheckedStatus>('none');

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.ADMIN, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/admins');
			}
		}
	}, [active]);

	useEffect(() => {
		if (adminType === 1) {
			setAllCheckedStatus('none');
			setPermissions([]);
		}
	}, [adminType]);

	const handleCheckAction = (id: PermissionType, isChecked: boolean) => {
		setAllCheckedStatus('some');

		if (isChecked && !permissions.includes(id)) {
			setPermissions((prev) => [id, ...Permission.addListAndReadPermissions(prev, id)]);
			return;
		}

		if (!isChecked && permissions.includes(id)) {
			setPermissions((prev) => [...Permission.removeCreateAndUpdatePermissions(prev, id)]);
		}
	};

	const handleCreate = () => {
		dispatch(
			actions.addAdmin({
				name,
				roleId: adminType ? adminType : 2,
				permissions,
				email,
				dateOfBirth: Timing.formatDate(new Date()),
				phoneNumber: phoneNumber !== '' ? phoneNumber : undefined,
				password,
			})
		);
	};

	useComprehensiveTopNav('Create a new Admin Profile');

	return (
		<div
			className={classes['profile-container']}
			style={{
				flexDirection: 'column',
			}}
		>
			<>
				<div className={classes['top-container']}>
					{console.log({ permissions })}
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<Image src={'plAvatar'} style={{ width: '100%', height: '100%' }} />
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Name:
							</Typography>
							<input
								className={classes['input']}
								style={{
									width: '15rem',
									minWidth: 250,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<AdminTypeSelect setValue={setAdminType} />
						</div>
					</div>
				</div>

				<div className={classes['section']}>
					<div className={classes['section-group']}>
						<Typography weight="bold">Basic info:</Typography>
						<div className={classes['section-groupFields']}>
							<TextField
								placeholder="+1 75 85 41 223"
								label="Mobile Number:"
								variant="labelled"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							<TextField
								placeholder="reginasmith@spencerhospital.com"
								label="Email:"
								variant="labelled"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes['section-group']}>
						<Typography weight="bold">Set New Password:</Typography>
						<div className={classes['section-groupFields']}>
							<TextField
								placeholder="*****************"
								label="Password:"
								variant="labelled"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>
				</div>

				{adminType === 2 && (
					<div className={classes['permissions']}>
						<Typography style={{ paddingLeft: '2rem' }} weight="bold">
							Edit Roles Permissions
						</Typography>
						<div className={classes['permissions-table']}>
							<div className={classes['permissions-tableRow']}>
								<div className={classes['permissions-tableCol']}>
									<Typography weight="bold">Role</Typography>
								</div>
								<div className={classes['permissions-tableCol']}>
									<Typography weight="bold">Permissions</Typography>
								</div>
								<div className={classes['permissions-tableCol']} />
							</div>
							{PERMISSIONS.map((permission) => (
								<PermissionRow
									key={permission}
									permission={permission}
									list={permissions}
									status={allCheckedStatus}
									checkAction={handleCheckAction}
								/>
							))}
						</div>
					</div>
				)}

				<div
					className={classes['row']}
					style={{
						margin: 'auto 0 1rem 1rem',
					}}
				>
					<button
						className={classes['button']}
						style={{
							width: 'auto',
							marginRight: '1rem',
						}}
						onClick={handleCreate}
					>
						<p>Create New Profile</p>
					</button>
					<button
						className={`${classes['button']} ${classes['palewhite']}`}
						style={{
							width: 'auto',
						}}
						onClick={() => push('/admins')}
					>
						<p>Cancel</p>
					</button>
				</div>
			</>
		</div>
	);
};

export default AdminCreate;
