import { PermissionType } from '.';

export default class Permission {
	static readonly CREATE_HOSPITAL = 0;
	static readonly READ_HOSPITAL = 1;
	static readonly UPDATE_HOSPITAL = 2;
	static readonly DELETE_HOSPITAL = 3;
	static readonly LIST_HOSPITAL = 4;
	static readonly CREATE_NURSE = 10;
	static readonly READ_NURSE = 11;
	static readonly UPDATE_NURSE = 12;
	static readonly DELETE_NURSE = 13;
	static readonly LIST_NURSE = 14;
	static readonly CREATE_VENDOR = 20;
	static readonly READ_VENDOR = 21;
	static readonly UPDATE_VENDOR = 22;
	static readonly DELETE_VENDOR = 23;
	static readonly LIST_VENDOR = 24;
	static readonly CREATE_INVENTORY = 30;
	static readonly READ_INVENTORY = 31;
	static readonly UPDATE_INVENTORY = 32;
	static readonly DELETE_INVENTORY = 33;
	static readonly LIST_INVENTORY = 34;
	static readonly CREATE_JOB = 40;
	static readonly READ_JOB = 41;
	static readonly UPDATE_JOB = 42;
	static readonly DELETE_JOB = 43;
	static readonly LIST_JOB = 44;
	static readonly VOID_INVOICE = 50;
	static readonly LIST_INVOICE = 51;
	static readonly READ_INVOICE = 52;
	static readonly CREATE_EMPLOYEE = 60;
	static readonly READ_EMPLOYEE = 61;
	static readonly UPDATE_EMPLOYEE = 62;
	static readonly DELETE_EMPLOYEE = 63;
	static readonly LIST_EMPLOYEE = 64;
	static readonly CREATE_SERVICE = 70;
	static readonly READ_SERVICE = 71;
	static readonly UPDATE_SERVICE = 72;
	static readonly DELETE_SERVICE = 73;
	static readonly LIST_SERVICE = 74;
	static readonly CREATE_CUSTOM_COST = 80;
	static readonly READ_CUSTOM_COST = 81;
	static readonly UPDATE_CUSTOM_COST = 82;
	static readonly DELETE_CUSTOM_COST = 83;
	static readonly LIST_CUSTOM_COST = 84;

	private static readonly STATUS_TEXT = {
		[Permission.CREATE_HOSPITAL]: 'Create Hospitals',
		[Permission.READ_HOSPITAL]: 'Read Hospitals',
		[Permission.UPDATE_HOSPITAL]: 'Update Hospitals',
		[Permission.DELETE_HOSPITAL]: 'Delete Hospitals',
		[Permission.LIST_HOSPITAL]: 'List Hospitals',
		[Permission.CREATE_NURSE]: 'Create Nurses',
		[Permission.READ_NURSE]: 'Read Nurses',
		[Permission.UPDATE_NURSE]: 'Update Nurses',
		[Permission.DELETE_NURSE]: 'Delete Nurses',
		[Permission.LIST_NURSE]: 'List Nurses',
		[Permission.CREATE_VENDOR]: 'Create Vendors',
		[Permission.READ_VENDOR]: 'Read Vendors',
		[Permission.UPDATE_VENDOR]: 'Update Vendors',
		[Permission.DELETE_VENDOR]: 'Delete Vendors',
		[Permission.LIST_VENDOR]: 'List Vendors',
		[Permission.CREATE_INVENTORY]: 'Create Inventory',
		[Permission.READ_INVENTORY]: 'Read Inventory',
		[Permission.UPDATE_INVENTORY]: 'Update Inventory',
		[Permission.DELETE_INVENTORY]: 'Delete Inventory',
		[Permission.LIST_INVENTORY]: 'List Inventory',
		[Permission.CREATE_JOB]: 'Create Jobs',
		[Permission.READ_JOB]: 'Read Jobs',
		[Permission.UPDATE_JOB]: 'Update Jobs',
		[Permission.DELETE_JOB]: 'Delete Jobs',
		[Permission.LIST_JOB]: 'List Jobs',
		[Permission.VOID_INVOICE]: 'Void Invoice',
		[Permission.LIST_INVOICE]: 'List Invoice',
		[Permission.READ_INVOICE]: 'Read Invoice',
		[Permission.CREATE_EMPLOYEE]: 'Create Employee',
		[Permission.READ_EMPLOYEE]: 'Read Employee',
		[Permission.UPDATE_EMPLOYEE]: 'Update Employee',
		[Permission.DELETE_EMPLOYEE]: 'Delete Employee',
		[Permission.LIST_EMPLOYEE]: 'List Employee',
		[Permission.CREATE_SERVICE]: 'Create Service',
		[Permission.READ_SERVICE]: 'Read Service',
		[Permission.UPDATE_SERVICE]: 'Update Service',
		[Permission.DELETE_SERVICE]: 'Delete Service',
		[Permission.LIST_SERVICE]: 'List Service',
		[Permission.CREATE_CUSTOM_COST]: 'Create Custom Cost',
		[Permission.READ_CUSTOM_COST]: 'Read Custom Cost',
		[Permission.UPDATE_CUSTOM_COST]: 'Update Custom Cost',
		[Permission.DELETE_CUSTOM_COST]: 'Delete Custom Cost',
		[Permission.LIST_CUSTOM_COST]: 'List Custom Cost',
	};

	public static text = (type: PermissionType) => Permission.STATUS_TEXT[type];

	private static addListAndRead = (permissions: PermissionType[], toBeAdded: PermissionType[]) => {
		let list = [...permissions];
		for (let permission of toBeAdded) {
			if (!list.includes(permission)) list.push(permission);
		}

		return list;
	};

	private static removeCreateAndUpdate = (permissions: PermissionType[], toBeRemoved: PermissionType[]) =>
		permissions.filter((item) => !toBeRemoved.includes(item));

	public static addListAndReadPermissions = (permissions: PermissionType[], permission: PermissionType) => {
		if ([PermissionType.CREATE_HOSPITAL, PermissionType.UPDATE_HOSPITAL].includes(permission)) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_HOSPITAL, PermissionType.READ_HOSPITAL]);
		}

		if ([PermissionType.CREATE_EMPLOYEE, PermissionType.UPDATE_EMPLOYEE].includes(permission)) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_EMPLOYEE, PermissionType.READ_EMPLOYEE]);
		}

		if ([PermissionType.CREATE_INVENTORY, PermissionType.UPDATE_INVENTORY].includes(permission)) {
			return Permission.addListAndRead(permissions, [
				PermissionType.LIST_INVENTORY,
				PermissionType.READ_INVENTORY,
			]);
		}

		if ([PermissionType.CREATE_VENDOR, PermissionType.UPDATE_VENDOR].includes(permission)) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_VENDOR, PermissionType.READ_VENDOR]);
		}

		if ([PermissionType.CREATE_JOB, PermissionType.UPDATE_JOB].includes(permission)) {
			return Permission.addListAndRead(permissions, [
				PermissionType.LIST_JOB,
				PermissionType.READ_JOB,
				PermissionType.LIST_INVENTORY,
				PermissionType.LIST_VENDOR,
				PermissionType.LIST_NURSE,
				PermissionType.LIST_HOSPITAL,
				PermissionType.LIST_SERVICE,
				PermissionType.LIST_CUSTOM_COST,
			]);
		}

		if ([PermissionType.CREATE_NURSE, PermissionType.UPDATE_NURSE].includes(permission)) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_NURSE, PermissionType.READ_NURSE]);
		}

		if ([PermissionType.CREATE_SERVICE, PermissionType.UPDATE_SERVICE].includes(permission)) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_SERVICE, PermissionType.READ_SERVICE]);
		}

		if ([PermissionType.CREATE_CUSTOM_COST, PermissionType.UPDATE_CUSTOM_COST].includes(permission)) {
			return Permission.addListAndRead(permissions, [
				PermissionType.LIST_CUSTOM_COST,
				PermissionType.READ_CUSTOM_COST,
			]);
		}

		if (permission === PermissionType.VOID_INVOICE) {
			return Permission.addListAndRead(permissions, [PermissionType.LIST_INVOICE, PermissionType.READ_INVOICE]);
		}

		return permissions;
	};

	public static removeCreateAndUpdatePermissions = (permissions: PermissionType[], permission: PermissionType) => {
		if ([PermissionType.LIST_HOSPITAL, PermissionType.READ_HOSPITAL].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_HOSPITAL,
				PermissionType.UPDATE_HOSPITAL,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_NURSE, PermissionType.READ_NURSE].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_NURSE,
				PermissionType.UPDATE_NURSE,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_VENDOR, PermissionType.READ_VENDOR].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_VENDOR,
				PermissionType.UPDATE_VENDOR,
			]).filter((item) => item !== permission);
		}
		if ([PermissionType.LIST_INVENTORY, PermissionType.READ_INVENTORY].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_INVENTORY,
				PermissionType.UPDATE_INVENTORY,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_JOB, PermissionType.READ_JOB].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_JOB,
				PermissionType.UPDATE_JOB,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_INVOICE, PermissionType.READ_INVOICE].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [PermissionType.VOID_INVOICE]).filter(
				(item) => item !== permission
			);
		}

		if ([PermissionType.LIST_EMPLOYEE, PermissionType.READ_EMPLOYEE].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_EMPLOYEE,
				PermissionType.UPDATE_EMPLOYEE,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_SERVICE, PermissionType.READ_SERVICE].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_SERVICE,
				PermissionType.UPDATE_SERVICE,
				PermissionType.CREATE_JOB,
				PermissionType.UPDATE_JOB,
			]).filter((item) => item !== permission);
		}

		if ([PermissionType.LIST_CUSTOM_COST, PermissionType.READ_CUSTOM_COST].includes(permission)) {
			return Permission.removeCreateAndUpdate(permissions, [
				PermissionType.CREATE_CUSTOM_COST,
				PermissionType.UPDATE_CUSTOM_COST,
				PermissionType.CREATE_JOB,
				PermissionType.UPDATE_JOB,
			]).filter((item) => item !== permission);
		}

		return permissions.filter((item) => item !== permission);
	};
}
