import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'lib/components';
import { SideNavItem } from 'lib/components/types';
import classes from './index.module.scss';

interface Props extends Omit<SideNavItem, 'roles' | 'permission'> {}

const MenuItem = ({ to, icon, title }: Props) => (
	<NavLink className={classes['menu-item']} activeClassName={classes['menu-item-active']} to={to}>
		<Image
			src={icon}
			style={{
				width: '2rem',
				height: '2rem',
			}}
		/>
		<p>{title}</p>
	</NavLink>
);

export default MenuItem;
