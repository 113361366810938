import { useCustomToast, useWindowResize } from 'hooks';
import { Button, Typography } from 'lib/components';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Select from 'react-select';
import { TextField } from '../../lib/components/FormFields';
import { SelectOption } from '../../lib/components/types';
import { RateType } from '../../lib/util';
import { HospitalService, Service } from '../../types';
import classes from './index.module.scss';

interface Props {
	service?: HospitalService;
	options: Service[];
	isOpen: boolean;
	onClose: () => any;
	onConfirm: (
		serviceId: number,
		baseCost: number,
		hoursIncluded: number,
		regularRate: number,
		overtimeRate: number,
		weekendRate: number,
		holidayRate: number,
		regularRateTypeId: RateType,
		overtimeRateTypeId: RateType,
		weekendRateTypeId: RateType,
		holidayRateTypeId: RateType
	) => void;
	style?: React.CSSProperties;
}

const NUMBER_SYMBOL = ['$', '%'];

const HospitalServicesModal = ({ isOpen, style, onClose, ...props }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const toast = useCustomToast();
	const [options, setOptions] = useState<Array<SelectOption<number>>>([]);
	const [baseCost, setBaseCost] = useState(0);
	const [hoursIncluded, setHoursIncluded] = useState(0);
	const [regularRate, setRegularRate] = useState(0);
	const [overtimeRate, setOvertimeRate] = useState(0);
	const [weekendRate, setWeekendRate] = useState(0);
	const [holidayRate, setHolidayRate] = useState(0);
	const [regularRateTypeId, setRegularRateTypeId] = useState<RateType>(0);
	const [overtimeRateTypeId, setOvertimeRateTypeId] = useState<RateType>(0);
	const [weekendRateTypeId, setWeekendRateTypeId] = useState<RateType>(0);
	const [holidayRateTypeId, setHolidayRateTypeId] = useState<RateType>(0);

	const [option, setOption] = useState<SelectOption<number> | null>(null);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
	};

	const handleSymbolChange = (field: 'regular' | 'overtime' | 'weekend' | 'holiday', symbol: RateType) => {
		if (field === 'regular') setRegularRateTypeId(symbol);
		else if (field === 'overtime') setOvertimeRateTypeId(symbol);
		else if (field === 'weekend') setWeekendRateTypeId(symbol);
		else setHolidayRateTypeId(symbol);
	};

	const handleConfirm = () => {
		if (!option) {
			toast('Please select service.');
		} else {
			props.onConfirm(
				option.value,
				baseCost,
				hoursIncluded,
				regularRate,
				overtimeRate,
				weekendRate,
				holidayRate,
				regularRateTypeId,
				overtimeRateTypeId,
				weekendRateTypeId,
				holidayRateTypeId
			);
		}
	};

	useEffect(() => {
		if (props.service) {
			console.log(props.service);
			const index = options.findIndex((element) => element.value === props.service!.service.id);
			if (index !== -1) {
				setOption(options[index]);
			}
			setBaseCost(props.service.baseCost);
			setHoursIncluded(props.service.hoursIncluded);
			setRegularRate(props.service.regularRate);
			setOvertimeRate(props.service.overtimeRate);
			setWeekendRate(props.service.weekendRate);
			setHolidayRate(props.service.holidayRate);
			setRegularRateTypeId(props.service.regularRateTypeId);
			setOvertimeRateTypeId(props.service.overtimeRateTypeId);
			setWeekendRateTypeId(props.service.weekendRateTypeId);
			setHolidayRateTypeId(props.service.holidayRateTypeId);
		}
	}, [props.service, options]);

	useEffect(() => {
		const list = props.options.map((element) => ({
			value: element.id,
			label: element.name,
		}));

		setOptions(list);
	}, [props.options]);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div className={classes['modal-body']}>
						<div className={classes['textfield-container']} style={style}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Service:
							</Typography>
							<Select
								className={classes['react-select-container']}
								classNamePrefix="react-select"
								isSearchable={true}
								options={options}
								value={option}
								onChange={handleChange}
							/>
						</div>
						<div className={classes['service-pricing']}>
							<div style={{ height: 16 }} />
							<div className={classes['service-pricing-fields']}>
								<TextField
									placeholder="0.00"
									label="Base Cost"
									type="number"
									numberSymbol={['$']}
									variant="labelled"
									value={baseCost}
									onChange={(e) => setBaseCost(e.target.valueAsNumber)}
								/>
								<TextField
									placeholder="0"
									label="Hours Included"
									type="number"
									variant="labelled"
									value={hoursIncluded}
									noSymbol
									onChange={(e) => setHoursIncluded(e.target.valueAsNumber)}
								/>
								<TextField
									placeholder="0.00"
									label="Business Day (per hour)"
									type="number"
									variant="labelled"
									numberSymbol={NUMBER_SYMBOL}
									min={0}
									value={regularRate}
									defaultSymbolIndex={regularRateTypeId}
									onChange={(e) => setRegularRate(e.target.valueAsNumber)}
									onSymbolChange={(value) => handleSymbolChange('regular', value)}
								/>
								<TextField
									placeholder="0.00"
									label="Overtime (per hour)"
									type="number"
									variant="labelled"
									numberSymbol={NUMBER_SYMBOL}
									min={0}
									value={overtimeRate}
									onChange={(e) => setOvertimeRate(e.target.valueAsNumber)}
									defaultSymbolIndex={overtimeRateTypeId}
									onSymbolChange={(value) => handleSymbolChange('overtime', value)}
								/>
								<TextField
									placeholder="0.00"
									label="Weekend Day (per hour)"
									type="number"
									variant="labelled"
									numberSymbol={NUMBER_SYMBOL}
									min={0}
									value={weekendRate}
									defaultSymbolIndex={weekendRateTypeId}
									onChange={(e) => setWeekendRate(e.target.valueAsNumber)}
									onSymbolChange={(value) => handleSymbolChange('weekend', value)}
								/>
								<TextField
									placeholder="0.00"
									label="Holiday Day (per hour)"
									type="number"
									variant="labelled"
									numberSymbol={NUMBER_SYMBOL}
									min={0}
									value={holidayRate}
									defaultSymbolIndex={holidayRateTypeId}
									onChange={(e) => setHolidayRate(e.target.valueAsNumber)}
									onSymbolChange={(value) => handleSymbolChange('holiday', value)}
								/>
							</div>
						</div>
					</div>
					<div className={classes['modal-footer']}>
						<Button
							variant="standard"
							color="primary"
							title={props.service ? 'Update' : 'Create'}
							style={{
								marginRight: '1rem',
							}}
							onClick={handleConfirm}
						/>
						<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default HospitalServicesModal;
