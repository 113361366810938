import { PermissionType } from 'lib/util';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
	BuddyPunchEmployee,
	BuddyPunchLocation,
	Conversation,
	CustomCost,
	Employee,
	Hospital,
	HospitalService,
	Inventory,
	Job,
	Nurse,
	Service,
	Setting,
	User,
	Vendor,
} from 'types';
import {
	AppDispatch,
	AuthenticationState,
	InvoiceState,
	RequestState,
	RootState,
	SliceState,
	TopNavState,
} from './types';

//? Typed dispatch and selector hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

//! Selector hooks
export function useSelectState() {
	return useTypedSelector<RootState>((state) => state);
}

export function useSelectTopNavState() {
	return useTypedSelector<TopNavState>(({ nav }) => nav);
}

export function useSelectBuddyPunchEmployeeState() {
	return useTypedSelector<SliceState<BuddyPunchEmployee>>(({ buddyPunchEmployee }) => buddyPunchEmployee);
}

export function useSelectBuddyPunchLocationState() {
	return useTypedSelector<SliceState<BuddyPunchLocation>>(({ buddyPunchLocation }) => buddyPunchLocation);
}

export function useSelectCurrentUser() {
	return useTypedSelector<User>(({ user }) => user);
}

export function useSelectUsers() {
	return useTypedSelector<SliceState<User>>(({ users }) => users);
}

export function useSelectUser() {
	const users = useSelectUsers();
	return function (id: number) {
		return users.list.find((user) => user.id === id);
	};
}

export function useSelectAdmins() {
	return useTypedSelector<SliceState<User>>(({ admins }) => admins);
}

export function useSelectAdmin() {
	const admins = useSelectAdmins();
	return function (id: number) {
		return admins.list.find((admin) => admin.id === id);
	};
}

export function useSelectNurses() {
	return useTypedSelector<SliceState<Nurse>>(({ nurses }) => nurses);
}

export function useSelectNurse() {
	const nurses = useSelectNurses();
	return function (id: number) {
		return nurses.list.find((nurse) => nurse.id === id);
	};
}

export function useSelectHospitals() {
	return useTypedSelector<SliceState<Hospital>>(({ hospitals }) => hospitals);
}

export function useSelectHospital() {
	const hospitals = useSelectHospitals();
	return function (id: number) {
		return hospitals.list.find((hospital) => hospital.id === id);
	};
}

export function useSelectEmployees() {
	return useTypedSelector<SliceState<Employee>>(({ employees }) => employees);
}

export function useSelectEmployee() {
	const employees = useSelectEmployees();
	return function (id: number) {
		return employees.list.find((employee) => employee.id === id);
	};
}

export function useSelectVendors() {
	return useTypedSelector<SliceState<Vendor>>(({ vendors }) => vendors);
}

export function useSelectVendor() {
	const vendors = useSelectVendors();
	return function (id: number) {
		return vendors.list.find((vendor) => vendor.id === id);
	};
}

export function useSelectVendorName() {
	const vendors = useSelectVendors();
	return function (vendorId: number) {
		const vendor = vendors.list.find(({ id }) => id === vendorId);
		return vendor?.name ?? '';
	};
}

export function useSelectRestrictedVendors() {
	return useTypedSelector<SliceState<number>>(({ restrictedVendors }) => restrictedVendors);
}

export function useSelectInventory() {
	return useTypedSelector<SliceState<Inventory>>(({ inventory }) => inventory);
}

export function useSelectHospitalServices() {
	return useTypedSelector<SliceState<HospitalService>>(({ hospitalServices }) => hospitalServices);
}

export function useSelectRestrictedInventory() {
	return useTypedSelector<SliceState<number>>(({ restrictedInventory }) => restrictedInventory);
}

export function useSelectInventoryItem() {
	const inventory = useSelectInventory();
	return function (id: number) {
		return inventory.list.find((item) => item.id === id);
	};
}

export function useSelectTotalVendorItems() {
	const inventory = useSelectInventory();
	return function (vendorId: number) {
		const items = inventory.list.filter((item) => item.vendorId === vendorId);
		return items.length;
	};
}

export function useSelectInvoices() {
	return useTypedSelector<InvoiceState>(({ invoices }) => invoices);
}

export function useSelectInvoice() {
	const invoices = useSelectInvoices();
	return function (id: number) {
		return invoices.list.find((item) => item.id === id);
	};
}

export function useSelectJobs() {
	return useTypedSelector<SliceState<Job>>(({ jobs }) => jobs);
}

export function useSelectJob() {
	const jobs = useSelectJobs();
	return function (id: number) {
		const job = jobs.list.find((job) => job.id === id);
		return job;
	};
}

export function useSelectAuthentication() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useSelectRequest() {
	return useTypedSelector<RequestState>(({ request }) => request);
}

export function useSelectAuthorization() {
	const { roleId: role, permissions } = useSelectCurrentUser();
	const isSuperAdmin = role === 1;
	const isPermitted = (permission: PermissionType) => {
		if (isSuperAdmin) return true;
		return permissions.includes(permission);
	};

	return {
		role,
		permissions,
		isSuperAdmin,
		isPermitted,
		canCreateHospital: isPermitted(PermissionType.CREATE_HOSPITAL),
		canReadHospital: isPermitted(PermissionType.READ_HOSPITAL),
		canUpdateHospital: isPermitted(PermissionType.UPDATE_HOSPITAL),
		canDeleteHospital: isPermitted(PermissionType.DELETE_HOSPITAL),
		canListHospital: isPermitted(PermissionType.LIST_HOSPITAL),
		canCreateNurse: isPermitted(PermissionType.CREATE_NURSE),
		canReadNurse: isPermitted(PermissionType.READ_NURSE),
		canUpdateNurse: isPermitted(PermissionType.UPDATE_NURSE),
		canDeleteNurse: isPermitted(PermissionType.DELETE_NURSE),
		canListNurse: isPermitted(PermissionType.LIST_NURSE),
		canCreateVendor: isPermitted(PermissionType.CREATE_VENDOR),
		canReadVendor: isPermitted(PermissionType.READ_VENDOR),
		canUpdateVendor: isPermitted(PermissionType.UPDATE_VENDOR),
		canDeleteVendor: isPermitted(PermissionType.DELETE_VENDOR),
		canListVendor: isPermitted(PermissionType.LIST_VENDOR),
		canCreateInventory: isPermitted(PermissionType.CREATE_INVENTORY),
		canReadInventory: isPermitted(PermissionType.READ_INVENTORY),
		canUpdateInventory: isPermitted(PermissionType.UPDATE_INVENTORY),
		canDeleteInventory: isPermitted(PermissionType.DELETE_INVENTORY),
		canListInventory: isPermitted(PermissionType.LIST_INVENTORY),
		canCreateJob: isPermitted(PermissionType.CREATE_JOB),
		canReadJob: isPermitted(PermissionType.READ_JOB),
		canUpdateJob: isPermitted(PermissionType.UPDATE_JOB),
		canDeleteJob: isPermitted(PermissionType.DELETE_JOB),
		canListJob: isPermitted(PermissionType.LIST_JOB),
		canVoidInvoice: isPermitted(PermissionType.VOID_INVOICE),
		canListInvoice: isPermitted(PermissionType.LIST_INVOICE),
		canReadInvoice: isPermitted(PermissionType.READ_INVOICE),
		canCreateEmployee: isPermitted(PermissionType.CREATE_EMPLOYEE),
		canReadEmployee: isPermitted(PermissionType.READ_EMPLOYEE),
		canUpdateEmployee: isPermitted(PermissionType.UPDATE_EMPLOYEE),
		canDeleteEmployee: isPermitted(PermissionType.DELETE_EMPLOYEE),
		canListEmployee: isPermitted(PermissionType.LIST_EMPLOYEE),
		canCreateService: isPermitted(PermissionType.CREATE_SERVICE),
		canReadService: isPermitted(PermissionType.READ_SERVICE),
		canUpdateService: isPermitted(PermissionType.UPDATE_SERVICE),
		canDeleteService: isPermitted(PermissionType.DELETE_SERVICE),
		canListService: isPermitted(PermissionType.LIST_SERVICE),
		canCreateCustomCost: isPermitted(PermissionType.CREATE_CUSTOM_COST),
		canReadCustomCost: isPermitted(PermissionType.READ_CUSTOM_COST),
		canUpdateCustomCost: isPermitted(PermissionType.UPDATE_CUSTOM_COST),
		canDeleteCustomCost: isPermitted(PermissionType.DELETE_CUSTOM_COST),
		canListCustomCost: isPermitted(PermissionType.LIST_CUSTOM_COST),
	};
}

export function useSelectConversations() {
	return useTypedSelector<SliceState<Conversation>>(({ conversations }) => conversations);
}

export function useSelectConversation() {
	const conversations = useSelectConversations();
	return function (id: number) {
		const conversation = conversations.list.find((conversation) => conversation.id === id);
		return conversation;
	};
}

export function useSelectMessages() {
	const getConversationById = useSelectConversation();
	return function (id: number) {
		const conversation = getConversationById(id);
		return conversation?.messages ?? [];
	};
}

export function useSelectServices() {
	return useTypedSelector<SliceState<Service>>(({ services }) => services);
}

export function useSelectService() {
	const services = useSelectServices();
	return function (id: number) {
		const service = services.list.find((service) => service.id === id);
		return service;
	};
}

export function useSelectCustomCosts() {
	return useTypedSelector<SliceState<CustomCost>>(({ customCosts }) => customCosts);
}

export function useSelectSettings() {
	return useTypedSelector<SliceState<Setting>>(({ setting }) => setting);
}

export function useSelectCustomCost() {
	const customCosts = useSelectCustomCosts();
	return function (id: number) {
		const customCost = customCosts.list.find((cost) => cost.id === id);
		return customCost;
	};
}
