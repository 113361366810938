import { useComprehensiveTopNav } from 'hooks';
import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import SuperAdminRoute from 'routes/super-admin';
import CostAmplifierTab from './CostAmplifierTab';
import classes from './index.module.scss';
import ServicesTab from './ServicesTab';
import Tab from './Tab';
import GeneralTab from './GeneralTab';

interface Props {}

const SettingsScreen = ({}: Props) => {
	const { url, path } = useRouteMatch();
	useComprehensiveTopNav('Settings', { notificationVisible: true });

	return (
		<div className={classes['settings']}>
			<div className={classes['settings-tabs']}>
				<Tab iconSrc="icTax" label="General" to={`${url}/general`} />
				<Tab iconSrc="icCostAmp" label="Custom Cost Amplifiers" to={`${url}/cost-amplifier`} />
				<Tab iconSrc="icServices" label="Services" to={`${url}/services`} />
			</div>
			<div className={classes['settings-tabBody']}>
				<Switch>
					<SuperAdminRoute exact path={`${path}/general`} component={GeneralTab} />
					<SuperAdminRoute exact path={`${path}/cost-amplifier`} component={CostAmplifierTab} />
					<SuperAdminRoute exact path={`${path}/services`} component={ServicesTab} />
					<Redirect to={`${path}/general`} />
				</Switch>
			</div>
		</div>
	);
};

export default SettingsScreen;
