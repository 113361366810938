import React, { CSSProperties } from 'react';
import classes from './index.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	checked: boolean;
	label?: string;
	labelStyle?: CSSProperties;
}

const Checkbox = ({ name, label, id, checked, className, labelStyle, onChange, ...props }: Props) => {
	return (
		<label style={labelStyle} className={classes['checkbox']} htmlFor={id}>
			<input
				name={name}
				id={id}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				className={className}
				{...props}
			/>
			{label}
		</label>
	);
};

export default Checkbox;
