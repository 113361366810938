import { SelectOption } from 'lib/components/types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { HospitalService } from 'types';
import classes from './index.module.scss';

interface Props {
	items: Array<HospitalService>;
	value?: number;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
	setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
	disabled?: boolean;
	selected: Array<number>;
}

const ServiceSelect = ({ value, setValue, setLabel, disabled, items, selected }: Props) => {
	const [options, setOptions] = useState<SelectOption<number>[]>([]);
	const [option, setOption] = useState<SelectOption<number> | null>(null);

	useEffect(() => {
		if (items.length === 0) return;

		const all: SelectOption<number>[] = items.map(({ id, service }) => ({
			value: id,
			label: service.name,
		}));

		setOptions(all);
	}, [items]);

	useEffect(() => {
		if (selected.length === 0) return;
		const filtered: SelectOption<number>[] = items
			.filter((item) => !selected.includes(item.id))
			.map(({ id, service }) => ({
				value: id,
				label: service.name,
			}));

		setOptions(filtered);
	}, [selected]);

	useEffect(() => {
		if (value) {
			const item = items.find((item) => item.id === value)!;
			const option: SelectOption<number> = { label: item.service.name, value: item.id };
			setOption(option!);
		}
	}, [value]);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
		setValue(option?.value);
		setLabel(option?.label);
	};

	return (
		<Select
			autoFocus
			isDisabled={disabled}
			placeholder="Select Service"
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			options={options}
			isClearable={true}
			isSearchable={true}
			value={option}
			onChange={handleChange}
		/>
	);
};

export default ServiceSelect;
