import { Image, Typography } from 'lib/components';
import { ImageSource } from 'lib/components/types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './index.module.scss';

interface Props {
	to: string;
	iconSrc: ImageSource;
	label: string;
}

const Tab = ({ to, iconSrc, label }: Props) => {
	return (
		<NavLink to={to} className={classes['settings-tabLink']} activeClassName={classes['settings-tabLinkActive']}>
			<div className={classes['settings-tab']}>
				<div className={classes['settings-tab-icon']}>
					<Image src={iconSrc} />
				</div>
				<Typography variant="h3" className={classes['settings-tab-label']}>
					{label}
				</Typography>
			</div>
		</NavLink>
	);
};

export default Tab;
