import { Color } from 'lib/components/types';
import { InvoicePaymentStatusType } from '.';

export default class InvoicePaymentStatus {
	static readonly UNPAID = 0;
	static readonly PAID = 1;
	static readonly REFUNDED = 2;

	private static readonly STATUS_TEXT = {
		[InvoicePaymentStatus.UNPAID]: 'Unpaid',
		[InvoicePaymentStatus.PAID]: 'Paid',
		[InvoicePaymentStatus.REFUNDED]: 'Refunded',
	};

	public static text = (type: InvoicePaymentStatusType) => InvoicePaymentStatus.STATUS_TEXT[type];

	public static color = (status: InvoicePaymentStatusType): Color => {
		if (status === InvoicePaymentStatusType.UNPAID) {
			return 'danger';
		}

		if (status === InvoicePaymentStatusType.REFUNDED) {
			return 'darkgrey';
		}

		return 'success';
	};
}
