import React, { useState, useEffect } from 'react';
import { Typography } from 'lib/components';
import Avatar from 'lib/components/Avatar';
import { Checkbox } from 'lib/components/FormFields';
import { Employee } from 'types';
import classes from './index.module.scss';
import adjustTime from '../../../adjustTime';
import { useSelectSettings } from '../../../../store';

interface Props {
	employee: Employee;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
	onClick: () => void;
}

const EmployeeRow = ({ employee, checkedId, checkAction, onClick }: Props) => {
	const settingsState = useSelectSettings();

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checkedId === employee.id) return;
		if (checkedId !== employee.id || checkedId === -1) setChecked(false);
	}, [checkedId]);

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(employee.id, e.target.checked);
	};

	return (
		<div key={employee.id} className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleAction}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div className={classes['col']} onClick={onClick}>
				<Avatar src={employee.avatar ?? 'plAvatar'} size={'32px'} />
			</div>
			<div className={classes['col']} onClick={onClick}>
				<Typography variant={'h6'}>{employee.name}</Typography>
			</div>
			<div className={classes['col']} onClick={onClick}>
				<Typography variant={'h6'}>{employee.email}</Typography>
			</div>
			<div className={classes['col']} onClick={onClick}>
				<Typography variant={'h6'}>{employee.phoneNumber ?? 'N/A'}</Typography>
			</div>
			<div className={classes['col']} onClick={onClick}>
				<Typography variant={'h6'}>
					{adjustTime(settingsState, employee.createdAt).format('MM/DD/YYYY hh:mm A')}
				</Typography>
			</div>
		</div>
	);
};

export default EmployeeRow;
