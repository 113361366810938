import { useOnOutClick } from 'hooks';
import React, { useRef, useState } from 'react';
import Typography from '../Typography';
import classes from './index.module.scss';

interface Props {
	items: string[] | number[];
	label: string;
	onItemClick: (item: any) => any;
}

const Dropdown = ({ items, onItemClick, label }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const closeDropdown = () => setIsOpen(false);
	const toggleDropdown = () => setIsOpen((prev) => !prev);

	useOnOutClick({ ref, onOutClick: closeDropdown });

	return (
		<>
			<div style={{ cursor: 'pointer' }} onClick={toggleDropdown}>
				<Typography variant="body1">{label}</Typography>
			</div>
			{isOpen && (
				<div ref={ref} className={classes['dpc-dateText-dropdown']}>
					<div className={classes['dpc-dateText-dropdownList']}>
						{items.map((item: any) => (
							<a
								onClick={() => {
									onItemClick(item);
									closeDropdown();
								}}
								className={classes['dpc-dateText-dropdownItem']}
								key={item}
							>
								{item}
							</a>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Dropdown;
