import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import { SelectOption } from 'lib/components/types';
import React, { useState } from 'react';
import Select from 'react-select';
import { useSelectBuddyPunchEmployeeState } from '../../../../store';
import { BuddyPunchEmployee } from '../../../../types';
import classes from './index.module.scss';

interface Props {
	value?: BuddyPunchEmployee;
	setValue: React.Dispatch<React.SetStateAction<BuddyPunchEmployee | undefined>>;
}

const BuddyPunchEmployeeSelect = ({ value, setValue }: Props) => {
	const buddyPunchState = useSelectBuddyPunchEmployeeState();

	const { isSmallerDevice } = useWindowResize();

	const options = React.useMemo<SelectOption<BuddyPunchEmployee>[]>(
		() =>
			buddyPunchState.list.map((element) => ({
				label: element.name,
				value: element,
			})),
		[buddyPunchState.list, buddyPunchState.updatedAt]
	);

	const [option, setOption] = useState<SelectOption<BuddyPunchEmployee> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<BuddyPunchEmployee> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	return (
		<>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				BuddyPunch ID:
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</>
	);
};

export default BuddyPunchEmployeeSelect;
