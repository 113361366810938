import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import { SelectOption } from 'lib/components/types';
import React, { useState } from 'react';
import Select from 'react-select';
import { useSelectBuddyPunchLocationState } from '../../../../store';
import { BuddyPunchLocation } from '../../../../types';
import classes from './index.module.scss';

interface Props {
	value?: BuddyPunchLocation;
	setValue: React.Dispatch<React.SetStateAction<BuddyPunchLocation | undefined>>;
}

const BuddyPunchLocationSelect = ({ value, setValue }: Props) => {
	const buddyPunchState = useSelectBuddyPunchLocationState();

	const { isSmallerDevice } = useWindowResize();

	const options = React.useMemo<SelectOption<BuddyPunchLocation>[]>(
		() =>
			buddyPunchState.list.map((element) => ({
				label: element.name,
				value: element,
			})),
		[buddyPunchState.list, buddyPunchState.updatedAt]
	);

	const [option, setOption] = useState<SelectOption<BuddyPunchLocation> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<BuddyPunchLocation> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	React.useEffect(() => {
		if (value !== undefined && (option === null || option === undefined)) {
			const findIndex = options.findIndex((option) => option.value.id === value.id);
			if (findIndex !== -1) {
				setOption(options[findIndex]);
			}
		}
	}, [value]);

	return (
		<>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				BuddyPunch Location ID:
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</>
	);
};

export default BuddyPunchLocationSelect;
