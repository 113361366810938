import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Typography, Pagination, LoadingIndicator } from 'lib/components';
import { useAppDispatch, actions, useSelectCustomCosts, useSelectRequest, RequestManager } from 'store';
import { useCustomToast, usePagination } from 'hooks';
import { CustomCost } from 'types';
import { routes } from 'lib/api';
import classes from './index.module.scss';
import { RateType } from '../../lib/util';

interface TableRowProps {
	cost: CustomCost;
	onDelete: (id: number) => void;
}

const TableHeader = () => (
	<div className={classes['costAmplifierTab-tableHeader']}>
		<div>
			<Typography color="darkgrey" variant="h6">
				Title
			</Typography>
		</div>
		<div>
			<Typography color="darkgrey" variant="h6">
				Business Day
			</Typography>
		</div>
		<div>
			<Typography color="darkgrey" variant="h6">
				Overtime
			</Typography>
		</div>
		<div>
			<Typography color="darkgrey" variant="h6">
				Weekend
			</Typography>
		</div>
		<div>
			<Typography color="darkgrey" variant="h6">
				Holiday
			</Typography>
		</div>
	</div>
);

const TableRow = ({ cost, onDelete }: TableRowProps) => {
	const { push } = useHistory();

	const navToEditCostAmp = useCallback(() => {
		push(`/settings/cost-amplifier/edit/${cost.id}`);
	}, [cost]);

	const getValue = useCallback((value: number, rateType: RateType) => {
		if (rateType === RateType.FIXED) {
			return `$${value.toFixed(2)}`;
		}

		return `${value.toFixed(0)}%`;
	}, []);

	return (
		<div className={classes['costAmplifierTab-tableRow']}>
			<div>
				<Typography variant="h6">{cost.name}</Typography>
			</div>
			<div>
				<Typography color="primary" variant="h6">
					{getValue(cost.regularRate, cost.regularRateTypeId)}
				</Typography>
			</div>
			<div>
				<Typography color="primary" variant="h6">
					{getValue(cost.overtimeRate, cost.overtimeRateTypeId)}
				</Typography>
			</div>
			<div>
				<Typography color="primary" variant="h6">
					{getValue(cost.weekendRate, cost.weekendRateTypeId)}
				</Typography>
			</div>
			<div>
				<Typography color="primary" variant="h6">
					{getValue(cost.holidayRate, cost.holidayRateTypeId)}
				</Typography>
			</div>
			<div className={classes['costAmplifierTab-tableRowActions']}>
				<Image src="icEdit" onClick={navToEditCostAmp} />
				<Image src="icTrashDanger" onClick={() => onDelete(cost.id)} />
			</div>
		</div>
	);
};

const CostAmplifierTab = () => {
	const toast = useCustomToast();
	const { push } = useHistory();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const costs = useSelectCustomCosts();

	const [toDelete, setToDelete] = useState(-1);

	useEffect(() => {
		dispatch(actions.fetchCustomCosts());
	}, []);

	const { isDataFetched, listing, paginatedData, paginationProps } = usePagination(costs, { itemsPerPage: 8 });

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (toDelete !== -1 && RM.req(routes.CUSTOM_COST_ID(toDelete), 'DELETE')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				setToDelete(-1);
			}
		}
	}, [active]);

	const navToAddCostAmp = useCallback(() => {
		push('/settings/cost-amplifier/add');
	}, []);

	const handleDelete = (id: number) => {
		setToDelete(id);
		dispatch(actions.deleteCustomCost(id));
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<div className={classes['costAmplifierTab']}>
				<div className={classes['costAmplifierTab-table']}>
					{listing.length > 0 && <TableHeader />}
					<div className={classes['costAmplifierTab-tableBody']}>
						{paginatedData.map((cost) => (
							<TableRow key={cost.id} cost={cost} onDelete={handleDelete} />
						))}
					</div>
					<div className={classes['costAmplifierTab-tableFooter']}>
						<a onClick={navToAddCostAmp} className={classes['costAmplifierTab-tableFooterButton']}>
							+ Add New Custom Cost Amplifier
						</a>
					</div>
				</div>
			</div>
			<Pagination {...paginationProps} />
		</>
	);
};

export default CostAmplifierTab;
