import React from 'react';
import { NavLink, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import Inventory from './Inventory';
import Vendors from './Vendors';
import Authorized from 'routes/authorized';
import { useSelectAuthorization } from 'store';
import { PermissionType as P } from 'lib/util';
import classes from './index.module.scss';

const InventoriesScreen = () => {
	const { url, path } = useRouteMatch();
	const { canListInventory, canListVendor } = useSelectAuthorization();

	return (
		<>
			<div className={classes['inventories']}>
				<div className={classes['tabs']}>
					{canListInventory && (
						<NavLink to={`${url}/home`} className={classes['tab']} activeClassName={classes['active-tab']}>
							Inventory
						</NavLink>
					)}
					{canListVendor && (
						<NavLink
							to={`${url}/vendors`}
							className={classes['tab']}
							activeClassName={classes['active-tab']}
						>
							Vendors
						</NavLink>
					)}
				</div>
				<div className={classes['tab-content']}>
					<Switch>
						<Authorized permission={P.LIST_INVENTORY} exact path={`${path}/home`} component={Inventory} />
						<Authorized permission={P.LIST_VENDOR} exact path={`${path}/vendors`} component={Vendors} />
						<Redirect from="/" to={`${url}/home`} />
					</Switch>
				</div>
			</div>
		</>
	);
};

export default InventoriesScreen;
