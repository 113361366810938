import { Hospital } from 'types';
import { Sort } from './types';

export const hospitalNameCompareFn = (a: Hospital, b: Hospital, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const hospitalEmailCompareFn = (a: Hospital, b: Hospital, sort: Sort) => {
	if (a.email && b.email) {
		if (sort === 'asc') {
			return a.email.localeCompare(b.email) === -1 ? 1 : -1;
		} else {
			return a.email.localeCompare(b.email) === -1 ? -1 : 1;
		}
	}
	return 0;
};
