import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { Timing } from 'lib/util';
import { useSelectAuthentication } from 'store';

const Authenticated = ({ component, ...rest }: RouteProps) => {
	const authentication = useSelectAuthentication();
	const { pathname } = useLocation();

	return authentication.isAuthenticated && !Timing.isExpired(authentication.expiryAt) ? (
		<Route {...rest} component={component} />
	) : (
		<Redirect to={`/login?redirectTo=${pathname}`} />
	);
};

export default Authenticated;
