import { RequestInfo, LoadingState } from '.';
import { redirectToAuthPortal } from 'lib/redirect';
import { Method } from 'types';

class RequestManager {
	private readonly active: RequestInfo;

	constructor(active: RequestInfo) {
		this.active = active;

		if (this.is401()) {
			this.signout();
		}
	}

	private name = (name: string) => this.active.name === name;
	private method = (method: Method) => this.active.method === method;
	private status = (status: LoadingState) => this.active.status === status;

	private is401 = () => this.active.message === 'Unauthorized';
	private signout = () => {
		redirectToAuthPortal();
	};

	public idle = () => this.status('idle');
	public pending = () => this.status('pending');
	public failed = () => this.status('failed');
	public succeeded = () => this.status('succeeded');

	public req = (name: string, method: Method) => this.name(name) && this.method(method);
}

export default RequestManager;
