import { useComprehensiveTopNav, useCustomToast } from 'hooks';
import { routes } from 'lib/api';
import { Button, LoadingIndicator } from 'lib/components';
import { TextField } from 'lib/components/FormFields';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { actions, RequestManager, useAppDispatch, useSelectRequest, useSelectService, useSelectServices } from 'store';
import { Service } from 'types';
import classes from './index.module.scss';

interface Props {
	service: Service;
}

const EditServiceScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const services = useSelectServices();
	const getServiceById = useSelectService();

	const [service, setService] = useState<Service>();

	useEffect(() => {
		dispatch(actions.fetchService(parseInt(id)));
	}, []);

	useEffect(() => {
		const service = getServiceById(parseInt(id));

		if (service) {
			setService(service);
		}
	}, [services.updatedAt]);

	return service ? <EditServiceScreenView service={service} /> : <LoadingIndicator />;
};

const EditServiceScreenView = ({ service }: Props) => {
	const toast = useCustomToast();
	const { push, goBack } = useHistory();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState(service.name);
	const [description, setDescription] = useState(service.description);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.SERVICE_ID(service.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/settings/services');
			}
		}
	}, [active]);

	const handleUpdate = () => {
		dispatch(
			actions.updateService(service.id, {
				name,
				description,
			})
		);
	};

	useComprehensiveTopNav('Edit Service', { notificationVisible: true });

	return (
		<div className={classes['service']}>
			<div className={classes['service-form']}>
				<div className={classes['service-info']}>
					<TextField
						placeholder="Does the patient have COVID-19?"
						label="Title"
						variant="labelled"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						label="Description"
						variant="labelled"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
			</div>
			<div className={classes['service-footerButtons']}>
				<Button title="Update" variant="standard" color="primary" onClick={handleUpdate} />
				<Button variant="standard" color="gray" title="Cancel" onClick={goBack} />
			</div>
		</div>
	);
};

export default EditServiceScreen;
