import { Inventory } from 'types';
import { Sort } from './types';

export const inventoryNameCompareFn = (a: Inventory, b: Inventory, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const inventoryVendorNameCompareFn = (a: string, b: string, sort: Sort) => {
	if (sort === 'asc') {
		return a.localeCompare(b) === -1 ? 1 : -1;
	} else {
		return a.localeCompare(b) === -1 ? -1 : 1;
	}
	// return 0;
};

export const inventoryStockCompareFn = (a: Inventory, b: Inventory, sort: Sort) => {
	if (a.stock > b.stock) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.stock < b.stock) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
