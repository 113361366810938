import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOption } from 'lib/components/types';
import { InventoryType } from 'lib/util';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import classes from './index.module.scss';

interface Props {
	value?: InventoryType;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
	style?: React.CSSProperties;
}

const options: SelectOption<InventoryType>[] = [
	{ label: 'Unit', value: 0 },
	{ label: 'Package', value: 1 },
	{ label: 'Liquid', value: 2 },
];

const InventoryTypeSelect = ({ value, setValue, style }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [option, setOption] = useState<SelectOption<InventoryType> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<InventoryType> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	return (
		<div className={classes['textfield-container']} style={style}>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				Inventory Type:
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</div>
	);
};

export default InventoryTypeSelect;
