import { useState, useEffect } from 'react';
import { Address } from 'types';

export default function useAddressInput(address?: Address) {
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [postalCode, setPostalCode] = useState('');

	useEffect(() => {
		if (address) {
			setAddressLine1(address.addressLine1);
			setAddressLine2(address.addressLine2 ?? '');
			setCity(address.city);
			setState(address.state);
			setPostalCode(address.postalCode);
		} else {
			setAddressLine1('');
			setAddressLine2('');
			setCity('');
			setState('');
			setPostalCode('');
		}
	}, [address]);

	return {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	};
}
