import { useState, useEffect } from 'react';
import { SliceState } from 'store';
import { Invoice, Hospital } from 'types';

const ITEMS_PER_PAGE = 10;
const INITIAL_PAGE = 1;

export default function useInvoicePagination(invoices: SliceState<Invoice>, hospitals: SliceState<Hospital>) {
	const [isDataFetched, setIsDataFetched] = useState(false);
	const [fetchedCount, setFetchedCount] = useState(0);
	const [fetchedInvoices, setFetchedInvoices] = useState<Invoice[]>([]);
	const [, setFetchedHospitals] = useState<Hospital[]>([]);
	const [data, setData] = useState<Invoice[]>([]);
	const [showPagination, setShowPagination] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [pages, setPages] = useState<number[]>([]);
	const [paginatedData, setPaginatedData] = useState<Invoice[]>([]);

	useEffect(() => {
		if (invoices.list.length === 0 || invoices.list.length === data.length) {
			return;
		} else {
			setFetchedInvoices(invoices.list);
			setFetchedCount((prev) => prev + 1);
		}
	}, [invoices.updatedAt]);

	useEffect(() => {
		setFetchedHospitals(hospitals.list);
		setFetchedCount((prev) => prev + 1);
	}, [hospitals.updatedAt]);

	useEffect(() => {
		if (fetchedCount < 2) return;
		setIsDataFetched(true);
	}, [fetchedCount]);

	useEffect(() => {
		setData(fetchedInvoices);
	}, [fetchedInvoices]);

	useEffect(() => {
		if (data.length === 0) {
			setTotalPages(0);
			return;
		}

		const TOTAL_PAGES = Math.ceil(data.length / ITEMS_PER_PAGE);
		setTotalPages(TOTAL_PAGES);
	}, [data]);

	useEffect(() => {
		if (totalPages === 0) {
			setCurrentPage(1);
			setShowPagination(false);
			return;
		}

		const CURRENT = INITIAL_PAGE;
		setCurrentPage(CURRENT);
		setShowPagination(data.length > ITEMS_PER_PAGE);
	}, [totalPages, data]);

	useEffect(() => {
		if (currentPage === 0) return;

		const PAGES_SHOWN = totalPages <= 5 ? totalPages : 5;
		let START_PAGE: number, END_PAGE: number;

		if (currentPage <= Math.floor(PAGES_SHOWN / 2) + 1) {
			START_PAGE = 1;
			END_PAGE = PAGES_SHOWN;
		} else if (currentPage + Math.floor(PAGES_SHOWN / 2) - 1 >= totalPages) {
			START_PAGE = totalPages - (PAGES_SHOWN - 1);
			END_PAGE = totalPages;
		} else {
			START_PAGE = currentPage - Math.floor(PAGES_SHOWN / 2);
			END_PAGE = currentPage + (Math.floor(PAGES_SHOWN / 2) - 1);
		}

		const START_INDEX = (currentPage - 1) * ITEMS_PER_PAGE;
		const END_INDEX = Math.min(START_INDEX + (ITEMS_PER_PAGE - 1), data.length - 1);

		const PAGES = new Array(END_PAGE + 1 - START_PAGE).fill('').map((_, index) => START_PAGE + index);

		const PAGINATED_DATA = data.slice(START_INDEX, END_INDEX + 1);

		setPages(PAGES);
		setPaginatedData(PAGINATED_DATA);
	}, [currentPage, data, totalPages]);

	const setPage = (page: number) => {
		if (page < 1 || page > totalPages) {
			return;
		}
		setCurrentPage(page);
	};

	const incrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === totalPages) {
				return prev;
			}
			return prev + 1;
		});

	const decrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === 1) {
				return prev;
			}
			return prev - 1;
		});

	return {
		isDataFetched,
		invoiceListing: invoices.list,
		hospitalListing: hospitals.list,
		setData,
		paginatedData,
		setPaginatedData,
		paginationProps: {
			showPagination,
			pages,
			currentPage,
			totalPages,
			setCurrentPage,
			setPage,
			incrementPage,
			decrementPage,
		},
	};
}
