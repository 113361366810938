import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, SearchBar, Typography, LoadingIndicator, Pagination } from 'lib/components';
import { HospitalsDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectHospitals,
	useSelectAuthorization,
	useSelectRequest,
	RequestManager,
} from 'store';
import { useComprehensiveTopNav, useWindowResize, useSearch, usePagination } from 'hooks';
import classes from './index.module.scss';

const HospitalsScreen = () => {
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const hospitals = useSelectHospitals();
	const { active } = useSelectRequest();
	const { canCreateHospital, canDeleteHospital } = useSelectAuthorization();

	const [query, setQuery] = useState('');
	const [checked, setChecked] = useState<number[]>([]);

	useEffect(() => {
		dispatch(actions.fetchHospitals());
		dispatch(actions.getBuddyPunchLocations());
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;
	}, [active]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(hospitals, {});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(hospital) =>
					hospital.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					(hospital.email && hospital.email.toLowerCase().search(query.toLowerCase()) !== -1) ||
					(hospital.phoneNumber && hospital.phoneNumber.toLowerCase().search(query.toLowerCase()) !== -1)
			);
			setData(result);
		}
	);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteHospital(checked[0]));
	};

	useComprehensiveTopNav('Hospitals');

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div className={classes['hospitals']}>
			<div className={classes['hospitals-top']}>
				<SearchBar
					query={query}
					setQuery={setQuery}
					onSearch={() => console.log({ query })}
					className={classes['search-input-container']}
					placeholder="Search..."
				/>
				{canCreateHospital && (
					<Link to="/hospitals/new" className={classes['hospitals-top-addNewHospital']}>
						<span className={classes['hospitals-top-addNewHospital-title']}>
							<Typography variant={isSmallDevice ? 'h5' : 'h6'} weight="medium">
								Add New Hospital
							</Typography>
						</span>
						<div className={classes['hospitals-top-addNewHospital-icon']}>+</div>
					</Link>
				)}
			</div>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					{checked.length !== 0 && (
						<>
							{canDeleteHospital && (
								<button
									className={classes['action-button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							)}
						</>
					)}
				</div>
			</div>
			<HospitalsDataTable
				hospitals={paginatedData}
				checkedId={checked[0] ?? -1}
				checkAction={handleCheckAction}
			/>
			<Pagination {...paginationProps} />
		</div>
	);
};

export default HospitalsScreen;
