import { useComprehensiveTopNav, useCustomToast, useInvoicePagination, useSearch, useWindowResize } from 'hooks';
import routes from 'lib/api/routes';
import { Button, DropdownButton, LoadingIndicator, Pagination, SearchBar, Typography } from 'lib/components';
import CustomRangedDatePickerModal from 'lib/components/CustomRangedDatePickerModal';
import { InvoicesDataTable } from 'lib/components/DataTables';
import { DropdownItem } from 'lib/components/types';
import { InvoicePaymentStatus as IPS, InvoicePaymentStatusType as ST, InvoiceStatusType, Timing } from 'lib/util';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectHospital,
	useSelectHospitals,
	useSelectInvoices,
	useSelectRequest,
} from 'store';
import { Invoice } from 'types';
import AccrueInvoiceModal from './AccrueInvoiceModal';
import classes from './index.module.scss';

const STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: 'Created', value: InvoiceStatusType.CREATED },
	{ label: 'Voided', value: InvoiceStatusType.VOIDED },
];

const PAYMENT_STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: IPS.text(ST.PAID), value: ST.PAID },
	{ label: IPS.text(ST.UNPAID), value: ST.UNPAID },
	{ label: IPS.text(ST.REFUNDED), value: ST.REFUNDED },
];

const InvoicesScreen = () => {
	const toast = useCustomToast();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const invoices = useSelectInvoices();
	const hospitals = useSelectHospitals();
	const getHospitalById = useSelectHospital();

	const [showAccrueInvoiceModal, setShowAccrueInvoiceModal] = useState(false);
	const [showDateModal, setShowDateModal] = useState(false);
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [query, setQuery] = useState('');
	const [hospitalDropdownItems, setHospitalDropdownItems] = useState<DropdownItem<number>[]>([]);
	const [selectedHospitalDropdownItem, setSelectedHospitalDropdownItem] = useState<number>(-1);
	const [selectedStatusDropdownItem, setSelectedStatusDropdownItem] = useState<number>(-1);
	const [selectedPaymentStatusDropdownItem, setSelectedPaymentStatusDropdownItem] = useState<number>(-1);
	const [selectedYear, setSelectedYear] = useState('');

	useEffect(() => {
		dispatch(actions.fetchInvoices());
		dispatch(actions.fetchHospitals());
	}, []);

	const { isDataFetched, invoiceListing, hospitalListing, setData, paginatedData, paginationProps } =
		useInvoicePagination(invoices, hospitals);

	useSearch(
		query,
		() => {
			setData(invoiceListing);
		},
		() => {
			const result = invoiceListing.filter(
				(invoice: Invoice) =>
					getHospitalById(invoice.hospitalId)?.name?.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		if (selectedHospitalDropdownItem === -1 || invoiceListing.length === 0) return;

		const filtered = invoiceListing.filter((invoice) => invoice.hospitalId === selectedHospitalDropdownItem);
		setData(filtered);
	}, [selectedHospitalDropdownItem]);

	useEffect(() => {
		if (selectedStatusDropdownItem === -1) return;

		const result = invoiceListing.filter((invoice) => invoice.status === selectedStatusDropdownItem);
		setData(result);
	}, [selectedStatusDropdownItem]);

	useEffect(() => {
		if (selectedPaymentStatusDropdownItem === -1) return;

		const result = invoiceListing.filter((invoice) => invoice.status === selectedPaymentStatusDropdownItem);
		setData(result);
	}, [selectedPaymentStatusDropdownItem]);

	useEffect(() => {
		if (selectedYear === '') return;

		const result = invoiceListing.filter((invoice) => String(moment(invoice.createdAt).year()) === selectedYear);
		setData(result);
	}, [selectedYear]);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.INVOICE_GENERATE_ACCRUE, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		if (isDataFetched) {
			setHospitalDropdownItems(() => hospitalListing.map(({ id, name }) => ({ label: name, value: id })));
		}
	}, [isDataFetched]);

	const handleAccrueInvoice = (hospitalId: number) => {
		dispatch(
			actions.generateAccruedPDFs({
				hospitalId,
				rangeFrom: Timing.formatDate(startDate),
				rangeTo: Timing.formatDate(endDate),
			})
		);
	};

	const handleHospitalDropdownItemSelect = (value: number) => {
		setSelectedHospitalDropdownItem(value);
	};

	const handleStatusDropdownItemSelect = (value: number) => {
		setSelectedStatusDropdownItem(value);
	};

	const handlePaymentStatusDropdownItemSelect = (value: number) => {
		setSelectedPaymentStatusDropdownItem(value);
	};

	const handleYearDropdownItemSelect = React.useCallback(
		(value: string) => {
			setSelectedYear(value);
		},
		[setSelectedYear]
	);

	useComprehensiveTopNav('Invoices');

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<AccrueInvoiceModal
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				isOpen={showAccrueInvoiceModal}
				onClose={() => setShowAccrueInvoiceModal(false)}
				onAccrueInvoice={handleAccrueInvoice}
			/>
			<CustomRangedDatePickerModal
				onDatesSet={(start, end) => {
					setStartDate(start);
					setEndDate(end);
					setShowAccrueInvoiceModal(true);
				}}
				isOpen={showDateModal}
				onClose={() => setShowDateModal(false)}
			/>
			<div
				className={classes['invoices-container']}
				style={{
					padding: isSmallDevice ? '0 1rem' : undefined,
				}}
			>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<SearchBar
						query={query}
						setQuery={setQuery}
						onSearch={() => console.log({ query })}
						className={classes['invoices-searchbar']}
						placeholder="Search..."
					/>
					<div
						className={classes['row']}
						style={{
							marginTop: '1.3rem',
						}}
					>
						<Button title="Accrue Invoices" variant="standard" onClick={() => setShowDateModal(true)} />
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						marginTop: '0.1rem',
						marginBottom: '1rem',
					}}
				>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						style={{
							margin: '1rem 1rem 0 0',
						}}
						weight="medium"
					>
						Filter by:
					</Typography>
					<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
						<DropdownButton
							title="Hospital"
							list={hospitalDropdownItems}
							onItemSelect={handleHospitalDropdownItemSelect}
						/>
					</div>
					<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
						<DropdownButton
							title="Status"
							list={STATUS_DROPDOWN_ITEMS}
							onItemSelect={handleStatusDropdownItemSelect}
						/>
					</div>
					<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
						<DropdownButton
							title="Payment Status"
							list={PAYMENT_STATUS_DROPDOWN_ITEMS}
							onItemSelect={handlePaymentStatusDropdownItemSelect}
						/>
					</div>
					<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
						<DropdownButton
							title="Year"
							list={Array.from(new Set(invoiceListing.map((item) => moment(item.createdAt).year()))).map(
								(item) => ({ label: String(item), value: String(item) })
							)}
							onItemSelect={handleYearDropdownItemSelect}
						/>
					</div>
				</div>
				<InvoicesDataTable invoices={paginatedData} />
				<Pagination {...paginationProps} />
			</div>
		</>
	);
};

export default InvoicesScreen;
