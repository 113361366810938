import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Image } from 'lib/components';
import { Job } from 'types';
import Avatar from 'lib/components/Avatar';
import { JobStatus } from 'lib/util';
import classes from '../index.module.scss';
import { useSelectAuthorization } from 'store';

interface Props {
	jobs: Job[];
}

const HEADER_ITEMS = ['Status', 'Start Time', 'Nurses', 'Edit'];

const JobRequestTable = ({ jobs }: Props) => {
	const { push } = useHistory();
	const { canReadJob, canReadNurse, canUpdateJob } = useSelectAuthorization();

	const isSmallerDevice = window.innerWidth <= 500;

	const toViewNurse = useCallback((job: Job) => {
		if (canReadNurse) push(`/nurses/view/${job.nurse?.id}`);
	}, []);

	const toViewJob = useCallback((job: Job, openChat: boolean = false) => {
		if (canReadJob) push(`/jobs/view/${job.id}${openChat ? '?chat=open' : ''}`);
	}, []);

	const toEditJob = useCallback((job: Job) => {
		if (canUpdateJob) push(`/jobs/edit/${job.id}`);
	}, []);

	return (
		<div className={classes['dashboard-dataTable']}>
			<div className={classes['dashboard-dataTable-header']}>
				<div className={classes['dashboard-dataTable-grid-dispatchRequest']}>
					{HEADER_ITEMS.map((item) => (
						<div key={item} className={classes['dashboard-dataTable-grid-headerContent']}>
							<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{item}
							</Typography>
							<Image src={'icTableDown'} />
						</div>
					))}
				</div>
			</div>
			<div className={classes['dashboard-dataTable-rows']}>
				{jobs.map((job) => (
					<div
						key={job.id}
						onClick={() => toViewJob(job)}
						className={classes['dashboard-dataTable-grid-dispatchRequest']}
					>
						<div>
							<Typography weight="light" color="danger" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{JobStatus.progressText(job.status)}
							</Typography>
						</div>
						<div>
							<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{job.startingAt}
							</Typography>
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								toViewNurse(job);
							}}
						>
							<Avatar size="1.6rem" src={job.nurse?.user?.avatar ?? 'plAvatar'} />
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								toEditJob(job);
							}}
							style={{ cursor: 'pointer', opacity: canUpdateJob ? '100%' : '30%' }}
						>
							<Image src={'icMore'} />
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default JobRequestTable;
