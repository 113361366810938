import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import CustomCostSelect from './CustomCostSelect';
import { CustomCost, JobCustomCost } from 'types';
import classes from '../index.module.scss';

interface Props {
	items: Array<CustomCost>;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
	addCustomCost: (customCost: JobCustomCost) => void;
	selected: Array<number>;
}

const CustomCostInputModal = ({ items, selected, isOpen, setIsOpen, addCustomCost, onClose }: Props) => {
	const [customCostId, setCustomCostId] = useState<number | undefined>();
	const [name, setName] = useState<string | undefined>();

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['add-service-modal']}>
			<div className={classes['add-service-modal-content']}>
				<div className={classes['add-service-modal-content--main']}>
					<CustomCostSelect
						items={items}
						selected={selected}
						value={customCostId}
						setValue={setCustomCostId}
						setLabel={setName}
					/>
				</div>
				<div className={classes['add-service-modal-content--footer']}>
					<Button
						variant="standard"
						title={'Add Cost'}
						onClick={() => {
							if (name && customCostId) {
								addCustomCost({ customCostId, name });
								setCustomCostId(undefined);
								setName(undefined);
								setIsOpen(false);
							}
						}}
						disabled={!(name && customCostId)}
					/>
					<Button color="gray" variant="standard" title="Cancel" onClick={onClose} />
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default CustomCostInputModal;
