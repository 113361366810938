import { ImageSource } from 'lib/components/types';
import {
	ConversationStatusType,
	EmployeeType,
	InventoryType,
	InventoryUnitType,
	InvoicePaymentStatusType,
	InvoiceStatusType,
	JobLogType,
	JobStatusType,
	JobTreatmentType,
	MessageStatusType,
	PermissionType,
	RateType,
	Roles,
} from 'lib/util';
import JobType from '../lib/util/JobType';

export interface Address {
	location: Array<number>;
	addressLine1: string;
	addressLine2?: string;
	city: string;
	state: string;
	postalCode: string;
}

export interface User {
	id: number;
	name: string;
	email: string;
	dateOfBirth: string;
	avatar: ImageSource | null;
	phoneNumber?: string;
	location: Array<number>;
	roleId: Roles;
	permissions: Array<PermissionType>;
	createdAt: string;
	updatedAt: string;
}

export interface AvatarResponse {
	path: ImageSource;
}

export interface SimpleUser extends Pick<User, 'id' | 'name' | 'email' | 'phoneNumber' | 'avatar'> {}

export interface NurseSkill {
	id: number;
	name: string;
}

export interface NurseHours {
	id: number;
	monFrom: string | null;
	monTo: string | null;
	tueFrom: string | null;
	tueTo: string | null;
	wedFrom: string | null;
	wedTo: string | null;
	thuFrom: string | null;
	thuTo: string | null;
	friFrom: string | null;
	friTo: string | null;
	satFrom: string | null;
	satTo: string | null;
	sunFrom: string | null;
	sunTo: string | null;
}

export interface NurseBreak {
	id: number;
	startedAt: string;
	finishedAt: string;
}

export interface Nurse {
	id: number;
	employeeType: EmployeeType;
	yearsOfExperience: number;
	user: User;
	address: Address;
	notes?: string;
	buddyPunchId: string | null;
	buddyPunchGPSPermissions: number | null;
	skills: Array<NurseSkill>;
	breaks: Array<NurseBreak>;
	hours: NurseHours | null;
	createdAt: string;
	updatedAt: string;
}

export interface AuthData {
	accessToken: string;
	expiryAt: number;
	user: User;
}

export interface Vendor {
	id: number;
	name: string;
	email: string;
	address?: Address;
	phoneNumber: string;
	createdAt: string;
	updatedAt: string;
}

export interface Inventory {
	id: number;
	vendorId: number;
	typeId: InventoryType;
	unitId: InventoryUnitType;
	name: string;
	sku: string;
	stock: number;
	cost: number;
	packageQuantity: number;
	liquidQuantity?: number;
	createdAt: string;
	updatedAt: string;
}

export interface Hospital {
	id: number;
	name: string;
	timezone: string;
	address: Address;
	phoneNumber?: string;
	employeesAmount?: number;
	email?: string;
	payoutId?: string;
	buddyPunchId?: string;
	regularRate: number;
	overtimeRate: number;
	weekendRate: number;
	holidayRate: number;
	overtimeRateTypeId: RateType;
	weekendRateTypeId: RateType;
	holidayRateTypeId: RateType;
	workingHours: HospitalWorkingHours;
	cancellationPolicy: HospitalCancellationPolicy;
	createdAt: string;
	updatedAt: string;
}

export interface HospitalWorkingHours {
	monFrom: string | null;
	monTo: string | null;
	tueFrom: string | null;
	tueTo: string | null;
	wedFrom: string | null;
	wedTo: string | null;
	thuFrom: string | null;
	thuTo: string | null;
	friFrom: string | null;
	friTo: string | null;
	satFrom: string | null;
	satTo: string | null;
	sunFrom: string | null;
	sunTo: string | null;
}

export interface HospitalCancellationPolicy {
	beforeDispatch: number;
	afterDispatch: number;
	beforeTreatment: number;
	duringTreatment: number;
}

export interface Employee {
	id: number;
	name: string;
	email: string;
	phoneNumber: string;
	avatar?: ImageSource;
	createdAt: string;
	updatedAt: string;
}

export interface InvoiceItem {
	id: number;
	invoiceId: string;
	name: string;
	typeId: InvoiceItemType;
	vendorName: string | null;
	amount: number;
	price: number;
	total: number;
	isVoided: boolean;
	reason?: string;
}

export class InvoiceItemType {
	static readonly BASIC = 0;
	static readonly INVENTORY = 1;
	static readonly SERVICE = 2;
	static readonly CUSTOM_COST = 3;
}

export interface InvoiceLog {
	id: number;
	user?: SimpleUser;
	message: string;
	createdAt: string;
}

export interface InvoicePDF {
	url: string;
}

export interface Invoice {
	id: number;
	jobId: number | null;
	hospitalId: number;
	hospital?: Hospital;
	status: InvoiceStatusType;
	paymentStatus: InvoicePaymentStatusType;
	taxPercentage: number;
	subtotal: number;
	tax: number;
	total: number;
	reason?: string;
	path?: string;
	rangeFrom?: string;
	rangeTo?: string;
	items: Array<InvoiceItem>;
	logs: Array<InvoiceLog>;
	createdAt: string;
}

export interface JobFile {
	id: number;
	name: string;
	path: string;
	mimeType: string;
}

export interface JobRequirement {
	id: number;
	name: string;
}

export interface JobInventory {
	inventoryId: number;
	amount: number;
	name: string;
}

export interface JobPath {
	id: number;
	status: JobStatusType;
	longitude: number;
	latitude: number;
	createdAt: string;
}

export interface JobLog {
	id: number;
	typeId: JobLogType;
	user: SimpleUser;
	message: string;
	location?: Array<number>;
	createdAt: string;
}

export interface JobNote {
	id: number;
	user: SimpleUser;
	message: string;
	location?: Array<number>;
	createdAt: string;
}

export interface JobAttribute {
	id: string;
	jobId: string;
	status: JobStatusType;
	name: string;
	value: string;
}

export interface JobWait {
	id: string;
	jobId: string;
	startedAt: string;
	finishedAt: string | null;
	actualTime: number | null;
	roundedTime: number | null;
	attributes: Array<JobWaitAttribute>;
}

export interface JobWaitAttribute {
	id: string;
	jobId: string;
	status: JobStatusType;
	name: string;
	value: string;
}

export interface JobService {
	serviceId: number;
	name: string;
	description: string;
}

export interface JobCustomCost {
	customCostId: number;
	name: string;
}

export interface Setting {
	id: number;
	name: string;
	value: string;
}

export interface Job {
	id: number;
	typeId: JobType;
	hospital: Hospital;
	creator: SimpleUser;
	invoice?: Invoice;
	nurse?: Nurse;
	contact?: SimpleUser;
	description: string;
	status: JobStatusType;
	treatmentType: JobTreatmentType;
	requirements: Array<JobRequirement>;
	inventory: Array<JobInventory>;
	services: Array<JobService>;
	customCosts: Array<JobCustomCost>;
	files: Array<JobFile>;
	path: Array<JobPath>;
	logs: Array<JobLog>;
	notes: Array<JobNote>;
	attributes: Array<JobAttribute>;
	waits: Array<JobWait>;
	startingAt: string;
	nurseStartedAt?: string;
	nurseFinishedAt?: string;
	patientId?: string;
	patientName?: string;
	roomNumber?: string;
	orderingPhysician?: string;
	isWaiting: boolean;
	waitingTime: number;
	totalTreatmentTime: number;
	totalTreatmentTimeRounded: number;
	totalWaitingTime: number;
	totalWaitingTimeRounded: number;
	createdAt: string;
	updatedAt: string;
}

export interface Message {
	id: string | number;
	conversationId: number;
	userId: number;
	status: MessageStatusType;
	message: string;
	createdAt: string;
}

export interface ConversationUser {
	id: number;
	name: string;
	email: string;
	phoneNumber: string | null;
	avatar?: ImageSource;
}

export interface Conversation {
	id: number;
	jobId: number;
	manager?: ConversationUser;
	nurse: ConversationUser | null;
	status: ConversationStatusType;
	lastMessageBy?: string;
	lastMessage?: string;
	managerReadPointerId?: string;
	nurseReadPointerId?: string;
	messages: Array<Message>;
}

export interface Service {
	id: number;
	name: string;
	description: string;
	createdAt: string;
	updatedAt: string;
}

export interface HospitalService {
	id: number;
	service: Service;
	baseCost: number;
	hoursIncluded: number;
	regularRate: number;
	overtimeRate: number;
	weekendRate: number;
	holidayRate: number;
	regularRateTypeId: RateType;
	overtimeRateTypeId: RateType;
	weekendRateTypeId: RateType;
	holidayRateTypeId: RateType;
	createdAt: string;
	updatedAt: string;
}

export interface CustomCost {
	id: number;
	name: string;
	affectCost: boolean;
	regularRate: number;
	overtimeRate: number;
	weekendRate: number;
	holidayRate: number;
	regularRateTypeId: RateType;
	overtimeRateTypeId: RateType;
	weekendRateTypeId: RateType;
	holidayRateTypeId: RateType;
	createdAt: string;
	updatedAt: string;
}

export interface BuddyPunchEmployee {
	id: number;
	name: string;
	gpsPermissions: number | null;
}

export interface BuddyPunchLocation {
	id: number;
	name: string;
}
