import { useComprehensiveTopNav, useWindowResize } from 'hooks';
import { routes } from 'lib/api';
import { Button, Image, LoadingIndicator, Typography } from 'lib/components';
import { JobStatus } from 'lib/util';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectJobs,
	useSelectTopNavState,
	useSelectRequest,
} from 'store';
import MapView from '../../lib/components/MapView';
import ActiveJobsTable from './ActiveJobsTable';
import classes from './index.module.scss';
import JobRequestTable from './JobRequestTable';
import QuickAccessItem from './QuickAccessItem';

const DashboardScreen = () => {
	const { width } = useWindowResize();
	const { push } = useHistory();
	const isSmallerDevice = width <= 500;

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canListJob } = useSelectAuthorization();
	const { mapToggleVisible } = useSelectTopNavState();
	const jobs = useSelectJobs();

	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		if (canListJob) {
			dispatch(actions.fetchJobs());
		} else {
			setIsDataFetched(true);
		}
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.JOB, 'GET')) {
			if (RM.succeeded()) {
				setIsDataFetched(true);
			}
		}
	}, [active]);

	useComprehensiveTopNav('Dashboard', { mapToggleVisible: true });

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div className={classes['dashboard']}>
			{mapToggleVisible && (
				<div className={classes['dashboard-map']}>
					<MapView longitude={-74} latitude={40.72} />
				</div>
			)}
			{canListJob && (
				<div className={classes['dashboard-group']}>
					<div className={classes['dashboard-quickAccess']}>
						<Typography className={classes['dashboard-quickAccess-heading']} variant="h4">
							Quick Access
						</Typography>
						<div className={classes['dashboard-quickAccess-list']}>
							<QuickAccessItem
								title="Pending Invoices"
								value={jobs.list.filter((job) => (job.invoice ? false : true)).length}
								isSmallerDevice={isSmallerDevice}
							/>
							<QuickAccessItem
								title="Unassigned Nurses"
								value={jobs.list.filter((job) => (job.nurse ? false : true)).length}
								isSmallerDevice={isSmallerDevice}
							/>
							<QuickAccessItem
								title="Job Request"
								value={jobs.list.filter((job) => job.status === 0).length}
								isSmallerDevice={isSmallerDevice}
							/>
						</div>
					</div>

					<section className={`${classes['dashboard-section']} ${classes['dashboard-dispatchRequests']}`}>
						<div className={classes['dashboard-section-header']}>
							<Typography className={classes['dashboard-section-header-title']} variant="h4">
								Job Request
							</Typography>
							<Button
								className={classes['dashboard-section-header-button']}
								title="See all requests"
								color="primary"
								sizing="small"
								end={<Image src={'icRightCaretPrimary'} />}
								style={{
									marginTop: '-0.2rem',
								}}
								onClick={() => push('/jobs?filter=pending')}
							/>
						</div>
						<JobRequestTable jobs={jobs.list.filter((job) => job.status === 0).slice(0, 10)} />
					</section>

					<section
						style={!mapToggleVisible ? { flexBasis: '100%' } : {}}
						className={`${classes['dashboard-section']} ${classes['dashboard-activeDispatches']}`}
					>
						<div className={classes['dashboard-section-header']}>
							<Typography className={classes['dashboard-section-header-title']} variant="h4">
								Active Jobs
							</Typography>
							<Button
								className={classes['dashboard-section-header-button']}
								title="See all active jobs"
								color="primary"
								sizing="small"
								end={<Image src={'icRightCaretPrimary'} />}
								onClick={() => push('/jobs?filter=active')}
							/>
						</div>
						<ActiveJobsTable
							jobs={jobs.list.filter((job) => JobStatus.isActive(job.status)).slice(0, 10)}
						/>
					</section>
					<div className="dashboard-activeDispatches"></div>
				</div>
			)}
		</div>
	);
};

export default DashboardScreen;
