import React, { useCallback, useEffect, useState } from 'react';
import {
	useSelectAuthorization,
	useAppDispatch,
	useSelectRequest,
	useSelectInventory,
	actions,
	useSelectVendors,
	useSelectVendor,
	RequestManager,
} from 'store';
import { SearchBar, Typography, Image, LoadingIndicator, DropdownButton, Pagination } from 'lib/components';
import { InventoriesDataTable } from 'lib/components/DataTables';
import AddItemModal from './AddItemModal';
import { useComprehensiveTopNav, useWindowResize, useCustomToast, usePagination, useSearch } from 'hooks';
import { Inventory, InventoryInput } from 'types';
import routes from 'lib/api/routes';
import classes from './index.module.scss';
import { DropdownItem } from 'lib/components/types';

const Home = () => {
	const { isSmallerDevice } = useWindowResize();
	const toast = useCustomToast();

	const dispatch = useAppDispatch();
	const { canCreateInventory, canUpdateInventory, canDeleteInventory } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const inventory = useSelectInventory();
	const vendors = useSelectVendors();
	const getVendorById = useSelectVendor();

	const [showAddNewItemModal, setShowAddNewItemModal] = useState(false);
	const [checked, setChecked] = useState<number[]>([]);
	const [checkedItem, setCheckedItem] = useState<Inventory>();
	const [fetchedCount, setFetchedCount] = useState(0);
	const [query, setQuery] = useState('');
	const [vendorDropdownItems, setVendorDropdownItems] = useState<DropdownItem<number>[]>([]);
	const [selectedVendorDropdownItem, setSelectedVendorDropdownItem] = useState<number>(-1);

	useEffect(() => {
		dispatch(actions.fetchInventory());
		dispatch(actions.fetchVendors());
	}, []);

	useEffect(() => {
		setVendorDropdownItems(() => vendors.list.map(({ id, name }) => ({ label: name, value: id })));
		setFetchedCount((prev) => prev + 1);
	}, [vendors.updatedAt]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(inventory, {
		itemsPerPage: 9,
	});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = inventory.list.filter(
				(item) =>
					item.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					getVendorById(item.vendorId)?.name?.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.INVENTORY, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				toggleShowAddNewItemModal();
			}
		}

		if (RM.req(routes.INVENTORY_ID(checked[0]), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				toggleShowAddNewItemModal();
				setCheckedItem(undefined);
				setChecked([]);
			}
		}

		if (RM.req(routes.INVENTORY_ID(checked[0]), 'DELETE')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setCheckedItem(undefined);
				setChecked([]);
			}
		}
	}, [active]);

	useEffect(() => {
		if (selectedVendorDropdownItem === -1) return;

		const filtered = listing.filter((inventory) => inventory.vendorId === selectedVendorDropdownItem);
		setData(filtered);
	}, [selectedVendorDropdownItem]);

	useEffect(() => {
		if (!checkedItem) {
			return;
		}

		toggleShowAddNewItemModal();
	}, [checkedItem]);

	const handleVendorDropdownItemSelect = (value: number) => {
		setSelectedVendorDropdownItem(value);
	};

	const toggleShowAddNewItemModal = () => setShowAddNewItemModal((prev) => !prev);

	const handleAddInventoryOnClick = () => {
		setChecked([]);
		setCheckedItem(undefined);
		toggleShowAddNewItemModal();
	};

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleRowClick = (inventory: Inventory) => {
		if (canUpdateInventory) {
			setChecked([inventory.id]);
			setCheckedItem(inventory);
		}
	};

	const handleInventoryAction = (type: 'create' | 'update', data: InventoryInput, id?: number) => {
		if (type === 'create') {
			dispatch(actions.addInventory(data));
		} else {
			dispatch(actions.updateInventory(id!, data));
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteInventory(checked[0]));
	};

	const handleAddItemModalOnClose = useCallback(() => {
		if (checkedItem) {
			setCheckedItem(undefined);
			toggleShowAddNewItemModal();
		} else {
			toggleShowAddNewItemModal();
		}
	}, [checkedItem]);

	useComprehensiveTopNav('Inventory');

	if (!isDataFetched && fetchedCount < 1) return <LoadingIndicator />;

	return (
		<>
			<AddItemModal
				inventory={checkedItem}
				vendors={vendors.list}
				isOpen={showAddNewItemModal}
				onClose={handleAddItemModalOnClose}
				action={handleInventoryAction}
			/>
			<div
				className={classes['row']}
				style={{
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flex: 1,
						marginRight: isSmallerDevice ? 0 : '2rem',
					}}
				>
					<SearchBar
						query={query}
						setQuery={setQuery}
						onSearch={() => console.log({ query })}
						className={classes['inventories-searchbar']}
						placeholder="Search..."
						searchIconColor="primary"
					/>
				</div>
				<div
					className={classes['row']}
					style={{
						marginTop: '1.3rem',
					}}
				>
					{canCreateInventory && (
						<button
							className={`${classes['row']} ${classes['add-new']}`}
							onClick={handleAddInventoryOnClick}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Add new</Typography>
							<div>
								<Image src={'icPlusPrimary'} />
							</div>
						</button>
					)}
				</div>
			</div>
			<div
				className={classes['row']}
				style={{
					marginTop: '0.1rem',
				}}
			>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					style={{
						margin: isSmallerDevice ? '0.5rem 0.5rem 0 0' : '1rem 1rem 0 0',
					}}
					weight="medium"
				>
					Filter by:
				</Typography>
				<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
					<DropdownButton
						title="Vendor"
						list={vendorDropdownItems}
						onItemSelect={handleVendorDropdownItemSelect}
					/>
				</div>
				<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
					<DropdownButton
						title="Stock Status"
						list={[
							{ label: 'Stock Status 1', value: 'stock1' },
							{ label: 'Stock Status 2', value: 'stock2' },
							{ label: 'Stock Status 3', value: 'stock3' },
						]}
					/>
				</div>
			</div>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					{checked.length !== 0 && (
						<>
							{canDeleteInventory && (
								<button
									className={classes['button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							)}
						</>
					)}
				</div>
				{/* <div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					<button
						className={classes['button']}
						style={{
							minWidth: 70,
							backgroundColor: '#E4F4F6',
							borderColor: '#A6E5E9',
							marginRight: '1rem',
							justifyContent: 'space-evenly',
						}}
					>
						<Image
							src="icExport"
							style={{
								marginRight: '0.5rem',
							}}
						/>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
							Export
						</Typography>
					</button>
				</div> */}
			</div>
			<InventoriesDataTable
				inventory={paginatedData}
				checkedId={checked[0] ?? -1}
				checkAction={handleCheckAction}
				onRowClick={handleRowClick}
			/>
			<Pagination {...paginationProps} />
		</>
	);
};

export default Home;
