import { useWindowResize } from 'hooks';
import { Image, Typography } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactDom from 'react-dom';
import { JobNote } from 'types';
import adjustTime from '../../lib/adjustTime';
import { useSelectSettings } from '../../store';
import classes from './index.module.scss';

interface Props {
	items: JobNote[];
	deletable?: boolean;
	onDelete?: (id: number) => void;
}

interface NoteProps {
	note: JobNote;
	deletable?: boolean;
	onDelete?: (id: number) => void;
}

interface NoteModalProps {
	isOpen: boolean;
	message: string;
	onClose: () => void;
}

const sortNotes = (notes: JobNote[]) => [...notes].sort((a, b) => b.id - a.id);

const NoteViewModal = ({ isOpen, onClose, message }: NoteModalProps) => {
	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['note-view-modal']}>
			<div className={classes['note-view-modal--content']}>
				<button className={classes['note-view-modal--close']} onClick={onClose}>
					x
				</button>
				<div className={classes['note-view-modal--message']}>
					<Typography>{message}</Typography>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

const Note: React.FC<NoteProps> = ({ note, deletable, onDelete }: NoteProps) => {
	const settingsState = useSelectSettings();

	const { isSmallerDevice } = useWindowResize();
	const [isOpen, setIsOpen] = useState(false);

	const handleDelete = () => {
		onDelete && onDelete(note.id);
	};

	return (
		<>
			<NoteViewModal isOpen={isOpen} message={note.message} onClose={() => setIsOpen(false)} />
			<div
				className={classes['note-container']}
				style={{
					width: '96%',
					justifyContent: 'space-between',
				}}
			>
				<div
					onClick={() => setIsOpen(true)}
					style={{
						width: deletable ? '90%' : '100%',
						display: 'flex',
						justifyContent: 'space-between',
						cursor: 'pointer',
					}}
				>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
						Nurse Note #{note.id}
					</Typography>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray" style={{ marginLeft: '0.2rem' }}>
						{adjustTime(settingsState, note.createdAt, 'MM/DD/YYYY - hh:mm A').format('MM/DD/YYYY hh:mm A')}
					</Typography>
				</div>
				{deletable && <Image src="icTrashDanger" style={{ cursor: 'pointer' }} onClick={handleDelete} />}
			</div>
		</>
	);
};

const Notes = ({ items, deletable, onDelete }: Props) => {
	const [sortedNotes, setSortedNotes] = useState<JobNote[]>([]);

	useEffect(() => {
		if (items.length === 0) return;

		setSortedNotes(sortNotes(items));
	}, [items]);

	return (
		<>
			<Scrollbars
				renderThumbVertical={(props) => <div {...props} className={classes['vertical-scroll-thumb']} />}
			>
				{sortedNotes.map((note) => (
					<Note key={note.id} note={note} deletable={deletable} onDelete={onDelete} />
				))}
			</Scrollbars>
		</>
	);
};

export default Notes;
