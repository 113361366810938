import React from 'react';
import SideNavLink from './SideNavLink';
import { useSelectAuthorization } from 'store';
import { SideNavItem } from 'lib/components/types';
import { PermissionType } from 'lib/util';

interface Props {
	list: SideNavItem[];
}

const SideNavLinkList = ({ list }: Props) => {
	const { role, isPermitted, canListInventory, canListVendor } = useSelectAuthorization();

	return (
		<>
			{list
				.filter(({ roles }) => roles.includes(role))
				.map((link) => {
					if (link.to === '/inventory') {
						// Check if user can list vendors page but not inventory
						if (!canListInventory && canListVendor) {
							link.to = '/inventory/vendors';
							link.title = 'Vendors';
							return link;
						} else if (!canListInventory && !canListVendor) {
							// If user can't list any
							link.permission = PermissionType.LIST_INVENTORY;
							return link;
						} else return link;
					} else return link;
				})
				.filter(({ permission }) => permission === undefined || isPermitted(permission))
				.map(({ to, title, icon }) => (
					<SideNavLink key={title} to={to} title={title} icon={icon} />
				))}
		</>
	);
};

export default SideNavLinkList;
