import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import classes from './index.module.scss';
import { TextField } from 'lib/components/FormFields';

interface Props {
	isOpen: boolean;
	onClose: () => any;
	onVoidInvoice: (reason: string) => void;
}

const VoidInvoiceModal = ({ isOpen, onClose, onVoidInvoice }: Props) => {
	const [reason, setReason] = useState('');

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div className={classes['modal-form']}>
						{/* <Typography>
							Void Reasoning <small>(required)</small>
						</Typography>
						<textarea></textarea> */}
						<TextField
							placeholder="Enter your void reasoning"
							label="Void Reasoning (required)"
							variant="labelled"
							textArea
							rows={9}
							value={reason}
							onChange={(e) => setReason(e.target.value)}
						/>
					</div>
					<div className={classes['modal-footer']}>
						<Button
							variant="standard"
							color="primary"
							title="Void Invoice"
							style={{
								marginRight: '1rem',
							}}
							onClick={() => {
								onVoidInvoice(reason);
								onClose();
							}}
						/>
						<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default VoidInvoiceModal;
