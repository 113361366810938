import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import { AuthenticationState, AuthSuccessPayload } from 'store/types';
import { AppThunk, actions } from '..';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	accessToken: '',
	expiryAt: -1,
};

export default createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		authenticationStarted: (state) => {
			state.isAuthenticated = false;
		},
		authenticationFinished: (state, { payload }: PA<AuthSuccessPayload>) => {
			state.isAuthenticated = true;
			state.accessToken = payload.accessToken;
			state.expiryAt = payload.expiryAt;
		},
	},
});

const getAccessToken = (uniqueId: string): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.authenticationStarted());
		dispatch(actions.requestStarted({ name: routes.AUTH_CALLBACK, method: 'POST' }));

		const { data, error } = await Api.auth.signin(uniqueId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.AUTH_CALLBACK,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			const { accessToken, expiryAt, user } = data!;
			dispatch(actions.currentUserFetched(user));
			dispatch(actions.authenticationFinished({ accessToken, expiryAt }));
			dispatch(
				actions.requestFinished({
					name: routes.AUTH_CALLBACK,
					method: 'POST',
					message: 'Login successful',
					payload: {},
				})
			);
		}
	};
};

const signout = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SIGN_OUT, method: 'GET' }));

		const { data, error } = await Api.auth.signout();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SIGN_OUT,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.SIGN_OUT,
					method: 'GET',
					message: 'Signed out successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const authThunks = {
	getAccessToken,
	signout,
};
