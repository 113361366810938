import { useWindowResize } from 'hooks/useWindowResize';
import { Image, Typography } from 'lib/components';
import React, { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Job } from 'types';
import classes from './index.module.scss';
import JobRow from './JobRow';
import { jobIdCompareFn, jobRoomNoCompareFn, jobStartTimeCompareFn, jobStatusCompareFn, typeIdCompareFn } from './sort';
import { HeaderItem, Sort, SortField } from './types';

interface Props {
	jobs: Job[];
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'Status', sort: 'asc', field: 'status' },
	{ label: 'Priority', sort: 'asc', field: 'typeId' },
	{ label: 'Treatment Type', sort: 'asc', field: 'treatmentType' },
	{ label: 'Job ID', sort: 'asc', field: 'jobId' },
	{ label: 'Hospital' },
	{ label: 'Room No.', sort: 'asc', field: 'roomNo' },
	{ label: 'Ordering Physician' },
	{ label: 'Patient Name' },
	{ label: 'Assigned Nurse' },
	{ label: 'Start Time', sort: 'asc', field: 'startTime' },
];

const JobsDataTable: React.FC<Props> = ({ jobs }: Props) => {
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'jobId',
	});
	const [sortedJobs, setSortedJobs] = useState<Job[]>(jobs);

	const { isSmallerDevice } = useWindowResize();

	const sortJobs = useCallback(
		(jobs: Job[]) => {
			const sorted = [...jobs].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'jobId') {
							return jobIdCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'typeId') {
							return typeIdCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'treatmentType') {
							return typeIdCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'startTime') {
							return jobStartTimeCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'endTime') {
							// endTime value is not value on Job
							// jobEndTimeCompareFn(a, b, 'asc');
							return 0;
						} else if (currentSortField?.field === 'roomNo') {
							return jobRoomNoCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'status') {
							return jobStatusCompareFn(a, b, 'asc');
						}
						return 0;

					case 'desc':
						if (currentSortField?.field === 'jobId') {
							return jobIdCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'typeId') {
							return typeIdCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'treatmentType') {
							return typeIdCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'startTime') {
							return jobStartTimeCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'endTime') {
							// endTime value is not value on Job
							// jobEndTimeCompareFn(a, b, 'desc');
							return 0;
						} else if (currentSortField?.field === 'roomNo') {
							return jobRoomNoCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'status') {
							return jobStatusCompareFn(a, b, 'desc');
						}
						return 0;

					default:
						return 0;
				}
			});
			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedJobs(sortJobs(jobs));
	}, [jobs, sortJobs]);

	useEffect(() => {
		const sorted = sortJobs(sortedJobs);

		console.log(sorted);

		setSortedJobs(sorted);
	}, [sortJobs, setSortedJobs]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '6px',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};
	return (
		<div className={classes['inventories-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>{HEADER_ITEMS.map((item) => renderHeaderItem(item))}</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedJobs?.map((job) => (
								<JobRow key={job.id} job={job} />
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobsDataTable;
