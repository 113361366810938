import { Button } from 'lib/components';
import { TextField, TimezoneSelect } from 'lib/components/FormFields';
import React, { useCallback, useEffect, useState } from 'react';
import { useCustomToast } from '../../hooks';
import { routes } from '../../lib/api';
import { actions, RequestManager, useAppDispatch, useSelectRequest, useSelectSettings } from '../../store';
import classes from './index.module.scss';

interface Props {}

const GeneralTab = ({}: Props) => {
	const toast = useCustomToast();

	const [taxAmount, setTaxAmount] = useState(0);
	const [globalTimezone, setGlobalTimezone] = useState<string | undefined>('UTC-06:00 Central Time (US & Canada)');

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const settings = useSelectSettings();

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.SETTINGS, 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		for (const setting of settings.list) {
			if (setting.name === 'tax_amount') {
				setTaxAmount(parseFloat(setting.value));
				continue;
			}

			if (setting.name === 'global_timezone') {
				setGlobalTimezone(setting.value);
				continue;
			}
		}
	}, []);

	const handleUpdate = useCallback(() => {
		dispatch(
			actions.updateSettings([
				{
					name: 'tax_amount',
					value: taxAmount.toFixed(2),
				},
				{
					name: 'global_timezone',
					value: globalTimezone || 'UTC-06:00 Central Time (US & Canada)',
				},
			])
		);
	}, [dispatch, taxAmount, globalTimezone]);

	useEffect(() => {
		console.log('Global Timezone: ', globalTimezone);
	}, [globalTimezone]);

	return (
		<div className={classes['generalTab']}>
			<div className={classes['settings-form']}>
				<TextField
					variant="labelled"
					label="Tax Amount: "
					placeholder="9.00"
					value={taxAmount.toLocaleString()}
					onChange={(e) => setTaxAmount(+e.target.value)}
					numberSymbol={['$']}
				/>
				<div style={{ width: 16 }} />
				<TimezoneSelect label={'System Timezone:'} value={globalTimezone} setValue={setGlobalTimezone} />
			</div>
			<div className={classes['footButtons']}>
				<Button variant="standard" title="Update" onClick={handleUpdate} />
			</div>
		</div>
	);
};

export default GeneralTab;
