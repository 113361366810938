import React, { useCallback, useEffect, useState } from 'react';
import {
	useAppDispatch,
	actions,
	useSelectRequest,
	useSelectVendors,
	useSelectAuthorization,
	useSelectInventory,
	RequestManager,
} from 'store';
import { SearchBar, Typography, Image, LoadingIndicator, Pagination } from 'lib/components';
import { VendorsDataTable } from 'lib/components/DataTables';
import AddVendorModal from './AddVendorModal';
import { Vendor, VendorInput } from 'types';
import { useComprehensiveTopNav, useWindowResize, useCustomToast, usePagination, useSearch } from 'hooks';
import routes from 'lib/api/routes';
import classes from './index.module.scss';

const Vendors = () => {
	const { isSmallerDevice } = useWindowResize();
	const toast = useCustomToast();

	const dispatch = useAppDispatch();
	const { canCreateVendor, canUpdateVendor, canDeleteVendor } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const vendors = useSelectVendors();
	const inventory = useSelectInventory();

	const [showAddNewItemModal, setShowAddNewItemModal] = useState(false);
	const [checked, setChecked] = useState<number[]>([]);
	const [checkedVendor, setCheckedVendor] = useState<Vendor>();
	const [fetchedCount, setFetchedCount] = useState(0);
	const [query, setQuery] = useState('');

	useEffect(() => {
		dispatch(actions.fetchVendors());
		dispatch(actions.fetchInventory());
	}, []);

	useEffect(() => {
		setFetchedCount((prev) => prev + 1);
	}, [inventory.updatedAt]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(vendors, {
		itemsPerPage: 9,
	});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(vendor) =>
					vendor.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					vendor.phoneNumber.search(query) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.VENDOR, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				toggleShowAddNewItemModal();
			}
		}

		if (RM.req(routes.VENDOR_ID(checked[0]), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				toggleShowAddNewItemModal();
				setCheckedVendor(undefined);
				setChecked([]);
				setData(listing);
			}
		}

		if (RM.req(routes.VENDOR_ID(checked[0]), 'DELETE')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setCheckedVendor(undefined);
				setChecked([]);
			}
		}
	}, [active]);

	useEffect(() => {
		if (!checkedVendor) {
			return;
		}

		toggleShowAddNewItemModal();
	}, [checkedVendor]);

	const toggleShowAddNewItemModal = () => setShowAddNewItemModal((prev) => !prev);

	const handleAddVendorOnClick = () => {
		setChecked([]);
		setCheckedVendor(undefined);
		toggleShowAddNewItemModal();
	};

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleRowClick = (vendor: Vendor) => {
		if (canUpdateVendor) {
			setChecked([vendor.id]);
			setCheckedVendor(vendor);
		}
	};

	const handleVendorAction = (type: 'create' | 'update', data: VendorInput, id?: number) => {
		if (type === 'create') {
			dispatch(actions.addVendor(data));
		} else {
			dispatch(actions.updateVendor(id!, data));
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteVendor(checked[0]));
	};

	const handleAddItemModalOnClose = useCallback(() => {
		if (checkedVendor) {
			setCheckedVendor(undefined);
			setChecked([]);
			toggleShowAddNewItemModal();
		} else {
			toggleShowAddNewItemModal();
		}
	}, [checkedVendor]);

	useComprehensiveTopNav('Vendors');

	if (!isDataFetched || fetchedCount < 1) return <LoadingIndicator />;

	return (
		<>
			<AddVendorModal
				isOpen={showAddNewItemModal}
				vendor={checkedVendor}
				onClose={handleAddItemModalOnClose}
				action={handleVendorAction}
			/>
			<div
				className={classes['row']}
				style={{
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flex: 1,
						marginRight: isSmallerDevice ? 0 : '2rem',
					}}
				>
					<SearchBar
						query={query}
						setQuery={setQuery}
						onSearch={() => console.log({ query })}
						className={classes['inventories-searchbar']}
						placeholder="Search..."
						searchIconColor="primary"
					/>
				</div>
				<div
					className={classes['row']}
					style={{
						marginTop: '1.3rem',
					}}
				>
					{canCreateVendor && (
						<button className={`${classes['row']} ${classes['add-new']}`} onClick={handleAddVendorOnClick}>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Add new</Typography>
							<div>
								<Image src={'icPlusPrimary'} />
							</div>
						</button>
					)}
				</div>
			</div>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					{checked.length !== 0 && (
						<>
							{canDeleteVendor && (
								<button
									className={classes['button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							)}
						</>
					)}
				</div>
				{/* <div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					<button
						className={classes['button']}
						style={{
							minWidth: 70,
							backgroundColor: '#E4F4F6',
							borderColor: '#A6E5E9',
							marginRight: '1rem',
							justifyContent: 'space-evenly',
						}}
					>
						<Image
							src="icExport"
							style={{
								marginRight: '0.5rem',
							}}
						/>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
							Export
						</Typography>
					</button>
				</div> */}
			</div>
			<VendorsDataTable
				vendors={paginatedData}
				checkedId={checked[0] ?? -1}
				checkAction={handleCheckAction}
				onRowClick={handleRowClick}
			/>
			<Pagination {...paginationProps} />
		</>
	);
};

export default Vendors;
