import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Public from './public';
import Authenticated from './authenticated';
import Authorized from './authorized';
import SuperAdminRoute from './super-admin';
import Auth from 'lib/components/Auth';
import ProfileScreen from 'screens/ProfileScreen';
import AdminsScreen from 'screens/AdminScreen';
import AdminCreateScreen from 'screens/AdminScreen/AdminCreate';
import AdminEditScreen from 'screens/AdminScreen/AdminEdit';
import Logout from 'lib/components/Auth/logout';
import DashboardScreen from 'screens/DashboardScreen';
import NursesScreen from 'screens/NursesScreen';
import NurseScreen from 'screens/NursesScreen/Nurse';
import NurseCreateScreen from 'screens/NursesScreen/NurseCreate';
import JobsScreen from 'screens/JobsScreen';
import InventoriesScreen from 'screens/InventoriesScreen';
import InvoicesScreen from 'screens/InvoicesScreen';
import HospitalsScreen from 'screens/HospitalsScreen';
import HospitalDetailScreen from 'screens/HospitalDetailScreen';
import HospitalNewScreen from 'screens/HospitalNewScreen';
import HospitalEditScreen from 'screens/HospitalEditScreen';
import EmployeeCreateScreen from 'screens/EmployeesScreen/EmployeeCreateScreen';
import EmployeeEditScreen from 'screens/EmployeesScreen/EmployeeEditScreen';
import JobScreen from 'screens/JobsScreen/Job';
import ActiveJobScreen from 'screens/JobsScreen/ActiveJob';
import EditJob from 'screens/JobsScreen/EditJob';
import CreateJob from 'screens/JobsScreen/CreateJob';
import CompleteJob from 'screens/JobsScreen/CompleteJob';
import InvoiceDetailScreen from 'screens/InvoiceDetailScreen';
import { PermissionType as P, ERROR_403_ITEM } from 'lib/util';
import ErrorScreen from 'screens/ErrorScreen';
import SettingsScreen from 'screens/SettingsScreen';
import AddCostAmplifierScreen from 'screens/SettingsScreen/AddCostAmplifierScreen';
import EditCostAmplifierScreen from 'screens/SettingsScreen/EditCostAmplifierScreen';
import AddServiceScreen from 'screens/SettingsScreen/AddServiceScreen';
import EditServiceScreen from 'screens/SettingsScreen/EditServiceScreen';

const Routes = () => {
	return (
		<Switch>
			<Switch>
				<Public exact path="/login" component={Auth} />
				<Authenticated exact path={'/profile'} component={ProfileScreen} />
				<SuperAdminRoute exact path={'/admins'} component={AdminsScreen} />
				<SuperAdminRoute exact path={'/admins/create'} component={AdminCreateScreen} />
				<SuperAdminRoute exact path={'/admins/edit/:id'} component={AdminEditScreen} />
				<SuperAdminRoute exact path={'/settings/cost-amplifier/add'} component={AddCostAmplifierScreen} />
				<SuperAdminRoute exact path={'/settings/cost-amplifier/edit/:id'} component={EditCostAmplifierScreen} />
				<SuperAdminRoute exact path={'/settings/services/add'} component={AddServiceScreen} />
				<SuperAdminRoute exact path={'/settings/services/edit/:id'} component={EditServiceScreen} />
				<SuperAdminRoute path={'/settings'} component={SettingsScreen} />

				<Authenticated exact path={'/logout'} component={Logout} />
				<Authorized permission={P.LIST_NURSE} exact path={'/nurses'} component={NursesScreen} />
				<Authorized permission={P.READ_NURSE} exact path={'/nurses/view/:id'} component={NurseScreen} />
				<Authorized permission={P.CREATE_NURSE} exact path={'/nurses/create'} component={NurseCreateScreen} />
				<Authenticated exact path={'/dashboard'} component={DashboardScreen} />
				<Authorized permission={P.LIST_INVOICE} exact path={'/invoices'} component={InvoicesScreen} />
				<Authorized
					permission={P.READ_INVOICE}
					exact
					path={'/invoices/view/:id'}
					component={InvoiceDetailScreen}
				/>
				<Authorized permission={P.LIST_HOSPITAL} exact path={'/hospitals'} component={HospitalsScreen} />
				<Authorized
					permission={P.CREATE_HOSPITAL}
					exact
					path={'/hospitals/new'}
					component={HospitalNewScreen}
				/>
				<Authorized
					permission={P.UPDATE_HOSPITAL}
					exact
					path={'/hospitals/edit/:id'}
					component={HospitalEditScreen}
				/>
				<Authorized permission={P.READ_HOSPITAL} path={'/hospitals/:id'} component={HospitalDetailScreen} />
				<Authorized
					permission={P.CREATE_EMPLOYEE}
					exact
					path={'/employees/:hospitalId/new'}
					component={EmployeeCreateScreen}
				/>
				<Authorized
					permission={P.READ_EMPLOYEE}
					exact
					path={'/employees/:hospitalId/view/:employeeId'}
					component={EmployeeEditScreen}
				/>
				<Authenticated path={'/inventory'} component={InventoriesScreen} />
				<Authorized permission={P.LIST_JOB} exact path={'/jobs'} component={JobsScreen} />
				<Authorized permission={P.READ_JOB} exact path={'/jobs/view/:id'} component={JobScreen} />
				<Authorized permission={P.READ_JOB} exact path={'/jobs/active/:id'} component={ActiveJobScreen} />
				<Authorized permission={P.UPDATE_JOB} exact path={'/jobs/edit/:id'} component={EditJob} />
				<Authorized permission={P.CREATE_JOB} exact path={'/jobs/create'} component={CreateJob} />
				<Authorized permission={P.READ_JOB} exact path={'/jobs/complete/:id'} component={CompleteJob} />
				<Authenticated exact path="/403" render={() => <ErrorScreen error={ERROR_403_ITEM} />} />
				<Redirect exact from="/" to="login" />
			</Switch>
		</Switch>
	);
};

export default Routes;
