import React, { useCallback, useRef } from 'react';
import { Image, Typography } from 'lib/components';
import ReactDatePicker from 'react-datepicker';
import classes from './index.module.scss';

interface Props {
	value: Date;
	onChange: (value: Date) => any;
	label: string;
	disableDropdown?: boolean;
}

const CustomDatePicker = ({ value, onChange, label, disableDropdown = false }: Props) => {
	const reactDatePickerRef = useRef<ReactDatePicker>(null);

	const showDropdown = useCallback(() => {
		if (!disableDropdown) {
			reactDatePickerRef.current?.setOpen(true);
		}
	}, [disableDropdown]);

	return (
		<div className={classes['datePicker']}>
			<Typography className={classes['datePicker-label']}>{label}:</Typography>
			<div className={classes['datePicker-fieldArea']}>
				<Image onClick={showDropdown} src="icCalendar" />
				<ReactDatePicker
					ref={reactDatePickerRef}
					className={classes['datePicker-reactDatePicker']}
					selected={value}
					onChange={(date: Date) => date && onChange(date)}
				/>
			</div>
		</div>
	);
};

export default CustomDatePicker;
