import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { Typography, Image } from 'lib/components';
import InvoicePaymentStatusBadge from '../Invoices/InvoicePaymentStatusBadge';
import { useSelectAuthorization } from 'store';
import { HeaderItem, Sort, SortField } from '../Invoices/types';
import {
	invoicesCreatedAtCompareFun,
	invoicesIdCompareFun,
	invoicesPriceCompareFun,
	invoicesStatusCompareFn,
} from '../Invoices/sort';
import { useWindowResize, useAddIdPrefix } from 'hooks';
import { Invoice } from 'types';
import classes from './index.module.scss';

interface Props {
	invoices: Invoice[];
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'Status', field: 'status', sort: 'asc' },
	{ label: 'ID', field: 'id', sort: 'asc' },
	{ label: 'Date Range' },
	{ label: 'Price', field: 'price', sort: 'asc' },
	{ label: 'Created Date', field: 'createdAt', sort: 'asc' },
];

const HospitalInvoicesDataTable = ({ invoices }: Props) => {
	const { push } = useHistory();
	const { isSmallerDevice } = useWindowResize();
	const addPrefix = useAddIdPrefix();

	const { canReadInvoice } = useSelectAuthorization();

	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'id',
	});
	const [sortedInvoices, setSortedInvoices] = useState(invoices);

	const sortInvoices = useCallback(
		(allInvoices: Invoice[]) => {
			const sorted = [...allInvoices].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'status') {
							return invoicesStatusCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'id') {
							return invoicesIdCompareFun(a, b, 'asc');
						} else if (currentSortField?.field === 'price') {
							return invoicesPriceCompareFun(a, b, 'asc');
						} else if (currentSortField?.field === 'createdAt') {
							return invoicesCreatedAtCompareFun(a, b, 'asc');
						}

						return 0;

					case 'desc':
						if (currentSortField?.field === 'status') {
							return invoicesStatusCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'id') {
							return invoicesIdCompareFun(a, b, 'desc');
						} else if (currentSortField?.field === 'price') {
							return invoicesPriceCompareFun(a, b, 'desc');
						} else if (currentSortField?.field === 'createdAt') {
							return invoicesCreatedAtCompareFun(a, b, 'desc');
						}
						return 0;

					default:
						return 0;
				}
			});

			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedInvoices(sortInvoices(invoices));
	}, [invoices, sortInvoices]);

	useEffect(() => {
		const sorted = sortInvoices(sortedInvoices);

		setSortedInvoices(sorted);
	}, [setSortedInvoices, sortInvoices]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={classes['invoices-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>{HEADER_ITEMS.map((item) => renderHeaderItem(item))}</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedInvoices.map((invoice) => (
								<div
									key={invoice.id}
									className={classes['table-row']}
									onClick={() => canReadInvoice && push(`/invoices/view/${invoice.id}`)}
								>
									<div className={classes['col']}>
										<InvoicePaymentStatusBadge status={invoice.paymentStatus} />
									</div>
									<div className={classes['col']}>
										<Typography>{addPrefix(invoice.id)}</Typography>
									</div>
									<div className={classes['col']}>
										<Typography>
											{invoice.rangeFrom ? `${invoice.rangeFrom} - ${invoice.rangeTo}` : '-'}
										</Typography>
									</div>
									<div className={classes['col']}>
										<Typography>${invoice.total.toFixed(2)}</Typography>
									</div>
									<div className={classes['col']}>
										<Typography>{invoice.createdAt}</Typography>
									</div>
								</div>
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HospitalInvoicesDataTable;
