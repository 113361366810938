import React from 'react';
import { Checkbox } from 'lib/components/FormFields';
import { Typography, Image } from 'lib/components';
import InvoiceItemRow from './InvoiceItemRow';
import { InvoiceItemsAllCheckedStatus, Invoice, InvoiceItemType } from 'types';
import classes from './index.module.scss';
import { useSelectSettings } from '../../../../store';

interface Props {
	invoice: Invoice;
	allChecked: boolean;
	allCheckedStatus: InvoiceItemsAllCheckedStatus;
	handleAllCheckAction: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkAction: (id: number, isChecked: boolean, isVoided: boolean) => void;
}

const HEADER_ITEMS = ['Invoice Id', 'Item Name', 'Vendor', 'Amount', 'Price', 'Voided'];

const InvoiceDetailDataTable = ({
	invoice,
	allChecked,
	allCheckedStatus,
	handleAllCheckAction,
	checkAction,
}: Props) => {
	const settingsState = useSelectSettings();

	const taxPercentage = React.useMemo<number>(() => {
		const findTaxAmount = settingsState.list.find((element) => element.name === 'tax_amount');
		if (findTaxAmount !== undefined) {
			return parseFloat(findTaxAmount.value);
		}

		return 0;
	}, [settingsState.list]);

	return (
		<div className={classes['invoceDetailTable']}>
			<div className={classes['invoceDetailTable-header']}>
				<div className={classes['invoceDetailTable-headerRow']}>
					<Checkbox
						checked={allChecked}
						onChange={handleAllCheckAction}
						style={{
							margin: '0 2rem',
						}}
					/>
					{HEADER_ITEMS.map((label, index) => (
						<div className={classes['invoceDetailTable-col']} key={index}>
							<Typography weight="light">{label}</Typography>
							<Image
								src="icArrowDown"
								style={{
									marginLeft: '0.5rem',
								}}
							/>
						</div>
					))}
				</div>
			</div>
			<div className={classes['invoceDetailTable-body']}>
				{invoice.items
					.filter((element) => element.typeId !== InvoiceItemType.CUSTOM_COST)
					.map((item) => (
						<InvoiceItemRow
							key={item.id}
							item={item}
							checkAction={checkAction}
							allCheckedStatus={allCheckedStatus}
						/>
					))}

				{invoice.items.length > 0 && (
					<div className={classes['invoceDetailTable-summary']}>
						<div className={classes['invoceDetailTable-summary-items']}>
							<div className={classes['invoceDetailTable-summary-item']}>
								<Typography>Subtotal:</Typography>
								<Typography weight="bold">${invoice.subtotal.toFixed(2)}</Typography>
							</div>
							<div className={classes['invoceDetailTable-summary-item']}>
								<Typography>Tax({taxPercentage}%):</Typography>
								<Typography weight="bold">
									${(invoice.subtotal * (taxPercentage / 100)).toFixed(2)}
								</Typography>
							</div>
							{invoice.items
								.filter((element) => element.typeId === InvoiceItemType.CUSTOM_COST)
								.map((item) => (
									<div key={item.id} className={classes['invoceDetailTable-summary-item']}>
										<Typography>{item.name}:</Typography>
										<Typography weight="bold">${item.price.toFixed(2)}</Typography>
									</div>
								))}
							<div className={classes['invoceDetailTable-summary-item']}>
								<Typography>Total:</Typography>
								<Typography weight="bold">${invoice.total.toFixed(2)}</Typography>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default InvoiceDetailDataTable;
