import JobType from './JobType';

const parseType = (type: JobType) => {
	switch (type) {
		case JobType.ROUTINE:
			return 'Routine';
		case JobType.STAT:
			return 'STAT';
	}

	return 'Unknown';
};

export default parseType;
