import React, { useState, useEffect } from 'react';
import { Permission, PermissionType } from 'lib/util';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { AllCheckedStatus } from 'types';
import classes from './index.module.scss';

interface Props {
	permission: PermissionType;
	list?: PermissionType[];
	status: AllCheckedStatus;
	checkAction: (id: PermissionType, isChecked: boolean) => void;
}

const PermissionRow = ({ list = [], permission, status, checkAction }: Props) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (list.length === 0) return;
		if (list.includes(permission)) setChecked(true);
		else setChecked(false);
	}, [list]);

	useEffect(() => {
		if (status === 'some') return;
		if (status === 'none') setChecked(false);
	}, [status]);

	const handleCheckAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(permission, e.target.checked);
	};

	return (
		<div className={classes['permissions-tableRow']}>
			<div className={classes['permissions-tableCol']}>
				<Typography>{Permission.text(permission)}</Typography>
			</div>
			<div className={classes['permissions-tableCol']}>
				<Checkbox checked={checked} onChange={handleCheckAction} />
			</div>
			<div className={classes['permissions-tableCol']}>&nbsp;</div>
		</div>
	);
};

export default PermissionRow;
