import React from 'react';
import { Typography } from 'lib/components';
import { InvoicePaymentStatus, InvoicePaymentStatusType } from 'lib/util';
import classes from './index.module.scss';

interface Props {
	status: InvoicePaymentStatusType;
}

const InvoicePaymentStatusBadge = ({ status }: Props) => {
	let className = classes['table-badge'];

	if (status === InvoicePaymentStatusType.PAID) {
		className += ' ' + classes['table-badge-success'];
	} else if (status === InvoicePaymentStatusType.UNPAID) {
		className += ' ' + classes['table-badge-danger'];
	} else {
		className += ' ' + classes['table-badge-darkgray'];
	}

	return (
		<div className={className}>
			<Typography color={InvoicePaymentStatus.color(status)}>{InvoicePaymentStatus.text(status)}</Typography>
		</div>
	);
};

export default InvoicePaymentStatusBadge;
