import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducer';
import { RootState } from './types';

// const middleware = [...getDefaultMiddleware<RootState>().concat(logger)];
const middleware = [...getDefaultMiddleware<RootState>()];

export default configureStore({
	reducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development',
});
