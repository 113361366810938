import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'lib/components';
import Avatar from 'lib/components/Avatar';
import { Checkbox } from 'lib/components/FormFields';
import { User } from 'types';
import classes from './index.module.scss';
import { useSelectCurrentUser } from 'store';

interface Props {
	admin: User;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
}

const AdminRow = ({ admin, checkedId, checkAction }: Props) => {
	const { push } = useHistory();
	const me = useSelectCurrentUser();
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checkedId === admin.id) return;
		if (checkedId !== admin.id || checkedId === -1) setChecked(false);
	}, [checkedId]);

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(admin.id, e.target.checked);
	};

	const toAdminEdit = useCallback(() => {
		if (admin.id !== me.id) push(`/admins/edit/${admin.id}`);
		else push(`/profile`);
	}, [me]);

	return (
		<div key={admin.id} className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleAction}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div className={classes['col']} onClick={toAdminEdit}>
				<Avatar src={admin.avatar ?? 'plAvatar'} size={'32px'} />
			</div>
			<div className={classes['col']} onClick={toAdminEdit}>
				<Typography variant={'h6'}>{admin.name}</Typography>
			</div>
			<div className={classes['col']} onClick={toAdminEdit}>
				<Typography variant={'h6'}>{admin.email}</Typography>
			</div>
			<div className={classes['col']} onClick={toAdminEdit}>
				<Typography variant={'h6'}>{admin.phoneNumber ?? 'N/A'}</Typography>
			</div>
			<div className={classes['col']} onClick={toAdminEdit}>
				<Typography variant={'h6'}>{admin.roleId === 1 ? 'Super Administrator' : 'Administrator'}</Typography>
			</div>
		</div>
	);
};

export default AdminRow;
