import { Nurse } from 'types';
import { Sort } from './types';

export const nurseNameCompareFn = (a: Nurse, b: Nurse, sort: Sort) => {
	if (sort === 'asc') {
		return a.user.name.localeCompare(b.user.name) === -1 ? 1 : -1;
	} else {
		return a.user.name.localeCompare(b.user.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const nurseEmailCompareFn = (a: Nurse, b: Nurse, sort: Sort) => {
	if (sort === 'asc') {
		return a.user.email.localeCompare(b.user.email) === -1 ? 1 : -1;
	} else {
		return a.user.email.localeCompare(b.user.email) === -1 ? -1 : 1;
	}
	// return 0;
};

export const nurseExperienceCompareFn = (a: Nurse, b: Nurse, sort: Sort) => {
	if (a.yearsOfExperience > b.yearsOfExperience) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.yearsOfExperience < b.yearsOfExperience) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
