import React, { FC } from 'react';
import { MoonLoader } from 'react-spinners';
import colors from 'res/colors';
import classes from './index.module.scss';

const LoadingIndicator: FC = () => (
	<div className={classes['loading-indicator']}>
		<MoonLoader size={60} color={colors.tertiaryText} />
	</div>
);

export default LoadingIndicator;
