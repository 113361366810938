import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import moment from 'moment';
import TPicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import classes from './index.module.scss';

interface Props {
	label: string;
	value?: moment.Moment;
	onChange: (value: string) => void;
}

const TimePicker = ({ label, value, onChange, ...rest }: Props) => {
	const { isSmallerDevice } = useWindowResize();

	return (
		<div className={classes['time-picker-group']}>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				{label}:
			</Typography>
			<TPicker
				showSecond={false}
				defaultValue={moment()}
				onChange={(value: moment.Moment) => onChange(value.format('hh:mm A'))}
				className={classes['react-timepicker']}
				inputReadOnly
				use12Hours
				allowEmpty
				format="hh:mm A"
				clearIcon={<div />}
				value={value}
				{...rest}
			/>
		</div>
	);
};

export default TimePicker;
