import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Button, LoadingIndicator, DropdownButton, SearchBar, Pagination } from 'lib/components';
import { HospitalJobsDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectJobs,
	useSelectAuthorization,
	RequestManager,
	useSelectRequest,
} from 'store';
import { JobStatus as JS, JobStatusType as S } from 'lib/util';
import { DropdownItem } from 'lib/components/types';
import { usePagination, useSearch, useWindowResize } from 'hooks';
import classes from './index.module.scss';

const JOB_STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ value: S.PENDING, label: JS.text(S.PENDING) },
	{ value: S.ACCEPTED, label: JS.text(S.ACCEPTED) },
	{ value: S.ACKNOWLEDGED, label: JS.text(S.ACKNOWLEDGED) },
	{ value: S.ARRIVED, label: JS.text(S.ARRIVED) },
	{ value: S.NURSE_ACCEPTED, label: JS.text(S.NURSE_ACCEPTED) },
	{ value: S.NURSE_REJECTED, label: JS.text(S.NURSE_REJECTED) },
	{ value: S.TREATMENT_INITIATED, label: JS.text(S.TREATMENT_INITIATED) },
	{ value: S.TREATMENT_COMPLETED, label: JS.text(S.TREATMENT_COMPLETED) },
	{ value: S.COMPLETED, label: JS.text(S.COMPLETED) },
	{ value: S.ABORTED, label: JS.text(S.ABORTED) },
	{ value: S.CANCELLED, label: JS.text(S.CANCELLED) },
	{ value: S.VOIDED, label: JS.text(S.VOIDED) },
];

const JobsTab = () => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	const { isSmallerDevice } = useWindowResize();

	// Getting hospital id from pathname
	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canCreateJob } = useSelectAuthorization();
	const jobs = useSelectJobs();

	const [query, setQuery] = useState('');
	const [selectedStatusDropdownItem, setSelectedStatusDropdownItem] = useState<number>(-1);

	useEffect(() => {
		dispatch(actions.fetchJobsForHospital(parseInt(hospitalId)));
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;
	}, [active]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(jobs, {
		itemsPerPage: 8,
	});

	useSearch(
		query,
		() => {
			setData(jobs.list);
		},
		() => {
			const result = listing.filter(
				(job) =>
					(job.nurse && job.nurse.user.name.toLowerCase().search(query.toLowerCase()) !== -1) ||
					job.creator.name.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		if (selectedStatusDropdownItem === -1) return;

		const result = listing.filter((job) => job.status === selectedStatusDropdownItem);
		setData(result);
	}, [selectedStatusDropdownItem]);

	const handleStatusDropdownItemSelect = (value: number) => {
		setSelectedStatusDropdownItem(value);
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div className={classes['dispatches-tab-container']}>
			<div className={classes['row']}>
				<div
					style={{
						flex: 1,
						marginRight: isSmallerDevice ? 0 : '2rem',
					}}
				>
					<SearchBar
						query={query}
						setQuery={setQuery}
						onSearch={() => console.log({ query })}
						className={classes['search-input-container']}
						placeholder="Search..."
					/>
				</div>
				<div className={classes['row']} style={{ marginTop: '-0.7rem' }}>
					<Button
						title="Pending"
						className={`${classes['button']} ${classes['pending']}`}
						style={{
							marginTop: '1rem',
						}}
					/>
					<Button
						title="Approved"
						color="dark"
						className={classes['button']}
						style={{
							marginTop: '1rem',
						}}
					/>
					<Button
						title="Rejected"
						color="dark"
						className={classes['button']}
						style={{
							marginRight: '1rem',
							marginTop: '1rem',
						}}
					/>
					{canCreateJob && (
						<button
							className={classes['row']}
							style={{
								marginTop: '1rem',
							}}
							onClick={() => push('/jobs/create')}
						>
							<Typography weight="medium">Add New Job</Typography>
							<div className={classes['plus-icon']}>+</div>
						</button>
					)}
				</div>
			</div>
			<div
				className={classes['row']}
				style={{
					alignItems: 'center',
					marginBottom: '1rem',
				}}
			>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					weight="medium"
					style={{
						marginTop: '1rem',
					}}
				>
					Filter by:
				</Typography>
				<div style={{ margin: '1rem 0.5rem 0 1rem' }}>
					<DropdownButton
						title="Status"
						list={JOB_STATUS_DROPDOWN_ITEMS}
						onItemSelect={handleStatusDropdownItemSelect}
					/>
				</div>
			</div>
			<HospitalJobsDataTable jobs={paginatedData} />
			<Pagination {...paginationProps} />
		</div>
	);
};

export default JobsTab;
