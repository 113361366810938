import React from 'react';
import ReactDom from 'react-dom';
import { Button, Image, Typography } from 'lib/components';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	reason?: string;
	onClose: () => any;
	onUnvoidInvoice: () => any;
}

const UnvoidInvoiceModal = ({ isOpen, onClose, reason, onUnvoidInvoice }: Props) => {
	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div>
						<div className={classes['modal-title']}>
							<Image src="icDangerAlertPrimary" />
							<Typography variant="subheading1" weight="bold">
								Reasoning for Void Invoice:
							</Typography>
						</div>
						<Typography>{reason}</Typography>
					</div>
					<div className={classes['modal-unvoid-footer']}>
						<Button
							style={{
								justifyContent: 'center',
							}}
							variant="standard"
							color="gray"
							sizing="small"
							title="Cancel"
							onClick={onClose}
						/>
						<Button
							variant="standard"
							color="primary"
							title="Unvoid Invoice"
							style={{
								justifyContent: 'center',
							}}
							onClick={() => {
								onUnvoidInvoice();
								onClose();
							}}
						/>
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default UnvoidInvoiceModal;
