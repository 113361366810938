import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { PageLayout, PageWrapper, TopNav, SideNavModal } from 'lib/components/Page';
import validateEmail from 'lib/validateEmail';
import { store } from 'store';
import SocketManager from 'lib/components/SocketManager';
import Routes from 'routes';

const Bootstrap = () => {
	validateEmail('');

	return (
		<Provider store={store}>
			<SocketManager>
				<ToastProvider autoDismiss placement="top-left" autoDismissTimeout={2000}>
					<BrowserRouter>
						<TopNav />
						<SideNavModal />
						<PageWrapper>
							<PageLayout noPadding>
								<Routes />
							</PageLayout>
						</PageWrapper>
					</BrowserRouter>
				</ToastProvider>
			</SocketManager>
		</Provider>
	);
};

export default Bootstrap;
