import { Button } from 'lib/components';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { HospitalService, JobService } from 'types';
import classes from '../index.module.scss';
import ServiceSelect from './ServiceSelect';

interface Props {
	items: Array<HospitalService>;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
	addService: (service: JobService) => void;
	selected: Array<number>;
}

const ServiceInputModal = ({ items, selected, isOpen, setIsOpen, addService, onClose }: Props) => {
	const [serviceId, setServiceId] = useState<number | undefined>();
	const [name, setName] = useState<string | undefined>();
	const [description, setDescription] = useState<string | undefined>();

	useEffect(() => {
		if (!serviceId) return;
		const service = items.find((item) => item.id === serviceId);
		if (service) {
			setDescription(service.service.description);
		}
	}, [serviceId]);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['add-service-modal']}>
			<div className={classes['add-service-modal-content']}>
				<div className={classes['add-service-modal-content--main']}>
					<ServiceSelect
						items={items}
						selected={selected}
						value={serviceId}
						setValue={setServiceId}
						setLabel={setName}
					/>
				</div>
				<div className={classes['add-service-modal-content--footer']}>
					<Button
						variant="standard"
						title={'Add Service'}
						onClick={() => {
							if (name && description && serviceId) {
								addService({ description, serviceId, name });
								setServiceId(undefined);
								setName(undefined);
								setDescription(undefined);
								setIsOpen(false);
							}
						}}
						disabled={!(name && description && serviceId)}
					/>
					<Button color="gray" variant="standard" title="Cancel" onClick={onClose} />
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default ServiceInputModal;
