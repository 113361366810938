const getJobAttributeIgnores = () => [
	'patient_not_ready',
	'patient_not_in_room',
	'access_p',
	'procedure',
	'difficult_access',
	'troubleshoot_access',
	'waiting_other',
	'other',
];

export default getJobAttributeIgnores;
