import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { AvatarResponse, Nurse, NurseCreateInput, NurseUpdateInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Nurse> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'nurses',
	initialState,
	reducers: {
		nursesFetched: (state, { payload }: PA<Nurse[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		nurseFetched: (state, { payload }: PA<Nurse>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		nurseAvatarUploaded: (state, { payload }: PA<{ id: number; avatar: AvatarResponse }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list[index].user.avatar = payload.avatar.path;
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		nurseDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const addNurse = (input: NurseCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE, method: 'POST' }));

		const { data, error } = await Api.nurses.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.NURSE,
					method: 'POST',
					message: 'Nurse added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchNurses());
		}
	};
};

const updateNurse = (id: number, input: NurseUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE_ID(id), method: 'PUT' }));

		const { data, error } = await Api.nurses.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.nurseFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.NURSE_ID(id),
					method: 'PUT',
					message: 'Nurse updated successfully',
					payload: {},
				})
			);
		}
	};
};

const updateNurseAvatar = (id: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { data, error } = await Api.upload.avatar(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.nurseAvatarUploaded({ id, avatar: data! }));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Avatar uploaded successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteNurse = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.nurses.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.nurseDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.NURSE_ID(id),
					method: 'DELETE',
					message: 'Nurse deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

const fetchNurses = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE, method: 'GET' }));

		const { data, error } = await Api.nurses.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.nursesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.NURSE,
					method: 'GET',
					message: 'Nurses fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchNurse = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE_ID(id), method: 'GET' }));

		const { data, error } = await Api.nurses.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.nurseFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.NURSE_ID(id),
					method: 'GET',
					message: 'Nurse fetched successfully',
					payload: {},
				})
			);
		}
	};
};

export const nursesThunks = {
	addNurse,
	updateNurse,
	updateNurseAvatar,
	deleteNurse,
	fetchNurses,
	fetchNurse,
};
