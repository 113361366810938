import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Image, LoadingIndicator, Typography, Pagination } from 'lib/components';
import { HospitalVendorsDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectVendors,
	useSelectRestrictedVendors,
	useSelectRequest,
	useSelectAuthorization,
	RequestManager,
} from 'store';
import RestrictVendorModal from './RestrictVendorModal';
import routes from 'lib/api/routes';
import { useWindowResize, useCustomToast, useRestrictedVendorsPagination } from 'hooks';
import { AllCheckedStatus } from 'types';
import classes from './index.module.scss';

const VendorsTab = () => {
	const { pathname } = useLocation();
	const toast = useCustomToast();
	const { isSmallerDevice } = useWindowResize();

	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { canUpdateHospital } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const vendors = useSelectVendors();
	const restrictedVendors = useSelectRestrictedVendors();

	const [checked, setChecked] = useState<number[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [allCheckedStatus, setAllCheckedStatus] = useState<AllCheckedStatus>('none');
	const [showRestrictVendorModal, setShowRestrictVendorModal] = useState(false);

	const toggleRestrictVendorModal = () => setShowRestrictVendorModal((prev) => !prev);

	useEffect(() => {
		dispatch(actions.fetchVendors());
		dispatch(actions.fetchRestrictedVendors(parseInt(hospitalId)));
	}, []);

	const { isDataFetched, listing, restricted, paginatedData, paginationProps } = useRestrictedVendorsPagination(
		vendors,
		restrictedVendors
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL_VENDOR_RESTRICT(parseInt(hospitalId)), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}

		if (RM.req(routes.HOSPITAL_VENDOR_UNRESTRICT(parseInt(hospitalId)), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setAllChecked(false);
				setAllCheckedStatus('none');
			}
		}
	}, [active]);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		if (allCheckedStatus === 'all') setChecked(restricted);
		if (allCheckedStatus === 'none') setChecked([]);
	}, [allCheckedStatus]);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (allCheckedStatus !== 'some') {
			setAllChecked(false);
			setAllCheckedStatus('some');
		}

		if (isChecked && !checked.includes(id)) {
			setChecked((prev) => [...prev, id]);
			return;
		}

		if (!isChecked && checked.includes(id)) {
			setChecked((prev) => prev.filter((item) => item !== id));
		}
	};

	const handleAllCheckAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		if (checked) {
			setAllChecked(checked);
			setAllCheckedStatus('all');
		} else {
			{
				setAllChecked(checked);
				setAllCheckedStatus('none');
			}
		}
	};

	const handleRestrictVendorsAction = (ids: number[]) => {
		dispatch(actions.restrictVendors(parseInt(hospitalId), ids));
		setShowRestrictVendorModal(false);
	};

	const handleUnRestrictVendorsAction = () => {
		dispatch(actions.unRestrictVendors(parseInt(hospitalId), checked));
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<RestrictVendorModal
				vendors={listing}
				restricted={restricted}
				isOpen={showRestrictVendorModal}
				action={handleRestrictVendorsAction}
				onClose={toggleRestrictVendorModal}
			/>
			<div className={classes['inventories-tab-container']}>
				<div
					className={classes['row']}
					style={{
						justifyContent:
							allCheckedStatus !== 'none' && checked.length !== 0 ? 'space-between' : 'flex-end',
					}}
				>
					{allCheckedStatus !== 'none' && checked.length !== 0 && (
						<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
							{canUpdateHospital && (
								<a onClick={handleUnRestrictVendorsAction} className={classes['button-cancel']}>
									<Typography
										className={classes['button-cancel-title']}
										variant={isSmallerDevice ? 'h5' : 'h6'}
										weight="regular"
										style={{ cursor: 'pointer' }}
									>
										Unrestrict Vendors
									</Typography>
									<span className={classes['button-cancel-icon']}>
										<Image src="icCancelRed" />
									</span>
								</a>
							)}
						</div>
					)}
					<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
						{canUpdateHospital && (
							<a onClick={toggleRestrictVendorModal} className={classes['button-add']}>
								<Typography
									className={classes['button-add-title']}
									variant={isSmallerDevice ? 'h5' : 'h6'}
									weight="regular"
									style={{ cursor: 'pointer' }}
								>
									Restrict Vendors
								</Typography>
								<span className={classes['button-add-icon']}>
									<Image src="icPlusPrimary" />
								</span>
							</a>
						)}
						{/* <button
							className={classes['button']}
							style={{
								minWidth: 70,
								backgroundColor: '#E4F4F6',
								borderColor: '#A6E5E9',
								justifyContent: 'space-evenly',
							}}
						>
							<Image
								src="icExport"
								style={{
									marginRight: '0.5rem',
								}}
							/>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
								Export
							</Typography>
						</button> */}
					</div>
				</div>
				<HospitalVendorsDataTable
					vendors={paginatedData}
					allChecked={allChecked}
					allCheckedStatus={allCheckedStatus}
					handleAllCheckAction={handleAllCheckAction}
					checkAction={handleCheckAction}
				/>
				<Pagination {...paginationProps} />
			</div>
		</>
	);
};

export default VendorsTab;
