import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Image, LoadingIndicator } from 'lib/components';
import { DatePicker } from 'lib/components/FormFields';
import {
	useAppDispatch,
	actions,
	useSelectRequest,
	useSelectHospitals,
	useSelectHospital,
	RequestManager,
} from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { Timing } from 'lib/util';
import classes from './index.module.scss';

interface Props {
	hospitalId: number;
}

const EmployeeCreateScreen = () => {
	const { hospitalId } = useParams<{ hospitalId: string }>();

	const dispatch = useAppDispatch();
	const hospitals = useSelectHospitals();
	const getHospitalById = useSelectHospital();

	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		dispatch(actions.fetchHospitals());
	}, []);

	useEffect(() => {
		const hospital = getHospitalById(parseInt(hospitalId));

		if (hospital) {
			setIsValid(true);
			return;
		}
	}, [hospitals.updatedAt]);

	return isValid ? <EmployeeCreateScreenView hospitalId={parseInt(hospitalId)} /> : <LoadingIndicator />;
};

const EmployeeCreateScreenView = ({ hospitalId }: Props) => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL_EMPLOYEE(hospitalId), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push(`/hospitals/${hospitalId}/employees`);
			}
		}
	}, [active]);

	useComprehensiveTopNav('New Hospital Employee');

	const handleCreate = () => {
		dispatch(
			actions.addEmployee(hospitalId, {
				name,
				email,
				phoneNumber,
				dateOfBirth: Timing.formatDate(dateOfBirth),
				password,
			})
		);
	};

	return (
		<div
			className={classes['profile-container']}
			style={{
				flexDirection: 'column',
			}}
		>
			<>
				<div className={classes['top-container']}>
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<Image src={'plAvatar'} style={{ width: '100%', height: '100%' }} />
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Name:
							</Typography>
							<input
								className={classes['input']}
								style={{
									width: '15rem',
									minWidth: 250,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div
					className={classes['bottom-container']}
					style={{
						padding: isSmallDevice ? '1rem' : undefined,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography weight="bold">Basic info:</Typography>
						<div className={classes['row']}>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Mobile number:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
									}}
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Email:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 180,
									}}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<DatePicker label="Date of Birth" value={dateOfBirth} setValue={setDateOfBirth} />
							</div>
						</div>
						<div className={classes['row']} style={{ marginTop: '1.5rem' }}>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							{/* <div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Confirm Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={newPasswordConfirmation}
									onChange={(e) => setNewPasswordConfirmation(e.target.value)}
								/>
							</div> */}
						</div>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						margin: 'auto 0 1rem 1rem',
					}}
				>
					<button
						className={classes['button']}
						style={{
							width: 'auto',
							marginRight: '1rem',
						}}
						onClick={handleCreate}
					>
						<p>Add Employee</p>
					</button>
					<button
						className={`${classes['button']} ${classes['palewhite']}`}
						style={{
							width: 'auto',
						}}
						onClick={() => push(`/hospitals/${hospitalId}/employees`)}
					>
						<p>Cancel</p>
					</button>
				</div>
			</>
		</div>
	);
};

export default EmployeeCreateScreen;
