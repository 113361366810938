import useCustomToast from 'hooks/toast';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import routes from 'lib/api/routes';
import { Image, LoadingIndicator, Typography } from 'lib/components';
import { DatePicker, TextField } from 'lib/components/FormFields';
import Tag from 'lib/components/Tag';
import AddTag from 'lib/components/Tag/AddMore';
import { JobTreatmentType, Timing } from 'lib/util';
import { nanoid as ID } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectCustomCosts,
	useSelectHospitalServices,
	useSelectInventory,
	useSelectRequest,
} from 'store';
import { JobCustomCost, JobInventory, JobService } from 'types';
import JobType from '../../lib/util/JobType';
import CustomCostInputModal from './CustomCostInputModal';
import HospitalSelect from './HospitalSelect';
import classes from './index.module.scss';
// import SymbolToggle from './SymbolToggle';
// import SelectNewJobServiceModal from './SelectNewJobServiceModal';
// import SelectCostAmplifierModal from './SelectCostAmplifierModal';
import InventoryInputModal from './InventoryInputModal';
import JobCustomCostList from './JobCustomCostList';
import NurseSelect from './NurseSelect';
import PointOfContactSelect from './PointOfContactSelect';
import PrioritySelect from './PrioritySelect';
import ServiceInputModal from './ServiceInputModal';
import TreatmentTypeSelect from './TreatmentTypeSelect';

const CreateJobScreen: React.FC = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const containerRef = useRef<HTMLDivElement>(null);
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { isSuperAdmin } = useSelectAuthorization();
	const items = useSelectInventory();
	const serviceState = useSelectHospitalServices();
	const customCostState = useSelectCustomCosts();
	const { active } = useSelectRequest();

	const [nurseId, setNurseId] = useState<number | undefined>();
	const [typeId, setTypeId] = useState<JobType>(JobType.ROUTINE);
	const [treatmentType, setTreatmentType] = useState<JobTreatmentType>(JobTreatmentType.HD);
	const [hospitalId, setHospitalId] = useState<number | undefined>();
	const [contactId, setContactId] = useState<number | undefined>();
	const [description, setDescription] = useState('');
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [nurseStartedAt, setNurseStartedAt] = useState<Date | null>(null);
	const [nurseFinishedAt, setNurseFinishedAt] = useState<Date | null>(null);
	const [patientName, setPatientName] = useState('');
	const [roomNumber, setRoomNumber] = useState('');
	const [patientId, setPatientId] = useState('');
	const [orderingPhysician, setOrderingPhysician] = useState('');
	const [requirements, setRequirements] = useState<Record<string, boolean>>({});
	const [isInventoryInputModalOpen, setIsInventoryInputModalOpen] = useState(false);
	const [inventory, setInventory] = useState<JobInventory[]>([]);
	const [services, setServices] = useState<JobService[]>([]);
	const [initialCostAmplifiers, setInitialCostAmplifiers] = useState<JobCustomCost[]>([]);
	const [customCosts, setCustomCosts] = useState<JobCustomCost[]>([]);
	const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
	const [selectedServices, setSelectedServices] = useState<Array<number>>([]);
	const [selectedCustomCosts, setSelectedCustomCosts] = useState<Array<number>>([]);
	const [editableItem, setEditableItem] = useState<JobInventory>();
	const [fetchedCount, setFetchedCount] = useState(0);
	const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
	const [isCostAmpModalOpen, setIsCostAmpModalOpen] = useState(false);

	useEffect(() => {
		dispatch(actions.fetchServices());
		dispatch(actions.fetchNurses());
		dispatch(actions.fetchInventory());
		dispatch(actions.fetchCustomCosts());
		dispatch(actions.fetchHospitals());
	}, []);

	useEffect(() => {
		if (!hospitalId) return;

		dispatch(actions.fetchHospitalServices(hospitalId));
		dispatch(actions.fetchEmployees(hospitalId));
	}, [hospitalId]);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.INVENTORY, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.NURSE, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.SERVICE, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.CUSTOM_COST, 'GET')) {
			if (RM.succeeded()) {
				const initialCosts: JobCustomCost[] = customCostState.list
					.slice(0, 4)
					.map((cost) => ({ customCostId: cost.id, name: cost.name }));
				setInitialCostAmplifiers(initialCosts);
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.JOB, 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/jobs');
			}
		}
	}, [active]);

	useEffect(() => {
		if (inventory.length === 0) return;

		const ids = inventory.map(({ inventoryId }) => inventoryId);
		setSelectedItems(ids);
	}, [inventory]);

	useEffect(() => {
		if (services.length === 0) return;

		const ids = services.map(({ serviceId }) => serviceId);
		setSelectedServices(ids);
	}, [services]);

	useEffect(() => {
		if (initialCostAmplifiers.length === 0) return;

		const ids = initialCostAmplifiers.map(({ customCostId }) => customCostId);
		setSelectedCustomCosts(ids);
	}, [initialCostAmplifiers]);

	const requirementsToArray = (requirements_: Record<string, boolean>) => {
		return Object.entries(requirements_)
			.filter(([, value]) => value)
			.map(([requirement]) => requirement);
	};

	const addRequirement = (requirement: string) => {
		setRequirements((requirements) => ({ ...requirements, [requirement]: true }));
	};

	const removeRequirement = (requirement: string) => {
		setRequirements((requirements) => ({ ...requirements, [requirement]: false }));
	};

	const updateRequirement = (prev: string, cur: string) => {
		setRequirements((requirements_) => {
			const temp = { ...requirements_ };
			delete temp[prev];
			temp[cur] = true;
			return temp;
		});
	};

	const addInventory = (inventory: JobInventory) => {
		setInventory((items) => [...items, inventory]);
	};

	const editInventory = (inventory: JobInventory) => {
		setInventory((items) => [...items.filter((item) => item.inventoryId !== inventory.inventoryId), inventory]);
		setEditableItem(undefined);
	};

	const onInventoryClick = (inventory: JobInventory) => {
		setEditableItem(inventory);
		setIsInventoryInputModalOpen(true);
	};

	const removeInventory = (id: number) => {
		setInventory((items) => items.filter((item) => item.inventoryId !== id));
	};

	const addService = (service: JobService) => {
		setServices((items) => [...items, service]);
	};

	const removeService = (id: number) => {
		setServices((items) => items.filter((item) => item.serviceId !== id));
	};

	const addCustomCostAmplifier = (cost: JobCustomCost) => {
		setInitialCostAmplifiers((items) => [...items, cost]);
	};

	const updateCustomCosts = (cost: JobCustomCost, checked: boolean) => {
		if (checked) {
			setCustomCosts((items) => [...items, cost]);
		} else {
			setCustomCosts((items) => items.filter((item) => item.customCostId !== cost.customCostId));
		}
	};

	const handleCreateJob = () => {
		dispatch(
			actions.addJob({
				typeId,
				treatmentType,
				hospitalId: hospitalId as number,
				description,
				inventory,
				customCosts,
				services,
				nurseId,
				requirements: requirementsToArray(requirements),
				contactId,
				startingAt: Timing.formatDateToDateTime(startDate),
				nurseStartedAt: nurseStartedAt ? Timing.formatDateToDateTime(nurseStartedAt) : null,
				nurseFinishedAt: nurseFinishedAt ? Timing.formatDateToDateTime(nurseFinishedAt) : null,
				patientId: patientId !== '' ? patientId : undefined,
				patientName: patientName !== '' ? patientName : undefined,
				roomNumber: roomNumber !== '' ? roomNumber : undefined,
				orderingPhysician: orderingPhysician !== '' ? orderingPhysician : undefined,
			})
		);
	};

	useComprehensiveTopNav('Create a new Job');

	if (fetchedCount < 5) return <LoadingIndicator />;

	return (
		<>
			<InventoryInputModal
				items={items.list}
				item={editableItem}
				selected={selectedItems}
				addInventory={addInventory}
				editInventory={editInventory}
				isOpen={isInventoryInputModalOpen}
				setIsOpen={setIsInventoryInputModalOpen}
				onClose={() => {
					setEditableItem(undefined);
					setIsInventoryInputModalOpen(false);
				}}
			/>
			<ServiceInputModal
				isOpen={isServiceModalOpen}
				setIsOpen={setIsServiceModalOpen}
				items={serviceState.list}
				selected={selectedServices}
				addService={addService}
				onClose={() => setIsServiceModalOpen(false)}
			/>
			<CustomCostInputModal
				isOpen={isCostAmpModalOpen}
				setIsOpen={setIsCostAmpModalOpen}
				onClose={() => setIsCostAmpModalOpen(false)}
				addCustomCost={addCustomCostAmplifier}
				items={customCostState.list}
				selected={selectedCustomCosts}
			/>
			<div className={classes['dispatch-create-container']} ref={containerRef}>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					weight="medium"
					style={{
						marginTop: '1rem',
					}}
				>
					Job Information
				</Typography>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<div className={classes['row']}>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Hospital Name
							</Typography>
							<HospitalSelect value={hospitalId} setValue={setHospitalId} />
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Priority
							</Typography>
							<PrioritySelect value={typeId} setValue={setTypeId} />
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Treatment Type
							</Typography>
							<TreatmentTypeSelect value={treatmentType} setValue={setTreatmentType} />
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Point of Contact
							</Typography>
							<PointOfContactSelect value={contactId} setValue={setContactId} />
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={startDate}
								label="Start Date/Time"
								setValue={setStartDate}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={nurseStartedAt}
								label="Nurse Started At"
								setValue={setNurseStartedAt}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={nurseFinishedAt}
								label="Nurse Finished At"
								setValue={setNurseFinishedAt}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
					</div>
					<NurseSelect value={nurseId} setValue={setNurseId} />
				</div>
				<div className={classes['row']} style={{ alignItems: 'flex-start', marginTop: '1rem' }}>
					<div style={{ margin: '0.5rem 0.5rem' }}>
						<TextField
							value={patientId}
							onChange={(e) => setPatientId(e.target.value)}
							color="primary"
							variant="labelled"
							label="Patient ID:"
						/>
					</div>
					<div style={{ margin: '0.5rem 0.5rem' }}>
						<TextField
							value={patientName}
							onChange={(e) => setPatientName(e.target.value)}
							color="primary"
							variant="labelled"
							label="Patient Name:"
						/>
					</div>
					<div style={{ margin: '0.5rem 0.5rem' }}>
						<TextField
							value={roomNumber}
							onChange={(e) => setRoomNumber(e.target.value)}
							color="primary"
							variant="labelled"
							label="Room Number:"
						/>
					</div>
					<div style={{ margin: '0.5rem 0.5rem' }}>
						<TextField
							value={orderingPhysician}
							onChange={(e) => setOrderingPhysician(e.target.value)}
							color="primary"
							variant="labelled"
							label="Ordering Physician:"
						/>
					</div>
				</div>
				<div className={classes['row']} style={{ alignItems: 'flex-start' }}>
					<div style={{ flex: 2, paddingRight: '4rem' }}>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							color="darkgrey"
							style={{ marginTop: '2rem' }}
						>
							Description:
						</Typography>
						<textarea
							className={classes['textarea']}
							placeholder="Enter the description..."
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							weight="medium"
							style={{
								marginTop: '2rem',
							}}
						>
							Available Inventory for Job
						</Typography>
						<div
							className={classes['container']}
							style={{
								width: '100%',
								height: 'fit-content',
								paddingBottom: '0.5rem',
								alignItems: 'flex-start',
								flexWrap: 'wrap',
							}}
						>
							<div
								style={{
									flex: 1,
									minWidth: 200,
									marginRight: '1rem',
								}}
							>
								<div
									className={classes['row']}
									style={{
										justifyContent: 'space-between',
										margin: '1rem 0',
										padding: '0 3rem',
									}}
								>
									<div className={classes['row']}>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="light"
											style={{
												marginRight: '0.5rem',
											}}
										>
											Name
										</Typography>
										<Image src="icDownCaret" />
									</div>
									<div className={classes['row']}>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="light"
											style={{
												marginRight: '0.5rem',
											}}
										>
											Quantity
										</Typography>
										<Image src="icDownCaret" />
									</div>
								</div>
								{inventory.map(({ name, amount, inventoryId }) => (
									<div className={classes['inventory-row']} key={ID()}>
										<div
											className={classes['inventory-item']}
											onClick={() => onInventoryClick({ name, amount, inventoryId })}
										>
											<Typography
												variant={isSmallerDevice ? 'h5' : 'h6'}
												style={{ marginLeft: '1.5rem' }}
											>
												{name}
											</Typography>
											<Typography
												variant={isSmallerDevice ? 'h5' : 'h6'}
												style={{ marginRight: '4.5rem' }}
											>
												{amount}
											</Typography>
										</div>
										<Image
											src="icTrashDanger"
											className={classes['inventory-row-trash']}
											onClick={() => removeInventory(inventoryId)}
										/>
									</div>
								))}

								<button
									className={classes['badge']}
									style={{
										margin: '0 1rem 1rem 0',
										backgroundColor: '#F5F5F5',
										display: 'inline-block',
										width: '50%',
										textAlign: 'center',
									}}
									onClick={() => setIsInventoryInputModalOpen(true)}
								>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>+ Add New Item</Typography>
								</button>
							</div>
						</div>
						<div className={classes['jobServices']}>
							<Typography variant="h6" weight="bold">
								Services Provided for Job
							</Typography>
							<div className={classes['jobServices-table']}>
								<div className={classes['jobServices-tableHeader']}>
									<div className={classes['jobServices-tableHeaderItem']}>
										<Typography color="darkgrey" variant="h6">
											Title
										</Typography>
										<Image src="icCaretBlack" />
									</div>
									<div className={classes['jobServices-tableHeaderItem']}>
										<Typography color="darkgrey" variant="h6">
											Description
										</Typography>
										<Image src="icCaretBlack" />
									</div>
								</div>
								<div className={classes['jobServices-tableBody']}>
									{services.map(({ serviceId, name, description }) => (
										<div
											key={serviceId}
											className={classes['jobServices-tableRow']}
											style={{ margin: '0.5rem 0' }}
										>
											<div>
												<Typography variant="h6">{name}</Typography>
											</div>
											<div>
												<Typography variant="h6">{description}</Typography>
											</div>
											<div className={classes['jobServices-tableRowActions']}>
												<Image src="icTrashDanger" onClick={() => removeService(serviceId)} />
											</div>
										</div>
									))}
								</div>
								<div className={classes['jobServices-tableFooter']}>
									<a
										onClick={() => setIsServiceModalOpen(true)}
										className={classes['jobServices-tableFooterButton']}
									>
										<Typography variant="h6">+ Add New Service</Typography>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div style={{ flex: 1 }}>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							color="darkgrey"
							style={{ marginTop: '2rem', marginBottom: '1rem' }}
						>
							Requirements
						</Typography>
						<div className={classes['requirement-tags']}>
							{requirementsToArray(requirements).map((requirement) => (
								<Tag
									initialTitle={requirement}
									key={requirement}
									onValueChanged={(value) => updateRequirement(value.prev, value.cur)}
									onRemove={(requirement_) => removeRequirement(requirement_)}
								/>
							))}
							<AddTag onAddMore={(requirement_) => addRequirement(requirement_)} />
						</div>
						<div className={classes['costAmplifiers']}>
							<Typography weight="bold" variant="h6">
								Custom Cost Amplifiers
							</Typography>
							<JobCustomCostList items={initialCostAmplifiers} updateCustomCosts={updateCustomCosts} />
							<div className={classes['costAmplifiers-buttons']}>
								<a
									onClick={() => setIsCostAmpModalOpen(true)}
									className={classes['costAmplifiers-button']}
								>
									<Typography>Select New</Typography>
								</a>
								{isSuperAdmin && (
									<a
										onClick={() => push('/settings/cost-amplifier/add')}
										className={classes['costAmplifiers-button']}
									>
										<Typography>Create New</Typography>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className={classes['row']} style={{ marginTop: '2rem' }}>
					<button
						className={classes['container']}
						style={{
							backgroundColor: '#E4F4F6',
							marginRight: '1rem',
						}}
						onClick={handleCreateJob}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Create Job</Typography>
					</button>
					<button className={classes['container']} onClick={() => push('/jobs')}>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Cancel</Typography>
					</button>
				</div>
			</div>
		</>
	);
};

export default CreateJobScreen;
