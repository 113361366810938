import { useAddIdPrefix } from 'hooks';
import { Avatar, Image, Typography } from 'lib/components';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { useSelectCurrentUser } from 'store';
import { Conversation, Message } from 'types';
import ChatMessages from './ChatMessages';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => any;
	conversation: Conversation;
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
	onMessageSend: () => void;
	onMessagesRead: () => void;
}

const ChatPopup = ({ isOpen, onClose, message, setMessage, onMessageSend, onMessagesRead, conversation }: Props) => {
	const addPrefix = useAddIdPrefix();
	const me = useSelectCurrentUser();

	const chatRef = React.useRef<HTMLDivElement>(null);

	const [messages, setMessages] = useState<Array<Message>>([...conversation.messages]);

	useEffect(() => {
		const newMessages = [...conversation.messages];
		// if (newMessages.every((e) => typeof e.id === 'number')) {
		// newMessages.sort((a, b) => (a.id as number) - (b.id as number));
		// }

		setMessages(newMessages);

		setTimeout(() => {
			if (chatRef.current) {
				chatRef.current.scrollTo({
					left: 0,
					top: chatRef.current.scrollHeight,
					behavior: 'smooth',
				});
			}
		}, 250);
	}, [conversation.messages]);

	useEffect(() => {
		if (!isOpen) {
			return;
		}

		const listener = (e: Event) => {
			console.log(e);
		};

		if (chatRef.current) {
			chatRef.current.addEventListener('scroll', listener);
		}

		return () => {
			if (chatRef.current) {
				chatRef.current.removeEventListener('scroll', listener);
			}
		};
	}, [chatRef, isOpen]);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['chatPopup']}>
			<div className={classes['chatPopup-top']}>
				<a onClick={onClose} className={classes['chatPopup-close']}>
					<Typography>Close Chat</Typography>
					<Image src="icCloseChat" />
				</a>
			</div>
			<div className={classes['chatPopup-actions']}>
				{conversation.nurse !== null && conversation.nurse!.phoneNumber !== undefined && (
					<a className={classes['chatPopup-action']} href={`tel:${conversation.nurse.phoneNumber}`}>
						<Image src="icTelephonePrimary" />
						<Typography weight="bold">Call</Typography>
					</a>
				)}
				{conversation.nurse !== null && conversation.nurse!.email !== undefined && (
					<a className={classes['chatPopup-action']} href={`mailto:${conversation.nurse.email}`}>
						<Image src="icMailPrimary" />
						<Typography weight="bold">Email</Typography>
					</a>
				)}
			</div>
			<div className={classes['chatPopup-userInfo']}>
				<div className={classes['chatPopup-avatar']}>
					<Avatar src={conversation.nurse?.avatar ?? 'plAvatar'} size={87} />
					{/* <div className={classes['chatPopup-avatar-dot']} /> */}
				</div>
				<div className={classes['chatPopup-userInfoDetail']}>
					<Typography variant="h4" weight="bold">
						{conversation.nurse?.name ?? '-'}
					</Typography>
					{/* <Typography>On route to Job {addPrefix(conversation.jobId)}</Typography> */}
					<Typography>{conversation.nurse!.email}</Typography>
				</div>
			</div>
			<div ref={chatRef} className={classes['chatPopup-chat']}>
				<div className={classes['chatPopup-chatPeriod']}>
					{/* <Typography className={classes['chatPopup-chatPeriod-label']}>Friday, 11.45AM</Typography> */}
					<ChatMessages messages={messages} userId={me.id} />
				</div>
			</div>
			<div className={classes['chatPopup-footer']}>
				<div className={classes['chatPopup-chatTextField']}>
					{/* <Image src="icAttachmentPrimary" /> */}
					<textarea
						placeholder="Type your message"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					{/* <Image src="icEmoji" /> */}
					<Image src="icStyledSend" onClick={onMessageSend} />
				</div>
			</div>
		</div>,
		document.getElementById('chat-popup') as HTMLElement
	);
};

export default ChatPopup;
