import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Image, ProgressBar } from 'lib/components';
import { useSelectAuthorization } from 'store';
import { JobStatus } from 'lib/util';
import { Job } from 'types';
import { useAddIdPrefix, useWindowResize } from 'hooks';
import classes from './index.module.scss';

interface Props {
	job: Job;
	toViewJob: (job: Job, openChat?: boolean) => void;
}

const JobRow = ({ job, toViewJob }: Props) => {
	const { push } = useHistory();
	const addPrefix = useAddIdPrefix();
	const { isSmallerDevice } = useWindowResize();
	const { canReadJob } = useSelectAuthorization();

	return (
		<div className={classes['table-row']} onClick={() => canReadJob && push(`/jobs/view/${job.id}`)}>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{addPrefix(job.id)}
				</Typography>
			</div>
			<div className={classes['col']}>
				<div className={classes['dashboard-activeDispatches-currentStatus']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{JobStatus.progressText(job.status)}
					</Typography>
					<ProgressBar valuePercentage={JobStatus.progressPercentage(job.status)} />
				</div>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{job.roomNumber ?? '-'}
				</Typography>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-assignedNurse']}>
					<Image src="icNurses" style={{ width: '1rem' }} />
					<Typography weight="regular" variant={'h6'}>
						{job.orderingPhysician ?? '-'}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-createdBy']}>
					<Image src="icUser" />
					<Typography weight="regular" variant={'h6'}>
						{job.patientName ?? '-'}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-assignedNurse']}>
					<Image src="icNurses" style={{ width: '1rem' }} />
					<Typography weight="regular" variant={'h6'}>
						{job.nurse?.user?.name ?? '-'}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-startTime']}>
					<Image src="icClockSm" />
					<Typography weight="regular" variant={'h6'}>
						{job.startingAt}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{JobStatus.isChatOpen(job.status) ? (
						<div
							onClick={(e) => {
								e.stopPropagation();
								toViewJob(job, true);
							}}
							className={classes['dashboard-activeDispatches-chatItem']}
						>
							<Image src={'icComment'} />
						</div>
					) : (
						<span>-</span>
					)}
				</Typography>
			</div>
		</div>
	);
};

export default JobRow;
