import React, { useCallback, useState } from 'react';
import ReactDom from 'react-dom';
import classes from './index.module.scss';
import DatePicker from 'react-datepicker';
import { Image, Typography } from '..';
import Button from '../Button';

interface BodyProps {
	startDate: Date | null;
	endDate: Date | null;
	setStartDate: (date: Date) => any;
	setEndDate: (date: Date) => any;
}

const CustomDatePickerBody = ({ startDate, endDate, setStartDate, setEndDate }: BodyProps) => {
	const onChange = (dates: [start: Date, end: Date]) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const customHeader = useCallback(
		({
			date,
			decreaseMonth,
			increaseMonth,
		}: {
			date: Date;
			decreaseMonth: () => any;
			increaseMonth: () => any;
		}) => (
			<div
				style={{
					margin: 10,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div onClick={decreaseMonth} className={classes['dpc-btn']}>
					<Image src="icChevronLeft" />
				</div>
				<Typography variant="body1">
					{
						[
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December',
						][(date as Date).getMonth()]
					}{' '}
					{(date as Date).getFullYear()}
				</Typography>
				<div onClick={increaseMonth} className={classes['dpc-btn']}>
					<Image src="icCheveronRight" />
				</div>
			</div>
		),
		[]
	);

	return (
		<DatePicker
			autoFocus
			startDate={startDate}
			endDate={endDate}
			onChange={onChange}
			renderCustomHeader={customHeader}
			dateFormat="dd/MM/yyyy"
			calendarClassName={classes['dpc']}
			dayClassName={() => classes['dpc-dayName']}
			weekDayClassName={() => classes['dpc-weekName']}
			timeClassName={() => classes['dpc-timeName']}
			customInput={<input style={{ display: 'none' }} />}
			selectsRange
			inline
		/>
	);
};

interface ModalProps {
	isOpen: boolean;
	onClose: () => any;
	onDatesSet: (startDate: Date, endDate: Date) => any;
}

const CustomRangedDatePickerModal = ({ isOpen, onClose, onDatesSet }: ModalProps) => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	if (!isOpen) {
		return null;
	}

	// const headingText =
	// 	startDate === null ? 'Select start date' : endDate === null ? 'Select end date' : 'You can now submit';

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-heading']}>
					<Typography>Pick Invoice Dates</Typography>
				</div>
				<div className={classes['modal-calendar']}>
					<CustomDatePickerBody
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
				</div>
				<div className={classes['modal-footer']}>
					<Button
						variant="standard"
						color="primary"
						title="Accrue Invoice"
						disabled={!(startDate && endDate)}
						onClick={() => {
							onDatesSet(startDate!, endDate!);
							onClose();
						}}
					/>
					<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default CustomRangedDatePickerModal;
