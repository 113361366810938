import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Vendor, VendorInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Vendor> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'vendors',
	initialState,
	reducers: {
		vendorsFetched: (state, { payload }: PA<Vendor[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		vendorFetched: (state, { payload }: PA<Vendor>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		vendorDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const addVendor = (input: VendorInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.VENDOR, method: 'POST' }));

		const { data, error } = await Api.vendors.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.VENDOR,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.VENDOR,
					method: 'POST',
					message: 'Vendor added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchVendors());
		}
	};
};

const updateVendor = (id: number, input: VendorInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.VENDOR_ID(id), method: 'PUT' }));

		const { data, error } = await Api.vendors.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.VENDOR_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.vendorFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.VENDOR_ID(id),
					method: 'PUT',
					message: 'Vendor updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteVendor = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.VENDOR_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.vendors.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.VENDOR_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.vendorDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.VENDOR_ID(id),
					method: 'DELETE',
					message: 'Vendor deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

const fetchVendors = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.VENDOR, method: 'GET' }));

		const { data, error } = await Api.vendors.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.VENDOR,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.vendorsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.VENDOR,
					method: 'GET',
					message: 'Vendors fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchVendor = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.VENDOR_ID(id), method: 'GET' }));

		const { data, error } = await Api.vendors.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.VENDOR_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.vendorFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.VENDOR_ID(id),
					method: 'GET',
					message: 'Vendors fetched successfully',
					payload: {},
				})
			);
		}
	};
};

export const vendorsThunks = {
	addVendor,
	updateVendor,
	deleteVendor,
	fetchVendors,
	fetchVendor,
};
