import useCustomToast from 'hooks/toast';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import routes from 'lib/api/routes';
import { Image, LoadingIndicator, Typography } from 'lib/components';
import { DatePicker, TextField } from 'lib/components/FormFields';
import Tag from 'lib/components/Tag';
import AddTag from 'lib/components/Tag/AddMore';
import { JobTreatmentType, Timing } from 'lib/util';
import { nanoid as ID } from 'nanoid';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory, useParams } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectCustomCosts,
	useSelectHospitalServices,
	useSelectInventory,
	useSelectJobs,
	useSelectRequest,
} from 'store';
import { Job, JobCustomCost, JobInventory, JobService } from 'types';
import JobType from '../../lib/util/JobType';
import AddNoteModal from './AddNoteModal';
import CustomCostInputModal from './CustomCostInputModal';
import FileList from './FileList';
import HospitalSelect from './HospitalSelect';
import classes from './index.module.scss';
import InventoryInputModal from './InventoryInputModal';
import JobCustomCostList from './JobCustomCostList';
import JobStatusSelect from './JobStatusSelect';
import Notes from './Notes';
import NurseSelect from './NurseSelect';
import PointOfContactSelect from './PointOfContactSelect';
import PrioritySelect from './PrioritySelect';
import ServiceInputModal from './ServiceInputModal';
import TreatmentTypeSelect from './TreatmentTypeSelect';

interface Props {
	jobId: number;
}

const EditJobScreen = () => {
	const { id } = useParams<{ id: string }>();

	return <EditJobScreenView jobId={parseInt(id)} />;
};

const EditJobScreenView = ({ jobId }: Props) => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const jobs = useSelectJobs();
	const containerRef = React.useRef<HTMLDivElement>(null);
	const { isSmallerDevice, width } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { isSuperAdmin } = useSelectAuthorization();
	const serviceState = useSelectHospitalServices();
	const customCostState = useSelectCustomCosts();
	const items = useSelectInventory();

	const documentInputRef = React.useRef<HTMLInputElement>(null);

	const [job, setJob] = React.useState<Job>();
	const [nurseId, setNurseId] = React.useState<number | undefined>();
	const [typeId, setTypeId] = React.useState<JobType | undefined>();
	const [treatmentType, setTreatmentType] = React.useState<JobTreatmentType>(JobTreatmentType.HD);
	const [hospitalId, setHospitalId] = React.useState<number | undefined>();
	const [contactId, setContactId] = React.useState<number | undefined>();
	const [status, setStatus] = React.useState<number | undefined>();
	const [description, setDescription] = React.useState('');
	const [startDate, setStartDate] = React.useState<Date>(new Date());
	const [nurseStartedAt, setNurseStartedAt] = React.useState<Date | null>(null);
	const [nurseFinishedAt, setNurseFinishedAt] = React.useState<Date | null>(null);
	const [patientName, setPatientName] = React.useState('');
	const [roomNumber, setRoomNumber] = React.useState('');
	const [patientId, setPatientId] = React.useState('');
	const [orderingPhysician, setOrderingPhysician] = React.useState('');
	const [requirements, setRequirements] = React.useState<Record<string, boolean>>({});

	const [fetchedCount, setFetchedCount] = React.useState(0);
	const [isInventoryInputModalOpen, setIsInventoryInputModalOpen] = React.useState(false);
	const [inventory, setInventory] = React.useState<JobInventory[]>([]);
	const [services, setServices] = React.useState<JobService[]>([]);
	const [initialCostAmplifiers, setInitialCostAmplifiers] = React.useState<JobCustomCost[]>([]);
	const [customCosts, setCustomCosts] = React.useState<JobCustomCost[]>([]);
	const [selectedItems, setSelectedItems] = React.useState<Array<number>>([]);
	const [selectedServices, setSelectedServices] = React.useState<Array<number>>([]);
	const [selectedCustomCosts, setSelectedCustomCosts] = React.useState<Array<number>>([]);
	const [editableItem, setEditableItem] = React.useState<JobInventory>();

	const [isServiceModalOpen, setIsServiceModalOpen] = React.useState(false);
	const [isCostAmpModalOpen, setIsCostAmpModalOpen] = React.useState(false);
	const [isAddNoteModalOpen, setIsAddNoteModalOpen] = React.useState(false);
	const [defaultCheckedIds, setDefaultCheckedIds] = React.useState<Array<number>>([]);

	React.useEffect(() => {
		dispatch(actions.fetchJob(jobId));
		dispatch(actions.fetchInventory());
		dispatch(actions.fetchNurses());
		dispatch(actions.fetchVendors());
		dispatch(actions.fetchCustomCosts());
		dispatch(actions.fetchHospitals());
	}, []);

	React.useEffect(() => {
		const fetchedJob = jobs.list.find((job) => job.id === jobId);
		if (fetchedJob) {
			dispatch(actions.fetchHospitalServices(fetchedJob.hospital.id));
			setJob(fetchedJob);
			setStatus(fetchedJob.status);
			setNurseId(fetchedJob.nurse?.id);
			setTypeId(fetchedJob.typeId);
			setHospitalId(fetchedJob.hospital.id);
			setContactId(fetchedJob.contact?.id);
			setDescription(fetchedJob.description);
			setStartDate(Timing.dateTimeToDate(fetchedJob.startingAt));
			setNurseStartedAt(fetchedJob.nurseStartedAt ? Timing.dateTimeToDate(fetchedJob.nurseStartedAt) : null);
			setNurseFinishedAt(fetchedJob.nurseFinishedAt ? Timing.dateTimeToDate(fetchedJob.nurseFinishedAt) : null);
			setPatientId(fetchedJob.patientId ?? '');
			setPatientName(fetchedJob.patientName ?? '');
			setRoomNumber(fetchedJob.roomNumber ?? '');
			setOrderingPhysician(fetchedJob.orderingPhysician ?? '');
			setRequirementsFromArray(fetchedJob.requirements.map(({ name }) => name));
			setInventory(fetchedJob.inventory);
			setServices(fetchedJob.services);
			if (fetchedJob.customCosts.length === 0) {
				const initialCosts: JobCustomCost[] = customCostState.list
					.slice(0, 4)
					.map((cost) => ({ customCostId: cost.id, name: cost.name }));
				setInitialCostAmplifiers(initialCosts);
			} else {
				setInitialCostAmplifiers(fetchedJob.customCosts);
				setCustomCosts(fetchedJob.customCosts);
				setDefaultCheckedIds(fetchedJob.customCosts.map(({ customCostId }) => customCostId));
			}
			setFetchedCount((prev) => prev + 1);
		}
	}, [jobs.updatedAt]);

	React.useEffect(() => {
		if (!hospitalId) return;
		else dispatch(actions.fetchEmployees(hospitalId));
	}, [hospitalId]);

	React.useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.VENDOR, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.INVENTORY, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.NURSE, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.SERVICE, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.CUSTOM_COST, 'GET')) {
			if (RM.succeeded()) {
				setFetchedCount((prev) => prev + 1);
			}
		}

		if (RM.req(routes.JOB_ID(jobId), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/jobs');
			}
		}

		if (RM.req(routes.JOB_STATUS(jobId), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}

		if (RM.req(routes.DOCUMENT_ID(jobId), 'POST')) {
			if (active.status === 'pending') {
				toast('Uploading document...', 'info');
			} else if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}

		if (RM.req(routes.JOB_NOTE_ID(jobId), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	React.useEffect(() => {
		if (inventory.length === 0) return;

		const ids = inventory.map(({ inventoryId }) => inventoryId);
		setSelectedItems(ids);
	}, [inventory]);

	React.useEffect(() => {
		if (services.length === 0) return;

		const ids = services.map(({ serviceId }) => serviceId);
		setSelectedServices(ids);
	}, [services]);

	React.useEffect(() => {
		if (initialCostAmplifiers.length === 0) return;

		const ids = initialCostAmplifiers.map(({ customCostId }) => customCostId);
		setSelectedCustomCosts(ids);
	}, [initialCostAmplifiers]);

	const handleStatusChange = (status: number) => {
		dispatch(actions.updateJobStatus(jobId, status));
	};

	const setRequirementsFromArray = React.useCallback(
		(requirements_: string[]) => {
			const requirementsMap = requirements_.reduce<Record<string, boolean>>((acc, cur) => {
				acc[cur] = true;
				return acc;
			}, {});
			setRequirements(requirementsMap);
		},
		[setRequirements]
	);

	const requirementsToArray = (requirements_: Record<string, boolean>) => {
		return Object.entries(requirements_)
			.filter(([, value]) => value)
			.map(([requirement]) => requirement);
	};

	const addRequirement = (requirement: string) => {
		setRequirements((requirements) => ({ ...requirements, [requirement]: true }));
	};

	const removeRequirement = (requirement: string) => {
		console.log('remove req::', requirement);
		setRequirements((requirements) => ({ ...requirements, [requirement]: false }));
	};

	const updateRequirement = (prev: string, cur: string) => {
		setRequirements((requirements_) => {
			const temp = { ...requirements_ };
			delete temp[prev];
			temp[cur] = true;
			return temp;
		});
	};

	const addInventory = (inventory: JobInventory) => {
		setInventory((items) => [...items, inventory]);
	};

	const editInventory = (inventory: JobInventory) => {
		setInventory((items) => [...items.filter((item) => item.inventoryId !== inventory.inventoryId), inventory]);
		setEditableItem(undefined);
	};

	const onInventoryClick = (inventory: JobInventory) => {
		setEditableItem(inventory);
		setIsInventoryInputModalOpen(true);
	};

	const removeInventory = (id: number) => {
		setInventory((items) => items.filter((item) => item.inventoryId !== id));
	};

	const addCustomCostAmplifier = (cost: JobCustomCost) => {
		setInitialCostAmplifiers((items) => [...items, cost]);
	};

	const updateCustomCosts = (cost: JobCustomCost, checked: boolean) => {
		if (checked) {
			setCustomCosts((items) => [...items, cost]);
		} else {
			setCustomCosts((items) => items.filter((item) => item.customCostId !== cost.customCostId));
		}
	};

	const handleUpdateJob = async () => {
		await dispatch(
			actions.updateJob(jobId, {
				typeId: typeId as JobType,
				treatmentType: treatmentType as JobTreatmentType,
				hospitalId: hospitalId as number,
				description,
				inventory,
				services,
				customCosts,
				nurseId: nurseId as number,
				requirements: requirementsToArray(requirements),
				contactId: contactId as number,
				startingAt: Timing.formatDateToDateTime(startDate),
				nurseStartedAt: nurseStartedAt ? Timing.formatDateToDateTime(nurseStartedAt) : null,
				nurseFinishedAt: nurseFinishedAt ? Timing.formatDateToDateTime(nurseFinishedAt) : null,
				patientId: patientId !== '' ? patientId : undefined,
				patientName: patientName !== '' ? patientName : undefined,
				roomNumber: roomNumber !== '' ? roomNumber : undefined,
				orderingPhysician: orderingPhysician !== '' ? orderingPhysician : undefined,
			})
		);
		history.back();
	};

	const handleDocumentUpload = () => {
		const fileList = documentInputRef.current?.files as FileList;
		const file = fileList[0];
		dispatch(actions.uploadDocument(jobId, file));
	};

	const handleNoteAdd = (message: string) => {
		dispatch(actions.addNote(jobId, message));
	};

	const handleNoteDelete = (id: number) => {
		dispatch(actions.deleteNote(id, jobId));
	};

	const addService = (service: JobService) => {
		setServices((items) => [...items, service]);
	};

	const removeService = (id: number) => {
		setServices((items) => items.filter((item) => item.serviceId !== id));
	};

	useComprehensiveTopNav(`Edit Job`);

	if (fetchedCount < 7 || !job) return <LoadingIndicator />;

	return (
		<>
			{/* <SelectCostAmplifierModal isOpen={isCostAmpModalOpen} onClose={() => setIsCostAmpModalOpen(false)} /> */}
			{/* <SelectNewJobServiceModal isOpen={isServiceModalOpen} onClose={() => setIsServiceModalOpen(false)} /> */}
			<InventoryInputModal
				items={items.list}
				item={editableItem}
				selected={selectedItems}
				addInventory={addInventory}
				editInventory={editInventory}
				isOpen={isInventoryInputModalOpen}
				setIsOpen={setIsInventoryInputModalOpen}
				onClose={() => {
					setEditableItem(undefined);
					setIsInventoryInputModalOpen(false);
				}}
			/>
			<AddNoteModal
				isOpen={isAddNoteModalOpen}
				onClose={() => setIsAddNoteModalOpen(false)}
				onAddNote={handleNoteAdd}
			/>
			<ServiceInputModal
				isOpen={isServiceModalOpen}
				setIsOpen={setIsServiceModalOpen}
				items={serviceState.list}
				selected={selectedServices}
				addService={addService}
				onClose={() => setIsServiceModalOpen(false)}
			/>
			<CustomCostInputModal
				isOpen={isCostAmpModalOpen}
				setIsOpen={setIsCostAmpModalOpen}
				onClose={() => setIsCostAmpModalOpen(false)}
				addCustomCost={addCustomCostAmplifier}
				items={customCostState.list}
				selected={selectedCustomCosts}
			/>
			<div className={classes['dispatch-create-container']} ref={containerRef}>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					weight="medium"
					style={{
						marginTop: '1rem',
					}}
				>
					Job Information
				</Typography>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<div className={classes['row']}>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Hospital Name
							</Typography>
							<HospitalSelect
								value={hospitalId}
								setValue={setHospitalId}
								defaultValue={{
									label: job.hospital.name,
									value: job.hospital.id,
								}}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Priority
							</Typography>
							<PrioritySelect
								value={typeId}
								setValue={setTypeId}
								defaultValue={{
									label: job.typeId === JobType.ROUTINE ? 'Routine' : 'STAT',
									value: job.typeId,
								}}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Treatment Type
							</Typography>
							<TreatmentTypeSelect
								value={treatmentType}
								setValue={setTreatmentType}
								defaultValue={{
									label:
										job.treatmentType === JobTreatmentType.HD
											? 'Hemodialysis'
											: job.treatmentType === JobTreatmentType.PD
											? 'Peritoneal Dialysis'
											: 'Super Low Efficient Dialysis',
									value: job.treatmentType,
								}}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
								Point of Contact
							</Typography>
							<PointOfContactSelect
								value={contactId}
								setValue={setContactId}
								defaultValue={{
									label: job.contact?.name ?? '',
									value: job.contact?.id ?? 0,
								}}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={startDate}
								label="Start Date/Time"
								setValue={setStartDate}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={nurseStartedAt}
								label="Nurse Started At"
								setValue={setNurseStartedAt}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
						<div
							style={{
								marginTop: '1rem',
								marginRight: '1rem',
							}}
						>
							<DatePicker
								value={nurseFinishedAt}
								label="Nurse Finished At"
								setValue={setNurseFinishedAt}
								placeholderText="MM/DD/YYYY HH:MM"
								showTimeSelect
								dateFormat="MM/dd/yyyy hh:mm aa"
								timeIntervals={15}
							/>
						</div>
					</div>
					<NurseSelect value={nurseId} setValue={setNurseId} />
				</div>
				<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
					<div className={classes['row']} style={{ alignItems: 'flex-start', marginTop: '1rem' }}>
						<div style={{ margin: '0.5rem 0.5rem' }}>
							<TextField
								value={patientId}
								onChange={(e) => setPatientId(e.target.value)}
								color="primary"
								variant="labelled"
								label="Patient ID:"
							/>
						</div>
						<div style={{ margin: '0.5rem 0.5rem' }}>
							<TextField
								value={patientName}
								onChange={(e) => setPatientName(e.target.value)}
								color="primary"
								variant="labelled"
								label="Patient Name:"
							/>
						</div>
						<div style={{ margin: '0.5rem 0.5rem' }}>
							<TextField
								value={roomNumber}
								onChange={(e) => setRoomNumber(e.target.value)}
								color="primary"
								variant="labelled"
								label="Room Number:"
							/>
						</div>
						<div style={{ margin: '0.5rem 0.5rem' }}>
							<TextField
								value={orderingPhysician}
								onChange={(e) => setOrderingPhysician(e.target.value)}
								color="primary"
								variant="labelled"
								label="Ordering Physician:"
							/>
						</div>
					</div>
					<div style={{ margin: '0 0.5rem' }}>
						<JobStatusSelect value={status} setValue={setStatus} onChangeAction={handleStatusChange} />
					</div>
				</div>

				<div className={classes['row']} style={{ alignItems: 'flex-start', width: '66.66%' }}>
					<div style={{ flex: 2, paddingRight: '4rem' }}>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							color="darkgrey"
							style={{ marginTop: '2rem' }}
						>
							Description:
						</Typography>
						<textarea
							className={classes['textarea']}
							placeholder="Enter the description..."
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>
				</div>

				<div className={classes['row']} style={{ marginTop: '0.5rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '66.66%',
							height: '100%',
							minWidth: 300,
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								weight="medium"
								style={{
									marginTop: '2rem',
								}}
							>
								Available Inventory for Job
							</Typography>
							<div
								className={classes['container']}
								style={{
									width: '100%',
									height: '100%',
									paddingBottom: '0.5rem',
									alignItems: 'flex-start',
									flexWrap: 'wrap',
								}}
							>
								<Scrollbars
									renderThumbVertical={(props) => (
										<div {...props} className={classes['vertical-scroll-thumb']} />
									)}
								>
									<div
										style={{
											flex: 1,
											minWidth: 200,
											marginRight: '1rem',
										}}
									>
										<div
											className={classes['row']}
											style={{
												justifyContent: 'space-between',
												margin: '1rem 0',
												padding: '0 3rem',
											}}
										>
											<div className={classes['row']}>
												<Typography
													variant={isSmallerDevice ? 'h5' : 'h6'}
													weight="light"
													style={{
														marginRight: '0.5rem',
													}}
												>
													Name
												</Typography>
												<Image src="icDownCaret" />
											</div>
											<div className={classes['row']}>
												<Typography
													variant={isSmallerDevice ? 'h5' : 'h6'}
													weight="light"
													style={{
														marginRight: '0.5rem',
													}}
												>
													Quantity
												</Typography>
												<Image src="icDownCaret" />
											</div>
										</div>
										{inventory.map(({ name, amount, inventoryId }) => (
											<div className={classes['inventory-row']} key={ID()}>
												<div
													className={classes['inventory-item']}
													onClick={() => onInventoryClick({ name, amount, inventoryId })}
												>
													<Typography
														variant={isSmallerDevice ? 'h5' : 'h6'}
														style={{ marginLeft: '1.5rem' }}
													>
														{name}
													</Typography>
													<Typography
														variant={isSmallerDevice ? 'h5' : 'h6'}
														style={{ marginRight: '4.5rem' }}
													>
														{amount}
													</Typography>
												</div>
												<Image
													src="icTrashDanger"
													className={classes['inventory-row-trash']}
													onClick={() => removeInventory(inventoryId)}
												/>
											</div>
										))}
										{!isInventoryInputModalOpen && (
											<button
												className={classes['badge']}
												style={{
													margin: '0 1rem 1rem 0',
													backgroundColor: '#F5F5F5',
													display: 'inline-block',
													width: '50%',
													textAlign: 'center',
												}}
												onClick={() => setIsInventoryInputModalOpen(true)}
											>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
													+ Add New Item
												</Typography>
											</button>
										)}
									</div>
								</Scrollbars>
							</div>
						</div>
					</div>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<div style={{ flex: 1 }}>
								<div
									style={{
										width: '20rem',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										padding: '0 1rem',
									}}
								>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
										Uploads
									</Typography>
									<button
										style={{
											width: '8rem',
											display: 'flex',
											justifyContent: 'space-between',
										}}
										onClick={() => documentInputRef.current?.click()}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
											Upload Document
										</Typography>
										<Image src="icExportUpArrowPrimary" />
										<input
											type="file"
											ref={documentInputRef}
											style={{ display: 'none' }}
											onChange={handleDocumentUpload}
										/>
									</button>
								</div>
							</div>
							<div style={{ height: '15rem' }}>
								<FileList.Deletable files={job?.files ?? []} jobId={jobId} />
							</div>
						</div>
					</div>
				</div>

				<div className={classes['row']} style={{ marginTop: '0.5rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '66.66%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<div className={classes['jobServices']}>
								<Typography variant="h6" weight="bold">
									Services Provided for Job
								</Typography>
								<div className={classes['jobServices-table']}>
									<div className={classes['jobServices-tableHeader']}>
										<div className={classes['jobServices-tableHeaderItem']}>
											<Typography color="darkgrey" variant="h6">
												Title
											</Typography>
											<Image src="icCaretBlack" />
										</div>
										<div className={classes['jobServices-tableHeaderItem']}>
											<Typography color="darkgrey" variant="h6">
												Description
											</Typography>
											<Image src="icCaretBlack" />
										</div>
									</div>
									<Scrollbars>
										<div className={classes['jobServices-tableBody']}>
											{services.map(({ serviceId, name, description }) => (
												<div
													key={serviceId}
													className={classes['jobServices-tableRow']}
													style={{ margin: '0.5rem 0' }}
												>
													<div>
														<Typography variant="h6">{name}</Typography>
													</div>
													<div>
														<Typography variant="h6">{description}</Typography>
													</div>
													<div className={classes['jobServices-tableRowActions']}>
														<Image
															src="icTrashDanger"
															onClick={() => removeService(serviceId)}
														/>
													</div>
												</div>
											))}
										</div>
										<div className={classes['jobServices-tableFooter']}>
											<a
												onClick={() => setIsServiceModalOpen(true)}
												className={classes['jobServices-tableFooterButton']}
											>
												<Typography variant="h6">+ Add New Service</Typography>
											</a>
										</div>
									</Scrollbars>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<div className={classes['costAmplifiers']}>
								<Typography weight="bold" variant="h6">
									Custom Cost Amplifiers
								</Typography>
								<JobCustomCostList
									items={initialCostAmplifiers}
									updateCustomCosts={updateCustomCosts}
									defaultCheckedIds={defaultCheckedIds}
								/>
								<div className={classes['costAmplifiers-buttons']}>
									<a
										onClick={() => setIsCostAmpModalOpen(true)}
										className={classes['costAmplifiers-button']}
									>
										<Typography>Select New</Typography>
									</a>
									{isSuperAdmin && (
										<a
											className={classes['costAmplifiers-button']}
											onClick={() => push('/settings/cost-amplifier/add')}
										>
											<Typography>Create New</Typography>
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={classes['row']} style={{ marginTop: '0.5rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								color="darkgrey"
								style={{ marginTop: '2rem', marginBottom: '1rem' }}
							>
								Requirements
							</Typography>
							<div
								className={classes['wrapper']}
								style={{
									width: '100%',
									height: '100%',
									marginTop: '0.5rem',
								}}
							>
								<Scrollbars
									renderThumbVertical={(props) => (
										<div {...props} className={classes['vertical-scroll-thumb']} />
									)}
								>
									<div className={classes['requirement-tags']}>
										{requirementsToArray(requirements).map((requirement) => (
											<Tag
												initialTitle={requirement}
												key={requirement}
												onValueChanged={(value) => updateRequirement(value.prev, value.cur)}
												onRemove={(requirement_) => removeRequirement(requirement_)}
											/>
										))}
										<AddTag onAddMore={(requirement_) => addRequirement(requirement_)} />
									</div>
								</Scrollbars>
							</div>
						</div>
					</div>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<div style={{ width: '100%', justifyContent: 'center' }}>
								<div
									style={{
										width: '95%',
										margin: '0 auto',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										padding: '0 1.5rem 0 0',
									}}
								>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
										Nurse notes:{' '}
									</Typography>
									<button onClick={() => setIsAddNoteModalOpen(true)}>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
											+ Add New note
										</Typography>
									</button>
								</div>
							</div>
							<Notes items={job.notes} deletable onDelete={handleNoteDelete} />
						</div>
					</div>
				</div>

				<div className={classes['row']} style={{ marginTop: '2rem' }}>
					<button
						className={classes['container']}
						style={{
							backgroundColor: '#E4F4F6',
							marginRight: '1rem',
						}}
						onClick={handleUpdateJob}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Update Job</Typography>
					</button>
					<button className={classes['container']} onClick={() => push('/jobs')}>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Cancel</Typography>
					</button>
				</div>
			</div>
		</>
	);
};

export default EditJobScreen;
