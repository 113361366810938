const PORTAL = 'portal';
const ADMIN = 'admin';

export default {
	UPLOAD_ID: (id: number) => `${PORTAL}/upload/${id}`,
	DOCUMENT_ID: (jobId: number) => `${PORTAL}/upload-document/${jobId}`,
	DOCUMENT_DELETE_ID: (id: number) => `${ADMIN}/job/${id}/document`,
	AUTH_CALLBACK: `${PORTAL}/auth/callback`,
	USER: `${ADMIN}/user`,
	USER_LIST: `${ADMIN}/user/list`,
	USER_ID: (id: number) => `${ADMIN}/user/${id}`,
	USER_SUSPEND: (id: number) => `${ADMIN}/user/${id}/suspend`,
	ADMIN: `${ADMIN}/admin`,
	ADMIN_ID: (id: number) => `${ADMIN}/admin/${id}`,
	ADMIN_SUSPEND: (id: number) => `${ADMIN}/admin/${id}/suspend`,
	SIGN_OUT: `${PORTAL}/sign-out`,
	HOSPITAL: `${ADMIN}/hospital`,
	HOSPITAL_BUDDY_PUNCH: `${ADMIN}/hospital/buddy-punch`,
	HOSPITAL_ID: (id: number) => `${ADMIN}/hospital/${id}`,
	HOSPITAL_EMPLOYEE: (hospitalId: number) => `${ADMIN}/employee/${hospitalId}`,
	HOSPITAL_JOBS: (hospitalId: number) => `${ADMIN}/job/${hospitalId}/hospital`,
	HOSPITAL_INVOICES: (id: number) => `${ADMIN}/hospital/${id}/invoices`,
	HOSPITAL_EMPLOYEE_USER: (hospitalId: number, userId: number) => `${ADMIN}/employee/${hospitalId}/${userId}`,
	HOSPITAL_VENDOR_RESTRICT: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/restrictVendor`,
	HOSPITAL_VENDOR_UNRESTRICT: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/unrestrictVendor`,
	HOSPITAL_VENDOR_LIST_RESTRICTED: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/listRestrictedVendors`,
	HOSPITAL_INVENTORY_RESTRICT: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/restrictInventory`,
	HOSPITAL_INVENTORY_UNRESTRICT: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/unrestrictInventory`,
	HOSPITAL_INVENTORY_LIST_RESTRICTED: (hospitalId: number) =>
		`${ADMIN}/hospital/${hospitalId}/listRestrictedInventory`,
	JOB: `${ADMIN}/job`,
	JOB_ID: (id: number) => `${ADMIN}/job/${id}`,
	JOB_NOTE_ID: (id: number) => `${ADMIN}/job/${id}/note`,
	JOB_STATUS: (id: number) => `${ADMIN}/job/${id}/status`,
	NURSE: `${ADMIN}/nurse`,
	NURSE_BUDDY_PUNCH: `${ADMIN}/nurse/buddy-punch`,
	NURSE_ID: (id: number) => `${ADMIN}/nurse/${id}`,
	VENDOR: `${ADMIN}/vendor`,
	VENDOR_ID: (id: number) => `${ADMIN}/vendor/${id}`,
	INVENTORY: `${ADMIN}/inventory`,
	INVENTORY_ID: (id: number) => `${ADMIN}/inventory/${id}`,
	INVOICE: `${ADMIN}/invoice`,
	INVOICE_ID: (id: number) => `${ADMIN}/invoice/${id}`,
	INVOICE_VOID: (id: number) => `${ADMIN}/invoice/${id}/void`,
	INVOICE_VOID_ITEMS: (id: number) => `${ADMIN}/invoice/${id}/void-items`,
	INVOICE_UNVOID: (id: number) => `${ADMIN}/invoice/${id}/un-void`,
	INVOICE_UNVOID_ITEMS: (id: number) => `${ADMIN}/invoice/${id}/un-void-items`,
	INVOICE_GENERATE_ACCRUE: `${ADMIN}/invoice/generate-accrue-pdf`,
	INVOICE_GENERATE_ID: (id: number) => `${ADMIN}/invoice/${id}/generate-pdf`,
	INVOICE_SEND_ID: (id: number) => `${ADMIN}/invoice/${id}/send-pdf`,
	CONVERSATION: `${ADMIN}/conversation`,
	CONVERSATION_ID: (id: number) => `${ADMIN}/conversation/${id}`,
	MESSAGE_ID: (id: number) => `${ADMIN}/message/${id}`,
	SERVICE: `${ADMIN}/service`,
	SERVICE_ID: (id: number) => `${ADMIN}/service/${id}`,
	HOSPITAL_SERVICES: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/listServices`,
	HOSPITAL_SERVICE_STORE: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/storeService`,
	HOSPITAL_SERVICE_UPDATE: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/updateService`,
	HOSPITAL_SERVICE_DELETE: (hospitalId: number) => `${ADMIN}/hospital/${hospitalId}/deleteService`,
	CUSTOM_COST: `${ADMIN}/custom-cost`,
	CUSTOM_COST_ID: (id: number) => `${ADMIN}/custom-cost/${id}`,
	SETTINGS: `${ADMIN}/settings`,
};
