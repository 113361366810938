import React from 'react';
import { Image, Typography } from 'lib/components';
import { Color, ImageSource } from 'lib/components/types';
import classes from './index.module.scss';

interface Props {
	leftIconSrc?: ImageSource;
	onClick?: () => any;
	title: string;
	rightIconSrc?: ImageSource;
	status?: Color;
}

const ActionButton = ({ leftIconSrc, title, rightIconSrc, status = 'primary', onClick }: Props) => {
	const className = classes['actionButton'] + ' ' + classes['actionButton-' + status];

	return (
		<a onClick={onClick} className={className}>
			{leftIconSrc && <Image className={classes['actionButton-left']} src={leftIconSrc} />}
			<Typography className={classes['actionButton-title']} color={status}>
				{title}
			</Typography>
			{rightIconSrc && <Image className={classes['actionButton-right']} src={rightIconSrc} />}
		</a>
	);
};

export default ActionButton;
