import React, { useState } from 'react';
import { Typography, Image } from 'lib/components';
import classes from './index.module.scss';
import { Checkbox } from 'lib/components/FormFields';
import Scrollbars from 'react-custom-scrollbars';
import { useWindowResize } from 'hooks/useWindowResize';

const toolbarItems = [
	{ id: '0', label: 'Log ID' },
	{ id: '1', label: 'Log by' },
	{ id: '0', label: 'Description' },
	{ id: '0', label: 'Date' },
];

const tableItems = [
	{
		id: '0',
		checked: true,
		logId: '00037399',
		logBy: 'Regina Smith',
		description: 'Changed job status to "On Location"',
		date: '01.01.21 - 09:45AM',
	},
	{
		id: '1',
		checked: false,
		logId: '00032399',
		logBy: 'Regina Smith',
		description: 'Changed job status to "On Route"',
		date: '01.01.21 - 09:45AM',
	},
	{
		id: '2',
		checked: false,
		logId: '00047399',
		logBy: 'Regina Smith',
		description: 'Accepted the job request',
		date: '01.01.21 - 09:45AM',
	},
	{
		id: '3',
		checked: false,
		logId: '00077397',
		logBy: 'Administrator',
		description: 'Accepted the job request',
		date: '01.01.21 - 09:45AM',
	},
	{
		id: '4',
		checked: false,
		logId: '00037B69',
		logBy: 'Spencer Memorial Hospital',
		description: 'Submitted job information',
		date: '01.01.21 - 09:45AM',
	},
];

const pages = [1, 2, 3, 4];

const Invoices = () => {
	const [allChecked, setAllChecked] = useState(false);
	const [selectedPage, setSelectedPage] = useState(1);
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	return (
		<div
			className={classes['dispatches-logs-container']}
			style={{
				padding: isSmallDevice ? '0 1rem' : undefined,
			}}
		>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']}>
					<button
						className={classes['button']}
						style={{
							width: '8rem',
							backgroundColor: '#E4F4F6',
							borderColor: '#A6E5E9',
							padding: '0 1rem',
							marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
							Accept All
						</Typography>
						<Image
							src="icDownCaret"
							style={{
								marginLeft: 'auto',
							}}
						/>
					</button>
					<button
						className={classes['button']}
						style={{
							backgroundColor: '#FCD3D1',
							borderColor: '#EF2419',
							marginTop: '1rem',
						}}
					>
						<Image
							src="icTrashDanger"
							style={{
								marginRight: '0.5rem',
							}}
						/>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Delete</Typography>
					</button>
				</div>
				<button
					className={classes['button']}
					style={{
						backgroundColor: '#E7F7F9',
						marginTop: '1rem',
					}}
				>
					<Image
						src="icFilterPrimary"
						style={{
							marginRight: '0.5rem',
						}}
					/>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Filter by</Typography>
				</button>
			</div>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={() => setAllChecked(!allChecked)}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{toolbarItems.map(({ id, label }) => (
							<div className={classes['col-2']} key={id}>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
									{label}
								</Typography>
								<Image
									src="icArrowDown"
									style={{
										marginLeft: '0.5rem',
									}}
								/>
							</div>
						))}
					</div>
					<div className={classes['container']}>
						<Scrollbars>
							{tableItems.map(({ id, date, description, logBy, logId, checked }) => (
								<div key={id} className={classes['table-row']}>
									<Checkbox
										checked={checked}
										style={{
											margin: '0 2rem',
										}}
									/>
									<Typography variant={'h6'} className={classes['col-2']}>
										{logId}
									</Typography>
									<Typography variant={'h6'} className={classes['col-2']}>
										{logBy}
									</Typography>
									<Typography variant={'h6'} className={classes['col-2']}>
										{description}
									</Typography>
									<Typography variant={'h6'} className={classes['col-2']}>
										{date}
									</Typography>
								</div>
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
			<div className={classes['bottom-toolbar']}>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						First
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Prev.
					</Typography>
				</button>
				{pages.map((page) => {
					const classname =
						page === selectedPage ? `${classes['badge']} ${classes['active']}` : `${classes['badge']}`;
					return (
						<button className={classname} key={page.toString()} onClick={() => setSelectedPage(page)}>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								color={page === selectedPage ? 'primary' : 'darkgrey'}
							>
								{page}
							</Typography>
						</button>
					);
				})}

				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Next
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Last
					</Typography>
				</button>
			</div>
		</div>
	);
};

export default Invoices;
