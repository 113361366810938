import moment from 'moment';
import { SliceState } from '../store';
import { Setting } from '../types';

const adjustTime = (settings: SliceState<Setting>, time: string, format: string | undefined = undefined) => {
	const findTimezone = settings.list.find((element) => element.name === 'global_timezone');
	console.log(findTimezone);
	if (findTimezone) {
		return moment.utc(time, format).utcOffset(findTimezone.value.split(' ')[0].replace('UTC', ''));
	}

	return moment.utc(time, format);
};

export default adjustTime;
