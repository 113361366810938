import { HospitalService, Vendor } from 'types';
import { Sort } from './types';

export const itemNameCompareFn = (a: HospitalService, b: HospitalService, sort: Sort) => {
	if (sort === 'asc') {
		return a.service.name.localeCompare(b.service.name) === -1 ? 1 : -1;
	} else {
		return a.service.name.localeCompare(b.service.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const itemIDCompareFn = (a: HospitalService, b: HospitalService, sort: Sort) => {
	if (sort === 'asc') {
		return a.id > b.id ? 1 : -1;
	} else {
		return a.id > b.id ? -1 : 1;
	}
	// return 0;
};
