import { useCustomToast, useWindowResize } from 'hooks';
import routes from 'lib/api/routes';
import { Image, LoadingIndicator, Pagination, Typography } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	actions,
	useAppDispatch,
	useSelectAuthorization,
	useSelectHospitalServices,
	useSelectRequest,
	useSelectServices,
} from 'store';
import { AllCheckedStatus, HospitalService } from 'types';
import useHospitalServicesPagination from '../../hooks/useHospitalServicesPagination';
import HospitalServicesDataTable from '../../lib/components/DataTables/HospitalServices';
import { RateType } from '../../lib/util';
import HospitalServicesModal from './HospitalServicesModal';
import classes from './index.module.scss';

const ServicesTab = () => {
	const { pathname } = useLocation();
	const toast = useCustomToast();
	const { isSmallerDevice } = useWindowResize();

	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { canUpdateHospital } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const [service, setService] = useState<HospitalService>();
	const services = useSelectServices();
	const hospitalServices = useSelectHospitalServices();

	const [checked, setChecked] = useState<number[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [allCheckedStatus, setAllCheckedStatus] = useState<AllCheckedStatus>('none');
	const [showHospitalServicesModal, setShowHospitalServicesModal] = useState(false);

	const toggleRestrictItemsModal = () => setShowHospitalServicesModal((prev) => !prev);

	useEffect(() => {
		dispatch(actions.fetchServices());
		dispatch(actions.fetchHospitalServices(parseInt(hospitalId)));
	}, []);

	const { isDataFetched, paginatedData, paginationProps } = useHospitalServicesPagination(hospitalServices);

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.HOSPITAL_INVENTORY_RESTRICT(parseInt(hospitalId)) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
			}
		}

		if (active.name === routes.HOSPITAL_INVENTORY_UNRESTRICT(parseInt(hospitalId)) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				setAllChecked(false);
				setAllCheckedStatus('none');
			}
		}
	}, [active]);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		// if (allCheckedStatus === 'all') setChecked(restricted);
		if (allCheckedStatus === 'none') setChecked([]);
	}, [allCheckedStatus]);

	const handleOnClick = (id: number) => {
		const index = hospitalServices.list.findIndex((element) => element.id === id);
		if (index !== -1) {
			setService(hospitalServices.list[index]);
			setShowHospitalServicesModal(true);
		}
	};

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (allCheckedStatus !== 'some') {
			setAllChecked(false);
			setAllCheckedStatus('some');
		}

		if (isChecked && !checked.includes(id)) {
			setChecked((prev) => [...prev, id]);
			return;
		}

		if (!isChecked && checked.includes(id)) {
			setChecked((prev) => prev.filter((item) => item !== id));
		}
	};

	const handleAllCheckAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		if (checked) {
			setAllChecked(checked);
			setAllCheckedStatus('all');
		} else {
			{
				setAllChecked(checked);
				setAllCheckedStatus('none');
			}
		}
	};

	const handleCreateService = (
		serviceId: number,
		baseCost: number,
		hoursIncluded: number,
		regularRate: number,
		overtimeRate: number,
		weekendRate: number,
		holidayRate: number,
		regularRateTypeId: RateType,
		overtimeRateTypeId: RateType,
		weekendRateTypeId: RateType,
		holidayRateTypeId: RateType
	) => {
		if (!service) {
			dispatch(
				actions.addHospitalService(parseInt(hospitalId), {
					serviceId,
					baseCost,
					hoursIncluded,
					regularRate,
					overtimeRate,
					weekendRate,
					holidayRate,
					regularRateTypeId,
					overtimeRateTypeId,
					weekendRateTypeId,
					holidayRateTypeId,
				})
			);
		} else {
			dispatch(
				actions.updateHospitalService(parseInt(hospitalId), {
					id: service.id,
					serviceId,
					baseCost,
					hoursIncluded,
					regularRate,
					overtimeRate,
					weekendRate,
					holidayRate,
					regularRateTypeId,
					overtimeRateTypeId,
					weekendRateTypeId,
					holidayRateTypeId,
				})
			);
		}
		setService(undefined);
		setShowHospitalServicesModal(false);
	};

	const handleOnClose = () => {
		setService(undefined);
		setShowHospitalServicesModal(false);
	};

	const handleDeleteServices = () => {
		dispatch(actions.deleteHospitalService(parseInt(hospitalId), checked));
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<HospitalServicesModal
				service={service}
				options={services.list}
				isOpen={showHospitalServicesModal}
				onConfirm={handleCreateService}
				onClose={handleOnClose}
			/>
			<div className={classes['inventories-tab-container']}>
				<div
					className={classes['row']}
					style={{
						justifyContent:
							allCheckedStatus !== 'none' && checked.length !== 0 ? 'space-between' : 'flex-end',
					}}
				>
					{allCheckedStatus !== 'none' && checked.length !== 0 && (
						<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
							{canUpdateHospital && (
								<a onClick={handleDeleteServices} className={classes['button-cancel']}>
									<Typography
										className={classes['button-cancel-title']}
										variant={isSmallerDevice ? 'h5' : 'h6'}
										weight="regular"
										style={{ cursor: 'pointer' }}
									>
										Delete Services
									</Typography>
									<span className={classes['button-cancel-icon']}>
										<Image src="icCancelRed" />
									</span>
								</a>
							)}
						</div>
					)}
					<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
						{canUpdateHospital && (
							<a onClick={toggleRestrictItemsModal} className={classes['button-add']}>
								<Typography
									className={classes['button-add-title']}
									variant={isSmallerDevice ? 'h5' : 'h6'}
									weight="regular"
									style={{ cursor: 'pointer' }}
								>
									Create Service
								</Typography>
								<span className={classes['button-add-icon']}>
									<Image src="icPlusPrimary" />
								</span>
							</a>
						)}
						{/* <button
							className={classes['button']}
							style={{
								minWidth: 70,
								backgroundColor: '#E4F4F6',
								borderColor: '#A6E5E9',
								justifyContent: 'space-evenly',
							}}
						>
							<Image
								src="icExport"
								style={{
									marginRight: '0.5rem',
								}}
							/>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
								Export
							</Typography>
						</button> */}
					</div>
				</div>
				<HospitalServicesDataTable
					items={paginatedData}
					allChecked={allChecked}
					allCheckedStatus={allCheckedStatus}
					handleAllCheckAction={handleAllCheckAction}
					checkAction={handleCheckAction}
					onClick={handleOnClick}
				/>
				<Pagination {...paginationProps} />
			</div>
		</>
	);
};

export default ServicesTab;
