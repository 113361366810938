import { useComprehensiveTopNav, useCustomToast } from 'hooks';
import { routes } from 'lib/api';
import { Button, LoadingIndicator, Typography } from 'lib/components';
import { TextField } from 'lib/components/FormFields';
import { RateType } from 'lib/util';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectCustomCost,
	useSelectCustomCosts,
	useSelectRequest,
} from 'store';
import { CustomCost } from 'types';
import classes from './index.module.scss';

interface Props {
	cost: CustomCost;
}

const NUMBER_SYMBOL = ['$', '%'];

const EditCostAmplifierScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const costs = useSelectCustomCosts();
	const getCustomCostById = useSelectCustomCost();

	const [cost, setCost] = useState<CustomCost>();

	useEffect(() => {
		dispatch(actions.fetchCustomCost(parseInt(id)));
	}, []);

	useEffect(() => {
		const cost = getCustomCostById(parseInt(id));

		if (cost) {
			setCost(cost);
		}
	}, [costs.updatedAt]);

	return cost ? <EditCostAmplifierScreenView cost={cost} /> : <LoadingIndicator />;
};

const EditCostAmplifierScreenView = ({ cost }: Props) => {
	const toast = useCustomToast();
	const { push, goBack } = useHistory();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState(cost.name);
	const [affectCost, setAffectCost] = useState(cost.affectCost);
	const [regularRate, setRegularRate] = useState(cost.regularRate);
	const [overtimeRate, setOvertimeRate] = useState(cost.overtimeRate);
	const [weekendRate, setWeekendRate] = useState(cost.weekendRate);
	const [holidayRate, setHolidayRate] = useState(cost.holidayRate);
	const [regularRateTypeId, setRegularRateTypeId] = useState<RateType>(cost.regularRateTypeId);
	const [overtimeRateTypeId, setOvertimeRateTypeId] = useState<RateType>(cost.overtimeRateTypeId);
	const [weekendRateTypeId, setWeekendRateTypeId] = useState<RateType>(cost.weekendRateTypeId);
	const [holidayRateTypeId, setHolidayRateTypeId] = useState<RateType>(cost.holidayRateTypeId);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.CUSTOM_COST_ID(cost.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/settings/cost-amplifier');
			}
		}
	}, [active]);

	const handleSymbolChange = (field: 'regular' | 'overtime' | 'weekend' | 'holiday', symbol: RateType) => {
		if (field === 'regular') setRegularRateTypeId(symbol);
		else if (field === 'overtime') setOvertimeRateTypeId(symbol);
		else if (field === 'weekend') setWeekendRateTypeId(symbol);
		else setHolidayRateTypeId(symbol);
	};

	const handleUpdate = () => {
		dispatch(
			actions.updateCustomCost(cost.id, {
				name,
				affectCost,
				regularRate,
				overtimeRate,
				weekendRate,
				holidayRate,
				regularRateTypeId,
				overtimeRateTypeId,
				weekendRateTypeId,
				holidayRateTypeId,
			})
		);
	};

	useComprehensiveTopNav('Edit Custom Amplifier', { notificationVisible: true });

	return (
		<div className={classes['costAmplifier']}>
			<div className={classes['costAmplifier-form']}>
				<TextField
					placeholder="Does the patient have COVID-19?"
					label="Custom Field Title"
					variant="labelled"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<div className={classes['costAmplifier-priceIncrease']}>
					<Typography color="darkgrey" variant="h6">
						If Yes Increase Price?:
					</Typography>
					<div className={classes['costAmplifier-priceIncrease-buttons']}>
						<Button
							title="Yes"
							variant="standard"
							color={affectCost ? 'primary' : 'gray'}
							onClick={() => setAffectCost(true)}
						/>
						<Button
							title="No"
							variant="standard"
							color={!affectCost ? 'primary' : 'gray'}
							sizing="small"
							onClick={() => setAffectCost(false)}
						/>
					</div>
				</div>
				<div className={classes['costAmplifier-hours']}>
					<Typography color="darkgrey" variant="h6">
						Increase Price by How Much?:
					</Typography>
					<div className={classes['costAmplifier-hours-fields']}>
						<TextField
							placeholder="7.50"
							label="Business Day (per hour)"
							type="number"
							variant="labelled"
							numberSymbol={NUMBER_SYMBOL}
							min={0}
							value={regularRate}
							onChange={(e) => setRegularRate(e.target.valueAsNumber)}
							defaultSymbolIndex={regularRateTypeId}
							onSymbolChange={(value) => handleSymbolChange('regular', value)}
						/>
						<TextField
							placeholder="7.50"
							label="Overtime (per hour)"
							type="number"
							variant="labelled"
							numberSymbol={NUMBER_SYMBOL}
							min={0}
							value={overtimeRate}
							onChange={(e) => setOvertimeRate(e.target.valueAsNumber)}
							defaultSymbolIndex={overtimeRateTypeId}
							onSymbolChange={(value) => handleSymbolChange('overtime', value)}
						/>
						<TextField
							placeholder="7.50"
							label="Weekend Day (per hour)"
							type="number"
							variant="labelled"
							numberSymbol={NUMBER_SYMBOL}
							min={0}
							value={weekendRate}
							defaultSymbolIndex={weekendRateTypeId}
							onChange={(e) => setWeekendRate(e.target.valueAsNumber)}
							onSymbolChange={(value) => handleSymbolChange('weekend', value)}
						/>
						<TextField
							placeholder="7.50"
							label="Holiday Day (per hour)"
							type="number"
							variant="labelled"
							numberSymbol={NUMBER_SYMBOL}
							min={0}
							value={holidayRate}
							defaultSymbolIndex={holidayRateTypeId}
							onChange={(e) => setHolidayRate(e.target.valueAsNumber)}
							onSymbolChange={(value) => handleSymbolChange('holiday', value)}
						/>
					</div>
				</div>
			</div>
			<div className={classes['costAmplifier-footerButtons']}>
				<Button title="Update" variant="standard" color="primary" onClick={handleUpdate} />
				<Button variant="standard" color="gray" title="Cancel" onClick={goBack} />
			</div>
		</div>
	);
};

export default EditCostAmplifierScreen;
