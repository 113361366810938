import { useCustomToast, useWindowResize } from 'hooks';
import routes from 'lib/api/routes';
import { Image, LoadingIndicator, Pagination, Typography } from 'lib/components';
import HospitalItemsDataTable from 'lib/components/DataTables/HospitalItems';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	actions,
	useAppDispatch,
	useSelectAuthorization,
	useSelectInventory,
	useSelectRequest,
	useSelectRestrictedInventory,
} from 'store';
import { AllCheckedStatus } from 'types';
import useRestrictedInventoryPagination from '../../hooks/useRestrictedInventoryPagination';
import classes from './index.module.scss';
import RestrictItemsModal from './RestrictedItemsModal';

const ItemsTab = () => {
	const { pathname } = useLocation();
	const toast = useCustomToast();
	const { isSmallerDevice } = useWindowResize();

	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { canUpdateHospital } = useSelectAuthorization();
	const { active } = useSelectRequest();
	const inventory = useSelectInventory();
	const restrictedInventory = useSelectRestrictedInventory();

	const [checked, setChecked] = useState<number[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [allCheckedStatus, setAllCheckedStatus] = useState<AllCheckedStatus>('none');
	const [showRestrictItemsModal, setShowRestrictItemsModal] = useState(false);

	const toggleRestrictItemsModal = () => setShowRestrictItemsModal((prev) => !prev);

	useEffect(() => {
		dispatch(actions.fetchInventory());
		dispatch(actions.fetchRestrictedInventory(parseInt(hospitalId)));
	}, []);

	const { isDataFetched, listing, restricted, paginatedData, paginationProps } = useRestrictedInventoryPagination(
		inventory,
		restrictedInventory
	);

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.HOSPITAL_INVENTORY_RESTRICT(parseInt(hospitalId)) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
			}
		}

		if (active.name === routes.HOSPITAL_INVENTORY_UNRESTRICT(parseInt(hospitalId)) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				setAllChecked(false);
				setAllCheckedStatus('none');
			}
		}
	}, [active]);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		// if (allCheckedStatus === 'all') setChecked(restricted);
		if (allCheckedStatus === 'none') setChecked([]);
	}, [allCheckedStatus]);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (allCheckedStatus !== 'some') {
			setAllChecked(false);
			setAllCheckedStatus('some');
		}

		if (isChecked && !checked.includes(id)) {
			setChecked((prev) => [...prev, id]);
			return;
		}

		if (!isChecked && checked.includes(id)) {
			setChecked((prev) => prev.filter((item) => item !== id));
		}
	};

	const handleAllCheckAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		if (checked) {
			setAllChecked(checked);
			setAllCheckedStatus('all');
		} else {
			{
				setAllChecked(checked);
				setAllCheckedStatus('none');
			}
		}
	};

	const handleRestrictItemsAction = (ids: number[]) => {
		dispatch(actions.restrictInventory(parseInt(hospitalId), ids));
		setShowRestrictItemsModal(false);
	};

	const handleUnRestrictItemsAction = () => {
		dispatch(actions.unRestrictInventory(parseInt(hospitalId), checked));
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<RestrictItemsModal
				items={listing}
				restricted={restricted}
				isOpen={showRestrictItemsModal}
				action={handleRestrictItemsAction}
				onClose={toggleRestrictItemsModal}
			/>
			<div className={classes['inventories-tab-container']}>
				<div
					className={classes['row']}
					style={{
						justifyContent:
							allCheckedStatus !== 'none' && checked.length !== 0 ? 'space-between' : 'flex-end',
					}}
				>
					{allCheckedStatus !== 'none' && checked.length !== 0 && (
						<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
							{canUpdateHospital && (
								<a onClick={handleUnRestrictItemsAction} className={classes['button-cancel']}>
									<Typography
										className={classes['button-cancel-title']}
										variant={isSmallerDevice ? 'h5' : 'h6'}
										weight="regular"
										style={{ cursor: 'pointer' }}
									>
										Unrestrict Items
									</Typography>
									<span className={classes['button-cancel-icon']}>
										<Image src="icCancelRed" />
									</span>
								</a>
							)}
						</div>
					)}
					<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0rem' }}>
						{canUpdateHospital && (
							<a onClick={toggleRestrictItemsModal} className={classes['button-add']}>
								<Typography
									className={classes['button-add-title']}
									variant={isSmallerDevice ? 'h5' : 'h6'}
									weight="regular"
									style={{ cursor: 'pointer' }}
								>
									Restrict Items
								</Typography>
								<span className={classes['button-add-icon']}>
									<Image src="icPlusPrimary" />
								</span>
							</a>
						)}
						{/* <button
							className={classes['button']}
							style={{
								minWidth: 70,
								backgroundColor: '#E4F4F6',
								borderColor: '#A6E5E9',
								justifyContent: 'space-evenly',
							}}
						>
							<Image
								src="icExport"
								style={{
									marginRight: '0.5rem',
								}}
							/>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
								Export
							</Typography>
						</button> */}
					</div>
				</div>
				<HospitalItemsDataTable
					items={paginatedData}
					allChecked={allChecked}
					allCheckedStatus={allCheckedStatus}
					handleAllCheckAction={handleAllCheckAction}
					checkAction={handleCheckAction}
				/>
				<Pagination {...paginationProps} />
			</div>
		</>
	);
};

export default ItemsTab;
