import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import VendorsSelect from './VendorsSelect';
import { Vendor } from 'types';
import { useCustomToast } from 'hooks';
import classes from './index.module.scss';

interface Props {
	vendors: Vendor[];
	restricted: number[];
	isOpen: boolean;
	action: (vendors: number[]) => void;
	onClose: () => any;
}

const RestrictVendorModal = ({ vendors, action, isOpen, onClose, restricted }: Props) => {
	const toast = useCustomToast();
	const [restrictedVendors, setRestrictedVendors] = useState<number[]>([]);

	const handleAction = () => {
		if (restrictedVendors.length === 0) {
			toast('Please select at least one (1) Vendor', 'error');
		} else {
			action(restrictedVendors);
		}
	};

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-items']}>
					<div className={classes['modal-body']}>
						<VendorsSelect
							value={restrictedVendors}
							setValue={setRestrictedVendors}
							vendors={vendors.filter((vendor) => !restricted.includes(vendor.id))}
						/>
					</div>
					<div className={classes['modal-footer']}>
						<Button
							variant="standard"
							color="primary"
							title="Restrict Vendors"
							style={{
								marginRight: '1rem',
							}}
							onClick={handleAction}
						/>
						<Button variant="standard" color="gray" sizing="small" title="Cancel" onClick={onClose} />
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default RestrictVendorModal;
