import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Image } from 'lib/components';
import { SideNavItem } from 'lib/components/types';
import classes from './index.module.scss';

interface Props extends Omit<SideNavItem, 'roles' | 'permission'> {}

const SideNavLink = ({ to, icon, title }: Props) => (
	<NavLink key={title} className={classes['sideNav-item']} activeClassName={classes['sideNav-item-active']} to={to}>
		<Image className={classes['sideNav-item-icon']} src={icon} alt={title + ' icon'} />
		<Typography className={classes['sideNav-item-title']} variant="subheading1">
			{title}
		</Typography>
	</NavLink>
);

export default SideNavLink;
