import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { AccruedInvoiceInput, Invoice, InvoicePDF, UnvoidItemsInput, VoidItemsInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, InvoiceState } from '..';

const initialState: InvoiceState = {
	updatedAt: moment.utc().valueOf(),
	pdf: { url: '' },
	list: [],
};

export default createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		invoicesFetched: (state, { payload }: PA<Invoice[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		invoiceFetched: (state, { payload }: PA<Invoice>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		pdfGenerated: (state, { payload }: PA<InvoicePDF>) => {
			state.pdf = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		pdfCleared: (state) => {
			state.pdf.url = '';
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchInvoices = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE, method: 'GET' }));

		const { data, error } = await Api.invoices.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.invoicesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE,
					method: 'GET',
					message: 'Invoices fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchHospitalInvoices = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_INVOICES(id), method: 'GET' }));

		const { data, error } = await Api.invoices.getForHospital(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_INVOICES(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.invoicesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_INVOICES(id),
					method: 'GET',
					message: 'Hospital invoices fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchInvoice = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_ID(id), method: 'GET' }));

		const { data, error } = await Api.invoices.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.invoiceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_ID(id),
					method: 'GET',
					message: 'Invoice fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const sendPDF = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_SEND_ID(id), method: 'GET' }));

		const { data, error } = await Api.invoices.send(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_SEND_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_SEND_ID(id),
					method: 'GET',
					message: 'Invoice sent successfully',
					payload: { ...data! },
				})
			);
		}
	};
};

const generatePDF = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_GENERATE_ID(id), method: 'GET' }));

		const { data, error } = await Api.invoices.generate(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_GENERATE_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.pdfGenerated(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_GENERATE_ID(id),
					method: 'GET',
					message: 'PDF generated',
					payload: { ...data! },
				})
			);
		}
	};
};

const generateAccruedPDFs = (input: AccruedInvoiceInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_GENERATE_ACCRUE, method: 'POST' }));

		const { data, error } = await Api.invoices.generateAccrued(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_GENERATE_ACCRUE,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_GENERATE_ACCRUE,
					method: 'POST',
					message: 'Accrued PDFs generated successfully',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchInvoices());
		}
	};
};

const voidInvoice = (id: number, reason: string): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_VOID(id), method: 'POST' }));

		const { data, error } = await Api.invoices.void(id, reason);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_VOID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.invoiceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_VOID(id),
					method: 'POST',
					message: 'Invoice voided successfully',
					payload: {},
				})
			);
		}
	};
};

const voidInvoiceItems = (id: number, input: VoidItemsInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_VOID_ITEMS(id), method: 'POST' }));

		const { data, error } = await Api.invoices.voidItems(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_VOID_ITEMS(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_VOID_ITEMS(id),
					method: 'POST',
					message: 'Items voided successfully',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchInvoice(id));
		}
	};
};

const unvoidInvoice = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_UNVOID(id), method: 'POST' }));

		const { data, error } = await Api.invoices.unvoid(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_UNVOID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.invoiceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_UNVOID(id),
					method: 'POST',
					message: 'Invoice unvoided successfully',
					payload: {},
				})
			);
		}
	};
};

const unvoidInvoiceItems = (id: number, input: UnvoidItemsInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.INVOICE_UNVOID_ITEMS(id), method: 'POST' }));

		const { data, error } = await Api.invoices.unvoidItems(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.INVOICE_UNVOID_ITEMS(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.INVOICE_UNVOID_ITEMS(id),
					method: 'POST',
					message: 'Items unvoided successfully',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchInvoice(id));
		}
	};
};

export const invoiceThunks = {
	fetchInvoices,
	fetchHospitalInvoices,
	fetchInvoice,
	generatePDF,
	sendPDF,
	generateAccruedPDFs,
	voidInvoice,
	voidInvoiceItems,
	unvoidInvoice,
	unvoidInvoiceItems,
};
