import React from 'react';
import { Typography, Image } from 'lib/components';
import { Scrollbars } from 'react-custom-scrollbars';
import { useWindowResize } from 'hooks/useWindowResize';
import classes from './index.module.scss';

const inventory = [
	{ id: '0', name: 'Medical Item', count: 15 },
	{ id: '1', name: 'Surgical Mask', count: 20 },
	{ id: '2', name: 'Medicinal', count: 5 },
	{ id: '3', name: 'Pills', count: 3 },
	{ id: '4', name: 'Needles', count: 10 },
];

const skills = [
	{ id: '1', label: 'Many Variations' },
	{ id: '2', label: 'Words Which' },
	{ id: '3', label: 'Passage' },
	{ id: '4', label: 'Lorem Ipsum' },
	{ id: '5', label: 'Many Lore' },
	{ id: '5', label: 'Is a dummy text' },
	{ id: '5', label: 'Don"t even look' },
];

const timeline = [
	{ id: '0', time: '08/03/21 - 07:50AM', description: 'Hospital Submitted Job', location: 'NYC, 123th Street' },
	{
		id: '1',
		time: '08/03/21 - 10:50AM',
		description: 'Nurse Accepted Job',
		location: 'NYC, 23th Joogalo Street',
	},
];

const Overview = () => {
	const { isSmallDevice, isSmallerDevice, width } = useWindowResize();
	return (
		<>
			<div
				className={classes['row']}
				style={{
					padding: isSmallDevice ? '1rem' : '0.5rem 3rem 0rem 3rem',
				}}
			>
				<div
					className={classes['badge']}
					style={{
						marginTop: '0.5rem',
					}}
				>
					<Typography>
						Order Number:{' '}
						<span
							style={{
								fontWeight: 600,
							}}
						>
							#001256
						</span>
					</Typography>
				</div>
				<div
					className={classes['row']}
					style={{
						marginLeft: '1rem',
						marginTop: '0.5rem',
					}}
				>
					<Image src="icClockPrimary" />
					<Typography
						style={{
							marginLeft: '0.5rem',
						}}
					>
						Job submitted at : 08/08/21 - 07:33AM
					</Typography>
				</div>
				<div
					className={classes['row']}
					style={{
						marginLeft: 'auto',
					}}
				>
					<div
						className={classes['row']}
						style={{
							marginLeft: '2rem',
							marginTop: '0.5rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Action: </Typography>
						<div
							className={classes['badge']}
							style={{
								backgroundColor: '#FEFBF2',
								color: '#FFB903',
							}}
						>
							<Typography>Approved</Typography>
						</div>
					</div>
				</div>
			</div>
			<Typography
				variant="h5"
				weight="medium"
				style={{
					margin: '1rem 0',
					padding: '0rem 3rem 0rem 3rem',
				}}
			>
				In need of a nurse to support with a wounded patient
			</Typography>
			<div
				className={classes['row']}
				style={{
					padding: isSmallDevice ? '0 1rem' : '0rem 3rem 0rem 3rem',
				}}
			>
				<div
					style={{
						marginTop: '1rem',
					}}
				>
					<div className={classes['row']}>
						<div className={classes['icon-button']}>
							<Image
								style={{
									width: '0.75rem',
									height: '0.75rem',
								}}
								src="icThickPlusPrimary"
							/>
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Hospital Name</Typography>
					</div>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						weight="bold"
						style={{
							marginTop: '0.5rem',
						}}
					>
						Spencer Memorial Hospital
					</Typography>
				</div>
				<div className={classes['vertical-separator']} />
				<div
					style={{
						marginTop: '1rem',
					}}
				>
					<div className={classes['row']}>
						<div className={classes['icon-button']}>
							<Image
								style={{
									width: '0.75rem',
									height: '0.75rem',
								}}
								src="icClockPrimary"
							/>
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Date/Time</Typography>
					</div>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						weight="bold"
						style={{
							marginTop: '0.5rem',
						}}
					>
						03.01.21, 11:00AM
					</Typography>
				</div>
				<div className={classes['vertical-separator']} />

				<div
					style={{
						marginTop: '1rem',
					}}
				>
					<div className={classes['row']}>
						<div className={classes['icon-button']}>
							<Image
								style={{
									width: '0.75rem',
									height: '0.75rem',
								}}
								src="icEmail"
							/>
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Contact Information</Typography>
					</div>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						weight="bold"
						style={{
							marginTop: '0.5rem',
						}}
					>
						+1 68 54 71 248
					</Typography>
				</div>
				<div className={classes['vertical-separator']} />
				<div
					style={{
						marginTop: '1rem',
					}}
				>
					<div className={classes['row']}>
						<div className={classes['icon-button']}>
							<Image
								style={{
									width: '1rem',
									height: '1rem',
								}}
								src="icLocation"
							/>
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Job Location</Typography>
					</div>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						weight="bold"
						style={{
							marginTop: '0.5rem',
						}}
					>
						123th Main St. BTE B6, San Francisco, Carlifornia
					</Typography>
				</div>
				<div className={classes['vertical-separator']} />
				<div
					style={{
						marginTop: '1rem',
					}}
				>
					<div className={classes['row']}>
						<div className={classes['icon-button']}>
							<Image
								style={{
									width: '0.75rem',
									height: '0.75rem',
								}}
								src="icRoomPrimary"
							/>
						</div>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Room number</Typography>
					</div>
					<Typography
						variant={isSmallerDevice ? 'h5' : 'h6'}
						weight="bold"
						style={{
							marginTop: '0.5rem',
						}}
					>
						Floor 2, Room 123
					</Typography>
				</div>
			</div>
			<div
				style={{
					padding: isSmallDevice ? '0 1rem 1rem 1rem' : '0rem 3rem 1rem 3rem',
				}}
			>
				<div
					className={classes['row']}
					style={{
						alignItems: 'flex-start',
					}}
				>
					<div
						className={classes['wrapper']}
						style={{
							width: width <= 931 ? '100%' : '50%',
							minWidth: 300,
							margin: width <= 931 ? '1rem 0 0 0' : '1rem 1rem 0 0',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Map: </Typography>
						<Image
							src="map"
							style={{
								width: '100%',
								height: '14rem',
								marginTop: '0.5rem',
								borderRadius: '1rem',
								objectFit: 'cover',
							}}
						/>
					</div>
					<div
						className={classes['wrapper']}
						style={{
							flex: 1,
							height: '17.5rem',
							marginTop: '1rem',
							minWidth: 270,
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Status: </Typography>
						<div className={classes['starts-in-container']}>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									alignSelf: 'center',
									textAlign: 'center',
								}}
								color="primary"
							>
								Starts in{' '}
								<span
									style={{
										fontWeight: 500,
									}}
								>
									2 days, 11:00 AM
								</span>
							</Typography>
							<div
								className={classes['row']}
								style={{ justifyContent: 'space-between', margin: '0.25rem 0' }}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Not started</Typography>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>2 of 6</Typography>
							</div>
							<div className={classes['progress-container']}>
								<div
									className={classes['progress']}
									style={{
										width: `${(2 / 6) * 100}%`,
									}}
								/>
							</div>
						</div>

						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Timeline: </Typography>

						<div className={classes['wrapper']} style={{ marginTop: '0.5rem', flex: 1, padding: 0 }}>
							<Scrollbars>
								<div className={classes['timeline']}>
									<div
										className={classes['vertical-strip']}
										style={{
											height:
												!timeline?.length || timeline.length === 0
													? '100%'
													: `${timeline.length * 3.5}rem`,
										}}
									/>
									{timeline.map(({ id, description, location, time }) => (
										<div key={id} className={classes['row']}>
											<Typography
												variant={isSmallerDevice ? 'h5' : 'h6'}
												className={classes['first-col']}
											>
												{time}
											</Typography>
											<div className={classes['dot']} />
											<div className={classes['second-col']}>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="medium">
													{description}
												</Typography>
												<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
													{location}
												</Typography>
											</div>
										</div>
									))}
								</div>
							</Scrollbars>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					padding: isSmallDevice ? '1rem' : '1rem 3rem',
				}}
			>
				<div className={classes['row']}>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '1rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '14rem',
							}}
						>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginTop: isSmallerDevice ? '2rem' : '1rem',
								}}
							>
								Inventory Used/Assigned:{' '}
							</Typography>
							<div
								className={classes['wrapper']}
								style={{
									width: '100%',
									height: '100%',
									marginTop: '0.5rem',
								}}
							>
								<Scrollbars
									renderThumbVertical={(props) => (
										<div {...props} className={classes['vertical-scroll-thumb']} />
									)}
								>
									<div
										className={classes['row']}
										style={{
											width: '100%',
											padding: '0rem 1rem 1rem 1rem',
											justifyContent: 'space-between',
										}}
									>
										<div className={classes['row']}>
											<Typography
												variant={isSmallerDevice ? 'h5' : 'h6'}
												style={{
													marginRight: '0.5rem',
												}}
											>
												Name
											</Typography>
											<Image src="icDownCaret" />
										</div>
										<div className={classes['row']}>
											<Typography
												variant={isSmallerDevice ? 'h5' : 'h6'}
												style={{
													marginRight: '0.5rem',
												}}
											>
												Available
											</Typography>
											<Image src="icDownCaret" />
										</div>
									</div>
									{inventory.map(({ id, count, name }) => (
										<div className={classes['inventory-item']} key={id}>
											<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{name}</Typography>
											<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>{count}</Typography>
										</div>
									))}
								</Scrollbars>
							</div>
						</div>
					</div>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '14rem',
							}}
						>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginTop: isSmallerDevice ? '2rem' : '1rem',
								}}
							>
								Nurse Notes:{' '}
							</Typography>
							<div
								className={classes['wrapper']}
								style={{
									width: '100%',
									height: '100%',
									marginTop: '0.5rem',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
									Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam a aliquam impedit
									quisquam atque accusantium et in voluptate quam quibusdam eum, quod cum velit
									adipisci inventore, doloribus distinctio excepturi necessitatibus! Lorem ipsum dolor
									sit amet consectetur adipisicing elit. <br /> <br /> Lorem ipsum dolor, sit amet
									consectetur adipisicing elit. Earum illum iste voluptatem quidem pariatur id
									suscipit numquam error,
								</Typography>
							</div>
						</div>
					</div>
					<div
						style={{
							width: width <= 623 ? '100%' : '33.33%',
							height: '100%',
							minWidth: 300,
							marginTop: '2rem',
						}}
					>
						<div
							style={{
								width: width <= 623 ? '100%' : '96%',
								height: '15rem',
							}}
						>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginTop: isSmallerDevice ? '2rem' : '1rem',
								}}
							>
								Assigned Nurse:{' '}
							</Typography>
							<div
								className={classes['wrapper']}
								style={{
									width: '100%',
									height: '100%',
									marginTop: '0.5rem',
									padding: 0,
								}}
							>
								<div className={classes['assigned-nurse']}>
									<Image
										src="plNurse4"
										style={{
											width: '3rem',
											height: '3rem',
											borderRadius: '50%',
											margin: '0 0.5rem',
										}}
									/>
									<div>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="bold">
											Regina Smith
										</Typography>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Nurse staff</Typography>
									</div>
									<button
										className={classes['button']}
										style={{
											marginLeft: 'auto',
										}}
									>
										<Image
											src="icCall"
											style={{
												width: '1.3rem',
												height: '1.3rem',
												marginBottom: '0.5rem',
											}}
										/>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="bold">
											Call
										</Typography>
									</button>
									<button className={classes['button']}>
										<Image
											src="icEmail"
											style={{
												width: '1.3rem',
												height: '1.3rem',
												marginBottom: '0.5rem',
											}}
										/>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="bold">
											Message
										</Typography>
									</button>
								</div>
								<div
									className={classes['row']}
									style={{
										margin: '1rem 0',
										padding: '0 1rem',
									}}
								>
									<div
										style={{
											flex: 1,
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
											POSITION
										</Typography>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="medium"
											style={{
												marginTop: '0.5rem',
											}}
										>
											Nurse
										</Typography>
									</div>
									<div
										style={{
											flex: 1,
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
											MOBILE NUMBER
										</Typography>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="medium"
											style={{
												marginTop: '0.5rem',
											}}
										>
											+1 69 87 45 218
										</Typography>
									</div>
									<div
										style={{
											flex: 1,
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
											JOINED
										</Typography>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="medium"
											style={{
												marginTop: '0.5rem',
											}}
										>
											27.04.2019
										</Typography>
									</div>
								</div>
								<div
									className={classes['row']}
									style={{
										margin: '1rem 0',
										padding: '0 1rem',
									}}
								>
									<div
										style={{
											flex: 1,
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
											DATE OF BIRTH
										</Typography>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="medium"
											style={{
												marginTop: '0.5rem',
											}}
										>
											01.02.80
										</Typography>
									</div>
									<div
										style={{
											marginLeft: '1rem',
											flex: 2,
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
											EMAIL
										</Typography>
										<Typography
											variant={isSmallerDevice ? 'h5' : 'h6'}
											weight="medium"
											style={{
												marginTop: '0.5rem',
											}}
										>
											reginasmith@gmail.com
										</Typography>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={classes['row']}
				style={{
					marginTop: '1rem',
					padding: isSmallDevice ? '0 1rem 1rem 1rem' : '0rem 3rem 3rem 3rem',
				}}
			>
				<div style={{ width: width <= 623 ? '100%' : '33.33%', minWidth: 300, marginTop: '2rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '96%',
							height: '12rem',
						}}
					>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							Requirements:{' '}
						</Typography>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								height: '100%',
								marginTop: '0.5rem',
							}}
						>
							<Scrollbars>
								<div className={classes['row']}>
									{skills.map(({ id, label }) => (
										<div
											className={classes['badge']}
											key={id}
											style={{
												margin: '0 1rem 1rem 0',
											}}
										>
											<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="primary">
												{label}
											</Typography>
										</div>
									))}
									<button
										className={classes['badge']}
										style={{
											margin: '0 1rem 1rem 0',
											backgroundColor: '#F5F5F5',
										}}
									>
										<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>+ Add more</Typography>
									</button>
								</div>
							</Scrollbars>
						</div>
					</div>
				</div>
				<div style={{ width: width <= 623 ? '100%' : '33.33%', minWidth: 300, marginTop: '2rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '96%',
							height: '12rem',
						}}
					>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							Description:{' '}
						</Typography>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								height: '100%',
								marginTop: '0.5rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde temporibus non corporis
								illo error et alias doloremque saepe, voluptatum soluta tempore, placeat autem
								dignissimos repellat suscipit ut itaque iure ullam deserunt esse eos. Mollitia
								doloremque, tempore soluta assumenda ab natus rem dolorem illo tenetur expedita incidunt
								vitae, repellat doloribus illum!
							</Typography>
						</div>
					</div>
				</div>
				<div style={{ width: width <= 623 ? '100%' : '33.33%', minWidth: 300, marginTop: '2rem' }}>
					<div
						style={{
							width: width <= 623 ? '100%' : '96%',
							height: '12rem',
						}}
					>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							style={{
								marginTop: isSmallerDevice ? '2rem' : '1rem',
							}}
						>
							Downloads:{' '}
						</Typography>
						<div
							style={{
								width: '100%',
								height: '100%',
								marginTop: '0.5rem',
							}}
						>
							<button
								className={classes['row']}
								style={{
									padding: '0.75rem 1rem',
									border: '1px solid #E5E5E5',
									borderRadius: '0.5rem',
									backgroundColor: '#FCFCFC',
									marginBottom: '0.5rem',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="medium">
									Download Patient Data (CSV)
								</Typography>
								<Image src="icDownloadPrimary" />
							</button>
							<button
								className={classes['row']}
								style={{
									padding: '0.75rem 1rem',
									border: '1px solid #E5E5E5',
									borderRadius: '0.5rem',
									backgroundColor: '#FCFCFC',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="medium">
									Download Client Data (CSV)
								</Typography>
								<Image src="icDownloadPrimary" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Overview;
