import React, { useState, useEffect } from 'react';

import ReactDom from 'react-dom';
import Button from '../Button';
import { Checkbox } from '../FormFields';
import Image from '../Image';
import Typography from '../Typography';
import TimeField from '../WorkingHourInputField/TimeField';
import { DayItem, Time, WorkingHoursFactory } from '../WorkingHourInputField/types';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	workingHoursFactory: WorkingHoursFactory;
	onClose: () => any;
	onConfirmed: (dayItem: DayItem) => any;
	onNextDay: () => any;
	onPrevDay: () => any;
}

const WorkingHourModal = ({ isOpen, onClose, onConfirmed, onNextDay, onPrevDay, workingHoursFactory }: Props) => {
	if (!isOpen) {
		return null;
	}
	const [selectedDayItem, setSelectedDayItem] = useState<DayItem>(
		workingHoursFactory.days[workingHoursFactory.selectedIndex]
	);

	useEffect(() => {
		setSelectedDayItem(workingHoursFactory.days[workingHoursFactory.selectedIndex]);
	}, [workingHoursFactory]);

	const setTime = (time: Time, when: 'startTime' | 'endTime') => {
		setSelectedDayItem((prev) => {
			const newItem: DayItem = {
				...prev,
				[when]: time,
			};
			return newItem;
		});
	};

	const togglePm = (when: 'startTime' | 'endTime') => {
		setSelectedDayItem((prev) => {
			const newItem: DayItem = {
				...prev,
				[when]: { ...prev[when], pm: !prev[when].pm },
			};
			return newItem;
		});
	};

	const toggleIsOperating = () => {
		setSelectedDayItem((prev) => {
			const newItem: DayItem = {
				...prev,
				isOperating: !prev.isOperating,
			};
			return newItem;
		});
	};

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<Image onClick={onClose} src="icTimes" className={classes['modal-close']} />
				<Typography className={classes['modal-title']} variant="h6">
					Set Work Hours
				</Typography>
				<div className={classes['modal-day']}>
					<div onClick={onPrevDay} className={classes['modal-day-button']}>
						<Image src="icChevronLeft" />
					</div>
					<Typography className={classes['modal-day-title']}>{selectedDayItem.day}</Typography>
					<div onClick={onNextDay} className={classes['modal-day-button']}>
						<Image src="icCheveronRight" />
					</div>
				</div>
				<div className={classes['modal-times']}>
					<Checkbox
						style={{ marginRight: '1rem' }}
						labelStyle={{ position: 'relative', bottom: '1rem' }}
						checked={selectedDayItem.isOperating}
						label={'Is Operating'}
						onChange={toggleIsOperating}
					/>
					<div className={classes['modal-timesItem']}>
						<Typography className={classes['modal-timesItem-label']}>Start time:</Typography>
						<div className={classes['modal-timesItem-value']}>
							<TimeField
								bare
								textClassName={classes['modal-timesItem-timeFieldText']}
								hr={selectedDayItem.startTime.hr}
								min={selectedDayItem.startTime.min}
								pm={selectedDayItem.startTime.pm}
								onChange={(time) => setTime(time, 'startTime')}
								disabled={!selectedDayItem.isOperating}
							/>
							<div className={classes['modal-timesPM']} onClick={() => togglePm('startTime')}>
								<Image src="icChevronUpSm" />
								<Typography className={classes['modal-timesPM-text']}>
									{selectedDayItem.startTime.pm ? 'PM' : 'AM'}
								</Typography>
								<Image src="icChevronDownSm" />
							</div>
						</div>
					</div>
					<div className={classes['modal-timesItem']}>
						<Typography className={classes['modal-timesItem-label']}>End time:</Typography>
						<div className={classes['modal-timesItem-value']}>
							<TimeField
								bare
								textClassName={classes['modal-timesItem-timeFieldText']}
								hr={selectedDayItem.endTime.hr}
								min={selectedDayItem.endTime.min}
								pm={selectedDayItem.endTime.pm}
								onChange={(time) => setTime(time, 'endTime')}
								disabled={!selectedDayItem.isOperating}
							/>
							<div className={classes['modal-timesPM']} onClick={() => togglePm('endTime')}>
								<Image src="icChevronUpSm" />
								<Typography className={classes['modal-timesPM-text']}>
									{selectedDayItem.endTime.pm ? 'PM' : 'AM'}
								</Typography>
								<Image src="icChevronDownSm" />
							</div>
						</div>
					</div>
				</div>
				<div className={classes['modal-buttons']}>
					<Button
						variant="standard"
						color="primary"
						title="Confirm"
						onClick={() => {
							onConfirmed(selectedDayItem);
							onClose();
						}}
						block
					/>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default WorkingHourModal;
