import { JobTreatmentType } from './enums';

const parseTreatmentType = (treatmentType: JobTreatmentType) => {
	switch (treatmentType) {
		case JobTreatmentType.HD:
			return 'Hemodialysis';
		case JobTreatmentType.PD:
			return 'Peritoneal Dialysis';

		case JobTreatmentType.SLED:
			return 'SLED';
	}

	return 'Unknown';
};

export default parseTreatmentType;
