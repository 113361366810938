import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { Timing } from 'lib/util';
import { Inventory } from 'types';
import classes from './index.module.scss';
import { useSelectSettings } from '../../../../store';
import adjustTime from '../../../adjustTime';

interface Props {
	inventory: Inventory;
	vendorName: string;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
	onRowClick: (inventory: Inventory) => void;
}

const pointerProp = { cursor: 'pointer' };

const InventoryRow = ({ inventory, vendorName, checkedId, checkAction, onRowClick }: Props) => {
	const settingsState = useSelectSettings();

	const [checked, setChecked] = useState(false);

	const handleClick = useCallback(() => {
		onRowClick(inventory);
	}, []);

	useEffect(() => {
		if (checkedId === inventory.id) setChecked(true);
		if (checkedId !== inventory.id || checkedId === -1) setChecked(false);
	}, [checkedId]);

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(inventory.id, e.target.checked);
	};

	return (
		<div className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleAction}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>{inventory.name}</Typography>
			</div>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>{vendorName}</Typography>
			</div>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>{inventory.stock}</Typography>
			</div>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>${(inventory.cost / inventory.packageQuantity).toFixed(2)}</Typography>
			</div>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>${inventory.cost.toFixed(2)}</Typography>
			</div>
			<div className={classes['col']} style={pointerProp} onClick={handleClick}>
				<Typography variant={'h6'}>
					{adjustTime(settingsState, inventory.updatedAt).format('MM/DD/YYYY hh:mm A')}
				</Typography>
			</div>
		</div>
	);
};

export default InventoryRow;
