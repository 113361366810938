import useAddIdPrefix from 'hooks/useAddIdPrefix';
import { Image, Typography } from 'lib/components';
import { JobStatus } from 'lib/util';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelectAuthorization } from 'store';
import { Job } from 'types';
import parseTreatmentType from '../../../util/parseTreatmentType';
import parseType from '../../../util/parseType';
import parseTypeColor from '../../../util/parseTypeColor';
import classes from './index.module.scss';

interface Props {
	job: Job;
}

const JobRow: React.FC<Props> = ({ job }: Props) => {
	const { push } = useHistory();
	const addPrefix = useAddIdPrefix();
	const { canReadJob } = useSelectAuthorization();

	return (
		<div className={classes['table-row']} onClick={() => canReadJob && push(`/jobs/view/${job.id}`)}>
			<div className={classes['col']}>
				{!job.isWaiting && (
					<Typography color={JobStatus.color(job.status)}>{JobStatus.text(job.status)}</Typography>
				)}
				{job.isWaiting && <Typography color={'warning'}>WAITING</Typography>}
			</div>
			<div className={classes['col']}>
				<Typography color={parseTypeColor(job.typeId)}>{parseType(job.typeId)}</Typography>
			</div>
			<div className={classes['col']}>
				<Typography>{parseTreatmentType(job.treatmentType)}</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{addPrefix(job.id)}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{job.hospital ? job.hospital.name : '-'}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{job.roomNumber ?? '-'}
				</Typography>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-assignedNurse']}>
					<Image src="icNurses" style={{ width: '1rem' }} />
					<Typography weight="regular" variant={'h6'}>
						{job.orderingPhysician ?? '-'}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-createdBy']}>
					<Image src="icUser" />
					<Typography weight="regular" variant={'h6'}>
						{job.patientName ?? '-'}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-assignedNurse']}>
					<Image src="icNurses" style={{ width: '1rem' }} />
					<Typography weight="regular" variant={'h6'}>
						{job.nurse?.user?.name ?? '-'}
					</Typography>
				</div>
			</div>

			<div className={classes['col']}>
				<div className={classes['col-startTime']}>
					<Image src="icClockSm" />
					<Typography weight="regular" variant={'h6'}>
						{job.startingAt}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default JobRow;
