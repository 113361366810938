import React, { useState } from 'react';
import { Typography, Image } from 'lib/components';
import classes from './index.module.scss';
import { Checkbox } from 'lib/components/FormFields';
import Scrollbars from 'react-custom-scrollbars';
import { useWindowResize } from 'hooks/useWindowResize';

const toolbarItems = [
	{ id: '0', label: 'Requested by' },
	{ id: '1', label: 'Ticket ID' },
	{ id: '2', label: 'Sent to' },
	{ id: '3', label: 'Amount' },
	{ id: '4', label: 'Price' },
	{ id: '5', label: 'Created Date' },
];

const tableItems = [
	{
		id: '0',
		checked: true,
		requestedBy: 'Spencer Memorial Hospital',
		ticketId: '0001478',
		sentTo: 'Regina Smith',
		amount: 1,
		price: 14.99,
		createdDate: '01.01.21 - 09:45AM',
	},
	{
		id: '1',
		checked: false,
		requestedBy: 'Spencer Memorial Hospital',
		ticketId: '0001474',
		sentTo: 'Johnina Doetson',
		amount: 15,
		price: 259.49,
		createdDate: '01.01.21 - 09:10AM',
	},
	{
		id: '2',
		checked: false,
		requestedBy: 'Spencer Memorial Hospital',
		ticketId: '0001471',
		sentTo: 'Inventory LLC',
		amount: 100,
		price: 1000.99,
		createdDate: '01.01.21 - 07:25AM',
	},
];

const pages = [1, 2, 3, 4];

const Log = () => {
	const [allChecked, setAllChecked] = useState(false);
	const [selectedPage, setSelectedPage] = useState(1);
	const { isSmallDevice, isSmallerDevice } = useWindowResize();
	return (
		<div
			className={classes['dispatches-logs-container']}
			style={{
				padding: isSmallDevice ? '0 1rem' : undefined,
			}}
		>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']}>
					<button
						className={classes['button']}
						style={{
							width: '8rem',
							backgroundColor: '#E4F4F6',
							borderColor: '#A6E5E9',
							padding: '0 1rem',
							marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
							Accept All
						</Typography>
						<Image
							src="icArrowDown"
							style={{
								marginLeft: 'auto',
							}}
						/>
					</button>
					<button
						className={classes['button']}
						style={{
							backgroundColor: '#F2F2F2',
							borderColor: '#A9A9A9',
							marginTop: '1rem',
						}}
					>
						<Image
							src="icArchive"
							style={{
								marginRight: '0.5rem',
							}}
						/>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Archive</Typography>
					</button>
				</div>
				<button
					className={classes['button']}
					style={{
						backgroundColor: '#E7F7F9',
						marginTop: '1rem',
					}}
				>
					<Image
						src="icFilterPrimary"
						style={{
							marginRight: '0.5rem',
						}}
					/>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Filter by</Typography>
				</button>
			</div>

			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={() => setAllChecked(!allChecked)}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{toolbarItems.map(({ id, label }) => (
							<div className={classes['col']} key={id}>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
									{label}
								</Typography>
								<Image
									src="icArrowDown"
									style={{
										marginLeft: '0.5rem',
									}}
								/>
							</div>
						))}
					</div>
					<div
						className={classes['container']}
						style={{
							padding: '0 1rem',
						}}
					>
						<Scrollbars>
							{tableItems.map(
								({ id, amount, checked, createdDate, price, requestedBy, sentTo, ticketId }) => (
									<div key={id} className={classes['table-row']}>
										<Checkbox
											checked={checked}
											style={{
												margin: '0 2rem',
											}}
										/>
										<Typography variant={'h6'} className={classes['col']}>
											{requestedBy}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{ticketId}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{sentTo}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{amount}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{price}$
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{createdDate}
										</Typography>
									</div>
								)
							)}
						</Scrollbars>
					</div>
				</div>
			</div>
			<div className={classes['bottom-toolbar']}>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						First
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Prev.
					</Typography>
				</button>
				{pages.map((page) => {
					const classname =
						page === selectedPage ? `${classes['badge']} ${classes['active']}` : `${classes['badge']}`;
					return (
						<button className={classname} key={page.toString()} onClick={() => setSelectedPage(page)}>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								color={page === selectedPage ? 'primary' : 'darkgrey'}
							>
								{page}
							</Typography>
						</button>
					);
				})}

				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Next
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Last
					</Typography>
				</button>
			</div>
		</div>
	);
};

export default Log;
