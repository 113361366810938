import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Image, Typography, LoadingIndicator } from 'lib/components';
import InvoiceDetailDataTable from 'lib/components/DataTables/InvoiceDetail';
import InvoiceLogDataTable from 'lib/components/DataTables/InvoiceLog';
import ActionButton from './ActionButton';
import UnvoidInvoiceModal from './UnvoidInvoiceModal';
import VoidInvoiceModal from './VoidInvoiceModal';
import VoidInvoiceItemsModal from './VoidInvoiceItemsModal';
import {
	useAppDispatch,
	actions,
	useSelectInvoice,
	useSelectAuthorization,
	useSelectRequest,
	RequestManager,
	useSelectSettings,
} from 'store';
import { useComprehensiveTopNav, useAddIdPrefix, useCustomToast, useInvoiceDownload } from 'hooks';
import { InvoiceItemsAllCheckedStatus, Invoice } from 'types';
import routes from 'lib/api/routes';
import { getLocation } from 'lib/util';
import classes from './index.module.scss';
import PaymentStatusDropdown from './PaymentStatusDropdown';
import adjustTime from '../../lib/adjustTime';

interface Props {
	invoice: Invoice;
}

const InvoiceDetailScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const getInvoiceById = useSelectInvoice();

	const [invoice, setInvoice] = useState<Invoice>();

	useEffect(() => {
		dispatch(actions.fetchInvoice(parseInt(id)));
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.INVOICE_ID(parseInt(id)), 'GET')) {
			if (RM.succeeded()) {
				const invoice = getInvoiceById(parseInt(id));

				if (invoice) {
					setInvoice(invoice);
				}
			}
		}
	}, [active]);

	return invoice ? <InvoiceDetailScreenView invoice={invoice} /> : <LoadingIndicator />;
};

const InvoiceDetailScreenView = ({ invoice }: Props) => {
	const toast = useCustomToast();
	const addPrefix = useAddIdPrefix();

	const dispatch = useAppDispatch();

	const { active } = useSelectRequest();
	const settingsState = useSelectSettings();
	const { canVoidInvoice } = useSelectAuthorization();

	const [voided, setVoided] = useState(invoice.reason ? true : false);
	const [voidInvoiceModalOpen, setVoidInvoiceModalOpen] = useState(false);
	const [voidInvoiceItemsModalOpen, setVoidInvoiceItemsModalOpen] = useState(false);
	const [unvoidInvoiceModalOpen, setUnvoidInvoiceModalOpen] = useState(false);
	const [checked, setChecked] = useState<number[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [allCheckedStatus, setAllCheckedStatus] = useState<InvoiceItemsAllCheckedStatus>('none');

	const voidHandler = useCallback(() => {
		if (voided) {
			setUnvoidInvoiceModalOpen(true);
		} else {
			setVoidInvoiceModalOpen(true);
		}
	}, [voided]);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.INVOICE_VOID(invoice.id), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setVoidInvoiceModalOpen(false);
				setVoided(true);
			}
		}

		if (RM.req(routes.INVOICE_VOID_ITEMS(invoice.id), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setVoidInvoiceItemsModalOpen(false);
				setAllCheckedStatus('none');
			}
		}

		if (RM.req(routes.INVOICE_UNVOID(invoice.id), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setVoidInvoiceModalOpen(false);
				setVoided(false);
			}
		}

		if (RM.req(routes.INVOICE_UNVOID_ITEMS(invoice.id), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setAllCheckedStatus('none');
			}
		}

		if (RM.req(routes.INVOICE_SEND_ID(invoice.id), 'GET')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}

		if (RM.req(routes.INVOICE_GENERATE_ID(invoice.id), 'GET')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		if (allCheckedStatus === 'some') {
			return;
		}

		if (allCheckedStatus === 'all') {
			setChecked(invoice.items.map(({ id }) => id));
		}

		if (allCheckedStatus === 'none') {
			setChecked([]);
		}

		if (allCheckedStatus === 'voided') {
			const voidedItemIds = invoice.items.filter((item) => item.isVoided).map(({ id }) => id);
			setChecked((prev) => prev.filter((item) => voidedItemIds.includes(item)));
		}

		if (allCheckedStatus === 'unvoided') {
			const unvoidedItemIds = invoice.items.filter((item) => !item.isVoided).map(({ id }) => id);
			setChecked((prev) => prev.filter((item) => unvoidedItemIds.includes(item)));
		}
	}, [allCheckedStatus]);

	const handleCheckAction = (id: number, isChecked: boolean, isVoided: boolean) => {
		if (allCheckedStatus !== 'some') {
			setAllChecked(false);
			setAllCheckedStatus(isVoided ? 'voided' : 'unvoided');
		}

		if (isChecked && !checked.includes(id)) {
			setChecked((prev) => [...prev, id]);
			return;
		}

		if (!isChecked && checked.includes(id)) {
			setChecked((prev) => prev.filter((item) => item !== id));
		}
	};

	const handleAllCheckAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		if (checked) {
			setAllChecked(checked);
			setAllCheckedStatus('all');
		} else {
			{
				setAllChecked(checked);
				setAllCheckedStatus('none');
			}
		}
	};

	const handleInvoiceSend = useCallback(() => {
		dispatch(actions.sendPDF(invoice.id));
	}, []);

	const handleInvoiceGenerate = useCallback(() => {
		dispatch(actions.generatePDF(invoice.id));
	}, []);

	const handleInvoiceVoid = useCallback((reason: string) => {
		dispatch(actions.voidInvoice(invoice.id, reason));
	}, []);

	const handleItemsVoid = useCallback(
		(reason: string) => {
			dispatch(actions.voidInvoiceItems(invoice.id, { reason, ids: checked }));
		},
		[checked]
	);

	const handleInvoiceUnvoid = useCallback(() => {
		dispatch(actions.unvoidInvoice(invoice.id));
	}, []);

	const handleUnvoidItems = useCallback(() => {
		dispatch(actions.unvoidInvoiceItems(invoice.id, { ids: checked }));
	}, [checked]);

	useInvoiceDownload();

	useComprehensiveTopNav(`Invoice - ${addPrefix(invoice.id)}`);

	return (
		<>
			<VoidInvoiceModal
				isOpen={voidInvoiceModalOpen}
				onClose={() => setVoidInvoiceModalOpen(false)}
				onVoidInvoice={handleInvoiceVoid}
			/>
			<VoidInvoiceItemsModal
				isOpen={voidInvoiceItemsModalOpen}
				onClose={() => setVoidInvoiceItemsModalOpen(false)}
				onVoidInvoiceItems={handleItemsVoid}
			/>
			{console.log({ checked, allCheckedStatus })}
			<UnvoidInvoiceModal
				isOpen={unvoidInvoiceModalOpen}
				reason={invoice.reason}
				onClose={() => setUnvoidInvoiceModalOpen(false)}
				onUnvoidInvoice={handleInvoiceUnvoid}
			/>
			<div className={classes['invoiceDetail']}>
				<div className={classes['invoiceDetail-top']}>
					<div className={classes['invoiceDetail-top-infoGroup']}>
						<div className={classes['infoGroupItem']}>
							<div className={classes['infoGroupItem-label']}>
								<div className={classes['infoGroupItem-labelIcon']}>
									<Image src="icPlusPrimary" />
								</div>
								<Typography className={classes['infoGroupItem-labelTitle']}>Hospital Name</Typography>
							</div>
							<Typography weight="bold">{invoice.hospital!.name}</Typography>
						</div>
						<div className={classes['infoGroupItem-separator']} />
						<div className={classes['infoGroupItem']}>
							<div className={classes['infoGroupItem-label']}>
								<div className={classes['infoGroupItem-labelIcon']}>
									<Image src="icClockPrimary" />
								</div>
								<Typography className={classes['infoGroupItem-labelTitle']}>
									Invoice Created Date
								</Typography>
							</div>
							<Typography weight="bold">
								{adjustTime(settingsState, invoice.createdAt, 'MM/DD/YYYY hh:mm A').format(
									'MM/DD/YYYY hh:mm A'
								)}
							</Typography>
						</div>
						<div className={classes['infoGroupItem-separator']} />
						<div className={classes['infoGroupItem']}>
							<div className={classes['infoGroupItem-label']}>
								<div className={classes['infoGroupItem-labelIcon']}>
									<Image src="icLocation" />
								</div>
								<Typography className={classes['infoGroupItem-labelTitle']}>
									Hospital Location
								</Typography>
							</div>
							<Typography weight="bold">{getLocation(invoice?.hospital?.address)}</Typography>
						</div>
					</div>
					<div className={classes['invoiceDetail-top-actions']}>
						<PaymentStatusDropdown
							currentStatus={invoice.paymentStatus}
							onSelectStatus={(status) => console.log(status)}
						/>
						{canVoidInvoice && (
							<ActionButton
								onClick={voidHandler}
								title={voided ? 'Job Voided' : 'Void Invoice'}
								status={voided ? 'danger' : 'primary'}
								leftIconSrc="icDangerAlert"
							/>
						)}
					</div>
				</div>
				<div className={classes['invoiceDetail-content']}>
					<div className={classes['invoiceDetail-details']}>
						<Typography variant="h5" weight="bold">
							Invoice Details
						</Typography>
						{allCheckedStatus !== 'none' && checked.length !== 0 && (
							<>
								{allCheckedStatus === 'unvoided' && (
									<div className={classes['invoiceDetail-details-actions']}>
										{canVoidInvoice && (
											<a
												style={{ cursor: 'pointer' }}
												onClick={() => setVoidInvoiceItemsModalOpen(true)}
											>
												<Typography variant="h6">Void Items</Typography>
											</a>
										)}
									</div>
								)}
								{allCheckedStatus === 'voided' && (
									<div className={classes['invoiceDetail-details-actions']}>
										{canVoidInvoice && (
											<a style={{ cursor: 'pointer' }} onClick={handleUnvoidItems}>
												<Typography variant="h6">Unvoid Items</Typography>
											</a>
										)}
									</div>
								)}
							</>
						)}
						<div className={classes['invoiceDetail-details-table']}>
							<InvoiceDetailDataTable
								invoice={invoice}
								allChecked={allChecked}
								allCheckedStatus={allCheckedStatus}
								handleAllCheckAction={handleAllCheckAction}
								checkAction={handleCheckAction}
							/>
						</div>
					</div>
					<div className={classes['invoiceDetail-logs']}>
						<Typography variant="h5" weight="bold">
							Logs
						</Typography>
						<div className={classes['invoiceDetail-logs-table']}>
							<InvoiceLogDataTable logs={invoice.logs} />
						</div>
					</div>
				</div>
				<div className={classes['invoiceDetail-foot']}>
					<Button
						className={classes['btn']}
						title="Sent PDF by Email"
						variant="standard"
						onClick={handleInvoiceSend}
					/>
					<Button
						className={classes['btn']}
						title="Generate as PDF"
						variant="standard"
						onClick={handleInvoiceGenerate}
					/>
				</div>
			</div>
		</>
	);
};

export default InvoiceDetailScreen;
