import JobType from './JobType';

const parseTypeColor = (type: JobType) => {
	switch (type) {
		case JobType.ROUTINE:
			return 'success';

		case JobType.STAT:
			return 'danger';
	}

	return 'warning';
};

export default parseTypeColor;
