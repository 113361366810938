import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOption } from 'lib/components/types';
import { EmployeeType, NurseEmployeeType as NET } from 'lib/util';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import classes from './index.module.scss';

interface Props {
	value?: EmployeeType;
	setValue: React.Dispatch<React.SetStateAction<EmployeeType>>;
}

const options: SelectOption<EmployeeType>[] = [
	{ label: NET.text(EmployeeType.FULL_TIME), value: EmployeeType.FULL_TIME },
	{ label: NET.text(EmployeeType.PART_TIME), value: EmployeeType.PART_TIME },
	{ label: NET.text(EmployeeType.CONTRACTOR), value: EmployeeType.CONTRACTOR },
];

const EmployeeTypeSelect = ({ value, setValue }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [option, setOption] = useState<SelectOption<EmployeeType> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<EmployeeType> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	return (
		<>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				Employee Type:
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</>
	);
};

export default EmployeeTypeSelect;
