import React from 'react';
import { useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Image, Typography } from 'lib/components';
import MenuList from './MenuList';
import { useAppDispatch, actions, useSelectTopNavState, useSelectCurrentUser, useSelectAuthorization } from 'store';
import { SIDE_NAV_ITEMS, SIDE_NAV_EXTRA_ITEMS } from 'lib/util';
import classes from './index.module.scss';

const SideNavModal = () => {
	const { push } = useHistory();

	const dispatch = useAppDispatch();
	const me = useSelectCurrentUser();
	const { canCreateJob } = useSelectAuthorization();
	const { sidenavVisible, searchBarVisible } = useSelectTopNavState();

	const closeMenu = () => {
		dispatch(actions.sidenavToggled(false));
	};

	return (
		<AnimatePresence>
			{sidenavVisible && (
				<motion.div
					initial={{
						opacity: 0,
						translateX: '-60%',
					}}
					animate={{
						opacity: 1,
						translateX: '0%',
						transition: {
							duration: 0.5,
						},
					}}
					exit={{
						opacity: 0,
						translateX: '-60%',
					}}
					className={classes['sidenav-modal']}
				>
					<div className={classes['sidenav-container']}>
						{searchBarVisible && (
							<div className={classes['search-toolbar']}>
								<input placeholder="Search..." />
								<Image src="icSearch" />
							</div>
						)}
						<div className={classes['menu-items']}>
							<Typography
								variant="h6"
								style={{
									marginTop: '2rem',
									marginLeft: '0.5rem',
								}}
								color="darkgrey"
							>
								MENU
							</Typography>
							<MenuList list={SIDE_NAV_ITEMS} />
							{canCreateJob && (
								<button className={classes['create-new-dispatch']} onClick={() => push('/jobs/create')}>
									<p>Add New Job</p>
									<div className={classes['plus-icon']}>+</div>
								</button>
							)}
							<Typography
								variant="h6"
								style={{
									marginTop: 'auto',
									marginLeft: '0.5rem',
								}}
								color="darkgrey"
							>
								EXTRA
							</Typography>
							<MenuList list={SIDE_NAV_EXTRA_ITEMS} />
							<div className={classes['admin-profile']}>
								<Image
									src={me.avatar ?? 'plAvatar'}
									style={{
										width: '4.4rem',
										height: '4.4rem',
										borderRadius: '50%',
										marginRight: '1rem',
									}}
								/>
								<div>
									<Typography variant="h4">{me.name}</Typography>
									<Typography
										variant="h4"
										weight="light"
										color="darkgrey"
										style={{ marginTop: '0.5rem' }}
									>
										{me.roleId === 1 ? 'Super Administrator' : 'Administrator'}
									</Typography>
								</div>
							</div>
						</div>
					</div>
					<button className={classes['close-button']} onClick={closeMenu}>
						<Image src="icCancelBlack" />
					</button>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default SideNavModal;
