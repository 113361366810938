import { Color } from 'lib/components/types';
import { JobStatusType as S } from '.';

export default class JobStatus {
	static readonly PENDING = 0;
	static readonly ACCEPTED = 1;
	static readonly ACKNOWLEDGED = 2;
	static readonly ARRIVED = 3;
	static readonly NURSE_ACCEPTED = 4;
	static readonly NURSE_REJECTED = 5;
	static readonly TREATMENT_INITIATED = 6;
	static readonly TREATMENT_COMPLETED = 7;
	static readonly COMPLETED = 8;
	static readonly ABORTED = 9;
	static readonly CANCELLED = 10;
	static readonly VOIDED = 11;

	private static readonly STATUS_TEXT = {
		[JobStatus.PENDING]: 'Pending',
		[JobStatus.ACCEPTED]: 'Accepted',
		[JobStatus.ACKNOWLEDGED]: 'Acknowledged',
		[JobStatus.ARRIVED]: 'Arrived',
		[JobStatus.NURSE_ACCEPTED]: 'Nurse Accepted',
		[JobStatus.NURSE_REJECTED]: 'Nurse Rejected',
		[JobStatus.TREATMENT_INITIATED]: 'Treatment Initiated',
		[JobStatus.TREATMENT_COMPLETED]: 'Treatment Completed',
		[JobStatus.COMPLETED]: 'Completed',
		[JobStatus.ABORTED]: 'Aborted',
		[JobStatus.CANCELLED]: 'Canceled',
		[JobStatus.VOIDED]: 'Voided',
	};

	public static text = (type: S) => JobStatus.STATUS_TEXT[type];

	public static isActive = (type: S) =>
		[S.ACKNOWLEDGED, S.ARRIVED, S.NURSE_ACCEPTED, S.TREATMENT_INITIATED, S.TREATMENT_COMPLETED].includes(type);

	public static isApproved = (type: S) => [S.ACCEPTED, S.NURSE_ACCEPTED].includes(type);

	public static isChatOpen = (status: S) =>
		[S.NURSE_ACCEPTED, S.TREATMENT_INITIATED, S.TREATMENT_COMPLETED].includes(status);

	public static progressText = (status: S) => {
		if ([S.PENDING, S.ACCEPTED, S.ACKNOWLEDGED, S.ARRIVED].includes(status)) return 'Not Started';
		if (status === S.NURSE_ACCEPTED) return 'Nurse accepted job';
		if (status === S.TREATMENT_INITIATED) return 'Nurse initialized treatment';
		if (status === S.TREATMENT_COMPLETED) return 'Nurse completed treatment';
		if (status === S.COMPLETED) return 'Job completed';
		if (status === S.NURSE_REJECTED) return 'Nurse rejected job';
		if (status === S.CANCELLED) return 'Job cancelled';
		if (status === S.ABORTED) return 'Job aborted';
		if (status === S.VOIDED) return 'Job voided';
	};

	public static showProgressFractional = (status: S) => ![S.VOIDED, S.NURSE_REJECTED, S.CANCELLED].includes(status);

	public static progressFractional = (status: S): string => {
		if (status === S.PENDING) return '1 of 8';
		if (status === S.ACCEPTED) return '2 of 8';
		if (status === S.ACKNOWLEDGED) return '3 of 8';
		if (status === S.ARRIVED) return '4 of 8';
		if (status === S.NURSE_ACCEPTED) return '5 of 8';
		if (status === S.TREATMENT_INITIATED) return '6 of 8';
		if (status === S.TREATMENT_COMPLETED) return '7 of 8';
		if (status === S.COMPLETED) return '8 of 8';
		return '';
	};

	public static progressPercentage = (status: S): number => {
		if (status === S.PENDING) return (1 / 8) * 100;
		if (status === S.ACCEPTED) return (2 / 8) * 100;
		if (status === S.ACKNOWLEDGED) return (3 / 8) * 100;
		if (status === S.ARRIVED) return (4 / 8) * 100;
		if (status === S.NURSE_ACCEPTED) return (5 / 8) * 100;
		if (status === S.TREATMENT_INITIATED) return (6 / 8) * 100;
		if (status === S.TREATMENT_COMPLETED) return (7 / 8) * 100;
		if (status === S.COMPLETED) return 100;
		else return 0;
	};

	public static color = (status: S): Color => {
		if (status === S.PENDING) {
			return 'warning';
		}

		if ([S.NURSE_REJECTED, S.CANCELLED].includes(status)) {
			return 'danger';
		}

		if ([S.COMPLETED].includes(status)) {
			return 'darkgrey';
		}

		return 'success';
	};
}
