import { EmployeeType as ET } from './enums';

export default class NurseEmployeeType {
	static readonly FULL_TIME = 0;
	static readonly PART_TIME = 1;
	static readonly CONTRACTOR = 2;

	private static readonly STATUS_TEXT = {
		[NurseEmployeeType.FULL_TIME]: 'Full Time',
		[NurseEmployeeType.PART_TIME]: 'Part Time',
		[NurseEmployeeType.CONTRACTOR]: 'Contractor',
	};

	public static text = (type: ET) => NurseEmployeeType.STATUS_TEXT[type];
}
