import React, { useState, useEffect, useRef, useCallback } from 'react';
import Image from '../Image';
import classes from './index.module.scss';

interface Props {
	initialTitle: string;
	onValueChanged?: ({ prev, cur }: { prev: string; cur: string }) => any;
	onRemove?: (value: string) => any;
	focused?: boolean;
	editable?: boolean;
}

const Tag = ({ initialTitle = '', onValueChanged, focused, onRemove, editable = true }: Props) => {
	const [title, setTitle] = useState(initialTitle);
	const [tempTitle, setTempTitle] = useState(initialTitle);
	const [remove, setRemove] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const selectText = () => {
		if (!inputRef.current || !editable) {
			return;
		}
		inputRef.current.select();
	};

	useEffect(() => {
		if (inputRef.current && focused) {
			inputRef.current.select();
		}
	}, [focused]);

	useEffect(() => {
		if (remove && onRemove) {
			onRemove(title);
		}
	}, [remove, title]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Escape') {
				inputRef.current?.blur();
			}
			if (e.key === 'Enter') {
				inputRef.current?.blur();
				if (tempTitle.trim().length) {
					setTitle((prev) => {
						onValueChanged && onValueChanged({ prev, cur: tempTitle });
						return tempTitle;
					});
				} else {
					setRemove(true);
				}
			}
		},
		[tempTitle, setTitle, onValueChanged]
	);

	const handleBlur = useCallback(() => {
		if (title.length <= 0) {
			setRemove(true);
		} else {
			setTempTitle(title);
		}
	}, [title, setTempTitle]);

	const handleRemove = useCallback(() => {
		setRemove(true);
	}, [title]);

	return (
		<>
			{!remove && (
				<div className={classes['tag']}>
					<input
						style={{ width: title.length * 8 + 'px' }}
						ref={inputRef}
						onClick={() => selectText()}
						onFocus={() => selectText()}
						onKeyDown={handleKeyDown}
						onBlur={handleBlur}
						className={classes['tag-title']}
						value={tempTitle}
						onChange={(e) => editable && setTempTitle(e.target.value)}
						disabled={!editable}
					/>
					{editable && (
						<span onClick={() => handleRemove()} className={classes['tag-close']}>
							<Image src="icClose" />
						</span>
					)}
				</div>
			)}
		</>
	);
};

export default Tag;
