import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Image, SearchBar, Typography, LoadingIndicator, Pagination } from 'lib/components';
import { EmployeesDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectEmployees,
	useSelectAuthorization,
	useSelectRequest,
	RequestManager,
} from 'store';
import { useWindowResize, usePagination, useSearch, useCustomToast } from 'hooks';
import routes from 'lib/api/routes';
import classes from './index.module.scss';

const EmployeesTab = () => {
	const { push } = useHistory();
	const toast = useCustomToast();
	const { pathname } = useLocation();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	// Getting hospital id from pathname
	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canCreateEmployee, canDeleteEmployee } = useSelectAuthorization();
	const employees = useSelectEmployees();

	// const [isDataFetched, setIsDataFetched] = useState(false);
	const [query, setQuery] = useState('');
	const [checked, setChecked] = useState<number[]>([]);

	useEffect(() => {
		dispatch(actions.fetchEmployees(parseInt(hospitalId)));
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;
	}, [active]);

	const { isDataFetched, setData, listing, paginatedData, paginationProps } = usePagination(employees, {
		itemsPerPage: 8,
	});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(employee) =>
					employee.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					employee.email.toLowerCase().search(query.toLowerCase()) !== -1 ||
					employee.phoneNumber.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		if (active.status === 'idle') return;

		if (
			active.name === routes.HOSPITAL_EMPLOYEE_USER(parseInt(hospitalId), checked[0]) &&
			active.method === 'DELETE'
		) {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				setChecked([]);
			}
		}
	}, [active]);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteEmployee(parseInt(hospitalId), checked[0]));
	};

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div className={classes['nurses-tab-container']}>
			<div className={classes['row']}>
				<SearchBar
					query={query}
					setQuery={setQuery}
					onSearch={() => console.log({ query })}
					className={classes['search-input-container']}
					placeholder="Search..."
				/>
				{canCreateEmployee && (
					<button
						className={classes['row']}
						style={{
							marginTop: '1rem',
						}}
						onClick={() => push(`/employees/${hospitalId}/new`)}
					>
						<Typography variant={isSmallDevice ? 'h5' : 'h6'} weight="medium">
							Add New Employee
						</Typography>
						<div className={classes['plus-icon']}>+</div>
					</button>
				)}
			</div>
			<div className={classes['row']} style={{ justifyContent: 'space-between', marginTop: '0.5rem' }}>
				<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					{checked.length !== 0 && (
						<>
							{canDeleteEmployee && (
								<button
									className={classes['button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							)}
						</>
					)}
				</div>
			</div>
			<EmployeesDataTable
				hospitalId={parseInt(hospitalId)}
				employees={paginatedData}
				checkAction={handleCheckAction}
				checkedId={checked[0] ?? -1}
			/>
			<Pagination {...paginationProps} />
		</div>
	);
};

export default EmployeesTab;
