import icCalendar from './images/ic_Calendar.svg';
import icAngleDown from './images/ic_angle_down.svg';
import icArchive from './images/ic_archive.svg';
import icArrowDown from './images/ic_arrow_down.svg';
import icArrowRightBlack from './images/ic_arrow_right_black.svg';
import icAttachmentBlack from './images/ic_attachment_black.svg';
import icAttachmentPrimary from './images/ic_attachment_primary.svg';
import icAttachmentWhite from './images/ic_attachment_white.svg';
import icBellPrimary from './images/ic_bell_primary.svg';
import icCalendarLeft from './images/ic_calendar_left.png';
import icCalendarRight from './images/ic_calendar_right.png';
import icCall from './images/ic_call.svg';
import icCancelBlack from './images/ic_cancel_black.svg';
import icCancelRed from './images/ic_cancel_red.svg';
import icCaretBlack from './images/ic_caret_black.svg';
import icChat from './images/ic_chat.svg';
import icChatBlack from './images/ic_chat_black.svg';
import icCheckPrimary from './images/ic_check_primary.svg';
import icCheveronRight from './images/ic_cheveron_right.svg';
import icChevron from './images/ic_chevron.svg';
import icChevronDown from './images/ic_chevron_down.svg';
import icChevronDownPrimary from './images/ic_chevron_down_primary.svg';
import icChevronDownSm from './images/ic_chevron_down_sm.svg';
import icChevronLeft from './images/ic_chevron_left.svg';
import icChevronUp from './images/ic_chevron_up.svg';
import icChevronUpPrimary from './images/ic_chevron_up_primary.svg';
import icChevronUpSm from './images/ic_chevron_up_sm.svg';
import icClockBlack from './images/ic_clock_black.svg';
import icClockPrimary from './images/ic_clock_primary.svg';
import icClockSm from './images/ic_clock_sm.svg';
import icClose from './images/ic_close.svg';
import icCloseChat from './images/ic_close_chat.svg';
import icComment from './images/ic_comment.svg';
import icCommentPrimary from './images/ic_comment_primary.svg';
import icCorrectPrimary from './images/ic_correct_primary.svg';
import icCostAmp from './images/ic_cost_amp.svg';
import icDangerAlert from './images/ic_danger_alert.svg';
import icDangerAlertPrimary from './images/ic_danger_alert_primary.svg';
import icDashboard from './images/ic_dashboard.svg';
import icDispatch from './images/ic_dispatch.svg';
import icDispatches from './images/ic_dispatches.svg';
import icDownCaret from './images/ic_down_caret.svg';
import icDownCaretSm from './images/ic_down_caret_sm.svg';
import icDownloadPrimary from './images/ic_download_primary.svg';
import icEdit from './images/ic_edit.svg';
import icEmail from './images/ic_email.svg';
import icEmailBlack from './images/ic_email_black.svg';
import icEmoji from './images/ic_emoji.svg';
import icErrorLock from './images/ic_error_lock.svg';
import icExport from './images/ic_export.svg';
import icExportBlack from './images/ic_export_black.svg';
import icExportUpArrowPrimary from './images/ic_export_up_arrow_primary.svg';
import icFilter from './images/ic_filter.svg';
import icFilterPrimary from './images/ic_filter_primary.svg';
import icHospital from './images/ic_hospital.svg';
import icHospitalAdminAvatarSelect from './images/ic_hospital_admin_avatar_select.png';
import icHospitalPlus from './images/ic_hospital_plus.svg';
import icHospitals from './images/ic_hospitals.svg';
import icImageUpload from './images/ic_image_upload.svg';
import icImageupload from './images/ic_imageupload.svg';
import icImport from './images/ic_import.svg';
import icInventory from './images/ic_inventory.svg';
import icInvoice from './images/ic_invoice.svg';
import icInvoicePrimary from './images/ic_invoice_primary.svg';
import icInvoices from './images/ic_invoices.svg';
import icLeftCaret from './images/ic_left_caret.svg';
import icLeftCaretSm from './images/ic_left_caret_sm.svg';
import icLocation from './images/ic_location.svg';
import icLocationBlack from './images/ic_location_black.svg';
import icLocationSm from './images/ic_location_sm.svg';
import icLocationSmGray from './images/ic_location_sm_gray.svg';
import icLock from './images/ic_lock.svg';
import icLoginHeroBar from './images/ic_login_hero_bar.svg';
import icLogout from './images/ic_logout.svg';
import icMailPrimary from './images/ic_mail_primary.svg';
import icMailSm from './images/ic_mail_sm.svg';
import icMenu from './images/ic_menu.svg';
import icMore from './images/ic_more.svg';
import icNoImage from './images/ic_no_image.svg';
import icNoimage from './images/ic_noimage.svg';
import icNurses from './images/ic_nurses.svg';
import icPencil from './images/ic_pencil.svg';
import icPlusPrimary from './images/ic_plus_primary.svg';
import icRightCaretPrimary from './images/ic_right_caret_primary.svg';
import icRightCaretSm from './images/ic_right_caret_sm.svg';
import icRoomPrimary from './images/ic_room_primary.svg';
import icSearch from './images/ic_search.svg';
import icSearchPrimary from './images/ic_search_primary.svg';
import icSelectIndicator from './images/ic_select_indicator.svg';
import icSendPrimary from './images/ic_send_primary.svg';
import icServices from './images/ic_services.svg';
import icSettings from './images/ic_settings.svg';
import icSmileyFace from './images/ic_smiley_face.svg';
import icStarGold from './images/ic_star_gold.svg';
import icStarGrey from './images/ic_star_grey.svg';
import icStarPrimary from './images/ic_star_primary.svg';
import icStyledSend from './images/ic_styled_send.svg';
import icTableDown from './images/ic_table_down.svg';
import icTabledown from './images/ic_tabledown.png';
import icTax from './images/ic_tax.svg';
import icTelephonePrimary from './images/ic_telephone_primary.svg';
import icThickPlus from './images/ic_thick_plus.svg';
import icThickPlusPrimary from './images/ic_thick_plus_primary.svg';
import icTimes from './images/ic_times.svg';
import icTimesDanger from './images/ic_times_danger.svg';
import icTimesDanger2 from './images/ic_times_danger2.png';
import icTrashDanger from './images/ic_trash_danger.svg';
import icTriArrowBlack from './images/ic_tri_arrow_black.svg';
import icUser from './images/ic_user.svg';
import icUserCog from './images/ic_user_cog.svg';
import icVideoPrimary from './images/ic_video_primary.svg';
import ilLoginHero from './images/il_login_hero.png';
import map from './images/map.png';
import plAvatar from './images/pl_avatar.png';
import plEmployee from './images/pl_employee.png';
import plHospitalNewUser from './images/pl_hospital_new_user.png';
import plMap from './images/pl_map.png';
import plNurse from './images/pl_nurse.png';
import plNurse1 from './images/pl_nurse1.png';
import plNurse2 from './images/pl_nurse2.png';
import plNurse3 from './images/pl_nurse3.png';
import plNurse4 from './images/pl_nurse4.png';

const images = {
	dark: {},
	light: {},
	default: {
		icCalendar,
		icAngleDown,
		icArchive,
		icArrowDown,
		icArrowRightBlack,
		icAttachmentBlack,
		icAttachmentPrimary,
		icAttachmentWhite,
		icBellPrimary,
		icCalendarLeft,
		icCalendarRight,
		icCall,
		icCancelBlack,
		icCancelRed,
		icCaretBlack,
		icChat,
		icChatBlack,
		icCheckPrimary,
		icCheveronRight,
		icChevron,
		icChevronDown,
		icChevronDownPrimary,
		icChevronDownSm,
		icChevronLeft,
		icChevronUp,
		icChevronUpPrimary,
		icChevronUpSm,
		icClockBlack,
		icClockPrimary,
		icClockSm,
		icClose,
		icCloseChat,
		icComment,
		icCommentPrimary,
		icCorrectPrimary,
		icCostAmp,
		icDangerAlert,
		icDangerAlertPrimary,
		icDashboard,
		icDispatch,
		icDispatches,
		icDownCaret,
		icDownCaretSm,
		icDownloadPrimary,
		icEdit,
		icEmail,
		icEmailBlack,
		icEmoji,
		icErrorLock,
		icExport,
		icExportBlack,
		icExportUpArrowPrimary,
		icFilter,
		icFilterPrimary,
		icHospital,
		icHospitalAdminAvatarSelect,
		icHospitalPlus,
		icHospitals,
		icImageUpload,
		icImageupload,
		icImport,
		icInventory,
		icInvoice,
		icInvoicePrimary,
		icInvoices,
		icLeftCaret,
		icLeftCaretSm,
		icLocation,
		icLocationBlack,
		icLocationSm,
		icLocationSmGray,
		icLock,
		icLoginHeroBar,
		icLogout,
		icMailPrimary,
		icMailSm,
		icMenu,
		icMore,
		icNoImage,
		icNoimage,
		icNurses,
		icPencil,
		icPlusPrimary,
		icRightCaretPrimary,
		icRightCaretSm,
		icRoomPrimary,
		icSearch,
		icSearchPrimary,
		icSelectIndicator,
		icSendPrimary,
		icServices,
		icSettings,
		icSmileyFace,
		icStarGold,
		icStarGrey,
		icStarPrimary,
		icStyledSend,
		icTableDown,
		icTabledown,
		icTax,
		icTelephonePrimary,
		icThickPlus,
		icThickPlusPrimary,
		icTimes,
		icTimesDanger,
		icTimesDanger2,
		icTrashDanger,
		icTriArrowBlack,
		icUser,
		icUserCog,
		icVideoPrimary,
		ilLoginHero,
		map,
		plAvatar,
		plEmployee,
		plHospitalNewUser,
		plMap,
		plNurse,
		plNurse1,
		plNurse2,
		plNurse3,
		plNurse4,
	},
};

export default images;
