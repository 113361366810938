import React from 'react';
import { Typography, Image } from 'lib/components';
import { JobFile } from 'types';
import { useWindowResize } from 'hooks/useWindowResize';
import classes from './index.module.scss';

interface Props {
	file: JobFile;
}

const FileDownload = ({ file }: Props) => {
	const { isSmallerDevice } = useWindowResize();

	return (
		<a
			href={file.path}
			download
			className={classes['file-container']}
			style={{
				width: '20rem',
				justifyContent: 'space-between',
			}}
		>
			<div style={{ display: 'flex' }}>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Download -</Typography>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray" style={{ marginLeft: '0.2rem' }}>
					{file.name}
				</Typography>
			</div>
			<Image src="icDownloadPrimary" />
		</a>
	);
};

export default FileDownload;
