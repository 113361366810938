import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Typography } from 'lib/components';
import SideNavLinkList from './SideNavLinkList';
import { SIDE_NAV_ITEMS, SIDE_NAV_EXTRA_ITEMS } from 'lib/util';
import { useSelectAuthorization } from 'store';
import classes from './index.module.scss';

const SideNav = () => {
	const history = useHistory();
	const { canCreateJob } = useSelectAuthorization();

	return (
		<div className={classes['sideNav']}>
			<Typography className={classes['sideNav-title']} variant="subheading2" color="primary">
				MENU
			</Typography>
			<div className={classes['sideNav-nav']} style={{ marginBottom: canCreateJob ? 0 : 'auto' }}>
				<SideNavLinkList list={SIDE_NAV_ITEMS} />
			</div>
			{canCreateJob && (
				<button className={classes['sideNav-addNewDispatch']} onClick={() => history.push('/jobs/create')}>
					<Typography className={classes['sideNav-addNewDispatch-title']} variant="p">
						Add New Job
					</Typography>
					<a className={classes['sideNav-addNewDispatch-button']}>
						<Image src={'icPlusPrimary'} alt="plus icon" />
					</a>
				</button>
			)}

			<Typography className={classes['sideNav-title']} variant="subheading2" color="primary">
				EXTRA
			</Typography>
			<div className={classes['sideNav-nav']}>
				<SideNavLinkList list={SIDE_NAV_EXTRA_ITEMS} />
			</div>
		</div>
	);
};

export default SideNav;
