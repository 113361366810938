import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import moment from 'moment';
import { BuddyPunchEmployee } from 'types';
import { actions, AppThunk, SliceState } from '..';

const initialState: SliceState<BuddyPunchEmployee> = {
	list: [],
	updatedAt: moment.utc().valueOf(),
};

export default createSlice({
	name: 'buddyPunchEmployee',
	initialState,
	reducers: {
		buddyPunchEmployeeFetched: (state, action: PA<BuddyPunchEmployee[]>) => {
			state.list = action.payload;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const getBuddyPunchEmployees = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.NURSE_BUDDY_PUNCH, method: 'GET' }));

		const { data, error } = await Api.nurses.getBuddyPunchEmployee();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.NURSE_BUDDY_PUNCH,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.NURSE_BUDDY_PUNCH,
					method: 'GET',
					message: '',
					payload: { count: data!.length },
				})
			);
			dispatch(actions.buddyPunchEmployeeFetched(data!));
		}
	};
};

export const buddyPunchEmployeeThunks = {
	getBuddyPunchEmployees,
};
