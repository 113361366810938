import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import { SelectOption } from 'lib/components/types';
import { TIME_ZONES } from 'lib/util';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import classes from './index.module.scss';

interface Props {
	value?: string;
	setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
	style?: React.CSSProperties;
	label?: string;
}

const options: SelectOption<string>[] = TIME_ZONES.map((timezone) => ({
	value: timezone,
	label: timezone,
}));

const TimezoneSelect = ({ value, setValue, style, label = 'Timezone:' }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [option, setOption] = useState<SelectOption<string> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<string> | null) => {
		setOption(option);
		setValue(option!.value);
	};

	useEffect(() => {
		if (option?.value !== value && typeof value !== undefined) {
			setOption(options.find((option) => option.value === value)!);
		}
	}, [value]);

	return (
		<div className={classes['textfield-container']} style={style}>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				{label}
			</Typography>
			<Select
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				isSearchable={true}
				options={options}
				value={option}
				onChange={handleChange}
			/>
		</div>
	);
};

export default TimezoneSelect;
