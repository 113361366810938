import { useState, useEffect } from 'react';
import { SliceState } from 'store';

interface PaginationOptions {
	itemsPerPage?: number;
	initialPage?: number;
}

export default function usePagination<T>(
	items: SliceState<T>,
	{ itemsPerPage = 10, initialPage = 1 }: PaginationOptions
) {
	const [isDataFetched, setIsDataFetched] = useState(false);
	const [data, setData] = useState<T[]>([]);
	const [showPagination, setShowPagination] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pages, setPages] = useState<number[]>([]);
	const [paginatedData, setPaginatedData] = useState<T[]>([]);

	useEffect(() => {
		setData(items.list);
		setIsDataFetched(true);
	}, [items.updatedAt]);

	useEffect(() => {
		if (!isDataFetched) return;

		if (data.length === 0) {
			setTotalPages(0);
			return;
		}

		const TOTAL_PAGES = Math.ceil(data.length / itemsPerPage);
		setTotalPages(TOTAL_PAGES);
	}, [data, isDataFetched]);

	useEffect(() => {
		if (!isDataFetched) return;

		if (totalPages === 0) {
			setCurrentPage(1);
			setShowPagination(false);
			return;
		}

		const CURRENT = initialPage;
		setCurrentPage(CURRENT);
		setShowPagination(data.length > itemsPerPage);
	}, [totalPages, isDataFetched]);

	useEffect(() => {
		if (!isDataFetched) return;

		const PAGES_SHOWN = totalPages <= 5 ? totalPages : 5;
		let START_PAGE: number, END_PAGE: number;

		if (currentPage <= Math.floor(PAGES_SHOWN / 2) + 1) {
			START_PAGE = 1;
			END_PAGE = PAGES_SHOWN;
		} else if (currentPage + Math.floor(PAGES_SHOWN / 2) - 1 >= totalPages) {
			START_PAGE = totalPages - (PAGES_SHOWN - 1);
			END_PAGE = totalPages;
		} else {
			START_PAGE = currentPage - Math.floor(PAGES_SHOWN / 2);
			END_PAGE = currentPage + (Math.floor(PAGES_SHOWN / 2) - 1);
		}

		const START_INDEX = (currentPage - 1) * itemsPerPage;
		const END_INDEX = Math.min(START_INDEX + (itemsPerPage - 1), data.length - 1);

		const PAGES = new Array(END_PAGE + 1 - START_PAGE).fill('').map((_, index) => START_PAGE + index);

		const PAGINATED_DATA = data.slice(START_INDEX, END_INDEX + 1);

		setPages(PAGES);
		setPaginatedData(PAGINATED_DATA);
	}, [currentPage, data, isDataFetched, totalPages]);

	const setPage = (page: number) => {
		if (page < 1 || page > totalPages) {
			return;
		}
		setCurrentPage(page);
	};

	const incrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === totalPages) {
				return prev;
			}
			return prev + 1;
		});

	const decrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === 1) {
				return prev;
			}
			return prev - 1;
		});

	return {
		isDataFetched,
		listing: items.list,
		setData,
		paginatedData,
		setPaginatedData,
		paginationProps: {
			showPagination,
			pages,
			currentPage,
			totalPages,
			setCurrentPage,
			setPage,
			incrementPage,
			decrementPage,
		},
	};
}
