import { useEffect, useState } from 'react';
import { NurseHours } from '../types/entity';

export default function useNurseHoursInput(hours: NurseHours | null = null) {
	const [monFrom, setMonFrom] = useState<string | null>('');
	const [monTo, setMonTo] = useState<string | null>('');
	const [tueFrom, setTueFrom] = useState<string | null>('');
	const [tueTo, setTueTo] = useState<string | null>('');
	const [wedFrom, setWedFrom] = useState<string | null>('');
	const [wedTo, setWedTo] = useState<string | null>('');
	const [thuFrom, setThuFrom] = useState<string | null>('');
	const [thuTo, setThuTo] = useState<string | null>('');
	const [friFrom, setFriFrom] = useState<string | null>('');
	const [friTo, setFriTo] = useState<string | null>('');
	const [satFrom, setSatFrom] = useState<string | null>('');
	const [satTo, setSatTo] = useState<string | null>('');
	const [sunFrom, setSunFrom] = useState<string | null>('');
	const [sunTo, setSunTo] = useState<string | null>('');

	useEffect(() => {
		if (hours) {
			setMonFrom(hours.monFrom);
			setMonTo(hours.monTo);
			setTueFrom(hours.tueFrom);
			setTueTo(hours.tueTo);
			setWedFrom(hours.wedFrom);
			setWedTo(hours.wedTo);
			setThuFrom(hours.thuFrom);
			setThuTo(hours.thuTo);
			setFriFrom(hours.friFrom);
			setFriTo(hours.friTo);
			setSatFrom(hours.satFrom);
			setSatTo(hours.satTo);
			setSunFrom(hours.sunFrom);
			setSunTo(hours.sunTo);
		} else {
			setMonFrom('');
			setMonTo('');
			setTueFrom('');
			setTueTo('');
			setWedFrom('');
			setWedTo('');
			setThuFrom('');
			setThuTo('');
			setFriFrom('');
			setFriTo('');
			setSatFrom('');
			setSatTo('');
			setSunFrom('');
			setSunTo('');
		}
	}, [hours]);

	return {
		monFrom,
		setMonFrom,
		monTo,
		setMonTo,
		tueFrom,
		setTueFrom,
		tueTo,
		setTueTo,
		wedFrom,
		setWedFrom,
		wedTo,
		setWedTo,
		thuFrom,
		setThuFrom,
		thuTo,
		setThuTo,
		friFrom,
		setFriFrom,
		friTo,
		setFriTo,
		satFrom,
		setSatFrom,
		satTo,
		setSatTo,
		sunFrom,
		setSunFrom,
		sunTo,
		setSunTo,
	};
}
