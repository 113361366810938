import { useEffect } from 'react';
import { useAppDispatch, actions, useSelectInvoices } from 'store';

/**
 * Effect hook which handles generated pdf download
 */
export default function useInvoiceDownload() {
	const dispatch = useAppDispatch();
	const { pdf } = useSelectInvoices();

	return useEffect(() => {
		if (!pdf.url) return;

		// TODO Handle protocol switch between http and https
		window.open(`${pdf.url}`);
		dispatch(actions.pdfCleared());
	}, [pdf]);
}
