import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import moment from 'moment';
import { HospitalService, HospitalServiceCreateInput, HospitalServiceUpdateInput } from 'types';
import { actions, AppThunk, SliceState } from '..';

const initialState: SliceState<HospitalService> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'hospital-services',
	initialState,
	reducers: {
		hospitalServicesFetched: (state, { payload }: PA<Array<HospitalService>>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		hospitalServiceFetched: (state, { payload }: PA<HospitalService>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({ ...payload });
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		hospitalServiceDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchHospitalServices = (hospitalId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_SERVICES(hospitalId), method: 'GET' }));

		const { data, error } = await Api.hospitals.services.getAll(hospitalId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_SERVICES(hospitalId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalServicesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_SERVICES(hospitalId),
					method: 'GET',
					message: 'Hospital services fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addHospitalService = (hospitalId: number, input: HospitalServiceCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_SERVICE_STORE(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.services.store(hospitalId, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_SERVICE_STORE(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_SERVICE_STORE(hospitalId),
					method: 'POST',
					message: 'Hospital service added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchHospitalServices(hospitalId));
		}
	};
};

const updateHospitalService = (hospitalId: number, input: HospitalServiceUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_SERVICE_UPDATE(hospitalId), method: 'PUT' }));

		const { data, error } = await Api.hospitals.services.update(hospitalId, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_SERVICE_UPDATE(hospitalId),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalServiceFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_SERVICE_UPDATE(hospitalId),
					method: 'PUT',
					message: 'Hospital service updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteHospitalService = (hospitalId: number, ids: Array<number>): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_SERVICE_DELETE(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.services.delete(hospitalId, ids);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_SERVICE_DELETE(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalServiceDeleted(hospitalId));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_SERVICE_DELETE(hospitalId),
					method: 'POST',
					message: 'Hospital services deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const hospitalServiceThunks = {
	fetchHospitalServices,
	addHospitalService,
	updateHospitalService,
	deleteHospitalService,
};
