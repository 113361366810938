import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { AvatarResponse, Employee, EmployeeCreateInput, EmployeeUpdateInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Employee> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'employees',
	initialState,
	reducers: {
		employeesFetched: (state, { payload }: PA<Employee[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		employeeFetched: (state, { payload }: PA<Employee>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({ ...payload });

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		employeeAvatarUploaded: (state, { payload }: PA<{ id: number; avatar: AvatarResponse }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list[index].avatar = payload.avatar.path;
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		employeeDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchEmployees = (hospitalId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_EMPLOYEE(hospitalId), method: 'GET' }));

		const { data, error } = await Api.employees.getAll(hospitalId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_EMPLOYEE(hospitalId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeesFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_EMPLOYEE(hospitalId),
					method: 'GET',
					message: 'Employees fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchEmployee = (hospitalId: number, id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, id), method: 'GET' }));

		const { data, error } = await Api.employees.getOne(hospitalId, id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, id),
					method: 'GET',
					message: 'Employees fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addEmployee = (hospitalId: number, input: EmployeeCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_EMPLOYEE(hospitalId), method: 'POST' }));

		const { data, error } = await Api.employees.create(hospitalId, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_EMPLOYEE(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_EMPLOYEE(hospitalId),
					method: 'POST',
					message: 'Employee added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchEmployees(hospitalId));
		}
	};
};

const updateEmployee = (hospitalId: number, employeeId: number, input: EmployeeUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(
			actions.requestStarted({ name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId), method: 'PUT' })
		);

		const { data, error } = await Api.employees.update(hospitalId, employeeId, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId),
					method: 'PUT',
					message: 'Employee updated successfully',
					payload: {},
				})
			);
		}
	};
};

const updateEmployeeAvatar = (hospitalId: number, id: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { data, error } = await Api.upload.avatar(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeAvatarUploaded({ id, avatar: data! }));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Avatar uploaded successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteEmployee = (hospitalId: number, employeeId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(
			actions.requestStarted({ name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId), method: 'DELETE' })
		);

		const { data, error } = await Api.employees.delete(hospitalId, employeeId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeDeleted(employeeId));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employeeId),
					method: 'DELETE',
					message: 'Employee deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const employeeThunks = {
	fetchEmployees,
	fetchEmployee,
	addEmployee,
	updateEmployee,
	updateEmployeeAvatar,
	deleteEmployee,
};
