import React, { useEffect } from 'react';
import { actions, useAppDispatch, useSelectAuthentication } from '../../../../store';
import classes from './index.module.scss';

interface Props {
	children: React.ReactNode;
	className?: string;
}

const PageWrapper = ({ className = '', children }: Props) => {
	const dispatch = useAppDispatch();
	const authenticationState = useSelectAuthentication();

	useEffect(() => {
		if (authenticationState.isAuthenticated) {
			dispatch(actions.fetchSettings());
		}
	}, [dispatch, authenticationState.isAuthenticated]);

	const combinedClassNames = `${classes['pageWrapper']} ${className}`;
	return <div className={combinedClassNames}>{children}</div>;
};

export default PageWrapper;
