import { useComprehensiveTopNav, usePagination, useSearch, useWindowResize } from 'hooks';
import { routes } from 'lib/api';
import { Button, DropdownButton, JobsTable, LoadingIndicator, Pagination, SearchBar, Typography } from 'lib/components';
import { DropdownItem } from 'lib/components/types';
import { JobStatus as JS, JobStatusType as S } from 'lib/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	actions,
	RequestManager,
	useAppDispatch,
	useSelectAuthorization,
	useSelectHospitals,
	useSelectJobs,
	useSelectRequest,
} from 'store';
import { Job } from 'types';
import classes from './index.module.scss';

type FilterItem<T = any> = { label: string; value: T };
const JOB_STATUS_FILTERS: FilterItem<S>[] = [
	{ label: 'Pending', value: S.PENDING },
	{ label: 'Approved', value: S.ACCEPTED },
	{ label: 'Rejected', value: S.NURSE_REJECTED },
];

const JOB_STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ value: S.PENDING, label: JS.text(S.PENDING) },
	{ value: S.ACCEPTED, label: JS.text(S.ACCEPTED) },
	{ value: S.ACKNOWLEDGED, label: JS.text(S.ACKNOWLEDGED) },
	{ value: S.ARRIVED, label: JS.text(S.ARRIVED) },
	{ value: S.NURSE_ACCEPTED, label: JS.text(S.NURSE_ACCEPTED) },
	{ value: S.NURSE_REJECTED, label: JS.text(S.NURSE_REJECTED) },
	{ value: S.TREATMENT_INITIATED, label: JS.text(S.TREATMENT_INITIATED) },
	{ value: S.TREATMENT_COMPLETED, label: JS.text(S.TREATMENT_COMPLETED) },
	{ value: S.COMPLETED, label: JS.text(S.COMPLETED) },
	{ value: S.ABORTED, label: JS.text(S.ABORTED) },
	{ value: S.CANCELLED, label: JS.text(S.CANCELLED) },
	{ value: S.VOIDED, label: JS.text(S.VOIDED) },
];

const JobsScreen: React.FC = () => {
	const { push } = useHistory();
	const { search } = useLocation();
	const filter = new URLSearchParams(search).get('filter');
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canCreateJob } = useSelectAuthorization();
	const jobs = useSelectJobs();
	const hospitals = useSelectHospitals();

	const [hospitalDropdownItems, setHospitalDropdownItems] = useState<DropdownItem<number>[]>([]);
	const [selectedHospitalDropdownItem, setSelectedHospitalDropdownItem] = useState<number>(-1);
	const [selectedStatusDropdownItem, setSelectedStatusDropdownItem] = useState<number>(-1);

	const [fetchedCount, setFetchedCount] = useState(0);
	const [query, setQuery] = useState('');
	const [currentFilters, setCurrentFilters] = useState<Record<S, boolean>>(
		JOB_STATUS_FILTERS.reduce((acc, cur) => {
			if (filter) {
				if (filter === 'pending' && cur.label === 'Pending') {
					acc[cur.value] = true;
				}
			} else {
				acc[cur.value] = false;
			}
			return acc;
		}, {} as Record<S, boolean>)
	);

	useEffect(() => {
		dispatch(actions.fetchJobs());
		dispatch(actions.fetchHospitals());
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL, 'GET')) {
			if (RM.succeeded()) {
				setHospitalDropdownItems(() => hospitals.list.map(({ id, name }) => ({ label: name, value: id })));
				setFetchedCount((prev) => prev + 1);
			}
		}
	}, [active]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(jobs, {});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(job) =>
					job.hospital.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					(job.nurse && job.nurse.user.name.toLowerCase().search(query.toLowerCase()) !== -1) ||
					job.creator.name.toLowerCase().search(query.toLowerCase()) !== -1
			);
			setData(result);
		}
	);

	useEffect(() => {
		if (filter !== 'active') return;
		const activeJobs = listing.filter((job) => JS.isActive(job.status));
		setData(activeJobs);
	}, [filter, listing]);

	useEffect(() => {
		const filters = Object.entries(currentFilters).filter((item) => item[1] !== false);
		if (filters.length === 0) {
			return;
		}

		const filtered: Job[] = [];
		for (const job of listing) {
			if (currentFilters[job.status]) {
				filtered.push(job);
			}
		}
		setData(filtered);
	}, [listing]);

	useEffect(() => {
		const filters = Object.entries(currentFilters).filter((item) => item[1] !== false);
		if (filters.length === 0) {
			setData(listing);
			return;
		}

		const filtered: Job[] = [];
		for (const job of listing) {
			if (currentFilters[job.status]) {
				filtered.push(job);
			}
		}

		setData(filtered);
	}, [currentFilters]);

	const handleFilterClick = useCallback(
		(filterItem: FilterItem<S>) => {
			setCurrentFilters((filters) => {
				let currentFilterValue = filters[filterItem.value];
				return {
					...filters,
					[filterItem.value]: !currentFilterValue,
				};
			});
		},
		[setCurrentFilters]
	);

	useEffect(() => {
		if (selectedHospitalDropdownItem === -1) return;

		const filtered = listing.filter((job) => job.hospital.id === selectedHospitalDropdownItem);
		setData(filtered);
	}, [selectedHospitalDropdownItem]);

	useEffect(() => {
		if (selectedStatusDropdownItem === -1) return;

		const result = listing.filter((job) => job.status === selectedStatusDropdownItem);
		setData(result);
	}, [selectedStatusDropdownItem]);

	const handleHospitalDropdownItemSelect = (value: number) => {
		setSelectedHospitalDropdownItem(value);
	};

	const handleStatusDropdownItemSelect = (value: number) => {
		setSelectedStatusDropdownItem(value);
	};

	useComprehensiveTopNav('Jobs');

	if (!isDataFetched || fetchedCount < 1) return <LoadingIndicator />;

	return (
		<div className={classes['dispatches-container']}>
			<div className={classes['row']}>
				<div className={classes['search-container']}>
					<SearchBar
						query={query}
						setQuery={setQuery}
						onSearch={() => console.log({ query })}
						className={classes['search-input-container']}
						placeholder="Search..."
					/>
				</div>
				{JOB_STATUS_FILTERS.map((filterItem, idx, arr) => (
					<Button
						onClick={() => handleFilterClick(filterItem)}
						key={filterItem.value}
						title={filterItem.label}
						className={`${classes['button']}`}
						color="dark"
						style={{
							marginTop: '1rem',
							marginRight: idx === arr.length - 1 ? '1rem' : 'initial',
						}}
						isActivePrimary={currentFilters && currentFilters[filterItem.value]}
					/>
				))}
				{canCreateJob && (
					<button
						className={classes['row']}
						style={{
							marginTop: '1rem',
						}}
						onClick={() => push('/jobs/create')}
					>
						<Typography weight="medium">Add New Job</Typography>
						<div className={classes['plus-icon']}>+</div>
					</button>
				)}
			</div>
			<div
				className={classes['row']}
				style={{
					alignItems: 'center',
				}}
			>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					weight="medium"
					style={{
						marginTop: '1rem',
					}}
				>
					Filter by:
				</Typography>
				<div
					style={{
						margin: '1rem 0.5rem 0 1rem',
					}}
				>
					<DropdownButton
						title="Status"
						list={JOB_STATUS_DROPDOWN_ITEMS}
						onItemSelect={handleStatusDropdownItemSelect}
					/>
				</div>
				<div
					style={{
						margin: '1rem 1rem 0 0',
					}}
				>
					<DropdownButton
						title="Hospital"
						list={hospitalDropdownItems}
						onItemSelect={handleHospitalDropdownItemSelect}
					/>
				</div>
			</div>
			<JobsTable jobs={paginatedData} />
			<Pagination {...paginationProps} />
		</div>
	);
};

export default JobsScreen;
