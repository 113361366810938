import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { Typography, Image } from 'lib/components';
import EmployeeRow from './EmployeeRow';
import { Checkbox } from 'lib/components/FormFields';
import { useWindowResize } from 'hooks';
import { useSelectAuthorization } from 'store';
import { Employee } from 'types';
import { HeaderItem, Sort, SortField } from './types';
import {
	employeeEmailCompareFn,
	employeeNameCompareFn,
	employeePhoneCompareFn,
	employeeCreatedCompareFn,
} from './sort';
import classes from './index.module.scss';

interface Props {
	employees: Employee[];
	checkedId: number;
	hospitalId: number;
	checkAction: (id: number, isChecked: boolean) => void;
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'Avatar' },
	{ label: 'Name', field: 'name', sort: 'asc' },
	{ label: 'Email', field: 'email', sort: 'asc' },
	{ label: 'Phone', field: 'phone', sort: 'asc' },
	{ label: 'Created Date', field: 'created', sort: 'asc' },
];

const EmployeesDataTable = ({ hospitalId, employees, checkedId, checkAction }: Props) => {
	const { push } = useHistory();
	const { isSmallerDevice } = useWindowResize();
	const { canUpdateEmployee } = useSelectAuthorization();

	const [allChecked, setAllChecked] = useState(false);
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>({
		sort: 'asc',
		field: 'name',
	});
	const [sortedEmployees, setSortedEmployees] = useState<Employee[]>(employees);

	const sortEmployees = useCallback(
		(allEmployees: Employee[]) => {
			const sorted = [...allEmployees].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'name') {
							return employeeNameCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'email') {
							return employeeEmailCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'phone') {
							return employeePhoneCompareFn(a, b, 'asc');
						} else if (currentSortField?.field === 'created') {
							return employeeCreatedCompareFn(a, b, 'asc');
						}

						return 0;

					case 'desc':
						if (currentSortField?.field === 'name') {
							return employeeNameCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'email') {
							return employeeEmailCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'phone') {
							return employeePhoneCompareFn(a, b, 'desc');
						} else if (currentSortField?.field === 'created') {
							return employeeCreatedCompareFn(a, b, 'desc');
						}

						return 0;

					default:
						return 0;
				}
			});
			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedEmployees(sortEmployees(employees));
	}, [employees, sortEmployees]);

	useEffect(() => {
		const sorted = sortEmployees(sortedEmployees);

		setSortedEmployees(sorted);
	}, [sortEmployees, setSortedEmployees]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={classes['inventories-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={() => setAllChecked(!allChecked)}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{HEADER_ITEMS.map((item) => renderHeaderItem(item))}
					</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedEmployees.map((employee) => (
								<EmployeeRow
									key={employee.id}
									employee={employee}
									checkedId={checkedId}
									checkAction={checkAction}
									onClick={() =>
										canUpdateEmployee && push(`/employees/${hospitalId}/view/${employee.id}`)
									}
								/>
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmployeesDataTable;
