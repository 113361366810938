import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import moment from 'moment';
import { actions, AppThunk, SliceState } from '..';
import { BuddyPunchLocation } from '../../types/entity';

const initialState: SliceState<BuddyPunchLocation> = {
	list: [],
	updatedAt: moment.utc().valueOf(),
};

export default createSlice({
	name: 'buddyPunchLocation',
	initialState,
	reducers: {
		buddyPunchLocationFetched: (state, action: PA<BuddyPunchLocation[]>) => {
			state.list = action.payload;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const getBuddyPunchLocations = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_BUDDY_PUNCH, method: 'GET' }));

		const { data, error } = await Api.hospitals.getBuddyPunchLocation();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_BUDDY_PUNCH,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_BUDDY_PUNCH,
					method: 'GET',
					message: '',
					payload: { count: data!.length },
				})
			);
			dispatch(actions.buddyPunchLocationFetched(data!));
		}
	};
};

export const buddyPunchLocationThunks = {
	getBuddyPunchLocations,
};
