import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Image, LoadingIndicator, SearchBar, Typography, DropdownButton, Pagination } from 'lib/components';
import { NursesDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectNurses,
	useSelectAuthorization,
	useSelectRequest,
	RequestManager,
} from 'store';
import { DropdownItem } from 'lib/components/types';
import { EmployeeType, NurseEmployeeType as NET } from 'lib/util';
import routes from 'lib/api/routes';
import { useComprehensiveTopNav, useWindowResize, useCustomToast, usePagination, useSearch } from 'hooks';
import classes from './index.module.scss';

// type Nurse = { id: string; image: ImageSource; color: string };
// const nurses: Nurse[] = [
// 	{ id: '0', image: 'plEmployee', color: '#21DF27' },
// 	{ id: '1', image: 'plEmployee', color: '#FF3B30' },
// 	{ id: '2', image: 'plEmployee', color: '#D0D0D0' },
// 	{ id: '3', image: 'plEmployee', color: '#21DF27' },
// 	{ id: '4', image: 'plEmployee', color: '#21DF27' },
// 	{ id: '5', image: 'plEmployee', color: '#FF3B30' },
// 	{ id: '6', image: 'plEmployee', color: '#21DF27' },
// 	{ id: '7', image: 'plEmployee', color: '#21DF27' },
// 	{ id: '8', image: 'plEmployee', color: '#FF3B30' },
// 	{ id: '9', image: 'plEmployee', color: '#D0D0D0' },
// 	{ id: '10', image: 'plEmployee', color: '#D0D0D0' },
// ];

const POSITION_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: NET.text(EmployeeType.FULL_TIME), value: EmployeeType.FULL_TIME },
	{ label: NET.text(EmployeeType.PART_TIME), value: EmployeeType.PART_TIME },
	{ label: NET.text(EmployeeType.CONTRACTOR), value: EmployeeType.CONTRACTOR },
];

// const STATUS_DROPDOWN_ITEMS: DropdownItem<string>[] = [
// 	{ label: 'Busy', value: 'busy' },
// 	{ label: 'Break', value: 'break' },
// 	{ label: 'Free', value: 'free' },
// ];

const NursesScreen = () => {
	const { push } = useHistory();
	const toast = useCustomToast();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const { canCreateNurse, canDeleteNurse } = useSelectAuthorization();
	const nurses = useSelectNurses();

	const [selectedPositionDropdownItem, setSelectedPositionDropdownItem] = useState<number>(-1);
	// TODO For Nurse Status Dropdown
	// const [selectedStatusDropdownItem, setSelectedStatusDropdownItem] = useState<string>('');

	const [query, setQuery] = useState('');
	const [checked, setChecked] = useState<number[]>([]);

	useEffect(() => {
		dispatch(actions.fetchNurses());
		dispatch(actions.getBuddyPunchEmployees());
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.NURSE_ID(checked[0]), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setChecked([]);
			}
		}
	}, [active]);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(nurses, {});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter((nurse) => nurse.user.name.toLowerCase().search(query.toLowerCase()) !== -1);
			setData(result);
		}
	);

	useEffect(() => {
		if (selectedPositionDropdownItem === -1) return;

		const result = listing.filter((nurse) => nurse.employeeType === selectedPositionDropdownItem);
		setData(result);
	}, [selectedPositionDropdownItem]);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteNurse(checked[0]));
	};

	const handlePositionDropdownItemSelect = (value: number) => {
		setSelectedPositionDropdownItem(value);
	};

	// const handleStatusDropdownItemSelect = (value: string) => {
	// 	setSelectedStatusDropdownItem(value);
	// };

	useComprehensiveTopNav('Nurses');

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div className={classes['nurses-screen-container']}>
			<div className={classes['row']}>
				<SearchBar
					query={query}
					setQuery={setQuery}
					onSearch={() => console.log({ query })}
					className={classes['search-input-container']}
					placeholder="Search..."
				/>
				{canCreateNurse && (
					<button
						className={classes['row']}
						style={{
							marginTop: '1rem',
						}}
						onClick={() => push('/nurses/create')}
					>
						<Typography variant={isSmallDevice ? 'h5' : 'h6'} weight="medium">
							Add New Nurse
						</Typography>
						<div className={classes['plus-icon']}>+</div>
					</button>
				)}
			</div>
			<div
				className={classes['row']}
				style={{
					marginTop: '1.2rem',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						marginRight: '1rem',
					}}
				>
					<Typography weight="medium" variant={isSmallDevice ? 'h5' : 'h6'}>
						Filter by:
					</Typography>
				</div>
				<div
					style={{
						marginRight: '1rem',
					}}
				>
					<DropdownButton
						title="Employee Type"
						list={POSITION_DROPDOWN_ITEMS}
						onItemSelect={handlePositionDropdownItemSelect}
					/>
				</div>
				{/* <div
					style={{
						marginRight: '1rem',
					}}
				>
					<DropdownButton
						title="Status"
						list={STATUS_DROPDOWN_ITEMS}
						// onItemSelect={handleStatusDropdownItemSelect}
					/>
				</div> */}
			</div>
			<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
				<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
					{checked.length !== 0 && (
						<>
							{canDeleteNurse && (
								<button
									className={classes['action-button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							)}
						</>
					)}
				</div>
			</div>
			<NursesDataTable nurses={paginatedData} checkedId={checked[0] ?? -1} checkAction={handleCheckAction} />
			<Pagination {...paginationProps} />
		</div>
	);
};

export default NursesScreen;
