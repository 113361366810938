import React, { useEffect } from 'react';
import { LoadingIndicator } from 'lib/components';
import { redirectToAuthPortal } from 'lib/redirect';
import { useAppDispatch, actions } from 'store';
import { removeAuth } from 'lib/util/helpers';

const Logout = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(actions.signout());
		removeAuth();
		redirectToAuthPortal();
	}, []);

	return <LoadingIndicator />;
};

export default Logout;
