import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOption } from 'lib/components/types';
import { JobStatusType } from 'lib/util';
import classes from './index.module.scss';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';

interface Props {
	value?: number;
	defaultValue?: SelectOption<number>;
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
	onChangeAction: (status: number) => void;
	isDisabled?: boolean;
}

const options: SelectOption<JobStatusType>[] = [
	{ label: 'Pending', value: JobStatusType.PENDING },
	{ label: 'Accepted', value: JobStatusType.ACCEPTED },
	{ label: 'Acknowledged', value: JobStatusType.ACKNOWLEDGED },
	{ label: 'Arrived', value: JobStatusType.ARRIVED },
	{ label: 'Nurse Accepted', value: JobStatusType.NURSE_ACCEPTED },
	{ label: 'Nurse Rejected', value: JobStatusType.NURSE_REJECTED },
	{ label: 'Treatment Initiated', value: JobStatusType.TREATMENT_INITIATED },
	{ label: 'Treatment Completed', value: JobStatusType.TREATMENT_COMPLETED },
	{ label: 'Completed', value: JobStatusType.COMPLETED },
	{ label: 'Aborted', value: JobStatusType.ABORTED },
	{ label: 'Cancelled', value: JobStatusType.CANCELLED },
	{ label: 'Voided', value: JobStatusType.VOIDED },
];

const JobStatusSelect = ({ value, setValue, defaultValue, isDisabled, onChangeAction }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [option, setOption] = useState<SelectOption<number> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
		setValue(option?.value);
		option && onChangeAction(option!.value);
	};

	return (
		<>
			<Typography
				variant={isSmallerDevice ? 'h5' : 'h6'}
				weight="medium"
				style={{
					marginTop: '1rem',
				}}
			>
				Job Status
			</Typography>
			<Select
				placeholder="Job Status"
				defaultValue={defaultValue}
				className={classes['react-select-container']}
				classNamePrefix="react-select"
				options={options}
				isClearable={true}
				isSearchable={true}
				value={option}
				onChange={handleChange}
				isDisabled={isDisabled}
			/>
		</>
	);
};

export default JobStatusSelect;
