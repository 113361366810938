import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Image, LoadingIndicator } from 'lib/components';
import { DatePicker } from 'lib/components/FormFields';
import {
	useAppDispatch,
	actions,
	useSelectRequest,
	useSelectHospitals,
	useSelectEmployees,
	useSelectHospital,
	useSelectEmployee,
	RequestManager,
} from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { Timing } from 'lib/util';
import { Employee } from 'types';
import selectImageHandler from 'lib/util/selectImageHandler';
import classes from './index.module.scss';

interface Params {
	hospitalId: string;
	employeeId: string;
}

interface Props {
	hospitalId: number;
	employee: Employee;
}

const EmployeeEditScreen = () => {
	const { hospitalId, employeeId } = useParams<Params>();

	const dispatch = useAppDispatch();
	const hospitals = useSelectHospitals();
	const getHospitalById = useSelectHospital();
	const employees = useSelectEmployees();
	const getEmployeeById = useSelectEmployee();

	const [employee, setEmployee] = useState<Employee>();

	useEffect(() => {
		dispatch(actions.fetchHospitals());
	}, []);

	useEffect(() => {
		const hospital = getHospitalById(parseInt(hospitalId));

		if (hospital) {
			dispatch(actions.fetchEmployees(hospital.id));
		}
	}, [hospitals.updatedAt]);

	useEffect(() => {
		const employee = getEmployeeById(parseInt(employeeId));

		if (employee) {
			setEmployee(employee);
		}
	}, [employees.updatedAt]);

	return employee ? (
		<EmployeeEditScreenView hospitalId={parseInt(hospitalId)} employee={employee} />
	) : (
		<LoadingIndicator />
	);
};

const EmployeeEditScreenView = ({ hospitalId, employee }: Props) => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState(employee.name);
	const [email, setEmail] = useState(employee.email);
	const [phoneNumber, setPhoneNumber] = useState(employee.phoneNumber);
	const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [profileImageString, setProfileImageString] = useState<any>('plAvatar');
	const employeeProfileImageRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.HOSPITAL_EMPLOYEE_USER(hospitalId, employee.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push(`/hospitals/${hospitalId}/employees`);
			}
		}

		if (RM.req(routes.UPLOAD_ID(employee.id), 'POST')) {
			if (RM.pending()) {
				toast('Uploading avatar...', 'info');
			} else if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	const handleUpdate = () => {
		dispatch(
			actions.updateEmployee(hospitalId, employee.id, {
				name,
				email,
				phoneNumber,
				dateOfBirth: Timing.formatDate(dateOfBirth),
				password,
				passwordConfirmation,
			})
		);
	};

	const handleFileSelect = selectImageHandler((file, fileURL) => {
		setProfileImageString(fileURL);
		dispatch(actions.updateNurseAvatar(employee.id, file));
	});

	useComprehensiveTopNav(`${employee.name} - Update Profile`);

	return (
		<div
			className={classes['profile-container']}
			style={{
				flexDirection: 'column',
			}}
		>
			<>
				<div className={classes['top-container']}>
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<div className={classes['small-circle']}>
								<div
									onClick={() => employeeProfileImageRef.current?.click()}
									className={classes['inner-circle']}
									style={{ borderColor: '#0CB9C3' }}
								>
									<Image
										src={'icImageUpload'}
										style={{ width: 12, height: 12, objectFit: 'contain' }}
									/>
									<input
										style={{ display: 'none' }}
										onChange={handleFileSelect}
										ref={employeeProfileImageRef}
										type="file"
										id="nurseProfileImage"
										name="nurseProfileImage"
										accept="image/*"
									/>
								</div>
							</div>
							<Image
								src={employee.avatar ?? profileImageString}
								style={{ width: '100%', height: '100%' }}
							/>
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Name:
							</Typography>
							<input
								className={classes['input']}
								style={{
									width: '15rem',
									minWidth: 250,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div
					className={classes['bottom-container']}
					style={{
						padding: isSmallDevice ? '1rem' : undefined,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography weight="bold">Basic info:</Typography>
						<div className={classes['row']}>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Mobile number:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
									}}
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Email:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 180,
									}}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<DatePicker label="Date of Birth" value={dateOfBirth} setValue={setDateOfBirth} />
							</div>
						</div>
						<div className={classes['row']} style={{ marginTop: '1.5rem' }}>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Confirm Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={passwordConfirmation}
									onChange={(e) => setPasswordConfirmation(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						margin: 'auto 0 1rem 1rem',
					}}
				>
					<button
						className={classes['button']}
						style={{
							width: 'auto',
							marginRight: '1rem',
						}}
						onClick={handleUpdate}
					>
						<p>Update Employee</p>
					</button>
					<button
						className={`${classes['button']} ${classes['palewhite']}`}
						style={{
							width: 'auto',
						}}
						onClick={() => push(`/hospitals/${hospitalId}/employees`)}
					>
						<p>Cancel</p>
					</button>
				</div>
			</>
		</div>
	);
};

export default EmployeeEditScreen;
