import { Vendor } from 'types';
import { Sort } from './types';

export const vendorNameCompareFn = (a: Vendor, b: Vendor, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
};

export const inventoryItemsCompareFn = (a: number, b: number, sort: Sort) => {
	if (a > b) {
		return sort === 'asc' ? 1 : -1;
	} else if (a < b) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
