import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { AvatarResponse, CurrentUserUpdateInput, User } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions } from '..';

const initialState: User = {
	id: -1,
	name: '',
	email: '',
	dateOfBirth: '',
	avatar: 'plAvatar',
	roleId: 0,
	permissions: [],
	location: [],
	createdAt: '',
	updatedAt: '',
};

export default createSlice({
	name: 'user',
	initialState,
	reducers: {
		currentUserFetched: (state, { payload }: PA<User>) => payload,
		currentUserAvatarUploaded: (state, { payload }: PA<AvatarResponse>) => {
			state.avatar = payload.path;
		},
	},
});

const getCurrentUser = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER, method: 'GET' }));

		const { data, error } = await Api.user.profile();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.currentUserFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.USER,
					method: 'GET',
					message: 'Current user fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const updateCurrentUser = (input: CurrentUserUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER, method: 'PUT' }));

		const { data, error } = await Api.user.update(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER,
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.currentUserFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.USER,
					method: 'PUT',
					message: 'Profile updated',
					payload: {},
				})
			);
		}
	};
};

const updateCurrentUserAvatar = (file: File): AppThunk => {
	return async (dispatch, getState) => {
		const { id } = getState().user;
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { data, error } = await Api.upload.avatar(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.currentUserAvatarUploaded(data!));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Upload successful',
					payload: {},
				})
			);
		}
	};
};

export const userThunks = {
	getCurrentUser,
	updateCurrentUser,
	updateCurrentUserAvatar,
};
