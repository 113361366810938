import { SelectOption } from 'lib/components/types';
import React, { useState } from 'react';
import Select from 'react-select';
import { JobTreatmentType } from '../../lib/util';
import classes from './index.module.scss';

interface Props {
	value?: JobTreatmentType;
	defaultValue?: SelectOption<JobTreatmentType>;
	setValue: React.Dispatch<React.SetStateAction<JobTreatmentType>>;
}

const TreatmentTypeSelect = ({ value, setValue, defaultValue }: Props) => {
	const options: SelectOption<number>[] = [
		{
			id: JobTreatmentType.HD,
			name: 'Hemodialysis',
		},
		{
			id: JobTreatmentType.PD,
			name: 'Peritoneal Dialysis',
		},
		{
			id: JobTreatmentType.SLED,
			name: 'Super Low Efficient Dialysis',
		},
	].map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const [option, setOption] = useState<SelectOption<JobTreatmentType> | null>(
		typeof value !== undefined ? options.find((option) => option.value === value)! : null
	);

	const handleChange = (option: SelectOption<JobTreatmentType> | null) => {
		setOption(option);
		setValue(option!.value as JobTreatmentType);
	};

	return (
		<Select
			placeholder=""
			defaultValue={defaultValue}
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			options={options}
			isClearable={true}
			isSearchable={true}
			value={option}
			onChange={handleChange}
		/>
	);
};

export default TreatmentTypeSelect;
