import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Job, JobFile, JobInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';
import { JobStatusType } from '../../lib/util';

const initialState: SliceState<Job> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'jobs',
	initialState,
	reducers: {
		jobsFetched: (state, { payload }: PA<Job[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		jobFetched: (state, { payload }: PA<Job>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		jobDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		documentUploaded: (state, { payload }: PA<{ id: number; file: JobFile }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list[index].files.push(payload.file);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		documentDeleted: (state, { payload }: PA<{ id: number; jobId: number }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.jobId);

			if (index !== -1) {
				const files = list[index].files;
				const fileIndex = files.findIndex(({ id }) => id === payload.id);
				if (fileIndex !== -1) {
					files.splice(fileIndex, 1);
					list[index].files = files;
				}
			}
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		noteDeleted: (state, { payload }: PA<{ id: number; jobId: number }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.jobId);

			if (index !== -1) {
				const notes = list[index].notes;
				const noteIndex = notes.findIndex(({ id }) => id === payload.id);
				if (noteIndex !== -1) {
					notes.splice(noteIndex, 1);
					list[index].notes = notes;
				}
			}
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		pathUpdated: (
			state,
			action: PA<{
				nurseId: number;
				jobId: number;
				jobStatus: JobStatusType;
				longitude: number;
				latitude: number;
			}>
		) => {
			const findJob = state.list.findIndex((element) => element.id === action.payload.jobId);
			if (findJob !== -1) {
				let lastId = 100000000;
				if (state.list[findJob].path.length > 0) {
					if (state.list[findJob].path[state.list[findJob].path.length - 1].id === lastId) {
						lastId += 1;
					}
				}
				state.list[findJob].path.push({
					id: lastId,
					status: action.payload.jobStatus,
					longitude: action.payload.longitude,
					latitude: action.payload.latitude,
					createdAt: moment.utc().format('MM/DD/YYYY hh:mm A'),
				});

				state.list[findJob].status = action.payload.jobStatus;
				state.updatedAt = moment.utc().valueOf();
			}
		},
		statusUpdated: (state, action: PA<{ jobId: number; jobStatus: JobStatusType }>) => {
			const findJob = state.list.findIndex((element) => element.id === action.payload.jobId);
			if (findJob !== -1) {
				state.list[findJob].status = action.payload.jobStatus;
				state.updatedAt = moment.utc().valueOf();
			}
		},
	},
});

const fetchJobs = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB, method: 'GET' }));

		const { data, error } = await Api.jobs.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.JOB,
					method: 'GET',
					message: 'Jobs fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchJobsForHospital = (hospitalId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_JOBS(hospitalId), method: 'GET' }));

		const { data, error } = await Api.jobs.getForHospital(hospitalId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_JOBS(hospitalId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_JOBS(hospitalId),
					method: 'GET',
					message: 'Hospital jobs fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchJob = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'GET' }));

		const { data, error } = await Api.jobs.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'GET',
					message: 'Job fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addJob = (input: JobInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB, method: 'POST' }));

		const { data, error } = await Api.jobs.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB,
					method: 'POST',
					message: 'Job added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchJobs());
		}
	};
};

const updateJob = (id: number, input: JobInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'PUT' }));

		const { data, error } = await Api.jobs.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'PUT',
					message: 'Job updated successfully',
					payload: {},
				})
			);
		}
	};
};

const uploadDocument = (jobId: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.DOCUMENT_ID(jobId), method: 'POST' }));

		const { data, error } = await Api.upload.document(jobId, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.DOCUMENT_ID(jobId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.documentUploaded({ id: jobId, file: data! }));
			dispatch(
				actions.requestFinished({
					name: routes.DOCUMENT_ID(jobId),
					method: 'POST',
					message: 'Document uploaded successfully',
					payload: { ...data! },
				})
			);
		}
	};
};

const deleteDocument = (id: number, jobId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.DOCUMENT_DELETE_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.jobs.deleteDocument(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.DOCUMENT_DELETE_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.documentDeleted({ id, jobId }));
			dispatch(
				actions.requestFinished({
					name: routes.DOCUMENT_DELETE_ID(id),
					method: 'DELETE',
					message: 'Document deleted successfully',
					payload: { ...data! },
				})
			);
		}
	};
};

const updateJobStatus = (id: number, status: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_STATUS(id), method: 'POST' }));

		const { data, error } = await Api.jobs.updateStatus(id, status);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_STATUS(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB_STATUS(id),
					method: 'POST',
					message: 'Job status updated successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchJob(id));
		}
	};
};

const deleteJob = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.jobs.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'DELETE',
					message: 'Job removed successfully',
					payload: { ...data },
				})
			);
		}
	};
};

const addNote = (id: number, message: string): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_NOTE_ID(id), method: 'POST' }));

		const { data, error } = await Api.jobs.notes.create(id, message);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_NOTE_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB_NOTE_ID(id),
					method: 'POST',
					message: 'Note added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchJob(id));
		}
	};
};

const deleteNote = (id: number, jobId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_NOTE_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.jobs.notes.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_NOTE_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.noteDeleted({ id, jobId }));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_NOTE_ID(id),
					method: 'DELETE',
					message: 'Note removed successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const jobThunks = {
	fetchJob,
	fetchJobs,
	fetchJobsForHospital,
	addJob,
	updateJob,
	uploadDocument,
	updateJobStatus,
	deleteJob,
	deleteDocument,
	addNote,
	deleteNote,
};
