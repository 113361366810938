import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { AvatarResponse, User, UserCreateInput, UserUpdateInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<User> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'users',
	initialState,
	reducers: {
		usersFetched: (state, { payload }: PA<User[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		userFetched: (state, { payload }: PA<User>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		userAvatarUploaded: (state, { payload }: PA<{ id: number; avatar: AvatarResponse }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list[index].avatar = payload.avatar.path;
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		userDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchUsers = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER_LIST, method: 'GET' }));

		const { data, error } = await Api.users.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER_LIST,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.usersFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.USER_LIST,
					method: 'GET',
					message: 'Users fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchUser = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER_ID(id), method: 'GET' }));

		const { data, error } = await Api.users.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.userFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.USER_ID(id),
					method: 'GET',
					message: 'User fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addUser = (input: UserCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER, method: 'POST' }));

		const { data, error } = await Api.users.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.USER,
					method: 'POST',
					message: 'User added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchUsers());
		}
	};
};

const updateUser = (id: number, input: UserUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER_ID(id), method: 'PUT' }));

		const { data, error } = await Api.users.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.userFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.USER_ID(id),
					method: 'PUT',
					message: 'User updated successfully',
					payload: {},
				})
			);
		}
	};
};

const updateUserAvatar = (id: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { data, error } = await Api.upload.avatar(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.userAvatarUploaded({ id, avatar: data! }));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Avatar uploaded successfully',
					payload: {},
				})
			);
		}
	};
};

const toggleUserSuspend = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.USER_SUSPEND(id), method: 'POST' }));

		const { error } = await Api.users.toggleSuspend(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.USER_SUSPEND(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.USER_SUSPEND(id),
					method: 'POST',
					message: 'Success',
					payload: {},
				})
			);
			dispatch(actions.fetchUser(id));
		}
	};
};

export const usersThunks = {
	fetchUsers,
	fetchUser,
	updateUserAvatar,
	addUser,
	updateUser,
	toggleUserSuspend,
};
