import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography } from 'lib/components';
import classes from './index.module.scss';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'selected' | 'value' | 'minDate' | 'maxDate'> {
	label: string;
	value: Date | null;
	minDate?: Date;
	maxDate?: Date;
	setValue: (date: Date) => void;
}

const DatePicker = ({ label, value, minDate, maxDate, setValue, ...rest }: Props) => {
	const { isSmallerDevice } = useWindowResize();

	return (
		<div className={classes['date-picker-group']}>
			<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
				{label}:
			</Typography>
			<ReactDatePicker
				selected={value}
				onChange={(date: Date) => setValue(date)}
				minDate={minDate}
				maxDate={maxDate}
				className={classes['react-datepicker']}
				{...rest}
			/>
		</div>
	);
};

export default DatePicker;
