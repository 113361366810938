import { Avatar, Typography } from 'lib/components';
// import Avatar from 'lib/components/Avatar';
import { Checkbox } from 'lib/components/FormFields';
import { NurseEmployeeType } from 'lib/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelectAuthorization, useSelectSettings } from 'store';
import { Nurse } from 'types';
import adjustTime from '../../../adjustTime';
import classes from './index.module.scss';

interface Props {
	nurse: Nurse;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
}

const NurseRow = ({ nurse, checkedId, checkAction }: Props) => {
	const settingsState = useSelectSettings();

	const { push } = useHistory();
	const [checked, setChecked] = useState(false);

	const { canReadNurse } = useSelectAuthorization();

	useEffect(() => {
		if (checkedId === nurse.id) return;
		if (checkedId !== nurse.id || checkedId === -1) setChecked(false);
	}, [checkedId]);

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(nurse.id, e.target.checked);
	};

	const toNurseView = useCallback(() => {
		if (canReadNurse) push(`/nurses/view/${nurse.id}`);
	}, []);

	return (
		<div key={nurse.id} className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleAction}
				style={{
					margin: '0 2rem',
				}}
			/>
			<div className={classes['col']} onClick={toNurseView}>
				<Avatar src={nurse.user.avatar ?? 'plAvatar'} size={'32px'} />
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>{nurse.user.name}</Typography>
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>{nurse.user.email}</Typography>
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>{nurse.user.phoneNumber ?? 'N/A'}</Typography>
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>{NurseEmployeeType.text(nurse.employeeType)}</Typography>
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>{nurse.yearsOfExperience}</Typography>
			</div>
			<div className={classes['col']} onClick={toNurseView}>
				<Typography variant={'h6'}>
					{adjustTime(settingsState, nurse.createdAt).format('MM/DD/YYYY')}
				</Typography>
			</div>
		</div>
	);
};

export default NurseRow;
