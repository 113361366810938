import { useWindowResize } from 'hooks';
import { Typography } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { JobLog } from 'types';
import adjustTime from '../../lib/adjustTime';
import { useSelectSettings } from '../../store';
import classes from './index.module.scss';

interface Props {
	logs: JobLog[];
}

const sortLogs = (logs: JobLog[]) => [...logs].sort((a, b) => b.id - a.id);

const Timeline = ({ logs }: Props) => {
	const settingsState = useSelectSettings();

	const { isSmallerDevice } = useWindowResize();
	const [sortedLogs, setSortedLogs] = useState<JobLog[]>([]);

	useEffect(() => {
		if (logs.length === 0) return;

		setSortedLogs(sortLogs(logs));
	}, [logs]);

	return (
		<div className={classes['wrapper']} style={{ marginTop: '0.5rem', flex: 1, padding: 0 }}>
			<Scrollbars
				renderThumbVertical={(props) => <div {...props} className={classes['vertical-scroll-thumb']} />}
			>
				<div className={classes['timeline']}>
					<div
						className={classes['vertical-strip']}
						style={{
							height: logs.length === 0 ? '100%' : `${logs.length * 3.5}rem`,
						}}
					/>
					{sortedLogs.map(({ id, message, createdAt }) => (
						<div key={id} className={classes['row']}>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} className={classes['first-col']}>
								{adjustTime(settingsState, createdAt, 'MM/DD/YYYY - hh:mm A').format(
									'MM/DD/YYYY hh:mm A'
								)}
							</Typography>
							<div className={classes['dot']} />
							<div className={classes['second-col']}>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="medium">
									{message}
								</Typography>
							</div>
						</div>
					))}
				</div>
			</Scrollbars>
		</div>
	);
};

export default Timeline;
