import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Button } from 'lib/components';
import { TextField } from 'lib/components/FormFields';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => any;
	onAddNote: (message: string) => void;
}

const AddNoteModal = ({ isOpen, onClose, onAddNote }: Props) => {
	const [message, setMessage] = useState('');

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['add-note-modal']}>
			<div className={classes['add-note-modal-content']}>
				<div className={classes['add-note-modal-items']}>
					<div className={classes['add-note-modal-form']}>
						<TextField
							placeholder="Enter your message"
							label="Message (required)"
							variant="labelled"
							textArea
							rows={9}
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>
					<div className={classes['add-note-modal-footer']}>
						<Button
							variant="standard"
							color="primary"
							title="Add Note"
							style={{
								marginRight: '1rem',
							}}
							disabled={message === ''}
							onClick={() => {
								onAddNote(message);
								setMessage('');
								onClose();
							}}
						/>
						<Button
							variant="standard"
							color="gray"
							sizing="small"
							title="Cancel"
							onClick={() => {
								setMessage('');
								onClose();
							}}
						/>
					</div>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default AddNoteModal;
