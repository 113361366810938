import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Typography, DropdownButton, LoadingIndicator, Pagination } from 'lib/components';
import { HospitalInvoicesDataTable } from 'lib/components/DataTables';
import { useWindowResize, usePagination } from 'hooks';
import { useAppDispatch, actions, useSelectInvoices, RequestManager, useSelectRequest } from 'store';
import { InvoiceStatusType, InvoicePaymentStatus as IPS, InvoicePaymentStatusType as ST } from 'lib/util';
import { DropdownItem } from 'lib/components/types';
import classes from './index.module.scss';

const STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: 'Created', value: InvoiceStatusType.CREATED },
	{ label: 'Voided', value: InvoiceStatusType.VOIDED },
];

const PAYMENT_STATUS_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: IPS.text(ST.PAID), value: ST.PAID },
	{ label: IPS.text(ST.UNPAID), value: ST.UNPAID },
	{ label: IPS.text(ST.REFUNDED), value: ST.REFUNDED },
];

const InvoicesTab = () => {
	const { isSmallDevice, isSmallerDevice } = useWindowResize();
	const { pathname } = useLocation();

	// Getting hospital id from pathname
	const hospitalId = pathname.split('/')[2];

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const invoices = useSelectInvoices();

	const [selectedStatusDropdownItem, setSelectedStatusDropdownItem] = useState<number>(-1);
	const [selectedPaymentStatusDropdownItem, setSelectedPaymentStatusDropdownItem] = useState<number>(-1);
	const [selectedYear, setSelectedYear] = useState('');

	useEffect(() => {
		dispatch(actions.fetchHospitalInvoices(parseInt(hospitalId)));
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;
	}, [active]);

	const { isDataFetched, listing, paginatedData, paginationProps, setData } = usePagination(invoices, {
		itemsPerPage: 8,
	});

	useEffect(() => {
		if (selectedStatusDropdownItem === -1) return;

		const result = listing.filter((invoice) => invoice.status === selectedStatusDropdownItem);
		setData(result);
	}, [selectedStatusDropdownItem]);

	useEffect(() => {
		if (selectedPaymentStatusDropdownItem === -1) return;

		const result = listing.filter((invoice) => invoice.status === selectedPaymentStatusDropdownItem);
		setData(result);
	}, [selectedPaymentStatusDropdownItem]);

	useEffect(() => {
		if (selectedYear === '') return;

		const result = listing.filter((invoice) => String(moment(invoice.createdAt).year()) === selectedYear);
		setData(result);
	}, [selectedYear]);

	const handleStatusDropdownItemSelect = (value: number) => {
		setSelectedStatusDropdownItem(value);
	};

	const handlePaymentStatusDropdownItemSelect = (value: number) => {
		setSelectedPaymentStatusDropdownItem(value);
	};

	const handleYearDropdownItemSelect = React.useCallback(
		(value: string) => {
			setSelectedYear(value);
		},
		[setSelectedYear]
	);

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<div
			className={classes['invoices-tab-container']}
			style={{
				padding: isSmallDevice ? '0 1rem' : undefined,
			}}
		>
			<div className={classes['row']} style={{ marginBottom: '1rem' }}>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					style={{
						margin: '1rem 1rem 0',
					}}
					weight="medium"
				>
					Filter by:
				</Typography>
				<div style={{ marginTop: '1rem', marginLeft: '1rem', marginRight: '0.5rem' }}>
					<DropdownButton
						title="Status"
						list={STATUS_DROPDOWN_ITEMS}
						onItemSelect={handleStatusDropdownItemSelect}
					/>
				</div>
				<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
					<DropdownButton
						title="Payment Status"
						list={PAYMENT_STATUS_DROPDOWN_ITEMS}
						onItemSelect={handlePaymentStatusDropdownItemSelect}
					/>
				</div>
				<div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
					<DropdownButton
						title="Year"
						list={Array.from(new Set(invoices.list.map((item) => moment(item.createdAt).year()))).map(
							(item) => ({ label: String(item), value: String(item) })
						)}
						onItemSelect={handleYearDropdownItemSelect}
					/>
				</div>
			</div>
			<HospitalInvoicesDataTable invoices={paginatedData} />
			<Pagination {...paginationProps} />
		</div>
	);
};

export default InvoicesTab;
