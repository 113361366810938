import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import React, { useEffect, useState } from 'react';
import { InvoiceItem, InvoiceItemsAllCheckedStatus } from 'types';
import classes from './index.module.scss';
import VoidReasonModal from './VoidReasonModal';

interface Props {
	item: InvoiceItem;
	allCheckedStatus: InvoiceItemsAllCheckedStatus;
	checkAction: (id: number, isChecked: boolean, isVoided: boolean) => void;
}

const InvoiceItemRow = ({ item, allCheckedStatus, checkAction }: Props) => {
	const [checked, setChecked] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		if (allCheckedStatus === 'all') setChecked(true);
		if (allCheckedStatus === 'none') setChecked(false);
		if (allCheckedStatus === 'voided' && !item.isVoided) setChecked(false);
		if (allCheckedStatus === 'unvoided' && item.isVoided) setChecked(false);
	}, [allCheckedStatus]);

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(item.id, e.target.checked, item.isVoided);
	};

	return (
		<>
			<VoidReasonModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} reason={item.reason} />
			<div key={item.id} className={classes['invoceDetailTable-row']}>
				<Checkbox
					checked={checked}
					onChange={handleChecked}
					style={{
						margin: '0 2rem',
					}}
				/>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>{item.invoiceId ?? '-'}</Typography>
				</div>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>{item.name}</Typography>
				</div>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>{item.vendorName ?? '-'}</Typography>
				</div>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>{item.amount}</Typography>
				</div>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>${item.price.toFixed(2)}</Typography>
				</div>
				<div className={classes['invoceDetailTable-col']} onClick={() => item.isVoided && setIsModalOpen(true)}>
					<Typography>{item.isVoided ? 'Yes' : 'No'}</Typography>
				</div>
			</div>
		</>
	);
};

export default InvoiceItemRow;
