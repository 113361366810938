import React from 'react';
import { useAppDispatch, actions, TopNavState } from 'store';

interface Options extends Partial<Omit<TopNavState, 'contentHeader'>> {}

const useComprehensiveTopNav = (contentHeader: string, options: Options = {}) => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(actions.contentHeaderChanged(contentHeader));

		if (options.mapToggleVisible !== undefined) {
			dispatch(actions.mapDisplayToggled(options.mapToggleVisible));
		}

		if (options.searchBarVisible !== undefined) {
			dispatch(actions.searchBarDisplayToggled(options.searchBarVisible));
		}

		if (options.notificationVisible !== undefined) {
			dispatch(actions.notificationToggled(options.notificationVisible));
		}

		if (options.sidenavVisible !== undefined) {
			dispatch(actions.sidenavToggled(options.sidenavVisible));
		}
	}, []);
};

export default useComprehensiveTopNav;
