import { Vendor } from 'types';
import { Sort } from './types';

export const itemNameCompareFn = (a: Vendor, b: Vendor, sort: Sort) => {
	if (sort === 'asc') {
		return a.name.localeCompare(b.name) === -1 ? 1 : -1;
	} else {
		return a.name.localeCompare(b.name) === -1 ? -1 : 1;
	}
	// return 0;
};

export const itemIDCompareFn = (a: Vendor, b: Vendor, sort: Sort) => {
	if (sort === 'asc') {
		return a.id > b.id ? 1 : -1;
	} else {
		return a.id > b.id ? -1 : 1;
	}
	// return 0;
};
