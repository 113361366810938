import React, { useState, useRef, useCallback } from 'react';
import classes from './index.module.scss';

interface Props {
	onAddMore?: (value: string) => any;
	label?: string;
}

const AddTag = ({ onAddMore, label = '+ Add more' }: Props) => {
	const [title, setTitle] = useState(label);
	const inputRef = useRef<HTMLInputElement>(null);

	const selectText = () => {
		if (!inputRef.current) {
			return;
		}
		inputRef.current.select();
	};

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Escape') {
				inputRef.current?.blur();
			}
			if (e.key === 'Enter') {
				inputRef.current?.blur();

				if (onAddMore && title.trim().length > 0) {
					onAddMore(title);
				}
			}
		},
		[title]
	);

	const handleBlur = useCallback(() => {
		setTitle(label);
	}, [setTitle, label]);

	return (
		<div className={classes['addMoreTag']}>
			<input
				style={{ width: title.length * 9 + 'px' }}
				ref={inputRef}
				onClick={() => selectText()}
				onFocus={() => selectText()}
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				className={classes['addMoreTag-title']}
				value={title}
				onChange={({ target: { value } }) => setTitle(value)}
			/>
		</div>
	);
};

export default AddTag;
