import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Hospital, HospitalInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Hospital> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'hospitals',
	initialState,
	reducers: {
		hospitalsFetched: (state, { payload }: PA<Hospital[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		hospitalFetched: (state, { payload }: PA<Hospital>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({ ...payload });

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		hospitalDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const addHospital = (input: HospitalInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL, method: 'POST' }));

		const { data, error } = await Api.hospitals.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL,
					method: 'POST',
					message: 'Hospital added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchHospitals());
		}
	};
};

const updateHospital = (id: number, input: HospitalInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_ID(id), method: 'PUT' }));

		const { data, error } = await Api.hospitals.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_ID(id),
					method: 'PUT',
					message: 'Hospital updated successfully',
					payload: {},
				})
			);
		}
	};
};

const deleteHospital = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.hospitals.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_ID(id),
					method: 'DELETE',
					message: 'Hospital deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

const fetchHospitals = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL, method: 'GET' }));

		const { data, error } = await Api.hospitals.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL,
					method: 'GET',
					message: 'Hospitals fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const fetchHospital = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_ID(id), method: 'GET' }));

		const { data, error } = await Api.hospitals.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.hospitalFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_ID(id),
					method: 'GET',
					message: 'Hospital fetched successfully',
					payload: {},
				})
			);
		}
	};
};

export const hospitalThunks = {
	addHospital,
	updateHospital,
	deleteHospital,
	fetchHospitals,
	fetchHospital,
};
