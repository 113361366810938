import React from 'react';
import { Message } from 'types';
import ChatMessage from './ChatMessage';
import classes from '../index.module.scss';

interface Props {
	messages: Message[];
	userId: number;
}

const ChatMessages = ({ messages, userId }: Props) => (
	<div className={classes['chatPopup-chatMessages']}>
		{messages.map((message) => (
			<ChatMessage key={message.id} chat={message} isSender={userId === message.userId} />
		))}
		{/* <div className={classes['chatPopup-loadingIndicator']}>
			<div />
			<div />
			<div />
		</div> */}
	</div>
);

export default ChatMessages;
