import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { AvatarResponse, User, UserCreateInput, UserUpdateInput } from 'types';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<User> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'admins',
	initialState,
	reducers: {
		adminsFetched: (state, { payload }: PA<User[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		adminFetched: (state, { payload }: PA<User>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({
				...payload,
			});

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		adminAvatarUploaded: (state, { payload }: PA<{ id: number; avatar: AvatarResponse }>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list[index].avatar = payload.avatar.path;
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
		adminDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchAdmins = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN, method: 'GET' }));

		const { data, error } = await Api.users.getAll('admin');

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.adminsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN,
					method: 'GET',
					message: 'Admins fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const fetchAdmin = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN_ID(id), method: 'GET' }));

		const { data, error } = await Api.users.getOne(id, 'admin');

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.adminFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN_ID(id),
					method: 'GET',
					message: 'Admin fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addAdmin = (input: UserCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN, method: 'POST' }));

		const { data, error } = await Api.users.create(input, 'admin');

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN,
					method: 'POST',
					message: 'Admin added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchAdmins());
		}
	};
};

const updateAdmin = (id: number, input: UserUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN_ID(id), method: 'PUT' }));

		const { data, error } = await Api.users.update(id, input, 'admin');

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.adminFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN_ID(id),
					method: 'PUT',
					message: 'Admin updated successfully',
					payload: {},
				})
			);
		}
	};
};

const updateAdminAvatar = (id: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { data, error } = await Api.upload.avatar(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.adminAvatarUploaded({ id, avatar: data! }));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Avatar uploaded successfully',
					payload: {},
				})
			);
		}
	};
};

const toggleAdminSuspend = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN_SUSPEND(id), method: 'POST' }));

		const { error } = await Api.users.toggleSuspend(id, 'admin');

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN_SUSPEND(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN_SUSPEND(id),
					method: 'POST',
					message: 'Success',
					payload: {},
				})
			);
			dispatch(actions.fetchAdmins());
		}
	};
};

const deleteAdmin = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.ADMIN_ID(id), method: 'DELETE' }));

		const { data, error } = await Api.users.delete(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.ADMIN_ID(id),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.adminDeleted(id));
			dispatch(
				actions.requestFinished({
					name: routes.ADMIN_ID(id),
					method: 'DELETE',
					message: 'Admin removed successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const adminThunks = {
	fetchAdmins,
	fetchAdmin,
	addAdmin,
	updateAdmin,
	updateAdminAvatar,
	deleteAdmin,
	toggleAdminSuspend,
};
