const mapboxgl = require('!mapbox-gl'); // eslint-disable-line import/no-webpack-loader-syntax
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import Bootstrap from './Bootstrap';
import './index.scss';
import reportWebVitals from './reportWebVitals';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_KEY,
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Bootstrap />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
