import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useSelectAuthentication, useSelectAuthorization } from 'store';
import { Timing, PermissionType } from 'lib/util';

interface Props extends RouteProps {
	permission: PermissionType;
}

const Authorized = ({ component, permission, ...rest }: Props) => {
	const { isAuthenticated, expiryAt } = useSelectAuthentication();
	const { isPermitted } = useSelectAuthorization();
	const { pathname } = useLocation();

	return isAuthenticated && !Timing.isExpired(expiryAt) ? (
		isPermitted(permission) ? (
			<Route {...rest} component={component} />
		) : (
			<Redirect to="/403" />
		)
	) : (
		<Redirect to={`/login?redirectTo=${pathname}`} />
	);
};

export default Authorized;
