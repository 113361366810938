import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { Button, Typography } from 'lib/components';
import { Vendor, VendorInput } from 'types';
import { useAddressInput } from 'hooks';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	vendor?: Vendor;
	onClose: () => any;
	action: (type: 'create' | 'update', data: VendorInput, id?: number) => void;
}

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	margin?: string;
}

const TextInput = ({ label, margin, ...props }: TextInputProps) => {
	return (
		<div className={classes['textfield-container']} style={{ margin }}>
			<Typography variant="h6" color="darkgrey">
				{label}
			</Typography>
			<input {...props} />
		</div>
	);
};

const AddVendorModal = ({ isOpen, onClose, vendor, action }: Props) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	const {
		addressLine1,
		setAddressLine1,
		addressLine2,
		setAddressLine2,
		city,
		setCity,
		state,
		setState,
		postalCode,
		setPostalCode,
	} = useAddressInput(vendor?.address);

	useEffect(() => {
		if (vendor) {
			setName(vendor.name);
			setEmail(vendor.email);
			setPhoneNumber(vendor.phoneNumber);
		} else {
			setName('');
			setEmail('');
			setPhoneNumber('');
		}
	}, [vendor]);

	const handleAction = (type: 'create' | 'update') => {
		if (type === 'create') {
			action('create', {
				name,
				email,
				phoneNumber,
				addressLine1: addressLine1 === '' ? undefined : addressLine1,
				addressLine2: addressLine2 === '' ? undefined : addressLine2,
				city: city === '' ? undefined : city,
				state: state === '' ? undefined : state,
				postalCode: postalCode === '' ? undefined : postalCode,
			});
		} else {
			action(
				'update',
				{
					name,
					email,
					phoneNumber,
					addressLine1: addressLine1 === '' ? undefined : addressLine1,
					addressLine2: addressLine2 === '' ? undefined : addressLine2,
					city: city === '' ? undefined : city,
					state: state === '' ? undefined : state,
					postalCode: postalCode === '' ? undefined : postalCode,
				},
				vendor?.id
			);
		}
	};

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['vendor-modal-content']}>
				<div>
					<div className={classes['modal-form']}>
						<div
							style={{
								flexBasis: '40%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="Name"
								label="Vendor Name"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div
							style={{
								flexBasis: '29%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="email@example.com"
								label="Email"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div
							style={{
								flexBasis: '29%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder=""
								label="Phone Number"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes['modal-form']} style={{ marginTop: '1.5rem' }}>
						<div
							style={{
								flexBasis: '49%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="Ave 90940 Pron. LO"
								label="Address Line 1"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={addressLine1}
								onChange={(e) => setAddressLine1(e.target.value)}
							/>
						</div>
						<div
							style={{
								flexBasis: '49%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="Ave 90940 Pron. LO"
								label="Address Line 2"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={addressLine2}
								onChange={(e) => setAddressLine2(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes['modal-form']} style={{ marginTop: '1.5rem' }}>
						<div
							style={{
								flexBasis: '30%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="Ohio"
								label="City"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={city}
								onChange={(e) => setCity(e.target.value)}
							/>
						</div>
						<div
							style={{
								flexBasis: '30%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="Texas"
								label="State"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={state}
								onChange={(e) => setState(e.target.value)}
							/>
						</div>
						<div
							style={{
								flexBasis: '30%',
								marginBottom: '0.5rem',
							}}
						>
							<TextInput
								placeholder="00000"
								label="Postal Code"
								className={classes['name']}
								style={{
									width: '100%',
								}}
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className={classes['row']}>
					<Button
						variant="standard"
						color="primary"
						title={vendor ? 'Update Vendor' : 'Add New Vendor'}
						className={classes['button']}
						style={{
							marginRight: '1rem',
						}}
						onClick={() => handleAction(vendor ? 'update' : 'create')}
					/>
					<Button
						variant="standard"
						color="gray"
						sizing="small"
						title="Cancel"
						onClick={onClose}
						className={classes['button']}
					/>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default AddVendorModal;
