import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { Button, Typography } from 'lib/components';
import { InventoryTypeSelect, InventoryUnitTypeSelect } from 'lib/components/FormFields';
import VendorSelect from './VendorSelect';
import { useWindowResize } from 'hooks/useWindowResize';
import { Inventory, InventoryInput, Vendor } from 'types';
import classes from './index.module.scss';

interface Props {
	isOpen: boolean;
	inventory?: Inventory;
	vendors: Vendor[];
	onClose: () => any;
	action: (type: 'create' | 'update', data: InventoryInput, id?: number) => void;
}

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	margin?: string;
}

const TextInput = ({ label, margin, ...props }: TextInputProps) => {
	return (
		<div className={classes['textfield-container']} style={{ margin }}>
			<Typography variant="h6" color="darkgrey">
				{label}
			</Typography>
			<input {...props} />
		</div>
	);
};

const AddItemModal = ({ isOpen, inventory, vendors, onClose, action }: Props) => {
	const { isSmallerDevice } = useWindowResize();

	const [name, setName] = useState('');
	const [sku, setSku] = useState('');
	const [stock, setStock] = useState(0);
	const [cost, setCost] = useState(0);
	const [vendorId, setVendorId] = useState<number | undefined>();
	const [typeId, setTypeId] = useState<number | undefined>();
	const [unitId, setUnitId] = useState<number | undefined>();
	const [packageQuantity, setPackageQuantity] = useState(0);
	const [liquidQuantity, setLiquidQuantity] = useState(0);

	useEffect(() => {
		if (inventory) {
			setName(inventory.name);
			setSku(inventory.sku);
			setStock(inventory.stock);
			setVendorId(inventory.vendorId);
			setTypeId(inventory.typeId);
			setUnitId(inventory.unitId);
			setPackageQuantity(inventory.packageQuantity);
			setLiquidQuantity(inventory.liquidQuantity ?? 0);
		} else {
			resetFields();
		}
	}, [inventory]);

	const resetFields = () => {
		setName('');
		setSku('');
		setStock(0);
		setVendorId(undefined);
		setTypeId(undefined);
		setUnitId(undefined);
		setPackageQuantity(0);
		setLiquidQuantity(0);
	};

	const handleAction = (type: 'create' | 'update') => {
		if (type === 'create') {
			action('create', {
				name,
				sku,
				stock,
				cost,
				vendorId: vendorId as number,
				typeId: typeId as number,
				unitId: unitId as number,
				packageQuantity,
				liquidQuantity,
			});
		} else {
			action(
				'update',
				{
					name,
					sku,
					stock,
					cost,
					vendorId: vendorId as number,
					typeId: typeId as number,
					unitId: unitId as number,
					packageQuantity,
					liquidQuantity,
				},
				inventory?.id
			);
		}
		resetFields();
	};

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['inventory-modal-content']}>
				<div className={classes['modal-form']}>
					<div
						style={{
							flex: 1,
							marginRight: '1rem',
							minWidth: 300,
						}}
					>
						<TextInput
							placeholder="Name"
							label="Item Name"
							className={classes['name']}
							style={{
								width: '100%',
							}}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<VendorSelect vendors={vendors} value={vendorId} setValue={setVendorId} />
				</div>
				<Typography
					variant={isSmallerDevice ? 'h5' : 'h6'}
					style={{
						marginTop: '2rem',
					}}
					weight="bold"
				>
					Item Information
				</Typography>
				<div className={classes['row']}>
					<InventoryTypeSelect value={typeId} setValue={setTypeId} style={{ margin: '1rem 1.5rem 0 0' }} />
					<InventoryUnitTypeSelect
						value={unitId}
						setValue={setUnitId}
						style={{ margin: '1rem 1.5rem 0 0' }}
					/>
					<TextInput
						margin="1rem 0 0 0"
						label="SKU"
						placeholder="0000000"
						style={{ width: '7rem', minWidth: 110, marginRight: '1.5rem' }}
						value={sku}
						onChange={(e) => setSku(e.target.value)}
					/>
					<TextInput
						type="number"
						margin="1rem 0 0 0"
						label="Stock"
						placeholder="000"
						style={{ width: '5rem', minWidth: 44, marginRight: '1.5rem' }}
						value={stock}
						onChange={(e) => setStock(e.target.valueAsNumber)}
					/>
					<TextInput
						type="number"
						margin="1rem 0 0 0"
						label="Package Cost"
						placeholder="Enter Package Cost"
						style={{ width: '7rem', minWidth: 120, marginRight: '1.5rem' }}
						value={cost}
						onChange={(e) => setCost(e.target.valueAsNumber)}
					/>
					<TextInput
						type="number"
						margin="1rem 0 0 0"
						label="Package Quantity"
						placeholder="100"
						style={{ width: '6rem', minWidth: 90, marginRight: '1.5rem' }}
						value={packageQuantity}
						onChange={(e) => setPackageQuantity(e.target.valueAsNumber)}
					/>
					{typeId === 2 && (
						<TextInput
							type="number"
							margin="1rem 0 0 0"
							label="Liquid Quantity"
							placeholder=""
							style={{ width: '6rem', minWidth: 90 }}
							value={liquidQuantity}
							onChange={(e) => setLiquidQuantity(e.target.valueAsNumber)}
						/>
					)}
				</div>
				<div className={classes['row']}>
					<Button
						variant="standard"
						color="primary"
						title={inventory ? 'Update Item' : 'Add New Item'}
						className={classes['button']}
						style={{
							marginRight: '1rem',
						}}
						onClick={() => handleAction(inventory ? 'update' : 'create')}
					/>
					<Button
						variant="standard"
						color="gray"
						sizing="small"
						title="Cancel"
						onClick={onClose}
						className={classes['button']}
					/>
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default AddItemModal;
