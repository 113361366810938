import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import Api, { routes } from 'lib/api';
import moment from 'moment';
import { Setting, SettingInput } from 'types';
import { actions, AppThunk, SliceState } from '..';

const initialState: SliceState<Setting> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'setting',
	initialState,
	reducers: {
		settingsFetched: (state, { payload }: PA<Array<Setting>>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
		settingsUpdate: (state, { payload }: PA<Array<SettingInput>>) => {
			const list = [...state.list];
			for (const setting of payload) {
				const findSetting = list.findIndex((element) => element.name === setting.name);
				if (findSetting !== -1) {
					list[findSetting].value = setting.value;
				}
			}
			state.list = list;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchSettings = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SETTINGS, method: 'GET' }));

		const { data, error } = await Api.settings.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SETTINGS,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.settingsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.CUSTOM_COST,
					method: 'GET',
					message: 'Settings fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const updateSettings = (input: Array<SettingInput>): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.SETTINGS, method: 'PUT' }));

		const { error } = await Api.settings.update(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.SETTINGS,
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.settingsUpdate(input));
			dispatch(
				actions.requestFinished({
					name: routes.SETTINGS,
					method: 'PUT',
					message: 'Settings updated successfully',
					payload: {},
				})
			);
		}
	};
};

export const settingThunks = {
	fetchSettings,
	updateSettings,
};
