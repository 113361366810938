import React, { useState, useEffect } from 'react';
import { Typography } from 'lib/components';
import { Checkbox } from 'lib/components/FormFields';
import { AllCheckedStatus, Vendor } from 'types';
import classes from './index.module.scss';

interface Props {
	vendor: Vendor;
	allCheckedStatus: AllCheckedStatus;
	checkAction: (id: number, isChecked: boolean) => void;
}

const VendorRow = ({ vendor, allCheckedStatus, checkAction }: Props) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (allCheckedStatus === 'some') return;
		if (allCheckedStatus === 'all') setChecked(true);
		if (allCheckedStatus === 'none') setChecked(false);
	}, [allCheckedStatus]);

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		checkAction(vendor.id, e.target.checked);
	};

	return (
		<div className={classes['table-row']}>
			<Checkbox
				checked={checked}
				onChange={handleChecked}
				style={{
					margin: '0 2rem',
				}}
			/>
			<Typography variant={'h6'} className={classes['col']}>
				{vendor.name}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{vendor.email}
			</Typography>
			<Typography variant={'h6'} className={classes['col']}>
				{vendor.phoneNumber}
			</Typography>
		</div>
	);
};

export default VendorRow;
