import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { Image, SearchBar, Typography, LoadingIndicator, Pagination, DropdownButton } from 'lib/components';
import { useWindowResize } from 'hooks/useWindowResize';
import { AdminsDataTable } from 'lib/components/DataTables';
import {
	useAppDispatch,
	actions,
	useSelectAdmins,
	useSelectRequest,
	useSelectCurrentUser,
	RequestManager,
} from 'store';
import colors from 'res/colors';
import routes from 'lib/api/routes';
import { useCustomToast, usePagination, useSearch } from 'hooks';
import { DropdownItem } from 'lib/components/types';
import { Roles } from 'lib/util';
import classes from './index.module.scss';

const ROLE_DROPDOWN_ITEMS: DropdownItem<number>[] = [
	{ label: 'Super Admin', value: Roles.SUPER_ADMIN },
	{ label: 'Admin', value: Roles.ADMIN },
];

const AdminsScreen = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const admins = useSelectAdmins();
	const me = useSelectCurrentUser();
	const { active } = useSelectRequest();

	const [checked, setChecked] = useState<number[]>([]);
	const [query, setQuery] = useState('');
	const [selectedRoleDropdownItem, setSelectedRoleDropdownItem] = useState<number>(-1);

	useEffect(() => {
		dispatch(actions.fetchAdmins());
	}, []);

	const { isDataFetched, listing, setData, paginatedData, paginationProps } = usePagination(admins, {});

	useSearch(
		query,
		() => {
			setData(listing);
		},
		() => {
			const result = listing.filter(
				(admin) =>
					admin.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
					admin.email.toLowerCase().search(query.toLowerCase()) !== -1 ||
					(admin.phoneNumber && admin.phoneNumber.toLowerCase().search(query.toLowerCase()) !== -1)
			);
			setData(result);
		}
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.ADMIN_ID(checked[0]), 'DELETE')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setChecked([]);
			}
		}

		if (RM.req(routes.ADMIN_SUSPEND(checked[0]), 'POST')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				setChecked([]);
			}
		}
	}, [active]);

	useEffect(() => {
		if (selectedRoleDropdownItem === -1) return;

		const result = listing.filter((admin) => admin.roleId === selectedRoleDropdownItem);
		setData(result);
	}, [selectedRoleDropdownItem]);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const handleDelete = () => {
		dispatch(actions.deleteAdmin(checked[0]));
	};

	const handleSuspend = () => {
		dispatch(actions.toggleAdminSuspend(checked[0]));
	};

	const handleRoleDropdownItemSelect = (value: number) => {
		setSelectedRoleDropdownItem(value);
	};

	useComprehensiveTopNav('Admins');

	if (!isDataFetched) return <LoadingIndicator />;

	return (
		<>
			<div className={classes['inventories']}>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							flex: 1,
							marginRight: isSmallerDevice ? 0 : '2rem',
						}}
					>
						<SearchBar
							query={query}
							setQuery={setQuery}
							onSearch={() => console.log({ query })}
							className={classes['inventories-searchbar']}
							placeholder="Search..."
							searchIconColor="primary"
						/>
					</div>
					<div
						className={classes['row']}
						style={{
							marginTop: '1.3rem',
						}}
					>
						<button
							className={`${classes['row']} ${classes['add-new']}`}
							onClick={() => push('/admins/create')}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Add new</Typography>
							<div>
								<Image src={'icPlusPrimary'} />
							</div>
						</button>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						marginTop: '1.2rem',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							marginRight: '1rem',
						}}
					>
						<Typography weight="medium" variant={isSmallDevice ? 'h5' : 'h6'}>
							Filter by:
						</Typography>
					</div>
					<div
						style={{
							marginRight: '1rem',
						}}
					>
						<DropdownButton
							title="Role"
							list={ROLE_DROPDOWN_ITEMS}
							onItemSelect={handleRoleDropdownItemSelect}
						/>
					</div>
				</div>
				<div className={classes['row']} style={{ justifyContent: 'space-between' }}>
					<div className={classes['row']} style={{ marginTop: isSmallerDevice ? 0 : '0.5rem' }}>
						{checked.length !== 0 && checked[0] !== me.id && (
							<>
								<button
									className={classes['button']}
									style={{
										backgroundColor: colors.secondaryText,
										borderColor: colors.secondaryText,
										marginRight: '1rem',
									}}
									onClick={handleSuspend}
								>
									<Image
										src="icLock"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Toggle Suspend</Typography>
								</button>
								<button
									className={classes['button']}
									style={{
										backgroundColor: '#FCD3D1',
										borderColor: '#FF3B30',
									}}
									onClick={handleDelete}
								>
									<Image
										src="icTrashDanger"
										style={{
											marginRight: '0.5rem',
										}}
									/>
									<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="danger">
										Delete
									</Typography>
								</button>
							</>
						)}
					</div>
				</div>
				<AdminsDataTable admins={paginatedData} checkedId={checked[0] ?? -1} checkAction={handleCheckAction} />
				<Pagination {...paginationProps} />
			</div>
		</>
	);
};

export default AdminsScreen;
