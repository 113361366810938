import React, { useEffect, useRef, useState } from 'react';
import { Image } from '..';
import { DropdownItem } from '../types';
import { useOnOutClick } from 'hooks';
import classes from './index.module.scss';

interface Props<T> {
	title: string;
	list: DropdownItem<T>[];
	onItemSelect?: (value: T) => void;
	onSelectReset?: () => void;
}

const DropdownButton = <T extends string | number>({ list, title, onItemSelect }: Props<T>) => {
	const [listItems, setListItems] = useState(list);
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState<typeof list[0]>();
	const ref = useRef<HTMLDivElement>(null);

	const closeDropdown = () => setIsOpen(false);
	const toggleDropdown = () => setIsOpen((prev) => !prev);

	useEffect(() => {
		setListItems(list);
	}, [list]);

	useEffect(() => {
		if (selected && onItemSelect) {
			onItemSelect(selected.value);
		}
	}, [selected, onItemSelect]);

	useOnOutClick({ ref, onOutClick: closeDropdown });

	const handleItemSelect = (item: typeof list[0]) => {
		setSelected(item);
		closeDropdown();
	};

	return (
		<div ref={ref} className={classes['selectButtonDropdown-container']}>
			<a onClick={toggleDropdown} className={classes['selectButtonDropdown-header']}>
				<p className={classes['selectButtonDropdown-headerTitle']}>{selected?.label ?? title}</p>
				<Image src="icChevron" />
			</a>
			{isOpen && (
				<ul className={classes['selectButtonDropdown-list']}>
					{listItems.map((item) => (
						<li key={item.value} className={classes['selectButtonDropdown-listItem']}>
							<a onClick={() => handleItemSelect(item)}>{item.label}</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default DropdownButton;
