import React, { forwardRef } from 'react';
import Avatar from '../Avatar';
import { ImageSource } from '../types';

interface Props {
	src: ImageSource | string;
}

// eslint-disable-next-line react/display-name
const CustomMarker = forwardRef(({ src }: Props, ref: React.LegacyRef<HTMLDivElement>) => {
	return (
		<div
			ref={ref}
			style={{
				height: '40px',
				width: '40px',
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				border: '1px solid #0CB9C3',
			}}
		>
			<Avatar size={32} src={src} />
		</div>
	);
});

export default CustomMarker;
