import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import Api, { routes } from 'lib/api';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<number> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'restricted_vendors',
	initialState,
	reducers: {
		restrictedVendorsFetched: (state, { payload }: PA<number[]>) => {
			state.list = payload;
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchRestrictedVendors = (hospitalId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_VENDOR_LIST_RESTRICTED(hospitalId), method: 'GET' }));

		const { data, error } = await Api.hospitals.vendors.listRestricted(hospitalId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_VENDOR_LIST_RESTRICTED(hospitalId),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.restrictedVendorsFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_VENDOR_LIST_RESTRICTED(hospitalId),
					method: 'GET',
					message: 'Restricted vendors fetched successfully',
					payload: { count: data!.length },
				})
			);
		}
	};
};

const restrictVendors = (hospitalId: number, vendors: number[]): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_VENDOR_RESTRICT(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.vendors.restrict(hospitalId, vendors);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_VENDOR_RESTRICT(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_VENDOR_RESTRICT(hospitalId),
					method: 'POST',
					message: 'Added Vendor(s) to restricted list',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchRestrictedVendors(hospitalId));
		}
	};
};

const unRestrictVendors = (hospitalId: number, vendors: number[]): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.HOSPITAL_VENDOR_UNRESTRICT(hospitalId), method: 'POST' }));

		const { data, error } = await Api.hospitals.vendors.unrestrict(hospitalId, vendors);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.HOSPITAL_VENDOR_UNRESTRICT(hospitalId),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.HOSPITAL_VENDOR_UNRESTRICT(hospitalId),
					method: 'POST',
					message: 'Removed Vendor(s) from restricted list',
					payload: { ...data! },
				})
			);
			dispatch(actions.fetchRestrictedVendors(hospitalId));
		}
	};
};

export const restrictedVendorThunks = {
	fetchRestrictedVendors,
	restrictVendors,
	unRestrictVendors,
};
