import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Image, LoadingIndicator } from 'lib/components';
import { AdminTypeSelect, TextField } from 'lib/components/FormFields';
import PermissionRow from './PermissionRow';
import { useAppDispatch, actions, useSelectRequest, useSelectAdmin, RequestManager } from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { User, AllCheckedStatus } from 'types';
import { PermissionType, PERMISSIONS, Permission } from 'lib/util';
import selectImageHandler from 'lib/util/selectImageHandler';
import classes from './index.module.scss';

interface Props {
	admin: User;
}

const AdminEditScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const getAdminById = useSelectAdmin();

	const [admin, setAdmin] = useState<User>();

	useEffect(() => {
		dispatch(actions.fetchAdmin(parseInt(id)));
	}, []);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.ADMIN_ID(parseInt(id)), 'GET')) {
			if (RM.succeeded()) {
				const admin = getAdminById(parseInt(id));
				if (admin) setAdmin(admin);
			}
		}
	}, [active]);

	return admin ? <AdminEditScreenView admin={admin} /> : <LoadingIndicator />;
};

const AdminEditScreenView = ({ admin }: Props) => {
	const adminProfileImageRef = useRef<HTMLInputElement>(null);
	const toast = useCustomToast();
	const { push } = useHistory();
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState(admin.name);
	const [adminType, setAdminType] = useState<number | undefined>(admin.roleId);
	const [email, setEmail] = useState(admin.email);
	const [phoneNumber, setPhoneNumber] = useState(admin.phoneNumber ?? '');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
	const [profileImageString, setProfileImageString] = useState<any>('plAvatar');
	const [permissions, setPermissions] = useState<PermissionType[]>(admin.permissions);
	const [allCheckedStatus, setAllCheckedStatus] = useState<AllCheckedStatus>(
		admin.permissions.length !== 0 ? 'some' : 'none'
	);

	useEffect(() => {
		const RM = new RequestManager(active);
		if (RM.idle()) return;

		if (RM.req(routes.ADMIN_ID(admin.id), 'PUT')) {
			if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
				push('/admins');
			}
		}

		if (RM.req(routes.UPLOAD_ID(admin.id), 'POST')) {
			if (RM.pending()) {
				toast('Uploading avatar...', 'info');
			} else if (RM.failed()) {
				toast(active.message, 'error');
			} else if (RM.succeeded()) {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		if (adminType === 1) {
			setAllCheckedStatus('none');
			setPermissions([]);
		}
	}, [adminType]);

	const handleCheckAction = (id: PermissionType, isChecked: boolean) => {
		setAllCheckedStatus('some');

		if (isChecked && !permissions.includes(id)) {
			setPermissions((prev) => [id, ...Permission.addListAndReadPermissions(prev, id)]);
			return;
		}

		if (!isChecked && permissions.includes(id)) {
			setPermissions((prev) => [...Permission.removeCreateAndUpdatePermissions(prev, id)]);
		}
	};

	const handleUpdate = () => {
		dispatch(
			actions.updateAdmin(admin.id, {
				name,
				roleId: adminType ? adminType : 2,
				dateOfBirth: admin.dateOfBirth,
				email,
				permissions,
				phoneNumber: phoneNumber !== '' ? phoneNumber : undefined,
				newPassword,
				newPasswordConfirmation,
			})
		);
	};

	const handleFileSelect = selectImageHandler((file, fileURL) => {
		setProfileImageString(fileURL);
		dispatch(actions.updateAdminAvatar(admin.id, file));
	});

	useComprehensiveTopNav(`${admin.name} - Edit Admin Profile`);

	return (
		<div
			className={classes['profile-container']}
			style={{
				flexDirection: 'column',
			}}
		>
			<>
				<div className={classes['top-container']}>
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<div className={classes['small-circle']}>
								<div
									onClick={() => adminProfileImageRef.current?.click()}
									className={classes['inner-circle']}
									style={{ borderColor: '#0CB9C3' }}
								>
									<Image
										src={'icImageUpload'}
										style={{ width: 12, height: 12, objectFit: 'contain' }}
									/>
									<input
										style={{ display: 'none' }}
										onChange={handleFileSelect}
										ref={adminProfileImageRef}
										type="file"
										id="adminProfileImage"
										name="adminProfileImage"
										accept="image/*"
									/>
								</div>
							</div>
							<Image src={admin.avatar ?? profileImageString} style={{ width: '100%', height: '100%' }} />
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Name:
							</Typography>
							<input
								className={classes['input']}
								style={{
									width: '15rem',
									minWidth: 250,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<AdminTypeSelect value={adminType} setValue={setAdminType} />
						</div>
					</div>
				</div>

				<div className={classes['section']}>
					<div className={classes['section-group']}>
						<Typography weight="bold">Basic info:</Typography>
						<div className={classes['section-groupFields']}>
							<TextField
								placeholder="+1 75 85 41 223"
								label="Mobile Number:"
								variant="labelled"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							<TextField
								placeholder="reginasmith@spencerhospital.com"
								label="Email:"
								variant="labelled"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes['section-group']}>
						<Typography weight="bold">Set New Password:</Typography>
						<div className={classes['section-groupFields']}>
							<TextField
								placeholder="*****************"
								label="Set New Password:"
								variant="labelled"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<TextField
								placeholder="*****************"
								label="Confirm New Password:"
								variant="labelled"
								value={newPasswordConfirmation}
								onChange={(e) => setNewPasswordConfirmation(e.target.value)}
							/>
						</div>
					</div>
				</div>

				{adminType === 2 && (
					<div className={classes['permissions']}>
						<Typography style={{ paddingLeft: '2rem' }} weight="bold">
							Edit Roles Permissions
						</Typography>
						<div className={classes['permissions-table']}>
							<div className={classes['permissions-tableRow']}>
								<div className={classes['permissions-tableCol']}>
									<Typography weight="bold">Role</Typography>
								</div>
								<div className={classes['permissions-tableCol']}>
									<Typography weight="bold">Permissions</Typography>
								</div>
								<div className={classes['permissions-tableCol']} />
							</div>
							{PERMISSIONS.map((permission) => (
								<PermissionRow
									key={permission}
									permission={permission}
									list={permissions}
									status={allCheckedStatus}
									checkAction={handleCheckAction}
								/>
							))}
						</div>
					</div>
				)}

				<div
					className={classes['row']}
					style={{
						margin: 'auto 2rem 1rem',
					}}
				>
					<button
						className={classes['button']}
						style={{
							width: 'auto',
							marginRight: '1rem',
						}}
						onClick={handleUpdate}
					>
						<p>Update Profile</p>
					</button>
					<button
						className={`${classes['button']} ${classes['palewhite']}`}
						style={{
							width: 'auto',
						}}
						onClick={() => push('/admins')}
					>
						<p>Cancel</p>
					</button>
				</div>
			</>
		</div>
	);
};

export default AdminEditScreen;
